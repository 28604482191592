export const fetchRoles = ({ commit }, userId) => {
    return axios.get(`/api/admin/users/${userId}/roles`).then((response) => {
        commit('SET_ROLES_DATA', response.data)
    })
}

export const fetchCompanyRoles = ({ commit }, companyId) => {
    return axios.get(`/api/admin/companies/${companyId}/roles`).then((response) => {
        commit('SET_COMPANY_ROLES_DATA', response.data)
    })
}
