<template>
    <div>
        <v-card>
            <v-card-text>
                {{$lang('do you really want to delete this kanban circuit?')}}<br>

                    <v-switch
                        v-model="confirmDelete"
                        :label="$lang('delete')"
                    >

                    </v-switch>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    :disabled="!confirmDelete"
                    text
                    color="red darken-1"
                    @click="deleteKanbanCircuit"
                >
                    {{$lang('delete')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../../bus";

export default {
name: "DeleteKanbanCircuit",
    data () {
        return {
            confirmDelete: false,
        }
    },

    computed: {
        ...mapGetters({
            kanbanCircuit: 'kanbanCircuits/kanbanCircuit',
            user: 'auth/user'
        })
    },

    methods: {
        ...mapActions({
            destroy: 'kanbanCircuits/destroy',
            setKanbanCircuitDialog: 'kanbanCircuits/setKanbanCircuitDialog',
        }),

        deleteKanbanCircuit () {
            this.destroy({
                kanbanCircuitId: this.kanbanCircuit.id
            }).then(() => {
                Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('kanban circuit successfully deleted')})
                this.setKanbanCircuitDialog(false)
            })
        },
    },



}
</script>

<style scoped>

</style>
