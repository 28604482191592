<template>
    <v-tabs
        v-model="selectedTab"
    >

        <v-tab href="#info">{{ $lang('info')}}</v-tab>
        <v-tab href="#delete" v-if="_.includes(me.permissions, 'edit kanban order')">{{ $lang('delete')}}</v-tab>

        <v-tab-item value="info">
            <ProcurementKanbanOrderInfo></ProcurementKanbanOrderInfo>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteKanbanOrder></DeleteKanbanOrder>
        </v-tab-item>

    </v-tabs>
</template>

<script>

import ProcurementKanbanOrderInfo from "./ProcurementKanbanOrderInfo";
import DeleteKanbanOrder from "./DeleteKanbanOrder";
import {mapGetters} from "vuex";
export default {
name: "ProcurementKanbanOrderTabs",
    components: {DeleteKanbanOrder, ProcurementKanbanOrderInfo},
    data(){
    return {
        selectedTab: 'info'
        }
    },
    computed: {
        ...mapGetters({
            me: 'auth/user',
        }),
    },
}
</script>

<style scoped>

</style>
