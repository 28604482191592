<template>
    <v-dialog
        width="600"
        v-model="showKanbanCircuitDialog"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
        v-if="kanbanCircuit"
    >
        <v-card>
            <v-card-title>
                {{$lang('kanban circuit')}}&nbsp;<span class="blue--text">{{kanbanCircuit.part_name}}</span>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-2"
                    small
                    outlined
                    color="primary"
                    @click="closeDialog"
                >
                    <v-icon >fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <ProcurementKanbanCircuitTabs :key="kanbanCircuit.id"></ProcurementKanbanCircuitTabs>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    @click="closeDialog"
                    class="mt-2"
                    text
                >
                    {{$lang('close')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProcurementKanbanCircuitTabs from "./ProcurementKanbanCircuitTabs";

export default {
    name: "ProcurementKanbanCircuitDialog",
    components: {ProcurementKanbanCircuitTabs},
    computed: {
        ...mapGetters({
            tab: 'procurementKanban/selectedTab',
            kanbanCircuit: 'procurementKanban/kanbanCircuit',
            showKanbanCircuitDialog: 'procurementKanban/showKanbanCircuitDialog'
        }),
    },

    methods: {
        ...mapActions({
            setShowKanbanCircuitDialog: 'procurementKanban/setShowKanbanCircuitDialog',
        }),

        closeDialog () {
            this.setShowKanbanCircuitDialog(false)
        },
    }
}
</script>

<style scoped>

</style>
