export const todos = (state) => {
    return state.todos
}

export const todo = (state) => {
    return state.todo
}

export const showTodoDialog = (state) => {
    return state.showTodoDialog
}

export const showCreateTodoDialog = (state) => {
    return state.showCreateTodoDialog
}

export const createTodoEndpoint = (state) => {
    return state.createTodoEndpoint
}

export const todoDateLimits = (state) => {
    return state.todoDateLimits
}

export const todoDialogWidth= (state)   => {
    return state.todoDialogWidth
}

export const selectedTab = (state) => {
    return state.selectedTab
}
