<template>
    <div>

        <v-autocomplete
            :label="$lang('kanban circuit')"
            v-model="payload.kanbanCircuitId"
            :items="kanbanCircuits"
            item-value="id"
            item-text="part_name"
            @change="submitLoadKanbanChartsData"
        >
            <template v-slot:selection="data">
              {{data.item.name}} -  {{data.item.part_name}}
            </template>

            <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.part_name"></v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>


        <v-radio-group
            v-model="payload.scale"
            @change="submitLoadKanbanChartsData"
            row
        >
            <v-radio
                :label="$lang('day')"
                color="primary"
                value="day"
            ></v-radio>
            <v-radio
                :label="$lang('week')"
                color="primary"
                value="week"
            ></v-radio>
            <v-radio
                :label="$lang('month')"
                color="primary"
                value="month"
            ></v-radio>
        </v-radio-group>
        <v-divider></v-divider>

        <v-date-picker
            v-model="payload.range"
            @change="submitLoadKanbanChartsData"
            :max="new Date().toISOString()"
            no-title
            range
            :first-day-of-week="1"
        ></v-date-picker>


        <v-row>
            <v-col>
                <v-checkbox
                    class="mt-0"
                    v-model="payload.stock_quantity"
                    :label="$lang('stock')"
                    color="rgba(244, 67, 54, 0.9)"
                    @change="submitLoadKanbanChartsData"
                ></v-checkbox>


                <v-checkbox
                    class="mt-0"
                    v-model="payload.inventory_quantity"
                    :label="$lang('inventory')"
                    color="rgba(252, 186, 3, 0.9)"
                    @change="submitLoadKanbanChartsData"
                ></v-checkbox>

                <v-checkbox
                    class="mt-0"
                    v-model="payload.withdrawal_quantity"
                    :label="$lang('withdrawal')"
                    color="rgba(244, 67, 54, 0.9)"
                    @change="submitLoadKanbanChartsData"
                ></v-checkbox>

            </v-col>
            <v-col>
                <v-checkbox
                    class="mt-0"
                    v-model="payload.ordered_quantity"
                    :label="$lang('ordered')"
                    color="rgba(21, 101, 192, 0.9)"
                    @change="submitLoadKanbanChartsData"
                ></v-checkbox>

                <v-checkbox
                    class="mt-0"
                    v-model="payload.total_ordered_quantity"
                    :label="$lang('open orders')"
                    color="rgba(110, 71, 253, 0.9)"
                    @change="submitLoadKanbanChartsData"
                ></v-checkbox>

                <v-checkbox
                    class="mt-0"
                    v-model="payload.total_received_quantity"
                    :label="$lang('goods receipt')"
                    color="rgba(0, 137, 123, 0.9)"
                    @change="submitLoadKanbanChartsData"
                ></v-checkbox>

            </v-col>

        </v-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {fetchKanbanChartsData} from "../vuex/actions";

export default {
    name: "KanbanDashboardFilter",

    data () {
        return {
            payload: {
                kanbanCircuitId: null,
                scale: 'week',
                range:  null,
                stock_quantity: true,
                inventory_quantity: true,
                withdrawal_quantity: true,
                ordered_quantity: true,
                total_ordered_quantity: true,
                total_received_quantity: true
            }
        }
    },

    methods: {
        ...mapActions({
            fetchKanbanCircuits: 'procurementDashboard/fetchKanbanCircuits',
            fetchKanbanChartsData: 'procurementDashboard/fetchKanbanChartsData'
        }),

        submitLoadKanbanChartsData() {
            this.fetchKanbanChartsData(this.payload)
        }
    },


    computed: {
        ...mapGetters({
            me: 'auth/user',
            kanbanCircuits: 'procurementDashboard/kanbanCircuits'
        }),
    },

    mounted() {
        this.payload.kanbanCircuitId = this.kanbanCircuits[0].id
        this.submitLoadKanbanChartsData()
    }
}
</script>

<style scoped>

</style>
