<template>
    <div>
        {{$lang('do you really want to delete this project?')}}<br>

        <v-btn
            v-if="!deleteProject"
            color="red darken-1"
            class="mt-5"
            @click="deleteProject = !deleteProject"
            >
            {{$lang('delete')}}
        </v-btn>
        <template v-else>
            <v-divider></v-divider>
            <small>{{$lang('to be able to delete the project, you must enter your first name.')}}</small>
            <br>
            <v-text-field
                v-model="firstName"
                :label="$lang('first name')"
            >

            </v-text-field>

            <v-switch
                v-model="confirmDelete"
                :label="$lang('delete')"
            >

            </v-switch>
            <v-btn
                v-if="readyToDelete"
                color="red darken-1"
                class="white--text mt-5"
                @click="deleteProjectFinally"
            >
                {{$lang('delete')}}
            </v-btn>

        </template>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";

export default {
name: "DeleteProject",
    data () {
        return {
            deleteProject: false,
            confirmDelete: false,
            firstName: '',
            readyToDelete: false
        }
    },

    computed: {
        ...mapGetters({
            project: 'projects/project',
            user: 'auth/user'
        }),

    },

    methods: {
        ...mapActions({
            destroy: 'projects/destroy',
        }),

        deleteProjectFinally () {
            this.destroy({
                project: this.project
            }).then(() => {
                Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('project successfully deleted')})
                this.$router.push({name: `projects`})
            })
        },
    },


    watch: {
        firstName: function (value) {
            if (this.user.first_name === value) {
                this.readyToDelete = this.confirmDelete;
            }
        },

        confirmDelete: function () {
            if (this.user.first_name === this.firstName) {
                this.readyToDelete = this.confirmDelete;
            }
        },
    }
}
</script>

<style scoped>

</style>
