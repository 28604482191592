<template>
    <v-card
     :loading="loading"
    >
        <v-card-text

        >
            <v-col
                md="6"
                cols="12"
                v-if="company.hg_settings"
            >


                <v-text-field
                    :label="$lang('HG-2')"
                    v-model="company.hg_settings.hg2"
                    :error-messages="errors.hg2"
                >

                </v-text-field>

                <v-text-field
                    :label="$lang('HG-3')"
                    v-model="company.hg_settings.hg3"
                    :error-messages="errors.hg3"
                >

                </v-text-field>

            </v-col>

        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
    name: "HGSettings",
    data () {
        return {
            loading: false,
            errors: []
        }
    },

    computed: {
        ...mapGetters({
            company: "companyAdminCompany/company",
        }),
    },

    methods: {
        ...mapActions({
            updateHGSettings: 'companyAdminCompany/updateHGSettings'
        }),

        save() {
            this.loading = true
            this.errors = []
            const self = this
            this.updateHGSettings({
                payload: this.company.hg_settings,
                context: this
            }).then(() => {
                self.loading = false
                if (this.errors.length === 0) {

                    location.reload()
                }
            })
        },
    }
}
</script>

