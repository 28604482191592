<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>

</template>
<script>
    import  { mapGetters, mapActions} from 'vuex';
    import Bus from "../../../../bus";
    export default {
        name: "CompanyPlansTable",

        data() {
            return {
                loading: true,
                datatableExists: true
            }
        },

        computed: {
            ...mapGetters({
                company: 'adminCompanies/company'
            })
        },

        methods: {
            ...mapActions({
                fetchCompanyPlan: 'adminCompanies/fetchCompanyPlan',
                setShowCompanyPlanDialog: 'adminCompanies/setShowEditCompanyPlanDialog'
            }),


        },

        mounted:function(){
            const self = this

            this.datatableExists = true

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: 600,
                id: 'companyPlans',
                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                columns: [
                    {id: "plan", header: [ self.$lang('plan'),{ content:"textFilter" }],fillspace: true, minWidth: 200,  sort: "string"},
                    {id:"start_date", map:"(date)#start_date#", header:[self.$lang('start'), { content:"dateRangeFilter"}],  adjust: true , template: function (obj){
                            return webix.i18n.parseFormatStr(obj.start_date)
                        }},
                    {id:"end_date", map:"(date)#end_date#", header:[self.$lang('end'), { content:"dateRangeFilter"}],  adjust: true , template: function (obj){
                            return webix.i18n.parseFormatStr(obj.end_date)
                        }},

                    {id: "active_label", header: [self.$lang('active'), { content:"multiSelectFilter", suggest: {fitMaster: false, width: 200} }], minWidth: 150, adjust: true , sort: "string", template: function (obj){
                            return obj.active_label
                        }},
                    {id:"created_at", map:"(date)#created_at#", header:[self.$lang('created'), { content:"dateRangeFilter"}],  adjust: true , template: function (obj){
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }}
                ],
                on: {
                    onSelectChange: function(){
                        //self.fetchCompany(this.getSelectedId(true))
                    },
                    onItemDblClick: function (id){
                        self.fetchCompanyPlan(id).then(function (){
                            self.setShowCompanyPlanDialog(true)
                        })

                    },
                    onAfterLoad: function () {
                        // if (self.company && this.exists(self.company.id)) {
                        //     this.select(self.company.id)
                        //     this.showItem(self.company.id)
                        // }
                        self.loading = false
                    },
                },

                url: `/api/admin/companies/${this.company.id}/companyPlans`

            })

            Bus.$on('companyPlan:updated', function () {
                if(self.datatableExists) {
                    self.webixId.load(`/api/admin/companies/${self.company.id}/companyPlans`)
                }
            });
        },

        watch: {

        },

        destroyed:function(){
            this.datatableExists = false
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style >
    .webix_multilist .wxi-checkbox-blank, .webix_multilist .wxi-checkbox-marked {
        font-size: 24px;
        width: 24px;
        vertical-align: middle;
        margin-right: 4px;
        color: #1565C0 ;
    }
</style>
