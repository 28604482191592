<template>
    <div v-if="show">
        <template v-if="type === 'invest'">
            {{ $lang('invest')}}: {{ $num(invest) }} {{ company.currency}}
        </template>
        <template v-if="type === 'potential'">
            {{ $lang('potential')}}: {{ $num(potential) }} {{ company.currency}}
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "CalculateAndShowPotential",

    data() {
        return {
            potential: 0,
            invest: 0,
            type: ''
        }
    },

    props: [
        'form',
        'show'
    ],

    computed: {
        ...mapGetters({
            company: 'companyAdminCompany/company'
        }),
    },

    methods: {
        calculateTimePotential: function () {
            let unit

            switch (this.form.unit) {
                case 'seconds':
                    unit =  3600
                    break
                case 'minutes':
                    unit =  60
                    break
                case 'hours':
                    unit =  1
                    break
                default :
                    unit =  0
            }


            let potential = (((this.form.current / unit * this.form.hourly_rate_staff) + (this.form.current / unit * this.form.hourly_rate_machine)) -
                ((this.form.target / unit * this.form.hourly_rate_staff) + (this.form.target / unit * this.form.hourly_rate_machine))) * this.form.quantity * (this.form.chance / 100)

            this.showPotential(potential)
        },

        calculateTimeAbsolutPotential: function () {
            let unit

            switch (this.form.unit) {
                case 'seconds':
                    unit =  3600
                    break
                case 'minutes':
                    unit =  60
                    break
                case 'hours':
                    unit =  1
                    break
                default :
                    unit =  0
            }

            let potential = ((this.form.target / unit * this.form.hourly_rate_staff) + (this.form.target / unit * this.form.hourly_rate_machine)) * this.form.quantity * (this.form.chance / 100)

            this.showPotential(potential)
        },

        calculateCostPotential: function () {
            let potential = (parseFloat(this.form.material_single_cost_savings) * this.form.quantity + parseFloat(this.form.material_common_cost_savings)) * (this.form.chance / 100);

            this.showPotential(potential)
        },

        calculateCapitalPotential: function () {
            let potential = (parseFloat(this.form.current) - parseFloat(this.form.target)) * (parseFloat(this.form.interest)/100) * (this.form.chance / 100)

            this.showPotential(potential)
        },

        calculateSalePotential: function () {
            let potential = (parseFloat(this.form.target) - parseFloat(this.form.current)) * (parseFloat(this.form.ebit)/100) * (this.form.chance / 100)

            this.showPotential(potential)
        },

        calculateAreaPotential: function () {
            let potential = (parseFloat(this.form.current) - parseFloat(this.form.target)) * parseFloat(this.form.price) * (this.form.chance / 100)

            this.showPotential(potential)
        },

        calculateUniquePotential: function () {
            let potential = parseFloat(this.form.price) * (this.form.chance / 100)

            this.showPotential(potential)
        },

        calculateMaterialInvest: function () {
            let invest = parseFloat(this.form.purchasing_cost) * this.form.quantity ;

            this.showInvest(invest)
        },

        calculateWorkInvestIntern: function () {
            let invest = parseFloat(this.form.hourly_rate) * parseFloat(this.form.time_invest) ;

            this.showInvest(invest)
        },

        calculateWorkInvestExtern: function () {
            let invest = parseFloat(this.form.hourly_rate) * parseFloat(this.form.time_invest) ;

            this.showInvest(invest)
        },

        showPotential: function (potential) {
            if(!isNaN(potential)) {
                this.potential = potential.toFixed(2)
            }
            else {
                this.potential = 0
            }
            this.$emit("update-potential", this.potential);
        },

        showInvest: function (invest) {
            if(!isNaN(invest)) {
                this.invest = invest.toFixed(2)
            }
            else {
                this.invest = 0
            }
            this.$emit("update-invest", this.invest);
        }
    },


    watch: {
        form: {
            handler: function() {
                switch (this.form.type) {
                    case 'time':
                        this.type = 'potential'
                        this.calculateTimePotential()
                        break
                    case 'timeAbsolut':
                        this.type = 'potential'
                        this.calculateTimeAbsolutPotential()
                        break
                    case 'cost':
                        this.type = 'potential'
                        this.calculateCostPotential()
                        break
                    case 'capital':
                        this.type = 'potential'
                        this.calculateCapitalPotential()
                        break
                    case 'sale':
                        this.type = 'potential'
                        this.calculateSalePotential()
                        break
                    case 'area':
                        this.type = 'potential'
                        this.calculateAreaPotential()
                        break
                    case 'unique':
                        this.type = 'potential'
                        this.calculateUniquePotential()
                        break
                    case 'materialInvest':
                        this.type = 'invest'
                        this.calculateMaterialInvest()
                        break
                    case 'workInvestIntern':
                        this.type = 'invest'
                        this.calculateWorkInvestIntern()
                        break
                    case 'workInvestExtern':
                        this.type = 'invest'
                        this.calculateWorkInvestExtern()
                        break
                }

            },
            deep: true,
            immediate: true
        },
    },
}
</script>

<style scoped>

</style>
