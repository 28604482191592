export const fetchSuppliers = ({ commit }) => {
    return axios.get('/api/masterData/suppliers').then((response) => {
        commit('SET_SUPPLIERS_DATA', response.data)
        let activeSuppliers = response.data.filter((suppliers)=> {
            return suppliers.active;
        })
        commit('SET_ACTIVE_SUPPLIERS_DATA', activeSuppliers)
    })
}

export const fetchSupplier = ({ commit }, id) => {
    return axios.get(`/api/masterData/suppliers/${id}`).then((response) => {
        commit('setSupplierData', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/masterData/suppliers', payload).then((response) => {
        commit('setSupplierData', response.data)
        dispatch('fetchSuppliers')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/masterData/suppliers/${payload.id}`, payload).then((response) => {
        commit('setSupplierData', response.data)
        dispatch('fetchSuppliers')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, {id, context} ) => {
    return axios.delete(`/api/masterData/suppliers/${id}`).catch((error) => {
        context.error = true
    })
}

export const setCreateSupplierDialog = ({ dispatch, state, commit }, value) => {
    commit('setCreateSupplierDialog', value)
}
