<template>
    <div>
        <v-card v-if="showControllingSelectFilter" class="mb-5">
            <v-card-text>
                <v-row>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.location_ids"
                            :items="locations"
                            :label="$lang('locations') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.location_ids = []"
                            item-text="name"
                            item-value="id"
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.division_ids"
                            :items="divisions"
                            :label="$lang('divisions') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.division_ids = []"
                            item-text="name"
                            item-value="id"
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.department_ids"
                            :items="departments"
                            :label="$lang('departments') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.department_ids = []"
                            item-text="name"
                            item-value="id"
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.workplace_ids"
                            :items="workplaces"
                            :label="$lang('workplaces') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.workplace_ids = []"
                            item-text="name"
                            item-value="id"
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.project_ids"
                            :items="projects"
                            :label="$lang('projects') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.project_ids = []"
                            item-text="name"
                            item-value="id"
                            :disabled="filtering"
                        >
                        </v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.project_type_ids"
                            :items="projectTypes"
                            :label="$lang('project types') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.project_type_ids = []"
                            item-text="name"
                            item-value="id"
                            :disabled="filtering"
                        ></v-select>
                    </v-col>


                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.project_leader_ids"
                            :items="projectLeaders"
                            :label="$lang('project leader') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.project_leader_ids = []"
                            item-text="full_name"
                            item-value="id"
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.project_status_ids"
                            :items="projectStatuses"
                            :label="$lang('project status') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.project_status_ids = []"
                            item-text="name"
                            item-value="id"
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.priorities"
                            :items="priorities"
                            :label="$lang('prio')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.priorities = []"
                            item-text="name"
                            item-value="id"
                            :disabled="filtering"
                        >
                        </v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.issue_assignee_ids"
                            :items="issueAssignees"
                            :label="$lang('responsible') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.issue_assignee_ids = []"
                            item-text="full_name"
                            item-value="id"
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.issue_user_ids"
                            :items="users"
                            :label="$lang('team') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.issue_user_ids = []"
                            item-text="full_name"
                            item-value="id"
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            multiple
                            v-model="filter.issue_due_date_statuses"
                            :items="issueDueDateStatuses"
                            :label="$lang('issues status')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.issue_due_date_statuses = []"
                            item-text="name"
                            item-value="id"
                            :disabled="filtering"
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            v-model="filter.issue_hardness_degrees"
                            :items="hardnessDegrees"
                            :label="$lang('hardness degree') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.issue_hardness_degrees = []"
                            item-text="name"
                            item-value="value"
                            multiple
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            v-model="filter.cost_center_ids"
                            :items="costCenters"
                            :label="$lang('cost center') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.cost_center_ids = []"
                            item-text="name"
                            item-value="id"
                            multiple
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            v-model="filter.product_ids"
                            :items="products"
                            :label="$lang('products') "
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.product_ids = []"
                            item-text="name"
                            item-value="id"
                            multiple
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                        <v-select
                            v-model="filter.issue_tag_ids"
                            :items="issueTags"
                            :label="`${ $lang('issue tags') } `"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="filter.issue_tag_ids = []"
                            item-text="name"
                            item-value="id"
                            multiple
                            :disabled="filtering"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Bus from "../../../bus";
export default {
    name: "ControllingSelectFilter",
    data () {
        return {
            filteredProjectData: [],
            filteredIssuesData: [],
            filter: null
        }
    },

    props: {
        dashboardId: String
    },

    computed: {
        ...mapGetters({
            users: 'users/users',
            showControllingSelectFilter: 'controlling/showControllingSelectFilter',
            projectsData: 'projects/projects',
            dashboardIssuesData: 'controlling/dashboardIssuesData',
            hardnessDegrees: 'hardnessDegrees/hardnessDegrees',
            defaultFilterData: 'controlling/defaultFilterData',
            activeFilters: 'controlling/activeFilters',
            filtering: 'controlling/filtering',
            currentFilter: 'controlling/currentFilter'
        }),

        issuesData: function () {
            return _.flatten(_.map(this.projectsData, 'issues'))
        },

        locations: function () {
            let locations = []
            _.each(this.projectsData,function(value, key){
                if(value.location_id) {
                    locations.push({
                            id: value.location_id,
                            name: value.location_name
                        }
                    );
                }
            });

            return _.uniqBy(locations, 'id');
        },

        divisions: function () {
            let divisions = []
            _.each(this.projectsData,function(value, key){
                if(value.division_id) {
                    divisions.push({
                            id: value.division_id,
                            name: value.division_name
                        }
                    );
                }
            });

            return _.uniqBy(divisions, 'id');
        },

        departments: function () {
            let departments = []
            _.each(this.projectsData,function(value, key){
                if(value.department_id) {
                    departments.push({
                            id: value.department_id,
                            name: value.department_name
                        }
                    );
                }
            });

            return _.uniqBy(departments, 'id');
        },

        projectTypes: function () {
            let projectTypes = []
            _.each(this.projectsData,function(value, key){
                if(value.project_type) {
                    projectTypes.push({
                            id: value.project_type.id,
                            name: value.project_type.name
                        }
                    );
                }

            });
            return _.uniqBy(projectTypes, 'id');
        },

        projects: function () {
            let projects = []
            _.each(this.projectsData,function(value, key){
                projects.push({
                        id: value.id,
                        name: value.name
                    }
                );
            });

            return _.uniqBy(projects, 'id');
        },

        projectLeaders: function () {
            let projectLeaders = []
            _.each(this.projectsData,function(value, key){
                if(value.project_leader_name) {
                    projectLeaders.push({
                            id: value.project_leader_id,
                            full_name: value.project_leader_name
                        }
                    );
                }
            });
            return _.uniqBy(projectLeaders, 'id');
        },

        projectStatuses: function () {
            let projectStatuses = []
            _.each(this.projectsData,function(value, key){
                if(value.project_status) {
                    projectStatuses.push({
                            id: value.project_status.id,
                            name: value.project_status.name
                        }
                    );
                }

            });
            return _.uniqBy(projectStatuses, 'id');
        },

        priorities: function () {
            return  [
                    {id: 'low', name: this.$lang('low')},
                    {id: 'mid', name: this.$lang('mid')},
                    {id: 'high', name: this.$lang('high')}
                ]

        },

        issueDueDateStatuses: function () {
            return  [
                {id: 'draft', name: this.$lang('draft')},
                {id: 'ongoing', name: this.$lang('ongoing')},
                {id: 'delay threatens', name: this.$lang('delay threatens')},
                {id: 'delay', name: this.$lang('delay')},
                {id: 'checking', name: this.$lang('checking')},
                {id: 'closed', name: this.$lang('closed')}
            ]
        },

        issueAssignees: function () {
            let issueAssignees = []
            _.each(this.issuesData,function(value, key){
                if(value.assignee_id !== null) {
                    issueAssignees.push({
                            id: value.assignee.id,
                            full_name: value.assignee.full_name
                        }
                    );
                }

            });
            return _.uniqBy(issueAssignees, 'id');
        },

        workplaces: function () {
            let workplaces = []
            _.each(this.issuesData,function(value, key){
                if(value.workplace) {
                    workplaces.push({
                            id: value.workplace.id,
                            name: value.workplace.name
                        }
                    );
                }
            });

            return _.uniqBy(workplaces, 'id');
        },

        costCenters: function () {
            let costCenters = []
            _.each(this.issuesData,function(value, key){
                if(value.cost_center) {
                    costCenters.push({
                            id: value.cost_center.id,
                            name: value.cost_center.name
                        }
                    );
                }

            });
            return _.uniqBy(costCenters, 'id');
        },

        products: function () {
            let products = []
            _.each(this.issuesData,function(value, key){
                if(value.product) {
                    products.push({
                            id: value.product.id,
                            name: value.product.name
                        }
                    );
                }

            });
            return _.uniqBy(products, 'id');
        },

        issueTags: function () {
            let issueTagsData = _.flatten(_.map(this.issuesData, 'issue_tags'))
            let issueTags = []
            _.each(issueTagsData,function(value, key){
                issueTags.push({
                        id: value.id,
                        name: value.name
                    }
                );
            });
            return _.uniqBy(issueTags, 'id');
        }
    },

    methods: {
        ...mapActions({
            setDashboardIssuesData: 'controlling/setDashboardIssuesData',
            fetchProjects: 'projects/fetchProjects',
            setActiveFilter: 'controlling/setActiveFilter',
            setFiltering: 'controlling/setFiltering'
        }),


        async filterData() {
            const self = this

            this.setFiltering(true)

            let filter = new Promise((resolve, reject) => {
                this.filteredProjectData = _.filter(this.projectsData, function(o) {

                    if(self.filter.location_ids.length) {
                        if(!_.includes(self.filter.location_ids, o.location_id) ) {
                            return false
                        }
                    }

                    if(self.filter.division_ids.length) {
                        if(!_.includes(self.filter.division_ids, o.division_id)) {
                            return false
                        }
                    }

                    if(self.filter.department_ids.length) {
                        if(!_.includes(self.filter.department_ids, o.department_id)) {
                            return false
                        }
                    }

                    if(self.filter.project_type_ids.length) {
                        if(!_.includes(self.filter.project_type_ids, o.project_type_id)) {
                            return false
                        }
                    }

                    if(self.filter.project_leader_ids.length) {
                        if(!_.includes(self.filter.project_leader_ids, o.project_leader_id)) {
                            return false
                        }
                    }

                    if(self.filter.project_ids.length) {
                        if(!_.includes(self.filter.project_ids, o.id)) {
                            return false
                        }
                    }

                    if(self.filter.project_status_ids.lengthl) {
                        if(!_.includes(self.filter.project_status_ids, o.project_status_id)) {
                            return false
                        }
                    }

                    return true
                });


                let issues = _.flatten(_.map(this.filteredProjectData, 'issues'))

                self.filteredIssuesData = _.filter(issues, function(o) {

                    if(self.filter.issue_hardness_degrees.length) {
                        if(!_.includes(self.filter.issue_hardness_degrees, o.hardness_degree)) {
                            return false
                        }
                    }

                    if(self.filter.priorities.length) {
                        if(!_.includes(self.filter.priorities, o.priority)) {
                            return false
                        }
                    }

                    if(self.filter.issue_due_date_statuses.length) {
                        if(!_.includes(self.filter.issue_due_date_statuses, o.due_date_status)) {
                            return false
                        }
                    }

                    if(self.filter.issue_assignee_ids.length) {
                        if(!_.includes(self.filter.issue_assignee_ids, o.assignee.id)) {
                            return false
                        }
                    }

                    if(self.filter.issue_user_ids.length) {
                        let includes = false
                        _.each(o.user_ids,function(value, key){

                            if(_.includes(self.filter.issue_user_ids, value)) {
                                includes = true
                            }
                        });
                        return includes
                    }

                    if(self.filter.workplace_ids.length) {
                        if(!_.includes(self.filter.workplace_ids, o.workplace_id)) {
                            return false
                        }
                    }

                    if(self.filter.cost_center_ids.length) {
                        if(!_.includes(self.filter.cost_center_ids, o.cost_center_id)) {
                            return false
                        }
                    }

                    if(self.filter.product_ids.length) {
                        if(!_.includes(self.filter.product_ids, o.product_id)) {
                            return false
                        }
                    }

                    if(self.filter.issue_tag_ids.length) {

                        if(!_.intersection(self.filter.issue_tag_ids, o.issue_tag_ids).length) {
                            return false
                        }
                    }

                    return true
                });
                resolve()
            });

            return await filter
        },
    },

    watch: {
        currentFilter: {
            handler: function(value) {
                this.filter = this.currentFilter
                const self = this
                this.filterData().then(function () {
                    self.setDashboardIssuesData(self.filteredIssuesData)
                    self.setFiltering(false)
                })
            },
            deep:true,
            immediate: true
        },

        projectsData: {
            handler: function() {
                this.setActiveFilter({dashboardId: this.dashboardId, filter: this.filter})
                const self = this
                this.filterData().then(function () {
                    self.setDashboardIssuesData(self.filteredIssuesData)
                    self.setFiltering(false)
                })
            },
            deep:true,
        }
    }
}
</script>

<style scoped>

</style>
