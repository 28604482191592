<template>
    <div class="container">
        <Bar :chart-data="kanbanChartsData.chartData" :chart-options="options" />
    </div>
</template>
<script>

import { Bar } from 'vue-chartjs'
import {mapGetters} from "vuex";
import { Chart, LineController, LineElement, BarController, BarElement,  PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from 'chart.js';

Chart.register(LineController, LineElement, BarController, BarElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend);
export default {
    name: "KanbanCircuitLogChart",

    components: { Bar },

    props: {
        height: Number,
    },

    computed: {
        ...mapGetters({
            me: 'auth/user',
            kanbanChartsData: 'procurementDashboard/kanbanChartsData'
        }),
    },

        data () {
            return {
                options: {
                    scales: {
                        y: {
                            ticks: {
                                min: 0,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.$lang('number quantity')
                            }
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false
            }
        },

        // methods: {
        //     render() {
        //         this.renderChart(this.kanbanChartsData.chartdata, this.options)
        //     },
        // },
        //
        // watch: {
        //     kanbanChartsData: {
        //         handler: function() {
        //             if(this.kanbanChartsData.chartdata) {
        //                 this.render()
        //             }
        //         },
        //         deep: true
        //     },
        // },
    }
</script>

<style scoped>

</style>



