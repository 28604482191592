<template>
    <FileBrowser :endpoint="`/api/projects/${project.id}/fileManager`"></FileBrowser>

</template>

<script>
import {mapGetters} from "vuex";
import FileBrowser from "../../fileManager/components/FileBrowser";

export default {
name: "ProjectFiles",
    components: {FileBrowser},
    computed: {
        ...mapGetters({
            project: 'projects/project'
        }),
    }
}
</script>

<style scoped>

</style>
