<template>
    <v-menu
        v-if="_.includes(me.permissions, 'create project')"
        offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                small
                outlined
                v-bind="attrs"
                v-on="on"
                class="ml-2"
            >
                <v-icon>
                    fas fa-ellipsis-v
                </v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                @click="copyProject(true)"
            >
                <v-list-item-title>
                    {{ $lang('copy project')}}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
name: "ProjectActionMenu",


    computed: {
        ...mapGetters({
            me: 'auth/user',
        }),
    },

    methods: {
        ...mapActions({
            setShowCreateProjectDialog: 'projects/setCreateProjectDialog',
            setCopyProject: 'projects/setCopyProject',
        }),

        copyProject(value) {
            this.setShowCreateProjectDialog(value)
            this.setCopyProject(value)
        }
    },

}
</script>

<style scoped>

</style>
