import { Notes } from '../views'

export default [
    {
        path: '/notes',
        component: Notes,
        name: 'notes',
        meta: {
            guest: false,
            needsAuth: true
        }
    },


]
