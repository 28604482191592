<template>
    <div>
        <v-btn
            small
            color="primary"
            dark
            @click.stop="dialog = true"
        >
            <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('company')}}
        </v-btn>

        <v-dialog
            v-model="dialog"
            max-width="600"
        >
            <v-card>
                <v-card-title class="headline"> {{ $lang('create Company') }}</v-card-title>

                <v-card-text>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-text-field
                            v-model="form.street"
                            :label="$lang('address')"
                            :error-messages="errors.street"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.zip"
                            :label="$lang('zip')"
                            :error-messages="errors.zip"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.city"
                            :label="$lang('city')"
                            :error-messages="errors.city"
                        ></v-text-field>
                        <v-radio-group
                            v-model="form.name_format"
                            :error-messages="errors.name_format"
                            row
                        >
                            <v-radio
                                label="Max Muster"
                                value="first_name|last_name|&nbsp;"
                            ></v-radio>
                            <v-radio
                                label="Muster Max"
                                value="last_name|first_name|&nbsp;"
                            ></v-radio>
                            <v-radio
                                label="Muster, Max"
                                value="last_name|first_name|,&nbsp;"
                            ></v-radio>
                        </v-radio-group>
                        <v-radio-group
                            v-model="form.currency"
                            :error-messages="errors.currency"
                            row
                        >
                            <v-radio
                                label="€"
                                value="€"
                            ></v-radio>
                            <v-radio
                                label="CHF"
                                value="CHF"
                            ></v-radio>
                            <v-radio
                                label="$"
                                value="$"
                            ></v-radio>
                            <v-radio
                                label="PLN"
                                value="PLN"
                            ></v-radio>
                        </v-radio-group>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        {{ $lang('cancel') }}
                    </v-btn>

                    <v-btn
                        color="primary"
                        text
                        @click="save"
                    >
                        {{ $lang('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "CreateCompanyDialog",
    data() {
        return {
            dialog: false,
            errors: [],
            form: {
                name: '',
                street:'',
                zip: '',
                city: '',
                name_format: "first_name|last_name|&nbsp;",
                currency: '€'
            }
        }
    },

    methods: {
        ...mapActions({
            store: 'adminCompanies/store',
        }),

        save () {
            this.errors = []
            this.store({
                payload: this.form,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.$refs.form.reset()
                    this.dialog = false
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
