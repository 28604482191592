<template>
<div>
    <v-toolbar flat color="white" >
        <v-toolbar-title >{{ $lang('dashboard')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn-toggle
            v-if="$companyPlan('solex')"
            class="mr-2"
            v-model="showWorkingHours"
            mandatory
        >
            <v-btn
                small
                color="primary"
                outlined
            >
                {{ user.company.currency}}
            </v-btn>
            <v-btn
                small
                color="primary"
                outlined
            >
                {{$lang('hours')}}
            </v-btn>
        </v-btn-toggle>
        <DashboardFilterDropDown dashboard-id="dashboard" v-if="projects.length && dashboardFilters"></DashboardFilterDropDown>
        <v-btn
            small
            color="primary"
            :outlined="showControllingSelectFilter"
            @click="toggleControllingSelectFilter()"
        >
            <template v-if="!showControllingSelectFilter">
                {{$lang('show selects')}}
            </template>
            <template v-else>
                {{$lang('hide selects')}}
            </template>

        </v-btn>
    </v-toolbar>

    <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
    ></v-progress-linear>
    <ControllingSelectFilter dashboard-id="dashboard" v-if="projects.length"></ControllingSelectFilter>

    <v-row

    >
        <v-col
            cols="12"
            md="6"
        >
            <v-card
                class="mb-2"
                v-if="!showWorkingHours"
            >
                <v-card-title
                    class="pb-0"
                >
                    {{ $lang('saving potential') }} {{ user.company.currency}}
                </v-card-title>
                <v-card-text
                >
                    <BruttoPotentialWithHardnessDegree :filtered-issues-data="dashboardIssuesData" :height="150" :initial-render="true"></BruttoPotentialWithHardnessDegree>

                </v-card-text>
            </v-card>

            <v-card
                class="mb-2"
                v-if="showWorkingHours"
            >
                <v-card-title
                    class="pb-0"
                >
                    {{ $lang('saving potential hours') }}

                </v-card-title>
                <v-card-text
                >
                    <HoursPotentialWithHardnessDegree :filtered-issues-data="dashboardIssuesData" :height="150" :initial-render="true"></HoursPotentialWithHardnessDegree>

                </v-card-text>
            </v-card>
        </v-col>

        <v-col
            cols="12"
            md="6"
        >
            <v-card
                class="mb-2"
            >
                <v-card-title
                    class="pb-0"
                >
                    {{$lang('issues' )}}
                </v-card-title>
                <v-card-text
                >
                    <IssuesAndTodosWithStatus :filtered-issues-data="dashboardIssuesData" :height="150" :initial-render="true"></IssuesAndTodosWithStatus>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <v-row
        v-if="projects.length"
    >
        <v-col
            cols="12"
            md="12"

        >
            <v-card
                class="mb-2"
            >
                <v-card-title
                >
                    {{$lang('issues' )}}
                    <v-spacer></v-spacer>
                    <TableFilterDropDown table-id="dashboardIssuesData" key="dashboardIssuesData" ></TableFilterDropDown>
                </v-card-title>
                <v-card-text>
                    <DashboardIssuesTable :issues="dashboardIssuesData" table-id="dashboardIssuesData" ></DashboardIssuesTable>
                </v-card-text>
            </v-card>

            <v-card
                class="mb-2"
            >
                <v-card-title
                >
                    {{$lang('todos' )}}
                    <v-spacer></v-spacer>
                    <TableFilterDropDown table-id="dashboardTodosData" key="dashboardTodosData" ></TableFilterDropDown>
                </v-card-title>
                <v-card-text>
                    <DashboardTodosTable :issues="dashboardIssuesData" table-id="dashboardTodosData" ></DashboardTodosTable>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

</div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ControllingSelectFilter from "../components/ControllingSelectFilter";

    import DashboardFilterDropDown from "../components/DashboardFilterDropDown";
    import DashboardIssuesTable from "../tables/DashboardIssuesTable";
    import TableFilterDropDown from "../../helpers/tableFilters/components/TableFilterDropDown";
    import Bus from "../../../bus";
    import DashboardTodosTable from "../tables/DashboardTodosTable";
    import BruttoPotentialWithHardnessDegree from "@/app/controlling/charts/BruttoPotentialWithHardnessDegree.vue";
    import HoursPotentialWithHardnessDegree from "@/app/controlling/charts/HoursPotentialWithHardnessDegree.vue";
    import IssuesAndTodosWithStatus from "@/app/controlling/charts/IssuesAndTodosWithStatus.vue";


    export default {
        name: "Dashboard",
        data () {
            return {
                loading: true,
                showWorkingHours: false,
            }
        },

        components: {
            IssuesAndTodosWithStatus,
            HoursPotentialWithHardnessDegree,
            BruttoPotentialWithHardnessDegree,

            DashboardTodosTable,
            TableFilterDropDown,
            DashboardIssuesTable,
            DashboardFilterDropDown,

            ControllingSelectFilter

            },

        computed: {
            ...mapGetters({
                user: 'auth/user',
                projects: 'projects/projects',
                showControllingSelectFilter: 'controlling/showControllingSelectFilter',
                dashboardIssuesData: 'controlling/dashboardIssuesData',
                dashboardFilters: 'controlling/dashboardFilters'
            })
        },

        methods: {
            ...mapActions({
                fetchProjects: 'projects/fetchProjects',
                toggleControllingSelectFilter: 'controlling/toggleControllingSelectFilter',
                fetchDashboardFilters: 'controlling/fetchDashboardFilters',
            })
        },

        mounted() {
            const self = this

            this.fetchDashboardFilters()

            if(!this.projects.length) {
                this.fetchProjects().then(function (){
                    self.loading = false
                })
            }
            else {
                this.loading = false
            }

            Bus.$on('project:updated', function () {
                self.loading = true
                self.fetchProjects().then(function (){
                    self.loading = false
                })
            });

            Bus.$on('gantt:updated', function () {
                self.loading = true
                self.fetchProjects().then(function (){
                    self.loading = false
                })
            });

            Bus.$on('issue:updated', function () {
                self.loading = true
                self.fetchProjects().then(function (){
                    self.loading = false
                })
            });

            Bus.$on('issue:created', function () {
                self.loading = true
                self.fetchProjects().then(function (){
                    self.loading = false
                })
            });

            Bus.$on('issue:deleted', function (id) {
                self.loading = true
                self.fetchProjects().then(function (){
                    self.loading = false
                })
            });

            Bus.$on('todo:updated', function () {
                self.loading = true
                self.fetchProjects().then(function (){
                    self.loading = false
                })
            });

            Bus.$on('todo:created', function () {
                self.loading = true
                self.fetchProjects().then(function (){
                    self.loading = false
                })
            });

            Bus.$on('potential:updated', function () {
                self.loading = true
                self.fetchProjects().then(function (){
                    self.loading = false
                })
            });

            Bus.$on('potential:created', function () {
                self.loading = true
                self.fetchProjects().then(function (){
                    self.loading = false
                })
            });
        },

        destroyed: function () {
            Bus.$off('gantt:updated')
            Bus.$off('issue:updated')
            Bus.$off('issue:created')
            Bus.$off('todo:updated')
            Bus.$off('todo:created')
            Bus.$off('potential:updated')
            Bus.$off('potential:created')
        }
    }
</script>

<style >

</style>
87446d91-e083-4051-89f3-40de20ca0595
