<template>
<div>
    <v-card>
        <v-card-text
            class="pl-0 pr-0"
        >
            <v-form
                ref="solex"
            >
                <v-text-field
                    :label="$lang('title')"
                    v-model="solex.title"
                    required
                    :autofocus="!actionAutofocus"
                    :error-messages="errors.title"
                >
                </v-text-field>

                <h4>{{$lang('why')}}</h4>
                <div
                    :style="errors.why_body ? 'border: 2px red solid' : ''"
                    :class="errors.why_body ? '' : 'mb-5'"
                >
                    <Editor  :body="solex.why_body" v-on:update:body="solex.why_body = $event" v-on:update:tokens="solex.why_tokens = $event" ></Editor>
                </div>

                <template
                    v-if="errors.why_body"
                    class="mb-5"
                >
                    <span class="v-messages__message error--text v-messages">
                        {{errors.why_body[0]}}
                    </span>
                </template>

                <h4>{{$lang('what for')}}</h4>
                <div
                    :style="errors.what_for_body ? 'border: 2px red solid' : ''"
                    :class="errors.what_for_body ? '' : 'mb-5'"
                >
                    <Editor  :body="solex.what_for_body" v-on:update:body="solex.what_for_body = $event" v-on:update:tokens="solex.what_for_tokens = $event" ></Editor>
                </div>

                <template
                    v-if="errors.what_for_body"
                    class="mb-5"
                >
                    <span class="v-messages__message error--text v-messages">
                        {{errors.what_for_body[0]}}
                    </span>
                </template>

                <h4>{{$lang('what')}}</h4>
                <div
                    :style="errors.what_body ? 'border: 2px red solid' : ''"
                    :class="errors.what_body ? '' : 'mb-5'"
                >
                    <Editor  :body="solex.what_body" v-on:update:body="solex.what_body = $event" v-on:update:tokens="solex.what_tokens = $event" ></Editor>
                </div>

                <template
                    v-if="errors.what_body"
                    class="mb-5"
                >
                    <span class="v-messages__message error--text v-messages">
                        {{errors.what_body[0]}}
                    </span>
                </template>
                <v-row>
                    <v-col>
                        <h4>{{$lang('how')}}</h4>
                    </v-col>
                    <v-col
                        class="text-right"
                    >
                        <v-btn
                            small
                            outlined
                            color="primary"
                            @click="addStep"
                        >
                            <v-icon class="mr-1">fas fa-plus</v-icon>
                            {{$lang('step')}}
                        </v-btn>
                    </v-col>
                </v-row>



                <v-simple-table
                    class="mt-2"
                >
                    <template v-slot:default>
                        <tbody>
                        <tr
                            v-for="(step, index) in solex.steps"
                            :key="step.index"
                            :id="`row_${index}`"
                        >
                            <td
                                class="ml-0 pl-0"
                            >
                                <v-text-field
                                    v-model="solex.steps[index].title"
                                    :error-messages="errors[`steps.${[index]}.title`] ? errors[`steps.${[index]}.title`][0]: null"
                                    :autofocus="actionAutofocus"
                                >

                                </v-text-field>
                            </td>
                            <td
                                class="text-right"
                                width="100"
                            >
                                <v-btn
                                    text
                                    color="error"
                                    @click="deleteStep(index)"
                                >
                                    <v-icon>
                                        fas fa-trash-alt
                                    </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-divider></v-divider>

                <v-row
                    class="mt-2"
                >
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-select
                            v-model="solex.assignee_id"
                            :items="activeUsers"
                            :label="$lang('responsible')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="solex.assignee_id = null"
                            item-text="full_name"
                            item-value="id"
                            :error-messages="errors.assignee_id"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-select
                            v-model="solex.user_ids"
                            :items="activeUsers"
                            :label="$lang('team')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="solex.user_ids = []"
                            item-text="full_name"
                            item-value="id"
                            multiple
                            :error-messages="errors.user_ids"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" value="!loading" text @click="submit">{{ $lang('save') }}</v-btn>
            <v-btn color="blue darken-1" text @click="setShowSolexDialog(false)">{{$lang('close')}}</v-btn>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Editor from "../../../../components/Editor";
import Bus from "../../../../bus";

export default {
    name: "EditSolex",
    components: {Editor},
    data() {
        return {
            loading: false,
            errors: [],
            why_tokens: [],
            what_for_tokens: [],
            what_tokens: [],
            actionAutofocus: false
        }
    },
    computed: {
        ...mapGetters({
            solex: 'solexes/solex',
            activeUsers: 'users/activeUsers',
            project: 'projects/project'
        }),
    },

    methods: {
        ...mapActions({
            setSolexDialogWidth: 'solexes/setSolexDialogWidth',
            update:'solexes/update',
            setShowSolexDialog: 'solexes/setSolexDialog',

        }),

        async addStep() {
            const self = this
            await this.addRow().then(function (){
                let numRows = self.solex.steps.length

                var element = document.getElementById(`row_${numRows - 1}`);

                element.scrollIntoView()
            })
        },

        addRow() {
            return new Promise(resolve => {
                this.solex.steps.push({
                    title:null
                })
                this.actionAutofocus = true
                resolve('resolved');

            });
        },

        deleteStep(index) {
            if (confirm(this.$lang(`do you really want to delete this step?`))) {
                if(this.solex.steps.splice(index, 1)){
                    Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang(`step successfully deleted`)})
                }
            }
        },


        submit () {
            const self = this
            this.loading = true
            this.errors = []
            this.update({
                id: this.solex.id,
                payload: this.solex,
                context: this
            }).then(() => {
                this.loading = false
                if(this.errors.length === 0) {
                    this.dialog = false
                    Bus.$emit('solex:updated')
                    Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('solex successfully updated') });
                }
            })
        },

        saveAndClose() {
            this.confirmCloseDialog = false
            this.submit()
        },

        closeWithoutSaving() {
            this.errors = []
            this.closeDialog()
        },


    }
}
</script>

<style scoped>

</style>
