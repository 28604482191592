<template>
    <div>
        <v-dialog
            persistent
            v-model="showSolexDialog"
            :width="solexDialogWidth"
            @click:outside="confirmCloseDialog = true"
            @keydown.esc="confirmCloseDialog = true"
            v-bind:style="{ position: 'relativ',  left: '10px'}"
            scrollable
        >
            <v-card>
                <v-card-title>
                    {{solex.title}}
                    <v-spacer></v-spacer>
                    <span v-html="solex.status_label"></span>

                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="project && project.project_leader_id === user.id && solex.status === 'checking'"
                        class="ml-2"
                        small
                        outlined
                        color="primary"
                        @click="rework"
                    >
                        <v-icon class="mr-1">fas fa-ok</v-icon> {{ $lang('rework')}}
                    </v-btn>

                    <v-btn
                        v-if="project && project.project_leader_id === user.id && solex.status === 'checking'"
                        class="ml-2"
                        small
                        outlined
                        color="primary"
                        @click="approve"
                    >
                        <v-icon class="mr-1">fas fa-ok</v-icon> {{ $lang('approve')}}
                    </v-btn>

                    <v-btn
                        v-if="showSolexToIssueButton"
                        class="ml-2"
                        small
                        outlined
                        color="primary"
                        @click="solexToIssue"
                    >
                        <v-icon class="mr-1">fas fa-share</v-icon> {{ $lang('create issue')}}
                    </v-btn>

                    <v-btn
                        v-if="showRequestForApprovalButton"
                        class="ml-2"
                        small
                        outlined
                        color="primary"
                        @click="requestForApproval"
                    >
                        <v-icon class="mr-1">fas fa-share</v-icon> {{ $lang('request for approval')}}
                    </v-btn>
                    <v-btn
                        class="ml-2"
                        small
                        outlined
                        color="primary"
                        @click="closeDialog"
                    >
                        <v-icon >fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <SolexTabs></SolexTabs>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="confirmCloseDialog"
            width="600"
        >
            <v-card>
                <v-card-title
                    primary-title
                    class="justify-center"
                >
                    {{$lang('close dialog')}}
                </v-card-title>
                <v-card-text
                    class="text-center"
                >
                    <strong>
                        {{$lang('do you really want to close without saving?')}}
                    </strong>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="closeDialog"
                    >
                        {{$lang('close without saving')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="confirmCloseDialog = false"
                    >
                        {{$lang('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import SolexTabs from "./SolexTabs";
import Bus from "../../../../bus";
import {requestSolexToIssue} from "../vuex/actions";

export default {
    name: "SolexDialog",
    components: {SolexTabs},
    data() {
        return {
            confirmCloseDialog: false,
            showSolexToIssueButton: false,
            showRequestForApprovalButton: false
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
            solex: 'solexes/solex',
            showSolexDialog: 'solexes/showSolexDialog',
            solexDialogWidth: 'solexes/solexDialogWidth',
            selectedTab: 'solexes/selectedTab',
            project: 'projects/project'
        }),
    },

    methods: {
        ...mapActions({
            setShowSolexDialog: 'solexes/setSolexDialog',
            setSolexDialogWidth: 'solexes/setSolexDialogWidth',
            setSelectedTab: 'solexes/setSelectedTab',
            setSolexToIssue: 'solexes/setSolexToIssue',
            setCreateIssueDialog: 'issues/setCreateIssueDialog',
            requestSolexToIssue: 'solexes/requestSolexToIssue',
            approveSolexToIssue: 'solexes/approveSolexToIssue',
            reworkSolex: 'solexes/reworkSolex'
        }),

        closeDialog () {
            this.confirmCloseDialog = false
            this.setShowSolexDialog(false)
        },

        solexToIssue() {
            const self = this

            let projectEndDate = moment(this.project.end_date, "DD.MM.YYYY");
            let issueEndDate = moment().add(this.project.solex_settings.lead_time, 'days')

            if(!projectEndDate.isSameOrAfter(issueEndDate)) {
                Bus.$emit('showAlert', {color: 'error', 'message': self.$lang('project end date before needed end date') });
            }
            else {
                this.setSolexToIssue(this.solex.id).then(function (){
                    self.setShowSolexDialog(false)
                    self.setCreateIssueDialog({value: true, endpoint: `/api/solexes/${self.solex.id}/issues`})
                })
            }

        },

        requestForApproval () {
            const self = this
            if(!this.project.project_leader_id) {
                Bus.$emit('showAlert', {color: 'error', 'message': self.$lang('project leader is missing') });
            }
            else {
                if (confirm(this.$lang('do you really want to send the request for approval?'))) {
                    this.requestSolexToIssue(this.solex.id).then(function (){
                        Bus.$emit('solex:updated')
                        Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('request for approval successfully sent') });
                    })
                }

            }
        },

        approve () {
            const self = this
            if (confirm(this.$lang('do you really want to approve this solex?'))) {
                this.approveSolexToIssue(this.solex.id).then(function () {
                    Bus.$emit('solex:updated')
                    Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('approval successfully sent')});
                })
            }
        },

        rework () {
            const self = this
            if (confirm(this.$lang('do you really want to set status to rework?'))) {
                this.reworkSolex(this.solex.id).then(function () {
                    Bus.$emit('solex:updated')
                    Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('status successfully changed to rework')});
                })
            }
        }
    },

    watch: {

        solex: {
            immediate: true,
            handler() {
                if(this.project) {
                    this.showSolexToIssueButton = this.project.project_leader_id === this.user.id && ['rework', 'draft', 'checking'].includes(this.solex.status) || this.solex.status === 'approved'

                    this.showRequestForApprovalButton = this.project.project_leader_id !== this.user.id && ['rework', 'draft'].includes(this.solex.status)
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
