export const authenticated = (state) => {
    return state.authenticated
}

export const user = (state) => {
    return state.user
}

export const loginError = (state) => {
    return state.loginError
}

export const showLogoutMessage = (state) => {
    return state.showLogoutMessage
}
