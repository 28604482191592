export const potentials = (state) => {
    return state.potentials
}

export const potential = (state) => {
    return state.potential
}

export const showPotentialDialog = (state) => {
    return state.showPotentialDialog
}

export const showCreatePotentialDialog = (state) => {
    return state.showCreatePotentialDialog
}

export const createPotentialEndpoint = (state) => {
    return state.createPotentialEndpoint
}
