<template>
    <div>
        <v-card v-if="user.has_relations">
            <v-card-title >
                {{ $lang('user could not be deleted')}}
            </v-card-title>

        </v-card>
        <v-card v-else-if="user.id === me.id">
            <v-card-title >
                {{ $lang('user could not be deleted')}}
            </v-card-title>

        </v-card>
        <v-card v-else>
            <v-col style="height: 300px" class="ml-2 mr-2">
                <v-row>
                    <strong>
                        {{ $lang('do you really want to delete this user?')}}
                    </strong>
                </v-row>

                <v-row>
                    <v-btn
                        color="error"
                        @click="showConfirmDeleteUser = true"
                    >
                        {{ $lang('delete') }}
                    </v-btn>
                </v-row>

                <v-row>
                    <v-btn
                        v-if="showConfirmDeleteUser"
                        color="error"
                        class="mt-10"
                        @click="deleteUser"
                    >
                        {{$lang('delete')}}
                    </v-btn>
                </v-row>
            </v-col>

        </v-card>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "DeleteUser",
        data() {
            return {
                showConfirmDeleteUser: false,
            }
        },
        computed: {
            ...mapGetters({
                me: 'auth/user',
                user: 'companyAdminUsers/user',
            }),
        },

        methods: {
            ...mapActions({
                actionDeleteUser: 'companyAdminUsers/deleteUser',
            }),

            deleteUser() {
                const self = this
                this.showConfirmDeleteUser = false
                this.actionDeleteUser(this.user.id).then(function (){
                    Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('user successfully deleted')})
                    self.$router.push({ name: 'companyAdmin.users'})
                })
           }

        }
    }
</script>

<style scoped>

</style>
