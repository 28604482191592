export const fetchCompany = ({ commit }) => {
    return axios.get(`/api/companyAdmin/company`).then((response) => {
        commit('SET_COMPANY_DATA', response.data)
    })
}
export const updateLang = ({ dispatch, state, commit }, { payload,  context}) => {
    return axios.patch(`/api/companyAdmin/company/updateLang`, payload).then((response) => {
        commit('SET_COMPANY_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const updateHGSettings = ({ dispatch, state, commit }, { payload,  context}) => {
    return axios.patch(`/api/companyAdmin/company/updateHGSettings`, payload).then((response) => {
        commit('SET_COMPANY_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}
