export default {
    todos: [],
    todo: '',
    showTodoDialog: false,
    createTodoEndpoint: '',
    showCreateTodoDialog: false,
    todoDateLimits: null,
    todoDialogWidth: 1200,
    selectedTab: '',
}

