export const users = (state) => {
    return state.users
}

export const activeUsers = (state) => {
    return state.activeUsers
}

export const user = (state) => {
    return state.user
}

