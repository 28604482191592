<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "PotentialsTable",

        data() {
            return {
                loading: true,
                enableSelect: false,
                datatableExists: true
            }
        },

        props: {
            endpoint: {
                type: String
            },
            tableId: {
                type: String
            },
        },

        computed: {
            ...mapGetters({
                user: 'auth/user',
                potential: 'potentials/potential',
                issueDialogWidth: 'issues/issueDialogWidth'
            })
        },

        methods: {
            ...mapActions({
                actionShowPotentialDialog: 'potentials/setShowPotentialDialog',
                setPotential: 'potentials/setPotential',
                fetchPotential: 'potentials/fetchPotential',
            }),

            loadDatatable() {
                const self = this
                if(this.datatableExists) {
                    axios.get(this.endpoint).then(function (response){
                        self.webixId.parse(webix.copy(response.data))
                    })
                }
            }
        },

        mounted() {
            const self = this

            this.datatableExists = true

            const endpoint = this.endpoint

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: 600,
                id: this.tableId,

                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                footer:false,
                columns: [
                    {
                        id: "type_label",
                        header: [self.$lang('type'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 200}
                        }],
                        minWidth: 150,
                        adjust: true,
                        sort: "string",
                        template: function (obj) {
                            return obj.type_label
                        }
                    },
                    {
                        id: "title",
                        header: [self.$lang('title'), {content: "textFilter"}],
                        footer: "Total",
                        fillspace: true,
                        minWidth: 200,
                        sort: "string",
                        template: "#title_link#"
                    },
                    {
                        id: "active_label",
                        header: [self.$lang('active'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 100,
                        adjust: true,
                        sort: "string",
                        template: function (obj) {
                            return obj.active_label
                        }
                    },
                    {
                        id: "chance",
                        header: [self.$lang('probability'), {content: "textFilter"}],
                        sort: "string",
                        adjust: true,
                        css:{'text-align':'right'},
                        template: function (obj) {
                            if (obj.chance !== null) {
                                return obj.chance + '%'
                            }
                            return ''
                        }
                    },
                    {
                        id: "potential",
                        header: [self.$lang('potential')  + " " + self.user.company.currency, {content: "textFilter"}],
                        footer: {content: "summColumn"},
                        adjust: true,
                        css: {'text-align': 'right'},
                        sort: "string",
                        template: function (obj) {
                            return self.$num(obj.potential, 0)
                        }
                    },
                    {
                        id: "invest",
                        header: [self.$lang('invest')   + " " + self.user.company.currency, {content: "textFilter"}],
                        footer: {content: "summColumn"},
                        adjust: true,
                        css: {'text-align': 'right'},
                        sort: "string",
                        template: function (obj) {
                            return self.$num(obj.invest, 0)
                        }
                    },
                    {
                        id: "reduction_with_unit",
                        header: [self.$lang('reduction'), {content: "textFilter"}],
                        adjust: true,
                        sort: "string",
                    },
                    {
                        id: "increase",
                        header: [self.$lang('delivery reliability'), {content: "textFilter"}],
                        sort: "string",
                        adjust: true,
                        css:{'text-align':'right'},
                        template: function (obj) {
                            if (obj.increase !== null) {
                                return obj.increase + '%'
                            }
                            return ''
                        }
                    },
                    {
                        id: "creator_full_name",
                        header: [self.$lang('creator'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 200}
                        }
                        ],
                        adjust: true,
                        sort: "string"
                    },
                    {
                        id: "created_at",
                        map: "(date)#created_at#",
                        header: [self.$lang('created'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: true,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }
                    }
                ],
                onClick:{
                    "title_link" : function  (event, row, target) {
                        self.fetchPotential(row.row).then(function () {
                            self.actionShowPotentialDialog(true)
                        })
                    }
                },
                on: {
                    onAfterSelect: function(){
                        // if(self.enableSelect) {
                        //     self.fetchPotential(this.getSelectedId(true)[0].id)
                        // }
                    },
                    onItemDblClick: function (row) {
                        self.fetchPotential(row.row).then(function () {
                            self.actionShowPotentialDialog(true)
                        })
                    },

                    onAfterLoad: function () {
                        self.loading = false
                        this.define("width", self.issueDialogWidth -2);
                        this.resize();
                    },

                },

            })

            Bus.$on('potential:updated', function () {
                if(self.datatableExists) {
                    self.loadDatatable()
                }
            });

            Bus.$on('potential:created', function () {
                if(self.datatableExists) {
                    self.loadDatatable()
                }
            });

            Bus.$on('potential:deleted', function () {
                if(self.datatableExists) {
                    self.webixId.clearAll()
                    self.loadDatatable()
                }
            });

            this.loadDatatable()

        },

        watch: {
            potential: {
                handler: function (potential) {
                    this.enableSelect = false
                    this.$nextTick(() => {
                        if (potential && $$(this.webixId).exists(potential.id)) {
                            $$(this.webixId).select(potential.id)
                            $$(this.webixId).showItem(potential.id)
                        }
                        this.enableSelect = true
                    })

                },
                immediate: true
            },

            issueDialogWidth (value) {
                webix.$$(this.webixId).define("width", value -2);
                webix.$$(this.webixId).resize();
            }

        },
        destroyed:function(){
            this.datatableExists = false
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style scoped>

</style>
