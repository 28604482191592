export const SET_TODO_DATA = (state, data) => {
    state.todo = data
}

export const SET_TODOS_DATA = (state, data) => {
    state.todos = data
}

export const SET_TODO_DIALOG = (state, data) => {
    state.showTodoDialog = data
}

export const PUSH_TODOS_DATA = (state, data) => {
    state.todos.push(data)
}

export const UPDATE_TODOS_DATA = (state, data) => {
    Vue.set(state.todos, state.todos.findIndex(todos => todos.id === data.id), data)
}

export const REMOVE_TODO = (state, id) => {
    state.todos.splice(state.todos.findIndex(todos => todos.id === id), 1);
}

export const SET_CREATE_TODO_DIALOG = (state, data) => {
    state.showCreateTodoDialog = data
}

export const SET_CREATE_TODO_ENDPOINT = (state, data) => {
    state.createTodoEndpoint = data
}

export const SET_TODO_DATE_LIMITS= (state, value) => {
    state.todoDateLimits = value
}

export const SET_TODO_DIALOG_WIDTH= (state, value) => {
    state.todoDialogWidth = value
}

export const SET_SELECTED_TAB = (state, value) => {
    state.selectedTab = value
}
