
export const SET_PROJECTS_DATA = (state, data) => {
    state.projects = data
}

export const SET_PROJECT_DATA = (state, data) => {
    state.project = data
}
export const ADD_PROJECT = (state, data) => {
    state.projects.push(data)
}

export const updateProjectData = (state, data) => {
    Object.assign(state.projects[data.index], data.data)
}

export const UPDATE_PROJECTS_DATA = (state, data) => {
    Vue.set(state.projects, state.projects.findIndex(projects => projects.id === data.id), data)
}

export const REMOVE_PROJECT = (state, data) => {
    const index = _.indexOf(state.projects, data)
    state.projects.splice(index, 1)
    state.project = []
}

export const setCreateProjectDialog = (state, data) => {
    state.showCreateProjectDialog = data
}

export const SET_COPY_PROJECT = (state, value) => {
    state.copyProject = value
}

export const SET_SELECTED_TAB = (state, value) => {
    state.selectedTab = value
}

export const SET_TREE_FILTER_TODOS = (state, value) => {
    state.treeFilter.todos = value
}

export const SET_TREE_FILTER_POTENTIALS = (state, value) => {
    state.treeFilter.potentials = value
}

export const SET_TREE_FILTER_EXPAND_ALL = (state, value) => {
    state.treeFilter.expandAll = value
}

export const SET_TASKS_DATA = (state, data) => {
    state.gantt.tasks.data = data
}

export const SET_TASK_DATA = (state, data) => {
    state.gantt.task = data
}

export const SET_SHOW_TASK_CONTEXT_MENU = (state, data) => {
    state.gantt.showTaskContextMenu = data
}

export const SET_EVENT = (state, data) => {
    state.gantt.event = data
}

export const SET_GANTT_OPEN_ALL = (state, value) => {
    state.gantt.openAll = value
}

export const SET_GANTT_MOVE_PROJECT = (state, value) => {
    state.gantt.moveProject = value
}

export const SET_GANTT_SCALE = (state, value) => {
    state.gantt.scale = value
}

export const SET_GANTT_RANGE = (state, value) => {
    state.gantt.range = value
}

export const SET_GANTT_SHOW_POTENTIALS = (state, value) => {
    state.gantt.showPotentials = value
}

export const RESET_GANTT = (state, value) => {
    state.gantt.reset = value
    if(value) {
        state.gantt.changedTasks = []
    }
}

export const SET_GANTT_SEARCH = (state, value) => {
    state.gantt.search = value
}

export const SET_CHANGED_TASKS = (state, value) => {
    if (value !== 0) {
        state.gantt.changedTasks  = _.filter(state.gantt.changedTasks, function(task) {
            return task.id !== parseInt(value.id);
        });
        state.gantt.changedTasks.push(value)
    }
    else {
        state.gantt.changedTasks = []
    }

}

export const SET_PROJECT_DATE_LIMITS= (state, value) => {
    state.projectDateLimits = value
}
