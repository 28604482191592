<template>
    <v-tabs>
        <v-tab href="#info">Info</v-tab>
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#companies">Firmen</v-tab>
        <v-tab href="#permissions">Berechtigungen</v-tab>
        <v-tab href="#roles">Rollen</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="info" >
            <UserInfo></UserInfo>
        </v-tab-item>

        <v-tab-item value="edit" >
            <EditUser></EditUser>
        </v-tab-item>

        <v-tab-item value="companies" >
            <UserCompanies></UserCompanies>
        </v-tab-item>

        <v-tab-item value="permissions" >
            <UserPermissions></UserPermissions>
        </v-tab-item>

        <v-tab-item value="roles" >
            <UserRoles></UserRoles>
        </v-tab-item>

        <v-tab-item value="delete" >
            <DeleteUser></DeleteUser>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import UserInfo from "./UserInfo";
import EditUser from "./EditUser";
import UserCompanies from "./UserCompanies";
import UserPermissions from "./UserPermissions";
import UserRoles from "./UserRoles";
import DeleteUser from "./DeleteUser";

export default {
name: "UserTabs",
    components: {DeleteUser, UserRoles, UserPermissions, UserCompanies, EditUser, UserInfo}
}
</script>

<style scoped>

</style>
