<template>
    <FileBrowser endpoint="/api/companies/fileManager" :table-margin-bottom="200"></FileBrowser>
</template>

<script>


import FileBrowser from "../components/FileBrowser";
export default {
name: "FileManager",
    components: {FileBrowser},
}
</script>

<style scoped>

</style>
