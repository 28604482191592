import { FileManager } from '../views'

export default [
    {
        path: '/fileManager',
        component: FileManager,
        name: 'fileManager',
        meta: {
            guest: false,
            needsAuth: true
        }
    },


]
