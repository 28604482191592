<template>
    <v-tabs
        v-model="tab"
    >
        <v-tab
            href="#edit"
            v-if="_.includes(issue.user_permissions, 'edit')"
        >
            {{ $lang('edit') }}
        </v-tab>
        <v-tab href="#info">{{ $lang('info')}}</v-tab>
        <v-tab href="#potentials">{{ $lang('potentials')}}</v-tab>
       <v-tab href="#todos">{{ $lang('todos')}}</v-tab>
        <v-tab href="#files">{{ $lang('files')}}</v-tab>
        <v-tab href="#solex"
               v-if="issue.solex"

        >{{ $lang('solex')}}</v-tab>
        <v-tab href="#leadTimeChanges" v-if="issue.lead_time_settings.track_issues" >{{ $lang('lead time changes')}}</v-tab>
        <v-tab
            href="#delete"
            v-if="issue.todos && !issue.todos.length && _.includes(issue.user_permissions, 'edit')"
        >{{ $lang('delete')}}</v-tab>

        <v-tab-item value="edit">
            <EditIssue></EditIssue>
        </v-tab-item>

        <v-tab-item value="info">
            <IssueInfo></IssueInfo>
        </v-tab-item>

        <v-tab-item value="potentials">
            <PotentialsTable  table-id="issuePotentials" :endpoint="`/api/issues/${issue.id}/potentials`"></PotentialsTable>
        </v-tab-item>

        <v-tab-item value="todos">
            <TodosTable table-id="issueTodos" :drag-rows="true" :endpoint="`/api/issues/${issue.id}/todos`" :dialog="true"></TodosTable>
        </v-tab-item>

        <v-tab-item value="files">
            <IssueFiles></IssueFiles>
        </v-tab-item>

        <v-tab-item value="solex" v-if="issue.solex">
            <v-card >
                <v-card-text>
                    <SolexInfo :solex-id="issue.solex.id"></SolexInfo>
                </v-card-text>
            </v-card>
        </v-tab-item>

        <v-tab-item value="leadTimeChanges">
            <LeadTimeChanges :lead-time-changes="issue.lead_time_changes"></LeadTimeChanges>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteIssue></DeleteIssue>
        </v-tab-item>
    </v-tabs>
</template>

<script>

    import IssueInfo from "./IssueInfo";
    import EditIssue from "./EditIssue";
    import TodosTable from "../../todos/components/TodosTable";
    import {mapActions, mapGetters} from "vuex";
    import PotentialsTable from "../../potentials/components/PotentialsTable";
    import IssueFiles from "./IssueFiles";
    import DeleteIssue from "./DeleteIssue";
    import SolexInfo from "../solexes/components/SolexInfo";
    import LeadTimeChanges from "../../../components/LeadTimeChanges";
    export default {
        name: "IssueTabs",
        components: {
            LeadTimeChanges,
            SolexInfo, DeleteIssue, IssueFiles, PotentialsTable, TodosTable, EditIssue, IssueInfo},
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters({
                issue: 'issues/issue',
                selectedTab: 'issues/selectedTab'
            }),
            tab: {
                get(){
                    return this.selectedTab
                },
                set(value){
                    this.setSelectedTab(value)
                }
            },

            todos: function() {
                return this.issue.todos
            },
            potentials: function() {
                return this.issue.potentials
            }
        },

        created() {
            this.setTodos(this.issue.todos)
            this.setPotentials(this.issue.potentials)
        },


        methods: {
        ...mapActions({
                setTodos: 'todos/setTodos',
                setPotentials: 'potentials/setPotentials',
                setSelectedTab: 'issues/setSelectedTab'
            }),
        }
    }
</script>

<style scoped>

</style>
