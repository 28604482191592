<template>
<div>
    <v-toolbar flat color="white" >
        <v-toolbar-title >{{ $lang('users') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip
            class="white--text font-weight-black"
            color="primary"
            small
        >
            {{activeUserLabelText}}
        </v-chip>
        <v-chip
            class="white--text font-weight-black ml-2"
            small
        >
            {{ inactiveUserLabelText }}
        </v-chip>

        <v-spacer></v-spacer>
        <v-btn
            class="mr-2"
            small
            outlined
            color="primary"
            :href="`mailto:?bcc=${activeEmails}&subject=${$lang('7app news')}`"
        >
            {{$lang('mail to all active users')}}
        </v-btn>
        <CreateUserDialog></CreateUserDialog>
    </v-toolbar>
    <UsersTable></UsersTable>
</div>
</template>

<script>
import UsersTable from "../components/UsersTable";
import CreateUserDialog from "../components/CreateUserDialog";
import {mapActions, mapGetters} from "vuex";
export default {
name: "Users",
    components: {CreateUserDialog, UsersTable},
    computed: {
        ...mapGetters({
            users: 'adminUsers/users',
        }),

        activeUserLabelText: function () {
            let numActiveUsers = _.filter(this.users, function(o) { return o.active; }).length;
            if(numActiveUsers > 1 ) {
                return `${numActiveUsers} ${this.$lang('active users')}`
            }
            else if (numActiveUsers === 1 ){
                return `1 ${this.$lang('active user')}`
            }
            return this.$lang('no active users')
        },

        inactiveUserLabelText: function () {
            let numInactiveUsers = _.filter(this.users, function(o) { return !o.active; }).length;
            if(numInactiveUsers > 1 ) {
                return `${numInactiveUsers} ${this.$lang('inactive users')}`
            }
            else if (numInactiveUsers === 1 ){
                return `1 ${this.$lang('inactive user')}`
            }
            return this.$lang('no inactive users')
        },

        activeEmails: function () {
            let activeUsers = _.filter(this.users, function(o) { return o.active; })
            return _.map(activeUsers, 'email')
        }
    },

    methods: {
        ...mapActions({
            fetchUsers: 'adminUsers/fetchUsers'
        })
    },

    mounted() {
        this.fetchUsers()
    }
}
</script>

<style scoped>

</style>
