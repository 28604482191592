export const SET_KANBAN_CIRCUIT_DATA = (state, data) => {
    state.kanbanCircuit = data
}

export const SET_KANBAN_ORDERS_DATA = (state, data) => {
    state.kanbanOrders = data
}

export const SET_KANBAN_ORDER_DATA = (state, data) => {
    state.kanbanOrder = data
}

export const SET_KANBAN_ORDER_DIALOG = (state, data) => {
    state.showKanbanOrderDialog = data
}

export const SET_SHOW_CREATE_MANUAL_KANBAN_ORDER_DIALOG = (state, data) => {
    state.showCreateManualKanbanOrderDialog = data
}

export const SET_SHOW_KANBAN_CIRCUIT_DIALOG = (state, data) => {
    state.showKanbanCircuitDialog = data
}

export const SET_SELECTED_TAB = (state, value) => {
    state.selectedTab = value
}
