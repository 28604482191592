<template>
    <v-card>
        <v-card-title>
            <strong>
                {{ $lang('delete potential') }}
            </strong>
        </v-card-title>


        <v-card-title>
            {{ $lang('do you really want to delete this potential')}}
        </v-card-title>
        <v-card-text>
            <v-switch
                v-model="showConfirmDeletePotential"
                :label="$lang('yes, please delete!')"
            ></v-switch>
        </v-card-text>


        <v-card-actions>
            <v-btn
                v-if="showConfirmDeletePotential"
                color="error"
                @click="deletePotential"
            >
                {{ $lang('delete') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "DeletePotential",
        data() {
            return {
                showConfirmDeletePotential: false,
            }
        },
        computed: {
            ...mapGetters({
                potential: 'potentials/potential'
            }),
        },

        methods: {
            ...mapActions({
                actionDeletePotential: 'potentials/deletePotential',
            }),

            deletePotential() {
                this.showConfirmDeletePotential = false
                Bus.$emit('potential:deleted');
                this.actionDeletePotential({id: this.potential.id, issueId: this.potential.issue_id})
           }

        }
    }
</script>

<style scoped>

</style>
