<template>
    <v-card>
        <v-card-text>
            <v-form
                ref="form"
            >
                <v-text-field
                    label="Titel"
                    v-model="form.title"
                    required
                    autofocus
                    :error-messages="errors.title"
                >
                </v-text-field>

                <Editor  :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event"></Editor>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="submit">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import Editor from "../../../components/Editor";
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";
    export default {
        name: "EditNote",
        components: {Editor},
        data() {
            return {

                errors: [],
                form: {
                    title: '',
                    body: '',
                    tokens:[],
                }
            }
        },
        computed: {
            ...mapGetters({
                note: 'notes/note'
            }),
        },

        methods: {
            ...mapActions({
                update: 'notes/update',
            }),

            submit () {
                this.update({
                    id: this.note.id,
                    payload: this.form,
                    context: this,
                }).then(() => {
                    this.form.tokens= []
                    Bus.$emit('showAlert', {color : 'success', 'message' : 'Notiz erfolgreich geändert.'})
                })
            },
        },

        watch: {
            note: {
                handler: function() {
                    this.$nextTick(() => {
                        this.form = this.note
                    })
                },
                immediate: true
            }
        },
    }
</script>

<style scoped>
    img {
        max-width: 90%;
        height: auto;
    }
</style>
