<template>
    <div>
        <v-switch
            v-model="project.solex_settings.allow_solex"
            :label="$lang('solex optional')"
        ></v-switch>
        <v-switch
            v-model="project.solex_settings.only_solex"
            :label="$lang('solex mandatory')"
        ></v-switch>
        <v-text-field
            v-model="project.solex_settings.lead_time"
            :label="`${$lang('lead time')} (${$lang('days')})`"
        >

        </v-text-field>


        <v-divider>

        </v-divider>
        <v-btn
            class="mt-2"
            color="blue darken-1"
            text
            @click="submit"
        >{{ $lang('save') }}</v-btn>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";

export default {
    name: "SolexSettings",

    computed: {
        ...mapGetters({
            project: 'projects/project',
        }),

    },

    methods: {
        ...mapActions({
            updateSolexSettings: 'projects/updateSolexSettings',
            fetchProject: 'projects/fetchProject',
        }),
        submit () {

            this.updateSolexSettings({
                id: this.project.id,
                payload: {solex_settings: this.project.solex_settings},
            }).then(() => {
                const self = this
                self.fetchProject(self.project.id)
                Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('solex settings successfully changed')})
            })
        },
    }

}
</script>

<style scoped>

</style>
