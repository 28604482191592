export const fetchCompanies = ({ commit }) => {
    return axios.get(`/api/admin/companies`).then((response) => {
        commit('SET_COMPANIES_DATA', response.data)
    })
}

export const fetchCompany = ({ commit }, id) => {
    return axios.get(`/api/admin/companies/${id}`).then((response) => {
        commit('SET_COMPANY_DATA', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload,  context}) => {
    return axios.post(`/api/admin/companies`, payload).then((response) => {
        commit('SET_COMPANY_DATA', response.data)
        dispatch('fetchCompanies')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, id, context}) => {
    return axios.patch(`/api/admin/companies/${id}`, payload).then((response) => {
        commit('SET_COMPANY_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}


export const fetchCompanyPlan = ({ commit }, id) => {
    return axios.get(`/api/admin/companyPlans/${id}`).then((response) => {
        commit('SET_COMPANY_PLAN_DATA', response.data)
    })
}

export const updateCompanyPlan = ({ dispatch, state, commit }, { id, payload, context}) => {
    return axios.patch(`/api/admin/companyPlans/${id}`, payload).then((response) => {
        commit('SET_COMPANY_PLAN_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const setShowEditCompanyPlanDialog = ({ dispatch, state, commit }, value) => {
    commit('SET_SHOW_EDIT_COMPANY_PLAN_DIALOG', value)
}
