export const departments = (state) => {
    return state.departments
}

export const department = (state) => {
    return state.department
}

export const showCreateDepartmentDialog = (state) => {
    return state.showCreateDepartmentDialog
}
