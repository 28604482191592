<template>
    <v-card v-if="issue">
        <v-card-text>
            <span v-html="issue.body"></span>
            <v-divider></v-divider>
            <v-col
                md="12"
                cols="12"
            >
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>{{ $lang('responsible')}}</v-list-item-content>
                        <v-list-item-content class="align-end">
                            <template v-if="issue.assignee">
                                {{ issue.assignee.full_name }}
                            </template>
                            <template v-else>
                                {{ $lang('no responsible')}}
                            </template>

                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{ $lang('hardness degree')}}</v-list-item-content>
                        <v-list-item-content class="align-end">HG {{ issue.hardness_degree }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>{{ $lang('status')}}</v-list-item-content>
                        <v-list-item-content class="align-end" >
                            <span v-html="issue.issue_status_label">

                            </span>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>{{ $lang('creator')}}</v-list-item-content>
                        <v-list-item-content class="align-end" >
                            {{ issue.creator_full_name}}
                        </v-list-item-content>
                    </v-list-item>

                </v-list>
            </v-col>

        </v-card-text>
    </v-card>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "issueInfo",
        computed: {
            ...mapGetters({
                issue: 'issues/issue'
            }),
        },
    }
</script>

<style>
    img {
        max-width: 60%;
        height: auto;
    }
</style>
