<template>
    <v-col
        cols="12"
        md="5"
    >
        <v-card class="mt-2">
            <v-card-text>
                <v-container>
                    <v-alert
                        v-if="formAlert"
                        dense
                        text
                        type="success"
                    >
                        {{ $lang('supplier successfully changed')}}
                    </v-alert>
                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>

                    <v-form

                        ref="form"
                    >
                        <v-radio-group v-model="form.internal_supplier">
                            <v-radio
                                :label="$lang('external')"
                                :value="0"
                            ></v-radio>
                            <v-radio
                                :label="$lang('internal')"
                                :value="1"
                            ></v-radio>
                        </v-radio-group>
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-text-field
                            v-if="!form.internal_supplier"
                            v-model="form.street"
                            :label="$lang('address')"
                            :error-messages="errors.street"
                        ></v-text-field>
                        <v-text-field
                            v-if="!form.internal_supplier"
                            v-model="form.zip"
                            :label="$lang('zip')"
                            :error-messages="errors.zip"

                        ></v-text-field>
                        <v-text-field
                            v-if="!form.internal_supplier"
                            v-model="form.city"
                            :label="$lang('city')"
                            :error-messages="errors.city"
                        ></v-text-field>

                        <v-switch
                            v-model="form.active"
                            :label="$lang('active')"
                        ></v-switch>
                        <v-select
                            v-if="form.internal_supplier"
                            v-model="form.internal_contact_1_id"
                            :items="activeUsers"
                            :label="$lang('internal contact 1')"
                            item-text="full_name"
                            item-value="id"
                            :error-messages="errors.internal_contact_1_id"
                        ></v-select>
                        <v-select
                            v-if="form.internal_supplier"
                            v-model="form.internal_contact_2_id"
                            :items="activeUsers"
                            :label="$lang('internal contact 1')"
                            item-text="full_name"
                            item-value="id"
                            :error-messages="errors.internal_contact_2_id"
                        ></v-select>
                        <v-select
                            v-if="!form.internal_supplier"
                            v-model="form.network_supplier_id"
                            :items="networkSuppliers"
                            :label="$lang('supplier')"
                            item-text="name"
                            item-value="id"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.network_supplier_id = null"
                            :error-messages="errors.network_supplier_id"
                        >
                        </v-select>
                        <v-text-field
                            v-if="!form.internal_supplier"
                            v-model="form.supplier_email_1"
                            label="Email Lieferant 1"
                            :error-messages="errors.supplier_email_1"
                        ></v-text-field>
                        <v-text-field
                            v-if="!form.internal_supplier"
                            v-model="form.supplier_email_2"
                            label="Email Lieferant 2"
                            :error-messages="errors.supplier_email_2"
                        ></v-text-field>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="save" :disabled="loading">{{$lang('save')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "EditSupplier",

        props: [
            'supplierId'
        ],

        data() {
            return {
                loading: false,
                formAlert: false,
                errors: [],
                form: {
                    internal_supplier: 0,
                    name: '',
                    street: '',
                    zip: '',
                    city: '',
                    active: '',
                    internal_contact_1_id: '',
                    internal_contact_2_id: '',
                    network_supplier_id: '',
                    supplier_email_1: '',
                    supplier_email_2: ''
                }
            }
        },

        computed: {
            ...mapGetters({
                me: 'auth/user',
                networkSuppliers: 'networkSuppliers/networkSuppliers',
                activeUsers: 'users/activeUsers',
                supplier: 'suppliers/supplier'
            }),
        },

        methods: {
            ...mapActions({
                update: 'suppliers/update',

            }),

            save () {
                this.loading = true
                this.errors = []
                this.update({
                    payload: this.form,
                    context: this
                }).then(() => {
                    this.loading = false
                    if(this.errors.length === 0) {
                        this.showAlert()
                    }
                })
            },

            showAlert() {
                this.formAlert = true
                setTimeout(this.hideAlert, 3000)
            },

            hideAlert() {
                this.formAlert = false
            }
        },

        watch: {
            supplier: function (value) {
                this.form = this.supplier

            },
            'form.internal_supplier': function (value) {
                if(value) {
                    this.form.network_supplier_id = this.me.company.id
                }
            }
        },

        mounted() {
            this.form = this.supplier
        }
    }
</script>

<style scoped>

</style>
