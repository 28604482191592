<template>
    <v-card>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-card-text>
            <v-form
                ref="form"
            >
                <v-text-field
                    v-model="form.name"
                    :label="$lang('name')"
                    :error-messages="errors.name"
                    autofocus
                ></v-text-field>
                <v-text-field
                    v-model="form.description"
                    :label="$lang('description')"
                ></v-text-field>
                <v-row>
                    <v-col
                        cols="12"
                        md="6">
                        <v-select
                            v-model="form.project_type_id"
                            :items="projectTypes"
                            :label="$lang('project type')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.project_type_id = null"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors.project_type_id"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6">
                        <v-select
                            v-model="form.project_status_id"
                            :items="projectStatuses"
                            :label="$lang('project status')"
                            :item-text="item => $lang(item.name)"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.project_status_id = null"
                            item-value="id"
                            :error-messages="errors.project_status_id"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-select
                            v-model="form.location_id"
                            :items="locations"
                            :label="$lang('location')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.location_id = null"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors.location_id"
                        ></v-select>
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-select
                            v-model="form.project_leader_id"
                            :items="users"
                            :label="$lang('project leader')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.project_leader_id = null"
                            item-text="full_name"
                            item-value="id"
                            :error-messages="errors.project_leader_id"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-select
                            v-model="form.division_id"
                            :items="filtered_divisions"
                            :label="$lang('division')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.division_id = null"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors.division_id"
                        ></v-select>
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-select
                            v-model="form.department_id"
                            :items="filtered_departments"
                            :label="$lang('department')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.department_id = null"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors.department_id"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-dialog
                            ref="start_date_dialog"
                            v-model="start_date_modal"
                            :return-value.sync="picker_start_date"
                            persistent
                            width="290px"

                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="form.start_date"
                                    :label="$lang('start')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.start_date = null"
                                    append-icon="fas fa-calendar-alt"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="errors.start_date"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-if="projectDateLimits"
                                v-model="picker_start_date"
                                scrollable
                                @change="$refs.start_date_dialog.save(picker_start_date)"
                                :max="projectDateLimits.max_start_date"
                                :first-day-of-week="1"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="picker_start_date = ''">{{ $lang('delete') }}</v-btn>
                                <v-btn text color="primary" @click="start_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                <v-btn text color="primary" @click="$refs.start_date_dialog.save(picker_start_date)">{{ $lang('ok') }}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-dialog
                            ref="end_date_dialog"
                            v-model="end_date_modal"
                            :return-value.sync="picker_end_date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="form.end_date"
                                    :label="$lang('end')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.end_date = null"
                                    append-icon="fas fa-calendar-alt"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="errors.end_date"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-if="projectDateLimits"
                                v-model="picker_end_date"
                                scrollable
                                @change="$refs.end_date_dialog.save(picker_end_date)"
                                :min="projectDateLimits.min_end_date"
                                :first-day-of-week="1"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="picker_rnf_date = ''">{{ $lang('delete') }}</v-btn>
                                <v-btn text color="primary" @click="end_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                <v-btn text color="primary" @click="$refs.end_date_dialog.save(picker_end_date)">{{ $lang('ok') }}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-if="form.lead_time_change"
                            v-model="form.reason"
                            :label="$lang('reason')"
                            :error-messages="errors.reason"
                        >

                        </v-text-field>
                    </v-col>

                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="submit">{{ $lang('save') }}</v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";
    export default {
        name: "EditProject",
        data() {
            return {
                loading: true,
                locationLoaded: false,
                errors: [],
                previous_start_date: '',
                previous_end_date:'',
                picker_start_date:'',
                picker_end_date:'',
                start_date_modal: false,
                end_date_modal: false,
                filtered_divisions: [],
                filtered_departments: [],
                form: {
                    name: '',
                    description: '',
                    project_status_id: '',
                    project_type_id: '',
                    project_leader_id: '',
                    start_date:'',
                    end_date:'',
                    location_id: null,
                    division_id: null,
                    department_id: null,
                    lead_time_change: false,
                    reason:''
                }
            }
        },

        created() {
            const self = this
            this.fetchProjectStatuses()
            this.fetchProjectTypes()
            this.fetchUsers()
            this.fetchLocations().then(function (){
                self.setLocationsData()
                self.locationLoaded = true
            })
        },

        computed: {
            ...mapGetters({
                project: 'projects/project',
                projectTypes: 'projectTypes/projectTypes',
                projectStatuses: 'projectStatuses/projectStatuses',
                users: 'users/users',
                activeUsers: 'users/activeUsers',
                inactiveUsers: 'users/inactiveUsers',
                locations: 'locations/locations',
                projectDateLimits: 'projects/projectDateLimits'
            }),
        },

        methods: {
            ...mapActions({
                update: 'projects/update',
                fetchProjectStatuses: 'projectStatuses/fetchProjectStatuses',
                fetchProjectTypes: 'projectTypes/fetchProjectTypes',
                fetchUsers: 'users/fetchUsers',
                fetchLocations: 'locations/fetchLocations',
                fetchProjectDateLimits: 'projects/fetchProjectDateLimits'
            }),

            disableUserItem(user) {
                return !user.active
            },

            setLocationsData() {
                this.filtered_divisions = _.flatten(_.map(this.locations, 'divisions'))
                this.filtered_departments = _.flatten(_.map(this.filtered_divisions, 'departments'))
                this.loaded = true
            },

            submit () {
                this.loading = true
                const self = this
                this.errors = []
                this.update({
                    id: this.project.id,
                    payload: this.form,
                    context: this,
                }).then(() => {
                    self.loading = false
                    if(this.errors.length === 0) {
                        this.errors = []
                        Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('project successfully changed')})

                        Bus.$emit('project:updated', {projectId : self.project.id});
                    }
                })
            },

            formatDate (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            },
        },

        mounted() {
            const self = this
            this.form = this.project
            this.previous_start_date = self.project.start_date
            this.previous_end_date = self.project.end_date
            this.fetchUsers()
            this.fetchProjectDateLimits(`/api/projects/${this.project.id}`)
            this.loading = false
        },

        watch: {
            project (val) {
                this.form = this.project

            },

            picker_start_date (val) {
                this.form.start_date = this.formatDate(this.picker_start_date)
            },
            picker_end_date (val) {
                this.form.end_date = this.formatDate(this.picker_end_date)
            },

            'form.start_date': {
                handler: function() {
                    if(this.project.lead_time_settings.track_project) {
                        this.form.lead_time_change = this.previous_end_date !== this.form.end_date || this.previous_start_date !== this.form.start_date;
                    }
                }
            },

            'form.end_date': {
                handler: function() {
                    if(this.project.lead_time_settings.track_project) {
                        this.form.lead_time_change = this.previous_end_date !== this.form.end_date || this.previous_start_date !== this.form.start_date;
                    }
                }
            },

            'form.location_id': {
                handler: function() {
                    const self = this
                    if(this.locationLoaded) {
                        this.form.division_id = null
                        this.filtered_divisions = []
                        if (this.form.location_id !== null && typeof this.form.location_id !== 'undefined') {
                            this.filtered_divisions = _.find(this.locations, function (o) {
                                return o.id === self.form.location_id
                            }).divisions
                        }
                    }
                }
            },

            'form.division_id': {
                handler: function() {
                    const self = this
                    if(this.locationLoaded) {
                        this.form.department_id = null
                        this.filtered_departments = []
                        if (this.form.division_id !== null) {
                            this.filtered_departments = _.find(this.filtered_divisions, function (o) {
                                return o.id === self.form.division_id
                            }).departments
                        }
                    }
                },
            },
        },
    }
</script>

<style scoped>
    img {
        max-width: 90%;
        height: auto;
    }
</style>
