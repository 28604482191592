export const kanbanCircuit = (state) => {
    return state.kanbanCircuit
}

export const kanbanOrders= (state) => {
    return state.kanbanOrders
}

export const kanbanOrder = (state) => {
    return state.kanbanOrder
}

export const showKanbanOrderDialog = (state) => {
    return state.showKanbanOrderDialog
}
