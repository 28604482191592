<template>
    <v-card v-if="user">
        <v-card-text>
            <v-col
                md="6"
                cols="12"
            >
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>{{ $lang('first name') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ user.first_name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>{{ $lang('last name') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ user.last_name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>{{ $lang('email') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ user.email}}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>{{ $lang('company') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ user.company.name}}</v-list-item-content>
                    </v-list-item>

                </v-list>
            </v-col>

        </v-card-text>
    </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
name: "UserInfo",
    computed: {
        ...mapGetters({
            user: 'adminUsers/user',
        }),
    },
}
</script>

<style scoped>

</style>
