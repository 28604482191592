<template>
    <div
        class="mt-2"
    >
        <v-card
            height="400"
            style="overflow: auto"
        >
            <v-card
                rounded
                v-if="activityLogs"
                v-for="log in activityLogs"
                class="mb-1 pt-1 pb-1"
                :color="log.background_color"
            >
                <v-card-text
                    class="pt-1 pb-1"

                >

                    <v-row>
                        <v-col
                            class="pt-2 pb-2"
                        >
                            <span v-html="log.activity_type_label"></span>
                            <br>
                            <strong>
                                <span v-html="log.quantity_with_unit"></span>
                            </strong>

                        </v-col>
                        <v-col
                            class="pt-2 pb-2"
                            align="right"
                        >

                            {{log.created_at_formatted}}
                            <br>
                            {{log.causer_name}}
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

            <v-btn
                v-if="activityLogs && activityLogs.length >= 10 && !allLoaded"
                outlined
                color="primary"
                class="mt-2"
                block
                @click="loadAll"
            >
                {{ $lang('load all')}}
            </v-btn>
        </v-card>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {

    name: "KanbanCircuitActivityLogs",

    data() {
        return {
            activityLogs: null,
            allLoaded: false,
        }
    },

    computed: {
        ...mapGetters({
            me: 'auth/user',
            kanbanCircuit: 'procurementKanban/kanbanCircuit',
        }),
    },

    methods: {
        ...mapActions({
            setShowCreateManualKanbanOrderDialog: 'procurementKanban/setShowCreateManualKanbanOrderDialog',
            storeKanbanOrder: 'procurementKanban/storeKanbanOrder',
            fetchKanbanCircuit: 'procurementKanban/fetchKanbanCircuit'
        }),

        loadAll () {
            const self = this
            axios.get(`/api/procurement/kanban/kanbanCircuits/${this.kanbanCircuit.id}/activityLogs`).then((response) => {
                self.activityLogs = response.data
                self.allLoaded = true
            })
        }
    },

    mounted() {
        const self = this
        this.activityLogs = this.kanbanCircuit.activity_logs

        var channel = this.$pusher.subscribe('kanbanOrders.' + this.me.company.id)

        channel.bind('kanbanOrderCreated', (data) => {
            self.fetchKanbanCircuit(self.kanbanCircuit.id).then(function (){
                self.activityLogs = self.kanbanCircuit.activity_logs
            })
        });
    }


}
</script>

<style scoped>
.scroll {
    overflow: auto;
}


</style>
