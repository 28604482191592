<template>
    <div>
        <ScanKanbanCard
            v-if="scanString.type === 'kanbanCard'"
        >
        </ScanKanbanCard>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import ScanKanbanCard from "../../procurement/kanban/scan/ScanKanbanCard";

export default {
name: "ScanActionSwitch",
    components: {ScanKanbanCard},
    computed: {
        ...mapGetters({
            scanString: 'scanner/scanString'
        }),
    },
}
</script>

<style scoped>

</style>
