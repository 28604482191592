<template>
    <div v-if="division">
        <v-toolbar flat color="white" dense >
                <v-btn
                    small
                    outlined
                    color="primary"
                    to="/masterData/divisions"
                    class="mr-2"
                >
                    <v-icon class="mr-1">fas fa-list</v-icon>
                </v-btn>
            <v-toolbar-title >{{ division.name }}</v-toolbar-title>
        </v-toolbar>

        <DivisionTabs ></DivisionTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import DivisionTabs from "../components/DivisionTabs";

    export default {
        name: "Division",
        components: {
            DivisionTabs
        },
        props: [
            'divisionId'
        ],
        computed: {
            ...mapGetters({
                division: 'divisions/division',
            }),
        },
        created() {
            this.fetchDivision(this.divisionId)
        },

        methods: {
            ...mapActions({
                fetchDivision: 'divisions/fetchDivision',
            }),
        }
    }
</script>

<style scoped>

</style>
