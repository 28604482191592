<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>

</template>
<script>
    import  { mapGetters, mapActions} from 'vuex';
    import Bus from "../../../../bus";
    export default {
        name: "CompaniesTable",

        data() {
            return {
                loading: true
            }
        },

        computed: {
            ...mapGetters({
                companies: 'adminCompanies/companies',
                company: 'adminCompanies/company'
            })
        },

        methods: {
            ...mapActions({
                fetchCompanies: 'adminCompanies/fetchCompanies',
                fetchCompany: 'adminCompanies/fetchCompany',
            }),

            loadDatatable() {
                const self = this
                    axios.get('/api/admin/companies').then(function (response) {
                        self.webixId.parse(webix.copy(response.data))
                    })

            }
        },

        mounted:function(){
            const self = this
            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: 600,
                id: 'companies',
                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                columns: [
                    {id: "id",header:[ self.$lang('id'),{ content:"textFilter" }],  width: 50, sort: "int"},
                    {id: "name", header: [ self.$lang('name'),{ content:"textFilter" }],fillspace: true, minWidth: 200,  sort: "string"},
                    {id: "street", header: [ self.$lang('address'),{ content:"textFilter" }],fillspace: true, minWidth: 200,  sort: "string"},
                    {id: "zip", header: [ "PLZ",{ content:"textFilter" }], minWidth: 50,  sort: "string"},
                    {id: "city", header: [ "Ort",{ content:"textFilter" }],fillspace: true, minWidth: 200,  sort: "string"},
                    {id: "active_label", header: [self.$lang('active'), { content:"multiSelectFilter", suggest: {fitMaster: false, width: 200} }], minWidth: 150, adjust: true , sort: "string", template: function (obj){
                            return obj.active_label
                        }},
                    {id:"created_at", map:"(date)#created_at#", header:[self.$lang('created'), { content:"dateRangeFilter"}], sort: "date",  adjust: true , template: function (obj){
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }}
                ],
                on: {
                    onSelectChange: function(){
                        self.fetchCompany(this.getSelectedId(true))
                    },
                    onItemDblClick: function (id) {
                        self.$router.push({ name: 'companies.show', params: { companyId: id} })
                    },
                    onAfterLoad: function () {
                        if (self.company && this.exists(self.company.id)) {
                            this.select(self.company.id)
                            this.showItem(self.company.id)
                        }
                        self.loading = false
                    },
                },

            })
            this.loadDatatable()
        },

        watch: {
            companies: function () {
                this.loading = true
            },
        },

        destroyed:function(){
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style >
    .webix_multilist .wxi-checkbox-blank, .webix_multilist .wxi-checkbox-marked {
        font-size: 24px;
        width: 24px;
        vertical-align: middle;
        margin-right: 4px;
        color: #1565C0 ;
    }
</style>
