<template>
    <v-col
        cols="12"
        md="5"
    >
        <v-card class="mt-2">
            <v-card-text>
                <v-container>
                    <v-alert
                        v-if="formAlert"
                        dense
                        text
                        type="success"
                    >
                        {{ $lang('product successfully changed')}}
                    </v-alert>
                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-text-field
                            v-model="form.sku"
                            :label="$lang('number')"
                            :error-messages="errors.sku"
                        ></v-text-field>
                        <v-select
                            v-model="form.responsible_id"
                            :items="users"
                            :item-disabled="disableUserItem"
                            :label="$lang('responsible')"
                            item-text="full_name"
                            item-value="id"
                            :error-messages="errors.responsible_id"
                        ></v-select>
                        <v-text-field
                            v-model="form.current_manufacturing_costs"
                            :label="$lang('current costs')"
                            :error-messages="errors.current_manufacturing_costs"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.target_manufacturing_costs"
                            :label="$lang('target costs')"
                            :error-messages="errors.target_manufacturing_costs"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.production_amount_per_year"
                            :label="$lang('quantity p.a.')"
                            :error-messages="errors.production_amount_per_year"
                        ></v-text-field>

                        <v-switch
                            v-model="form.active"
                            :label="$lang('active')"
                        ></v-switch>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="reset">{{ $lang('reset') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="loading">{{ $lang('save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "EditProduct",

        props: [
            'productId'
        ],

        data() {
            return {
                loading: false,
                formAlert: false,
                errors: [],
                form: {
                    name: '',
                    color: '',
                    active: ''
                }
            }
        },

        computed: {
            ...mapGetters({
                users: 'users/users',
                product: 'masterProducts/product',
            }),
        },

        methods: {
            ...mapActions({
                update: 'masterProducts/update',
                fetchUsers: 'users/fetchUsers',
                fetchProduct: 'masterProducts/fetchProducts',
            }),

            disableUserItem(user) {
                return !user.active
            },

            save () {
                this.loading = true
                this.errors = []
                this.update({
                    payload: this.form,
                    context: this
                }).then(() => {
                    this.loading = false
                    if(this.errors.length === 0) {
                        this.showAlert()
                    }
                })
            },

            reset() {
                this.form.name = this.product.name
            },

            showAlert() {
                this.formAlert = true
                setTimeout(this.hideAlert, 3000)
            },

            hideAlert() {
                this.formAlert = false
            }
        },
        watch: {
            product: function (value) {
                this.form = this.product
            }
        },


        mounted() {
            this.fetchUsers()
            this.form = this.product
        }
    }
</script>

<style scoped>

</style>
