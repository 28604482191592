<template>
    <v-card>
        <v-alert
            v-if="alert"
            dense
            text
            type="success"
        >
            Rollen für <strong>{{ user.full_name}}</strong> erfolgreich geändert
        </v-alert>
        <v-card-text>
            <v-col
                cols="12"
                md="4"
            >
                <v-select
                    :items="user.companies"
                    label="Firma"
                    v-model="company_id"
                    item-text="name"
                    item-value="id"
                    :error-messages="errors.company_id"
                ></v-select>

                <v-switch
                    v-for="role in companyRoles"
                    v-model="role_ids"
                    :value="role.id"
                    :label="role.name"
                ></v-switch>
            </v-col>

        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
name: "UserRoles",
    data () {
        return {
            alert: false,
            role_ids: [],
            company_id: '',
            errors: [],
        }
    },

    computed: {
        ...mapGetters({
            user: 'adminUsers/user',
            roles: 'adminRoles/roles',
            companyRoles: 'adminRoles/companyRoles',
            me: 'auth/user'
        }),
    },

    methods: {
        ...mapActions({
            fetchUser: 'adminUsers/fetchUser',
            fetchRoles: 'adminRoles/fetchRoles',
            updateRoles: 'adminUsers/updateRoles',
            fetchCompanyRoles: 'adminRoles/fetchCompanyRoles'
        }),

        save () {
            this.errors = []
            this.updateRoles({
                payload: {
                    roleIds : this.role_ids,
                    company_id: this.company_id
                },
                id: this.user.id,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.showAlert()
                    this.fetchUser(this.user.id)
                }
            })
        },

        showAlert() {
            this.alert = true
            setTimeout(this.hideAlert, 3000)
        },

        hideAlert() {
            this.alert = false
        },

        getUserCompanyRoles() {
            const self = this
            let roles = _.filter(this.user.roles, function(role) { return role.company_id === self.company_id; });
            this.role_ids = _.map(roles, 'id')
        }
    },

    mounted() {
        this.fetchRoles(this.user.id)
    },

    watch :{
        company_id : function (id) {
            const self = this
            this.fetchCompanyRoles(id).then(function (){
                self.fetchRoles(self.user.id)
                self.getUserCompanyRoles()
            })
        }
    }
}
</script>

<style scoped>

</style>
