export default {
    projects: [],
    project: null,
    showCreateProjectDialog: false,
    selectedTab: 'info',
    copyProject: false,
    projectDateLimits: null,
    treeFilter: {
        todos: true,
        potentials: true,
        expandAll: false
    },
    gantt: {
        tasks: {
            data: [

            ],
            links: [

            ]
        },
        task: {},
        openAll: true,
        moveProject: false,
        scale: 'day',
        range: false,
        reset: false,
        changedTasks: [],
        showTaskContextMenu: false,
        event: null,
        search: null,
        showPotentials: false
    }
}
