<template>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <strong>
                        {{ $lang('email notification times')}}
                    </strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <NotificationTime></NotificationTime>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <strong>
                        {{ $lang('language')}}
                    </strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <Language></Language>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <strong>
                        {{ $lang('number format')}}
                    </strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <NumberFormat></NumberFormat>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import NotificationTime from "./NotificationTime";
import Language from "./Language";
import NumberFormat from "./NumberFormat";

export default {
name: "Settings",
    components: {NumberFormat, Language, NotificationTime},
    data () {
        return {

        }
    },
    computed: {
        ...mapGetters({

        }),

    },

    mounted() {

    },

    methods: {
        ...mapActions({

        }),

    }
}
</script>

<style >

</style>
