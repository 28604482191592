<template>
    <v-tabs >
        <v-tab href="#info">Info</v-tab>
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="info">
            <NoteInfo></NoteInfo>
        </v-tab-item>

        <v-tab-item value="edit">
            <EditNote></EditNote>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteNote></DeleteNote>
        </v-tab-item>

    </v-tabs>
</template>

<script>

    import NoteInfo from "./NoteInfo";
    import DeleteNote from "./DeleteNote";
    import EditNote from "./EditNote";
    export default {
        name: "NoteTabs",
        components: {EditNote, DeleteNote, NoteInfo}
    }
</script>

<style scoped>

</style>
