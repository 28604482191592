export const SET_ISSUES_DATA = (state, data) => {
    state.issues = data
}

export const UPDATE_ISSUES_DATA = (state, data) => {
    Vue.set(state.issues, state.issues.findIndex(issues => issues.id === data.id), data)
}

export const PUSH_ISSUES_DATA = (state, data) => {
    state.issues.push(data)
}

export const SET_ISSUE_DATA = (state, data) => {
    state.issue = data
}

export const SET_CREATE_ISSUE_DIALOG = (state, data) => {
    state.showCreateIssueDialog = data
}

export const SET_ISSUE_DIALOG = (state, data) => {
    state.showIssueDialog = data
}

export const SET_CREATE_ISSUE_ENDPOINT = (state, data) => {
    state.createIssueEndpoint = data
}

export const SET_ISSUES_FILTER = (state, value) => {
    state.issuesFilter = value
}

export const SET_ISSUE_DATE_LIMITS= (state, value) => {
    state.issueDateLimits = value
}

export const SET_ISSUE_DIALOG_WIDTH= (state, value) => {
    state.issueDialogWidth = value
}

export const SET_SELECTED_TAB = (state, value) => {
    state.selectedTab = value
}
