<template>
    <div>

    </div>
</template>
<script>
    import  { mapGetters, mapActions} from 'vuex';
    import Bus from "../../../../bus";
    export default {
        data() {
            return {
                datatableExists: true,
            }
        },

        computed: {
            ...mapGetters({
                    department: 'departments/department',
                    departments: 'departments/departments'
               })
        },

        methods: {
            ...mapActions({
                fetchDepartment: 'departments/fetchDepartments',
            }),
        },

        mounted:function(){
            const self = this

            this.datatableExists = true

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: 600,
                id: 'departments',
                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                columns: [
                    {
                        id: "name",
                        header: [self.$lang('name'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "label",
                        header: [self.$lang('label'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 150,
                        adjust: true,
                        sort: "string",
                        template: function (obj) {
                            return obj.label
                        }
                    },
                    {
                        id: "division_label",
                        header: [self.$lang('division'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 150,
                        adjust: true,
                        sort: "string",
                        template: function (obj) {
                            return obj.division_label
                        }
                    },
                    {
                        id: "active_label",
                        header: [self.$lang('active'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 150,
                        adjust: true,
                        sort: "string",
                        template: function (obj) {
                            return obj.active_label
                        }
                    },
                    {
                        id: "created_at",
                        map: "(date)#created_at#",
                        header: [self.$lang('created'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: true,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }
                    }
                ],
                on: {
                    onSelectChange: function(){
                        self.fetchDepartment(this.getSelectedId(true))
                    },
                    onItemDblClick: function (id) {
                        self.$router.push({ name: 'departments.show', params: { departmentId: id} })
                    },
                    onAfterLoad: function () {
                        if (self.department && this.exists(self.department.id)) {
                            this.select(self.department.id)
                            this.showItem(self.department.id)
                        }
                    },
                },
            })

            Bus.$on('department:update', function () {
                if(self.datatableExists) {
                    self.webixId.parse(self.departments)
                }
            });
        },


        watch: {
            departments: {
                handler: function () {
                    this.$nextTick(() => {
                        this.webixId.parse(this.departments)
                    })
                },
                immediate: true
            },
        },

        destroyed:function(){
            this.datatableExists = false
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style >
    .webix_multilist .wxi-checkbox-blank, .webix_multilist .wxi-checkbox-marked {
        font-size: 24px;
        width: 24px;
        vertical-align: middle;
        margin-right: 4px;
        color: #1565C0 ;
    }
</style>
