import { Calculations} from '../views'


export default [
    {
        path: '/admin/calculations',
        component: Calculations,
        name: 'calculations',
        meta: {
            guest: false,
            needsAuth: true,
            role: 'Admin'
        }
    },


]

