<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ $lang('divisions') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="showCreateDivisionDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('division') }}
            </v-btn>
        </v-toolbar>

        <DivisionsTable></DivisionsTable>
        <CreateDivisionDialog></CreateDivisionDialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import DivisionsTable from "../components/DivisionsTable";
    import CreateDivisionDialog from "../components/CreateDivisionDialog";
    export default {
        name: "Divisions",
        components: {
            DivisionsTable,
            CreateDivisionDialog
        },

        methods: {
            ...mapActions({
                showCreateDivisionDialog: 'divisions/setCreateDivisionDialog',
            }),
        },
    }
</script>

<style scoped>

</style>
