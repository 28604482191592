import { Suppliers, Supplier } from '../views'

export default [
    {
        path: '/masterData/suppliers',
        component: Suppliers,
        name: 'suppliers',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/masterData/suppliers/:supplierId/show',
        component: Supplier,
        name: 'suppliers.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },
]

