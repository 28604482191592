<template>
    <div>
        <v-toolbar flat color="white" >
            <v-toolbar-title >
                {{$lang('kanban orders')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <TableFilterDropDown table-id="ProcurementKanbanOrdersTable" key="ProcurementKanbanOrdersTable" ></TableFilterDropDown>
        </v-toolbar>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>

        <ProcurementKanbanOrdersTable table-id="ProcurementKanbanOrdersTable" ></ProcurementKanbanOrdersTable>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProcurementKanbanOrdersTable from "../components/ProcurementKanbanOrdersTable";
import TableFilterDropDown from "../../../helpers/tableFilters/components/TableFilterDropDown";


export default {
name: "KanbanOrders",
    components: {TableFilterDropDown, ProcurementKanbanOrdersTable},
    data () {
        return {
            loading: true
        }
    },

    computed: {
        ...mapGetters({

        }),
    },

    methods: {
        ...mapActions({

        }),
    },
    mounted() {
    this.loading = false
    const self = this

    },

    watch: {

    }
}
</script>

<style scoped>

</style>
