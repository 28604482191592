import { Products } from '../views'

export default [
    {
        path: '/products',
        component: Products,
        name: 'products',
        meta: {
            guest: false,
            needsAuth: true
        }
    },


]
