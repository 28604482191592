<template>
    <v-card>
        <v-card-title>
            <strong>
                {{ $lang('delete solex') }}
            </strong>
        </v-card-title>


        <v-card-title>
            {{ $lang('do you really want to delete this solex?') }}
        </v-card-title>
        <v-card-text>
            <v-switch
                v-model="showConfirmDeleteSolex"
                :label="$lang('yes, please delete!')"
            ></v-switch>
        </v-card-text>


        <v-card-actions>
            <v-btn
                v-if="showConfirmDeleteSolex"
                color="error"
                @click="deleteSolex"
            >
                {{ $lang('delete') }}
            </v-btn>
        </v-card-actions>


    </v-card>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "DeleteSolex",
        data() {
            return {
                showConfirmDeleteSolex: false,
            }
        },
        computed: {
            ...mapGetters({
                solex: 'solexes/solex'
            }),
        },

        methods: {
            ...mapActions({
                actionDeleteSolex: 'solexes/deleteSolex',
                setShowSolexDialog: 'solexes/setSolexDialog',
            }),

            deleteSolex() {
                const self = this
                this.showConfirmDeleteSolex = false
                this.actionDeleteSolex(this.solex.id).then(function (){
                    Bus.$emit('solex:deleted', self.solex.id);
                    Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('solex successfully deleted')});
                    self.setShowSolexDialog(false)
                })
           }

        }
    }
</script>

<style scoped>

</style>
