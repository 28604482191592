<template>
    <v-card>
        <v-alert
            v-if="alert"
            dense
            text
            type="success"
        >
            {{ $lang('permissions successfully updated')}}
        </v-alert>
        <v-card-text>
            <v-col
                md="6"
                cols="12"
            >
                <v-simple-table>
                    <thead>
                    <tr>
                        <th>
                            {{ $lang('name') }}
                        </th>
                        <th>
                            {{ $lang('permission') }}
                        </th>
                    </tr>
                    <tr
                        v-for="permission in permissions"
                    >
                        <td>
                            {{ $lang(permission.name) }}
                        </td>
                        <td>
                            <v-switch
                                v-model="permission_ids"
                                :value="permission.id"
                            ></v-switch>
                        </td>
                    </tr>
                    </thead>
                </v-simple-table>
            </v-col>

        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
name: "UserPermissions",
    data () {
        return {
            alert: false,
            permission_ids: []
        }
    },

    computed: {
        ...mapGetters({
            user: 'companyAdminUsers/user',
            permissions: 'companyAdminPermissions/permissions',
            me: 'auth/user'
        }),
    },

    methods: {
        ...mapActions({
            fetchPermissions: 'companyAdminPermissions/fetchPermissions',
            updatePermissions: 'companyAdminUsers/updatePermissions'
        }),

        save () {
            this.errors = []
            this.updatePermissions({
                payload: { permissionIds : this.permission_ids},
                id: this.user.id,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.showAlert()
                }
            })
        },

        showAlert() {
            this.alert = true
            setTimeout(this.hideAlert, 3000)
        },

        hideAlert() {
            this.alert = false
        }
    },

    mounted() {
        this.permission_ids = this.user.permission_ids
        this.fetchPermissions()
    }
}
</script>

<style scoped>

</style>
