export const kanbanCircuits = (state) => {
    return state.kanbanCircuits
}

export const kanbanCircuit = (state) => {
    return state.kanbanCircuit
}

export const showCreateKanbanCircuitDialog = (state) => {
    return state.showCreateKanbanCircuitDialog
}

export const showKanbanCircuitDialog = (state) => {
    return state.showKanbanCircuitDialog
}
