export const SET_SCAN_STRING = (state, data) => {
    state.scanString = data
}




export const SET_SCANNER_DIALOG = (state, data) => {
    state.showScannerDialog = data
}
