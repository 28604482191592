export default {
    showControllingSelectFilter: false,
    dashboardIssuesData: null,
    dashboardFilters: null,

    activeFilters: [],
    currentFilter: {
        location_ids: [],
        division_ids: [],
        department_ids: [],
        project_type_ids: [],
        project_ids: [],
        project_status_ids: [],
        project_leader_ids: [],
        issue_due_date_statuses: [],
        priorities: [],
        issue_assignee_ids: [],
        issue_hardness_degrees: [],
        workplace_ids: [],
        cost_center_ids: [],
        product_ids: [],
        issue_tag_ids: [],
        issue_user_ids: []
    },

    defaultFilterData: {
        location_ids: [],
        division_ids: [],
        department_ids: [],
        project_type_ids: [],
        project_ids: [],
        project_status_ids: [],
        project_leader_ids: [],
        issue_due_date_statuses: [],
        priorities: [],
        issue_assignee_ids: [],
        issue_hardness_degrees: [],
        workplace_ids: [],
        cost_center_ids: [],
        product_ids: [],
        issue_tag_ids: [],
        issue_user_ids: []
    },

    filtering: true
}

