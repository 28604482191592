<template>
    <v-tabs
        v-model="selectedTab"
    >
        <v-tab href="#edit"
        v-if="_.includes(todo.user_permissions, 'edit')"
        >{{ $lang('edit') }}</v-tab>
<!--        <v-tab href="#info">{{ $lang('info')}}</v-tab>-->
        <v-tab href="#todos">{{ $lang('todos')}}</v-tab>
        <v-tab href="#leadTimeChanges" v-if="todo.lead_time_settings.track_todos">{{ $lang('lead time changes')}}</v-tab>
        <v-tab href="#delete"
               v-if="_.includes(todo.user_permissions, 'edit')"
        >{{ $lang('delete') }}</v-tab>

        <v-tab-item value="edit">
            <EditTodo></EditTodo>
        </v-tab-item>

        <v-tab-item value="info">
            <TodoInfo></TodoInfo>
        </v-tab-item>

        <v-tab-item value="todos">
            <TodosTable  :table-id="tableId" :drag-rows="true" :endpoint="`/api/todos/${todo.id}/todos`" :sub-todo="true" :dialog="true"></TodosTable>
        </v-tab-item>

        <v-tab-item value="leadTimeChanges">
            <LeadTimeChanges :lead-time-changes="todo.lead_time_changes"></LeadTimeChanges>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteTodo></DeleteTodo>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import TodoInfo from "./TodoInfo";
import DeleteTodo from "./DeleteTodo";
import EditTodo from "./EditTodo";
import TodosTable from "./TodosTable";
import {mapGetters, mapActions} from "vuex";
import LeadTimeChanges from "../../../components/LeadTimeChanges";
export default {
name: "TodoTabs",
    components: {LeadTimeChanges, TodosTable, EditTodo, DeleteTodo, TodoInfo},
    data () {
        return {
            tableId: (new Date()).getTime(),
            selectedTab: 'edit'
        }
    },

    computed: {
        ...mapGetters({
            todo: 'todos/todo'
        }),
        todos: function() {
            return this.todo.data
        },
    },
    methods: {
        ...mapActions({
            setSelectedTab: 'todos/setSelectedTab'
        }),
    },
    watch: {
        selectedTab: {
            handler: function (value) {
                this.setSelectedTab(value)
            },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
