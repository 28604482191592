<template>
    <div >
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <TaskContextMenu></TaskContextMenu>
    </div>

</template>

<script>
import Bus from "../../../bus";
import {mapActions, mapGetters} from "vuex";
import TaskContextMenu from "./TaskContextMenu";

export default {
    name: "ProjectsTreeTable",
    components: {TaskContextMenu},
    data() {
        return {
            loading: true,
            enableSelect: false,
            list: '',
            datatableExists: true,
            defaultState: null
        }
    },

    props: [
        'endpoint',
        'issues',
        'tableId'
    ],

    computed: {
        ...mapGetters({
            user: 'auth/user',
            project: 'projects/project',
            issue: 'issues/issue',
            treeFilter: 'projects/treeFilter',
            todos: 'todos/todos',
            activeFilters: 'tableFilters/activeFilters'
        })
    },

    methods: {
        ...mapActions({
            fetchProject: 'projects/fetchProject',
            fetchIssue: 'issues/fetchIssue',
            fetchPotential: 'potentials/fetchPotential',
            setIssue: 'issues/setIssue',
            setTodos: 'todos/setTodos',
            setTodo: 'todos/setTodo',
            fetchTodo: 'todos/fetchTodo',
            actionShowIssueDialog: 'issues/setIssueDialog',
            actionShowTodoDialog: 'todos/setTodoDialog',
            actionShowPotentialDialog: 'potentials/setPotentialDialog',
            setIssuesFilter: 'issues/setIssuesFilter',
            setTask: 'projects/setTask',
            setShowTaskContextMenu: 'projects/setShowTaskContextMenu'
        }),

        parseData: function() {
            const self = this
            this.loading = true
            const issues = webix.copy(this.project.issues)
            var tasks=[];

            issues.map(function(value, key) {
                let data = []
                if (self.treeFilter.todos) {
                    data.push(value.todos)
                }

                if (self.treeFilter.potentials) {
                    data.push(value.potentials)
                }

                Vue.set(value, "data", _.flatten(data))
                tasks.push(value);
            });
            this.webixId.clearAll()
            this.webixId.parse(tasks)
            if(this.tableId in this.activeFilters ) {
                this.webixId.setState(JSON.parse(this.activeFilters[this.tableId].filter_data))
            }
        },


        filterTable(filter) {
            this.webixId.eachColumn(function(id, col){
                var columnFilter = this.getFilter(id);
                if (columnFilter){
                    if (columnFilter.setValue) columnFilter.setValue("")
                    else columnFilter.value = "";
                }
            });

            this.webixId.filter(function(obj){
                if (!filter.todos) {
                    return obj.data_type !== 'todo';
                }
                return true

            });

        },

        getIssue(id) {
            let parentId = this.webixId.getParentId(id)
            let parent = this.webixId.getItem(parentId)
            if (parent.data_type === 'issue') {
                this.fetchIssue(parent.id)
            }
            else {
                this.getIssue(parent.id)
            }
        }
    },

    mounted() {
        const self = this

        const endpoint = this.endpoint

        this.datatableExists = true

        this.webixId = webix.ui({
            container: this.$el,
            $scope: this,
            view:"treetable",
            height: window.innerHeight - 200,
            id: self.tableId,
            headermenu:{
                width:250,
            },
            resizeColumn: true,
            select: "row",
            dragColumn:true,
            headerRowHeight:40,
            footer:false,
            columns: [

                {
                    id: "number",
                    header: [self.$lang('no.'), {content: "textFilter"}],

                    sort: "int",
                },
                {
                    id: "data_type_label",
                    header: [self.$lang('type'), {content: "textFilter"}],
                    sort: "string",
                    template:"{common.space()}{common.icon()} #data_type_label# "
                },
                {
                    id: "issue_status_label",
                    header: [self.$lang('status'), {
                        content: "multiSelectFilter",
                        suggest: {fitMaster: false, width: 200}
                    }],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        if(obj.issue_status_label) {
                            return obj.issue_status_label
                        }
                        return ''
                    }
                },
                {
                    id: "title",
                    header: [self.$lang('title'), {content: "textFilter"}],
                    footer: self.$lang('total'),
                    fillspace: true,
                    minWidth: 200,
                    sort: "string",
                    adjust: false,
                    template: "#title_link#"
                },

                {
                    id: "assignee_label",
                    header: [self.$lang('responsible'), {
                        content: "multiSelectFilter",
                        suggest: {fitMaster: false, width: 200}
                    }],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        if(obj.assignee_label) {
                            return obj.assignee_label
                        }
                        return ''
                    }
                },

                {
                    id: "total_potential",
                    header: [self.$lang('potential')   + " " + self.user.company.currency, {content: "textFilter"}],
                    footer: {content: "summColumn"},
                    adjust: false,
                    sort: "int",
                    css:{'text-align':'right'},
                    template: function (obj) {
                        return self.$num(obj.total_potential, 0)
                    }
                },

                {
                    id: "total_invest",
                    header: [self.$lang('invest')   + " " + self.user.company.currency, {content: "textFilter"}],
                    footer: {content: "summColumn"},
                    adjust: false,
                    sort: "int",
                    css:{'text-align':'right'},
                    template: function (obj) {
                        return self.$num(obj.total_invest, 0)
                    }
                },

                {
                    id: "lost_potential",
                    header: [self.$lang('lost potential')   + " " + self.user.company.currency, {content: "textFilter"}],
                    footer: {content: "summColumn"},
                    adjust: false,
                    sort: "int",
                    css:{'text-align':'right'},
                    template: function (obj) {
                        return self.$num(obj.lost_potential, 0)
                    }
                },

                {
                    id: "hardness_degree_label",
                    header: [self.$lang('HG'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 180}}],
                    minWidth: 50,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        if(obj.hardness_degree_label) {
                            return obj.hardness_degree_label
                        }
                        return ''
                    }
                },
                {
                    id: "progress",
                    header: [self.$lang('progress'), {content: "textFilter"}],
                    sort: "string",
                    adjust: false,
                    css:{'text-align':'right'},
                    template: function (obj) {
                        if (obj.progress) {
                            return obj.progress + '%'
                        }
                        return ''
                    }
                },
                {
                    id: "due_date_status_label",
                    header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 180}}],
                    minWidth: 50,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        if(obj.due_date_status_label) {
                            return obj.due_date_status_label
                        }
                        return ''
                    }
                },

                {
                    id: "start_date",
                    map: "(date)#start_date#",
                    header: [self.$lang('start'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        if(obj.start_date) {
                            return webix.i18n.parseFormatStr(obj.start_date)
                        }
                        return ''
                    }
                },

                {
                    id: "end_date",
                    map: "(date)#end_date#",
                    header: [self.$lang('end'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.end_date)
                    }
                },

                {
                    id: "creator_name",
                    header: [self.$lang('creator'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 100}}],
                    adjust: false,
                    sort: "string"
                },

                {
                    id: "created_at",
                    map: "(date)#created_at#",
                    header: [self.$lang('created'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.created_at)
                    }
                }
            ],
            onClick:{
                "title_link" : function  (event, row, target) {
                    let record = this.getItem(row.row)
                    switch (record.data_type) {
                        case 'issue':
                            self.setIssue(row.row).then(function () {
                                self.actionShowIssueDialog(true)
                            })
                            break
                        case 'todo':
                            self.fetchTodo(row.row).then(function () {
                                self.actionShowTodoDialog(true)
                                self.getIssue(row.row)
                            })
                            break
                        case 'potential':
                            self.fetchPotential(row.row).then(function () {
                                self.actionShowPotentialDialog(true)
                            })
                    }
                }
            },
            on: {
                onAfterSelect: function(){
                    if(self.enableSelect) {
                        self.setIssue(this.getSelectedId(true)[0].id)
                    }
                },

                onItemDblClick: function (row) {
                    self.setIssue(row.row).then(function () {
                        self.actionShowIssueDialog(true)
                    })
                },

                onBeforeContextMenu: function (id, event, node) {
                    if(!_.includes(self.project.user_permissions, 'edit')) {
                        return false
                    }
                        self.setTask( this.getItem(id))
                        self.setShowTaskContextMenu(event)
                    event.preventDefault()
                    return false
                },

                onAfterLoad: function () {
                    self.loading = false

                    if(!self.defaultState) {
                        self.defaultState = this.getState()
                    }

                    this.eachRow(function(id){
                        const row = this.getItem(id)
                        this.addRowCss(id, "issueBackground");
                        if (row.data_type === 'issue') {

                        }
                    });

                    if(self.treeFilter.expandAll) {
                        this.openAll()
                    }

                },

                onAfterFilter: function () {
                    self.setIssuesFilter('')
                }
            },
        })

        Bus.$on('project:updated', function () {
            if(self.datatableExists) {
                self.parseData()
            }
        });

        Bus.$on('filterTable:' + self.tableId, function (data) {
            if(self.datatableExists) {
                if(!self.defaultState) {
                    self.defaultState = $$(self.webixId).getState()
                }
                $$(self.webixId).setState(JSON.parse(data))
            }
        });

        Bus.$on('resetFilterTable:' + self.tableId, function () {
            if(self.datatableExists) {
                $$(self.webixId).setState(self.defaultState)
            }
        });

        this.parseData()
    },

    watch: {
        treeFilter: {
            handler: function (filter) {
                this.loading = true
                this.parseData()
                if(filter.expandAll) {
                    $$(this.webixId).openAll()
                }
            },
            deep: true
        },

        project: {
            handler: function (filter) {
                this.loading = true
                this.parseData()
                if(filter.expandAll) {
                    $$(this.webixId).openAll()
                }
            },

        },
    },

    destroyed:function(){
        this.datatableExists = false
        webix.$$(this.webixId).destructor();
    }
}
</script>

<style >

</style>
