<template>
    <v-row justify="center">
        <v-dialog
            persistent
            :value="showCreatePotentialDialog"
            width="1200"
            @click:outside="confirmCloseDialog = true"
            @keydown.esc="confirmCloseDialog = true"
        >
            <v-card >

                <v-card-title>
                    <span class="headline">{{ $lang('create potential/invest')}}</span>
                    <v-spacer></v-spacer>
                    <CalculateAndShowPotential v-bind:form="form"  @update-potential="updatePotential"  @update-invest="updateInvest" :show="true"></CalculateAndShowPotential>
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        outlined
                        color="primary"
                        @click="closeDialog"
                    >
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <CalculateAndShowPotential v-bind:form="form"  @update-potential="updatePotential"  @update-invest="updateInvest" :show="false"></CalculateAndShowPotential>
                    <v-form
                        ref="form"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                md="3"
                            >
                                <v-select
                                    v-model="form.type"
                                    :items="types"
                                    :label="$lang('type')"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.type"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    :label="$lang('title')"
                                    v-model="form.title"
                                    required
                                    autofocus
                                    :error-messages="errors.title"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                md="3"
                            >
                                <v-select
                                    v-model="form.active"
                                    :items="active"
                                    :label="$lang('active')"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.active"
                                ></v-select>
                            </v-col>
                        </v-row>

                        <Editor :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event"></Editor>
                        <template v-if="form.type === 'time'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-select
                                        v-model="form.unit"
                                        :items="units"
                                        :label="$lang('unit')"
                                        item-text="name"
                                        item-value="id"
                                        :error-messages="errors.unit"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="$lang('quantity p.a.')"
                                        v-model="inputs.quantity"
                                        :error-messages="errors.quantity"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="$lang('current time')"
                                        v-model="inputs.current"
                                        :error-messages="errors.current"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >

                                    <v-text-field
                                        :label="$lang('target time')"
                                        v-model="inputs.target"
                                        :error-messages="errors.target"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="$lang('hourly rate machine')"
                                        v-model="inputs.hourly_rate_machine"
                                        :error-messages="errors.hourly_rate_machine"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >

                                    <v-text-field
                                        :label="$lang('hourly rate personnel')"
                                        v-model="inputs.hourly_rate_staff"
                                        :error-messages="errors.hourly_rate_staff"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="12"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('probability')"
                                        v-model="form.chance"
                                        thumb-label="always"
                                        step="5"
                                    ></v-slider>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-if="form.type === 'timeAbsolut'">

                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-select
                                        v-model="form.unit"
                                        :items="units"
                                        :label="$lang('time unit')"
                                        item-text="name"
                                        item-value="id"
                                        :error-messages="errors.unit"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="$lang('quantity (qty p.a.)')"
                                        v-model="inputs.quantity"
                                        :error-messages="errors.quantity"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>


                            <v-row>

                                <v-col
                                    cols="12"
                                    md="4"
                                >

                                    <v-text-field
                                        :label="$lang('time saving')"
                                        v-model="inputs.target"
                                        :error-messages="errors.target"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="4"
                                >
                                    <v-text-field
                                        :label="$lang('hourly rate machine')"
                                        v-model="inputs.hourly_rate_machine"
                                        :error-messages="errors.hourly_rate_machine"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="4"
                                >
                                    <v-text-field
                                        :label="$lang('hourly rate personnel')"
                                        v-model="inputs.hourly_rate_staff"
                                        :error-messages="errors.hourly_rate_staff"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="12"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('probability')"
                                        v-model="form.chance"
                                        thumb-label="always"
                                        step="5"
                                    ></v-slider>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-if="form.type === 'cost'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('current costs')} ${company.currency}`"
                                        v-model="inputs.current"
                                        :error-messages="errors.current"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="$lang('quantity (qty p.a.)')"
                                        v-model="inputs.quantity"
                                        :error-messages="errors.quantity"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('material single cost savings')} ${company.currency}`"
                                        v-model="inputs.material_single_cost_savings"
                                        :error-messages="errors.material_single_cost_savings"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >

                                    <v-text-field
                                        :label="`${$lang('material common cost savings')} ${company.currency}`"
                                        v-model="inputs.material_common_cost_savings"
                                        :error-messages="errors.material_common_cost_savings"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    md="12"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('probability')"
                                        v-model="form.chance"
                                        thumb-label="always"
                                        step="5"
                                    ></v-slider>
                                </v-col>
                            </v-row>

                        </template>
                        <template v-if="form.type === 'capital'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('current capital commitment')} ${company.currency}`"
                                        v-model="inputs.current"
                                        :error-messages="errors.current"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('target capital commitment')} ${company.currency}`"
                                        v-model="inputs.target"
                                        :error-messages="errors.target"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>


                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('expected interest rate')"
                                        v-model="inputs.interest"
                                        thumb-label="always"
                                        step="0.1"
                                        max="10"
                                    ></v-slider>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('probability')"
                                        v-model="form.chance"
                                        thumb-label="always"
                                        step="5"
                                    ></v-slider>
                                </v-col>
                            </v-row>

                        </template>
                        <template v-if="form.type === 'sale'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('current turnover')} ${company.currency}`"
                                        v-model="inputs.current"
                                        :error-messages="errors.current"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('target turnover')} ${company.currency}`"
                                        v-model="inputs.target"
                                        :error-messages="errors.target"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>


                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('ebit in %')"
                                        v-model="inputs.ebit"
                                        thumb-label="always"
                                        step="0.1"
                                        max="100"
                                    ></v-slider>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('probability')"
                                        v-model="form.chance"
                                        thumb-label="always"
                                        step="5"
                                    ></v-slider>
                                </v-col>
                            </v-row>

                        </template>
                        <template v-if="form.type === 'area'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        v-model="inputs.current"
                                        :error-messages="errors.current"
                                    >
                                        <template #label>
                                            <label>{{ $lang('current area')}} (in m<sup>2</sup>)</label>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        v-model="inputs.target"
                                        :error-messages="errors.target"
                                    >
                                        <template #label>
                                            <label>{{ $lang('target area')}} (in m<sup>2</sup>)</label>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>


                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('area price')} ${company.currency}`"
                                        v-model="inputs.price"
                                        :error-messages="errors.price"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('probability')"
                                        v-model="form.chance"
                                        thumb-label="always"
                                        step="5"

                                    ></v-slider>
                                </v-col>
                            </v-row>

                        </template>
                        <template v-if="form.type === 'unique'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('sales price')} ${company.currency}`"
                                        v-model="inputs.price"
                                        :error-messages="errors.price"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('probability')"
                                        v-model="form.chance"
                                        thumb-label="always"
                                        step="5"

                                    ></v-slider>
                                </v-col>
                            </v-row>

                        </template>
                        <template v-if="form.type === 'invest'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('purchasing costs')} ${company.currency}`"
                                        v-model="inputs.purchasing_cost"
                                        :error-messages="errors.purchasing_cost"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="$lang('quantity')"
                                        v-model="inputs.quantity"
                                        :error-messages="errors.quantity"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('hourly rate production')} ${company.currency}`"
                                        v-model="inputs.hourly_rate_production"
                                        :error-messages="errors.hourly_rate_production"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >

                                    <v-text-field
                                        :label="`${$lang('hourly rate administration')} ${company.currency}`"
                                        v-model="inputs.hourly_rate"
                                        :error-messages="errors.hourly_rate"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="$lang('time invest production')"
                                        v-model="inputs.time_invest_production"
                                        :error-messages="errors.time_invest_production"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >

                                    <v-text-field
                                        :label="$lang('time invest administration')"
                                        v-model="inputs.time_invest"
                                        :error-messages="errors.time_invest"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </template>
                        <template v-if="form.type === 'materialInvest'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('purchasing costs')} ${company.currency}`"
                                        v-model="inputs.purchasing_cost"
                                        :error-messages="errors.purchasing_cost"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="$lang('quantity')"
                                        v-model="inputs.quantity"
                                        :error-messages="errors.quantity"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </template>
                        <template v-if="form.type === 'workInvestIntern'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        :label="`${$lang('hourly rate')} ${company.currency}`"
                                        v-model="inputs.hourly_rate"
                                        :error-messages="errors.hourly_rate"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >

                                    <v-text-field
                                        :label="$lang('time invest')"
                                        v-model="inputs.time_invest"
                                        :error-messages="errors.time_invest"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </template>
                        <template v-if="form.type === 'workInvestExtern'">
                            <v-row>

                                <v-col
                                    cols="12"
                                    md="4"
                                >
                                    <v-text-field
                                        :label="`${$lang('rate')} ${company.currency}`"
                                        v-model="inputs.hourly_rate"
                                        :error-messages="errors.hourly_rate"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="4"
                                >
                                    <v-select
                                        v-model="form.unit"
                                        :items="consultingUnits"
                                        :label="$lang('time unit')"
                                        item-text="name"
                                        item-value="id"
                                        :error-messages="errors.unit"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="4"
                                >
                                    <v-text-field
                                        :label="$lang('time invest')"
                                        v-model="inputs.time_invest"
                                        :error-messages="errors.time_invest"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </template>
                        <template v-if="form.type === 'deliveryReliabilityIncrease'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="12"
                                    class="pt-8"
                                >
                                    <v-slider
                                        :label="$lang('% delivery reliability increase')"
                                        v-model="form.increase"
                                        thumb-label="always"
                                        step="1"
                                        max="100"
                                    ></v-slider>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-if="form.type === 'leadTimeReduction'">
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-select
                                        v-model="form.unit"
                                        :items="units"
                                        :label="$lang('unit')"
                                        item-text="name"
                                        item-value="id"
                                        :error-messages="errors.unit"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="9"
                                >
                                    <v-text-field
                                        :label="$lang('lead time reduction')"
                                        v-model="inputs.reduction"
                                        :error-messages="errors.reduction"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </template>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="submit" :disabled="form.type && !form.type.length">{{ $lang('save') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="closeDialog">{{ $lang('close') }}</v-btn>
                </v-card-actions>
            </v-card>
            <v-dialog
                v-model="confirmCloseDialog"
                width="800"
            >
                <v-card>
                    <v-card-title
                        primary-title
                        class="justify-center"
                    >
                        {{$lang('close dialog')}}
                    </v-card-title>
                    <v-card-text
                        class="text-center"
                    >
                        <strong>
                            {{$lang('do you really want to close without saving?')}}
                        </strong>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="primary"
                            text
                            @click="saveAndClose"
                        >
                            {{$lang('save and close')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="secondary"
                            text
                            @click="closeWithoutSaving"
                        >
                            {{$lang('close without saving')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="confirmCloseDialog = false"
                        >
                            {{$lang('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
    </v-row>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";

    import Bus from "../../../bus";
    import CalculateAndShowPotential from "./CalculateAndShowPotential";
    import Editor from "../../../components/Editor";


    export default {
        name: "CreatePotentialDialog",
        components: {
            CalculateAndShowPotential,
            Editor: Editor
        },

        data() {
            return {
                modal: false,
                errors: [],
                confirmCloseDialog: false,
                form: {
                    title: '',
                    body: '',
                    tokens: [],
                    type: '',
                    active: 1,
                    unit: 'seconds',
                    current: '',
                    target: '',
                    hourly_rate_machine: '',
                    hourly_rate_staff: '',
                    quantity: '',
                    chance: 100,
                    potential: 0,
                    material_single_cost_savings: '',
                    material_common_cost_savings: '',
                    purchasing_cost: '',
                    hourly_rate_production: '',
                    hourly_rate: '',
                    time_invest_production: '',
                    time_invest: '',
                    invest: 0,
                    interest: 0,
                    ebit: 0,
                    price:0,
                    increase: 0,
                    days: 0,
                    reduction: 0

                },

                inputs: {
                    current: '',
                    target: '',
                    hourly_rate_machine: '',
                    hourly_rate_staff: '',
                    quantity: '',
                    material_single_cost_savings: '',
                    material_common_cost_savings: '',
                    purchasing_cost: '',
                    hourly_rate_production: '',
                    hourly_rate: '',
                    time_invest_production: '',
                    time_invest: '',
                    invest: 0,
                    interest: 0,
                    ebit: 0,
                    price:0,
                    reduction: 0
                },

                types: [
                    {id: 'time', name: this.$lang('time potential relative')},
                    {id: 'timeAbsolut',  name: this.$lang('time potential absolute')},
                    {id: 'cost', name: this.$lang('cost potential')},
                    {id: 'capital', name: this.$lang('capital potential')},
                    {id: 'sale', name: this.$lang('turnover potential')},
                    {id: 'area', name: this.$lang('area potential')},
                    {id: 'unique', name: this.$lang('single potential')},
                    {id: 'materialInvest', name: this.$lang('material invest')},
                    {id: 'workInvestIntern', name: this.$lang('work invest internal')},
                    {id: 'workInvestExtern', name: this.$lang('work invest external')},
                    {id: 'deliveryReliabilityIncrease', name: this.$lang('delivery reliability increase')},
                    {id: 'leadTimeReduction', name: this.$lang('lead time reduction')}
                ],
                active: [
                    {id: 1, name: this.$lang('active')},
                    {id: 0, name: this.$lang('inactive')},
                ],
                units: [
                    {id: 'seconds', name: this.$lang('seconds')},
                    {id: 'minutes', name: this.$lang('minutes')},
                    {id: 'hours', name: this.$lang('hours')},
                ],
                consultingUnits: [
                    {id: 'hour', name: this.$lang('hour')},
                    {id: 'day', name: this.$lang('day')},
                ]
            }
        },

        computed: {
            ...mapGetters({
                me: 'auth/user',
                showCreatePotentialDialog: 'potentials/showCreatePotentialDialog',
                activeUsers: 'users/activeUsers',
                company: 'companyAdminCompany/company'
            }),
            decimal_delimiter: {
                get: function () {
                    return this.me.number_format.decimal_delimiter
                },
            },
        },

        methods: {
            ...mapActions({
                store: 'potentials/store',
                actionShowCreatePotentialDialog: 'potentials/setCreatePotentialDialog',
            }),

            updatePotential(val) {
                this.form.potential = val
            },

            updateInvest(val) {
                this.form.invest = val
            },

            submit () {
                const self = this
                this.errors = []
                this.form.tokens= []
                this.store({
                    payload: this.form,
                    type: this.form.type,
                    context: this,
                }).then(() => {
                    if(this.errors.length === 0) {
                        this.dialog = false
                        this.$refs.form.reset()
                        Bus.$emit('potential:created');
                        this.actionShowCreatePotentialDialog({value: false, endpoint: null}).then(function () {
                            Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('potential successfully created')});
                        })
                    }
                })
            },

            saveAndClose() {
                this.confirmCloseDialog = false
                this.submit()
            },

            closeWithoutSaving() {
                this.$refs.form.reset()
                this.errors = []
                this.closeDialog()
            },

            closeDialog () {
                this.actionShowCreatePotentialDialog({value: false, endpoint: null})
            },
        },

        watch: {

            'inputs.current' : {
                handler: function(value) {
                    this.form.current = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.target' : {
                handler: function(value) {
                    this.form.target = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.hourly_rate_machine' : {
                handler: function(value) {
                    this.form.hourly_rate_machine = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.hourly_rate_staff' : {
                handler: function(value) {
                    this.form.hourly_rate_staff = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.quantity' : {
                handler: function(value) {
                    this.form.quantity = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.material_single_cost_savings' : {
                handler: function(value) {
                    this.form.material_single_cost_savings = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.material_common_cost_savings' : {
                handler: function(value) {
                    this.form.material_common_cost_savings = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.purchasing_cost' : {
                handler: function(value) {
                    this.form.purchasing_cost = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.hourly_rate_production' : {
                handler: function(value) {
                    this.form.hourly_rate_production = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.hourly_rate' : {
                handler: function(value) {
                    this.form.hourly_rate = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.time_invest' : {
                handler: function(value) {
                    this.form.time_invest = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.invest' : {
                handler: function(value) {
                    this.form.invest = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.interest' : {
                handler: function(value) {
                    this.form.interest = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.ebit' : {
                handler: function(value) {
                    this.form.ebit = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.price' : {
                handler: function(value) {
                    this.form.price = _.replace(value, this.decimal_delimiter, '.');
                },
            },

            'inputs.reduction' : {
                handler: function(value) {
                    this.form.reduction = _.replace(value, this.decimal_delimiter, '.');
                },
            },
        },

    }
</script>

<style scoped>

</style>
