import { Companies, Company} from '../views'


export default [
    {
        path: '/admin/companies',
        component: Companies,
        name: 'companies',
        meta: {
            guest: false,
            needsAuth: true,
            role: 'Admin'
        }
    },
    {
        path: '/admin/companies/:companyId/show',
        component: Company,
        name: 'companies.show',
        meta: {
            guest: false,
            needsAuth: true,
            role: 'Admin'
        },
        props: true
    }

]

