import Vue from 'vue'
import Vuex from 'vuex'
import app from '../app/vuex'
import auth from '../app/auth/vuex'
import layout from '../app/layout/vuex'
import products from '../app/products/vuex'
import projects from '../app/projects/vuex'

import masterData from '../app/masterData/vuex'
import projectStatuses from '../app/masterData/projectStatuses/vuex'
import projectTypes from '../app/masterData/projectTypes/vuex'
import locations from '../app/masterData/locations/vuex'
import divisions from '../app/masterData/divisions/vuex'
import departments from '../app/masterData/departments/vuex'
import workplaces from '../app/masterData/workplaces/vuex'
import costCenters from '../app/masterData/costCenters/vuex'
import masterProducts from '../app/masterData/products/vuex'
import issueTags from '../app/masterData/issueTags/vuex'
import tableFilters from '../app/helpers/tableFilters/vuex'

import lang from '../app/masterData/lang/vuex'

import hardnessDegrees from '../app/masterData/hardnessDegrees/vuex'


import notes from '../app/notes/vuex'
import issues from '../app/issues/vuex'
import solexes from '../app/issues/solexes/vuex'
import todos from '../app/todos/vuex'
import potentials from '../app/potentials/vuex'
import lists from '../app/lists/vuex'
import fileManager from '../app/fileManager/vuex'

import account from '../app/account/vuex'
import notifications from '../app/notifications/vuex'


import adminUsers from '../app/admin/users/vuex'
import adminCompanies from '../app/admin/companies/vuex'
import adminPermissions from '../app/admin/permissions/vuex'
import adminRoles from '../app/admin/roles/vuex'

import companyAdminUsers from '../app/companyAdmin/users/vuex'
import companyAdminCompany from '../app/companyAdmin/company/vuex'
import companyAdminPermissions from '../app/companyAdmin/permissions/vuex'
import companyAdminRoles from '../app/companyAdmin/roles/vuex'
import users from '../app/users/vuex'

import controlling from '../app/controlling/vuex'



import scanner from '../app/scanner/vuex'
import suppliers from '../app/masterData/suppliers/vuex'
import procurementKanban from '../app/procurement/kanban/vuex'
import salesKanbanOrders from '../app/sales/kanbanOrders/vuex'
import salesKanbanCircuits from '../app/sales/kanbanCircuits/vuex'
import kanbanCircuits from '../app/masterData/kanbanCircuits/vuex'
import networkSuppliers from '../app/masterData/networkSuppliers/vuex'
import mobileDashboard from '../app/mobileDashboard/vuex'
import procurementDashboard from '../app/procurement/dashboard/vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app: app,
        layout: layout,
        auth: auth,
        products: products,
        projects: projects,

        masterData: masterData,
        projectStatuses: projectStatuses,
        projectTypes: projectTypes,
        locations:locations,
        divisions: divisions,
        departments: departments,
        workplaces: workplaces,
        costCenters: costCenters,
        masterProducts: masterProducts,
        issueTags: issueTags,
        tableFilters: tableFilters,

        lang: lang,
        hardnessDegrees: hardnessDegrees,


        notes: notes,
        issues: issues,
        solexes:solexes,
        users: users,
        todos: todos,
        potentials: potentials,
        lists: lists,
        fileManager: fileManager,
        account: account,
        notifications: notifications,

        adminUsers: adminUsers,
        adminCompanies: adminCompanies,
        adminPermissions: adminPermissions,
        adminRoles: adminRoles,

        controlling: controlling,



        companyAdminUsers: companyAdminUsers,
        companyAdminCompany: companyAdminCompany,
        companyAdminPermissions: companyAdminPermissions,
        companyAdminRoles: companyAdminRoles,


        scanner: scanner,
        suppliers:suppliers,
        procurementKanban: procurementKanban,
        salesKanbanOrders: salesKanbanOrders,
        kanbanCircuits:kanbanCircuits,
        networkSuppliers:networkSuppliers,
        salesKanbanCircuits: salesKanbanCircuits,


        mobileDashboard: mobileDashboard,
        procurementDashboard:procurementDashboard

    }
})
