export const SET_KANBAN_CIRCUIT_DATA = (state, data) => {
    state.kanbanCircuit = data
}

export const SET_KANBAN_ORDERS_DATA = (state, data) => {
    state.kanbanOrders = data
}

export const SET_KANBAN_ORDER_DATA = (state, data) => {
    state.kanbanOrder = data
}

export const SET_KANBAN_ORDER_DIALOG = (state, data) => {
    state.showKanbanOrderDialog = data
}
