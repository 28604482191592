<template>
    <div>
        <v-btn
            v-if="!treeFilter.expandAll"
            class="mr-2"
            small
            :outlined="true"
            color="primary"
            @click="toggleTreeFilterExpandAll()"
        >
            {{ $lang('show all')}}
        </v-btn>

        <v-btn
            v-if="treeFilter.expandAll"
            class="mr-2"
            small
            color="primary"
            @click="toggleTreeFilterExpandAll()"
        >
            {{ $lang('collapse all')}}
        </v-btn>

        <v-btn
            class="mr-2"
            small
            :outlined="!treeFilter.todos"
            color="primary"
            @click="toggleTreeFilterTodos()"
        >
            {{ $lang('todos')}}
        </v-btn>

        <v-btn
            class="mr-2"
            small
            :outlined="!treeFilter.potentials"
            color="primary"
            @click="toggleTreeFilterPotentials()"
        >
            {{ $lang('potentials')}}
        </v-btn>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "TreeFilter",
        computed: {
            ...mapGetters({
                treeFilter: 'projects/treeFilter',
            })
        },
        methods: {
            ...mapActions({
                toggleTreeFilterTodos: 'projects/toggleTreeFilterTodos',
                toggleTreeFilterPotentials: 'projects/toggleTreeFilterPotentials',
                toggleTreeFilterExpandAll: 'projects/toggleTreeFilterExpandAll'
            }),
        }
    }
</script>

<style scoped>

</style>
