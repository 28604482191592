export const SET_COMPANIES_DATA = (state, data) => {
    state.companies = data
}

export const SET_COMPANY_DATA = (state, data) => {
    state.company = data
}


export const SET_COMPANY_PLAN_DATA = (state, data) => {
    state.companyPlan = data
}

export const SET_SHOW_EDIT_COMPANY_PLAN_DIALOG = (state, value) => {
    state.showEditCompanyPlanDialog = value
}
