<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ $lang('departments') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="showCreateDepartmentDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('department') }}
            </v-btn>
        </v-toolbar>

        <DepartmentsTable></DepartmentsTable>
        <CreateDepartmentDialog></CreateDepartmentDialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import DepartmentsTable from "../components/DepartmentsTable";
    import CreateDepartmentDialog from "../components/CreateDepartmentDialog";
    export default {
        name: "Departments",
        components: {
            DepartmentsTable,
            CreateDepartmentDialog
        },

        methods: {
            ...mapActions({
                showCreateDepartmentDialog: 'departments/setCreateDepartmentDialog',
            }),
        },
    }
</script>

<style scoped>

</style>
