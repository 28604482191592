export const setAuthenticated = (state, value) => {
    state.authenticated = value
}

export const setUserData = (state, data) => {
    state.user = data
}

export const SET_LOGIN_ERROR = (state, data) => {
    state.loginError = data
}


export const SET_SHOW_LOGOUT_MESSAGE = (state, value) => {
    state.showLogoutMessage = value
}
