<template>
    <v-tabs >
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="edit">
            <EditProduct></EditProduct>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteProduct ></DeleteProduct>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";
    export default {
        name: "ProductTab",

        components: {
            EditProduct,
            DeleteProduct
        },

        methods: {

        },

    }
</script>

<style scoped>

</style>
