<template>
    <div>
<!--        <v-switch-->
<!--            v-model="part_function"-->
<!--            label="Artikel"-->
<!--            disabled-->
<!--        ></v-switch>-->

        <v-switch
            v-model="potential_function"
            label="Verbesserungen"
        ></v-switch>

        <v-divider>

        </v-divider>
        <v-btn
            class="mt-2"
            color="blue darken-1"
            text
            @click="submit"
        >{{ $lang('save') }}</v-btn>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "ProjectFunctions",

        data() {
            return {
                part_function: '',
                potential_function: ''
            }
        },

        computed: {
            ...mapGetters({
                project: 'projects/project',
                users: 'users/users'
            }),

        },

        created() {
            this.part_function = this.project.part_function
            this.potential_function = this.project.potential_function

        },

        methods: {
            ...mapActions({
                updateFunctions: 'projects/updateFunctions',
            }),
            submit () {

                this.updateFunctions({
                    id: this.project.id,
                    payload: {
                        part_function: this.part_function,
                        potential_function: this.potential_function
                    },
                }).then(() => {
                    Bus.$emit('showAlert', {color : 'success', 'message' : 'Funktionen erfolgreich geändert.'})
                })

            }
        }

    }
</script>

<style scoped>

</style>
