<template>
    <v-card>
        <v-card-title>
            Kalkulation Lizenzkosten 7app
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <p class="font-weight-black">
                        Währung
                    </p>
                    <v-radio-group
                        v-model="currency"
                        row
                    >
                        <v-radio
                            label="EUR"
                            value="EUR"
                        ></v-radio>
                        <v-radio
                            label="CHF"
                            value="CHF"
                        ></v-radio>
                    </v-radio-group>

                    <p class="font-weight-black">
                        Zahlmodell
                    </p>
                    <v-radio-group
                        v-model="payment"
                        row
                    >
                        <v-radio
                            label="Jährlich"
                            value="yearly"
                        ></v-radio>
                        <v-radio
                            label="Monatlich"
                            value="monthly"
                        ></v-radio>
                    </v-radio-group>

                    <p class="font-weight-black">
                        Anzahl Benutzer
                    </p>
                    <v-radio-group
                        v-model="numUsers"
                        row
                    >
                        <v-radio
                            :value="10"
                        >
                            <template v-slot:label>
                                <div>10 <small class="primary--text">({{ userLabelPrice(10) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="20"
                        >
                            <template v-slot:label>
                                <div>20 <small class="primary--text">({{ userLabelPrice(20) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="50"
                        >
                            <template v-slot:label>
                                <div>50 <small class="primary--text">({{ userLabelPrice(50) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="100"
                        >
                            <template v-slot:label>
                                <div>100 <small class="primary--text">({{ userLabelPrice(100) }})</small></div>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <p class="font-weight-black">
                        Module
                    </p>

                    <v-checkbox
                        v-model="projects"
                    >
                        <template v-slot:label>
                            <div>Projekte <small class="primary--text">({{projectsBasicLicense[payment].toFixed(2)}})</small></div>
                        </template>
                    </v-checkbox>

                    <v-checkbox
                        class="mt-0"
                        v-model="pmt"
                    >
                        <template v-slot:label>
                            <div>PMT <small class="primary--text">({{pmtBasicLicense[payment].toFixed(2)}})</small></div>
                        </template>
                    </v-checkbox>

                    <p class="font-weight-black">
                        Smart Tables (Anzahl Tabellen)
                    </p>

                    <v-checkbox
                        v-model="smartTable"
                    >
                        <template v-slot:label>
                            <div>Smart Table <small class="primary--text">({{smartTableBasicLicense[payment].toFixed(2)}})</small></div>
                        </template>
                    </v-checkbox>

                    <v-radio-group
                        class="mt-0"
                        v-model="numSmartTableLicences"
                        row
                    >
                        <v-radio
                            :value="5"
                        >
                            <template v-slot:label>
                                <div>5 <small class="primary--text">({{ smartTableLabelPrice(5) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="10"
                        >
                            <template v-slot:label>
                                <div>10 <small class="primary--text">({{ smartTableLabelPrice(10) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="20"
                        >
                            <template v-slot:label>
                                <div>20 <small class="primary--text">({{ smartTableLabelPrice(20) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="50"
                        >
                            <template v-slot:label>
                                <div>50 <small class="primary--text">({{ smartTableLabelPrice(50) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="100"
                        >
                            <template v-slot:label>
                                <div>100 <small class="primary--text">({{ smartTableLabelPrice(100) }})</small></div>
                            </template>
                        </v-radio>

                    </v-radio-group>

                    <p class="font-weight-black">
                        Kanban (Anzahl Kanban Regelkreise)
                    </p>

                    <v-checkbox
                        v-model="kanban"
                    >
                        <template v-slot:label>
                            <div>Kanban <small class="primary--text">({{kanbanBasicLicense[payment].toFixed(2)}})</small></div>
                        </template>
                    </v-checkbox>

                    <v-radio-group
                        class="mt-0"
                        v-model="numKanbanCircuitLicences"
                        row
                    >
                        <v-radio
                            :value="10"
                        >
                            <template v-slot:label>
                                <div>10 <small class="primary--text">({{ kanbanLabelPrice(10) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="20"
                        >
                            <template v-slot:label>
                                <div>20 <small class="primary--text">({{ kanbanLabelPrice(20) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="50"
                        >
                            <template v-slot:label>
                                <div>50 <small class="primary--text">({{ kanbanLabelPrice(50) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="100"
                        >
                            <template v-slot:label>
                                <div>100 <small class="primary--text">({{ kanbanLabelPrice(100) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="200"
                        >
                            <template v-slot:label>
                                <div>200 <small class="primary--text">({{ kanbanLabelPrice(200) }})</small></div>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <p class="font-weight-black">
                        Speicherplatz GB
                    </p>

                    <v-radio-group
                        class="mt-0"
                        v-model="storageSize"
                        row
                    >
                        <v-radio
                            :value="5"
                        >
                            <template v-slot:label>
                                <div>5 GB <small class="primary--text">({{ storageLabelPrice(5) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="10"
                        >
                            <template v-slot:label>
                                <div>10 GB <small class="primary--text">({{ storageLabelPrice(10) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="20"
                        >
                            <template v-slot:label>
                                <div>20 GB <small class="primary--text">({{ storageLabelPrice(20) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="50"
                        >
                            <template v-slot:label>
                                <div>50 GB <small class="primary--text">({{ storageLabelPrice(50) }})</small></div>
                            </template>
                        </v-radio>
                        <v-radio
                            :value="100"
                        >
                            <template v-slot:label>
                                <div>100 GB <small class="primary--text">({{ storageLabelPrice(100) }})</small></div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-col>

                <v-col>
                    <p class="font-weight-black">
                        Kalkulation
                    </p>

                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Position
                                    </th>
                                    <th class="text-right">
                                        Preis in {{currency}}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        Benutzerlizenzen
                                    </td>
                                    <td class="text-right">
                                        {{totalUserLicenses}} {{currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Projekte
                                    </td>
                                    <td class="text-right">
                                        {{ totalProjectLicense }} {{currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        PMT
                                    </td>
                                    <td class="text-right">
                                        {{ totalPmtLicense }} {{currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Smart Table
                                    </td>
                                    <td class="text-right">
                                        {{ totalSmartTableLicense }} {{currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tabellen
                                    </td>
                                    <td class="text-right">
                                        {{ totalSmartTableLicenses }} {{currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Kanban
                                    </td>
                                    <td class="text-right">
                                        {{ totalKanbanLicense }} {{currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Regelkreise
                                    </td>
                                    <td class="text-right">
                                        {{ totalKanbanCircuitLicenses }} {{currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Speicher {{storageSize}} GB
                                    </td>
                                    <td class="text-right">
                                        {{ totalStorageLicences }} {{currency}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="font-weight-black mb-0">
                                            Total pro Monat
                                        </p>
                                    </td>
                                    <td class="text-right">
                                        <p class="font-weight-black mb-0">
                                            {{ totalPricePerMonth }} {{currency}}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="font-weight-black mb-0">
                                            Total pro Jahr
                                        </p>
                                    </td>
                                    <td class="text-right">
                                        <p class="font-weight-black mb-0">
                                            {{ totalPriceYear }} {{currency}}
                                        </p>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                </v-col>

            </v-row>

        </v-card-text>


    </v-card>
</template>

<script>
export default {
name: "Calculations",
    data () {
        return  {
            currency: 'EUR',
            payment: 'monthly',
            pmt: false,
            projects: false,
            kanban: false,
            smartTable: false,
            numUsers: 10,
            numSmartTableLicences: 0,
            numKanbanCircuitLicences: 0,
            storageSize: 5,
            projectsBasicLicense:
                {
                    yearly : 35,
                    monthly : 50
                },

            pmtBasicLicense:
                {
                    yearly : 15,
                    monthly : 25
                },

            smartTableBasicLicense:
                {
                    yearly : 50,
                    monthly : 75
                },

            kanbanBasicLicense:
                {
                    yearly : 50,
                    monthly : 75
                },

            userLicences:
                {
                    yearly : {
                        '10' : 6.90,
                        '20' : 6.40,
                        '50' : 5.90,
                        '100': 5.40,
                        '200': 4.90
                    },
                    monthly : {
                        '10' : 9.90,
                        '20' : 9.40,
                        '50' : 8.90,
                        '100': 8.40,
                        '200': 7.90
                    }
                },

            smartTableLicences:
                {
                    yearly : {
                        '5' : 8.50,
                        '10' : 7.50,
                        '20' : 6.50,
                        '50' : 5.50,
                        '100': 4.50
                    },
                    monthly : {
                        '5' : 9.90,
                        '10' : 8.90,
                        '20' : 7.90,
                        '50' : 6.90,
                        '100': 5.90
                    }
                },

            kanbanCircuitLicences:
                {
                    yearly : {
                        '10' : 4.40,
                        '20' : 3.40,
                        '50' : 2.50,
                        '100': 1.70,
                        '200': 1.30
                    },
                    monthly : {
                        '10' : 4.90,
                        '20' : 3.90,
                        '50' : 2.70,
                        '100': 1.90,
                        '200': 1.50
                    }
                },

            storageLicences:
                {
                    yearly : {
                        '5' : 0.00,
                        '10' : 10.00,
                        '20' : 20.00,
                        '50' : 50.00,
                        '100': 100.00
                    },
                    monthly : {
                        '5' : 0.00,
                        '10' : 12.00,
                        '20' : 24.00,
                        '50' : 60.00,
                        '100': 120.00
                    }
                }
        }
    },

    computed: {
        totalUserLicenses: function () {
            if(this.payment === 'yearly') {
                return (this.numUsers * this.userLicences.yearly[this.numUsers]).toFixed(2)
            }
            else {
                return (this.numUsers * this.userLicences.monthly[this.numUsers]).toFixed(2)
            }
        },

        totalProjectLicense: function () {
            if(this.projects) {
                return this.projectsBasicLicense[this.payment].toFixed(2)
            }
            return (0).toFixed(2)
        },

        totalPmtLicense: function () {
            if(this.pmt) {
                return this.pmtBasicLicense[this.payment].toFixed(2)
            }
            return (0).toFixed(2)
        },

        totalSmartTableLicense: function () {
            if(this.smartTable) {
                return this.smartTableBasicLicense[this.payment].toFixed(2)
            }
            return (0).toFixed(2)
        },

        totalSmartTableLicenses: function () {
            if(this.smartTable) {
                if(this.payment === 'yearly') {
                    return (this.numSmartTableLicences * this.smartTableLicences.yearly[this.numSmartTableLicences]).toFixed(2)
                }
                else {
                    return (this.numSmartTableLicences * this.smartTableLicences.monthly[this.numSmartTableLicences]).toFixed(2)
                }
            }
            return (0).toFixed(2)
        },

        totalKanbanLicense: function () {
            if(this.kanban) {
                return this.kanbanBasicLicense[this.payment].toFixed(2)
            }
            return (0).toFixed(2)
        },

        totalKanbanCircuitLicenses: function () {
            if(this.kanban) {
                if(this.payment === 'yearly') {
                    return (this.numKanbanCircuitLicences * this.kanbanCircuitLicences.yearly[this.numKanbanCircuitLicences]).toFixed(2)
                }
                else {
                    return (this.numKanbanCircuitLicences * this.kanbanCircuitLicences.monthly[this.numKanbanCircuitLicences]).toFixed(2)
                }
            }
            return (0).toFixed(2)
        },


        totalStorageLicences: function () {
            return this.storageLicences[this.payment][this.storageSize].toFixed(2)
        },

        totalPricePerMonth: function () {
            return (parseFloat(this.totalUserLicenses) +
                parseFloat(this.totalProjectLicense) +
                parseFloat(this.totalPmtLicense) +
                parseFloat(this.totalSmartTableLicense) +
                parseFloat(this.totalSmartTableLicenses) +
                parseFloat(this.totalKanbanLicense) +
                parseFloat(this.totalKanbanCircuitLicenses)).toFixed(2)
        },

        totalPriceYear: function () {
            return (parseFloat(this.totalPricePerMonth) * 12).toFixed(2)
        },

    },

    methods: {
        userLabelPrice (value) {
            return this.userLicences[this.payment][value].toFixed(2)
        },

        smartTableLabelPrice (value) {
            return this.smartTableLicences[this.payment][value].toFixed(2)
        },

        kanbanLabelPrice (value) {
            return this.kanbanCircuitLicences[this.payment][value].toFixed(2)
        },

        storageLabelPrice (value) {
            return this.storageLicences[this.payment][value].toFixed(2)
        }
    },

    watch: {
        pmt: function (value) {
            if(value) {
                this.projects = true
            }
        },

        kanban : function (value) {
            if(!value) {
                this.numKanbanCircuitLicences = 0
            }
            else {
                if(this.numKanbanCircuitLicences === 0) {
                    this.numKanbanCircuitLicences = 10
                }
            }
        },

        numKanbanCircuitLicences : function (value) {
            if(value > 0) {
                this.kanban = true
            }
        },

        smartTable : function (value) {
            if(!value) {
                this.numSmartTableLicences = 0
            }
            else {
                if(this.numSmartTableLicences === 0) {
                    this.numSmartTableLicences = 5
                }
            }
        },

        numSmartTableLicences : function (value) {
            if(value > 0) {
                this.smartTable = true
            }
        }
    }
}
</script>

<style scoped>

</style>
