<template>
    <v-card v-if="company">
        <v-card-text>
            <v-col
                md="6"
                cols="12"
            >
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>Name:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ company.name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Adresse:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ company.street }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>PLZ:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ company.zip}}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Stadt:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ company.city}}</v-list-item-content>
                    </v-list-item>


                </v-list>
            </v-col>

        </v-card-text>
    </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
name: "CompanyInfo",
    computed: {
        ...mapGetters({
            company: 'adminCompanies/company',
        }),
    },
}
</script>

<style scoped>

</style>
