<template>
    <v-tabs >
        <v-tab href="#edit">{{$lang('edit')}}</v-tab>
        <v-tab href="#image">{{$lang('image')}}</v-tab>
        <v-tab href="#printKanbanCard">{{$lang('kanban card')}}</v-tab>
        <v-tab href="#delete">{{$lang('delete')}}</v-tab>
        <v-tab-item value="edit">
            <EditKanbanCircuit ></EditKanbanCircuit>
        </v-tab-item>
        <v-tab-item value="image">
            <KanbanCircuitImage></KanbanCircuitImage>
        </v-tab-item>
        <v-tab-item value="printKanbanCard">
           <KanbanCard></KanbanCard>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteKanbanCircuit></DeleteKanbanCircuit>
        </v-tab-item>
    </v-tabs>
</template>

<script>

    import EditKanbanCircuit from "./EditKanbanCircuit";
    import KanbanCard from "./KanbanCard";
    import KanbanCircuitImage from "./KanbanCircuitImage";
    import DeleteKanbanCircuit from "./DeleteKanbanCircuit";

    export default {
        name: "PartTabs",
        components: {DeleteKanbanCircuit, KanbanCircuitImage, KanbanCard, EditKanbanCircuit}
    }
</script>

<style scoped>

</style>
