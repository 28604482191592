<template>
    <div>
        <v-toolbar flat color="white" >
            <v-toolbar-title >
                {{$lang('kanban circuits')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <TableFilterDropDown table-id="ProcurementKanbanCircuitsTable" key="ProcurementKanbanCircuitsTable" ></TableFilterDropDown>
        </v-toolbar>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <ProcurementKanbanCircuitsTable table-id="ProcurementKanbanCircuitsTable"></ProcurementKanbanCircuitsTable>
        <ProcurementKanbanCircuitDialog></ProcurementKanbanCircuitDialog>
        <ProcurementCreateManualKanbanOrderDialog></ProcurementCreateManualKanbanOrderDialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProcurementKanbanOrdersTable from "../components/ProcurementKanbanOrdersTable";
import ProcurementKanbanCircuitsTable from "../components/ProcurementKanbanCircuitsTable";
import TableFilterDropDown from "../../../helpers/tableFilters/components/TableFilterDropDown";
import ProcurementCreateManualKanbanOrderDialog from "../components/ProcurementCreateManualKanbanOrderDialog";
import ProcurementKanbanCircuitDialog from "../components/ProcurementKanbanCircuitDialog";


export default {
name: "KanbanCircuits",
    components: {
        ProcurementKanbanCircuitDialog,
        ProcurementCreateManualKanbanOrderDialog,
        TableFilterDropDown, ProcurementKanbanCircuitsTable, ProcurementKanbanOrdersTable},
    data () {
        return {
            loading: true
        }
    },

    mounted() {
    this.loading = false
    const self = this

    }
}
</script>

<style scoped>

</style>
