import { ProjectTypes, ProjectType } from '../views'
import {Project} from "../../../projects/views";

export default [
    {
        path: '/masterData/projectTypes',
        component: ProjectTypes,
        name: 'projectTypes',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/masterData/projectTypes/:projectTypeId/show',
        component: ProjectType,
        name: 'projectTypes.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },
]

