export const fetchKanbanCircuit = ({ commit }, kanbanCircuitId) => {
    return axios.get(`/api/sales/kanban/kanbanCircuits/${kanbanCircuitId}`).then((response) => {
        commit('SET_KANBAN_CIRCUIT_DATA', response.data)
    })
}

export const fetchKanbanOrders = ({ commit }) => {
    return axios.get(`/api/sales/kanban/kanbanOrders`).then((response) => {
        commit('SET_KANBAN_ORDERS_DATA', response.data)
    })
}

export const fetchKanbanOrder = ({ commit }, id) => {
    return axios.get(`/api/sales/kanban/kanbanOrders/${id}`).then((response) => {
        commit('SET_KANBAN_ORDER_DATA', response.data)
    })
}

export const confirmKanbanOrder = ({ commit },{id,payload, context}) => {
    return axios.patch(`/api/sales/kanban/kanbanOrders/${id}/confirmKanbanOrder`, payload).then((response) => {
        commit('SET_KANBAN_ORDER_DATA', response.data)
    })
}

export const shipKanbanOrder = ({ commit },{id, payload, context}) => {
    return axios.patch(`/api/sales/kanban/kanbanOrders/${id}/shipKanbanOrder`, payload).then((response) => {
        commit('SET_KANBAN_ORDER_DATA', response.data)
    })
}

export const closeKanbanOrder = ({ commit },{id, payload, context}) => {
    return axios.patch(`/api/sales/kanban/kanbanOrders/${id}/closeKanbanOrder`, payload).then((response) => {
        commit('SET_KANBAN_ORDER_DATA', response.data)
    })
}

export const setShowKanbanOrderDialog = ({ dispatch, state, commit }, value) => {
    commit('SET_KANBAN_ORDER_DIALOG', value)
}

