export const fetchKanbanCircuit = ({ commit }, kanbanCircuitId) => {
    return axios.get(`/api/sales/kanban/kanbanCircuits/${kanbanCircuitId}`).then((response) => {
        commit('SET_KANBAN_CIRCUIT_DATA', response.data)
    })
}

export const update = ({ dispatch, state, commit }, { kanbanCircuitId, payload, context }) => {
    return axios.patch(`/api/sales/kanban/kanbanCircuits/${kanbanCircuitId}`, payload).then((response) => {
        commit('SET_KANBAN_CIRCUIT_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const setKanbanCircuitDialog = ({ dispatch, state, commit }, value) => {
    commit('SET_KANBAN_CIRCUIT_DIALOG', value)
}

