<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ $lang('locations') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="showCreateLocationDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon>{{ $lang('location') }}
            </v-btn>
        </v-toolbar>

        <LocationsTable></LocationsTable>
        <CreateLocationDialog></CreateLocationDialog>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import LocationsTable from "../components/LocationsTable";
    import CreateLocationDialog from "../components/CreateLocationDialog";
    export default {
        name: "Locations",
        components: {
            LocationsTable,
            CreateLocationDialog
        },

        methods: {
            ...mapActions({
                showCreateLocationDialog: 'locations/setCreateLocationDialog',
            }),
        },
    }
</script>
