<template>
    <FileBrowser :endpoint="`/api/issues/${issue.id}/fileManager`"></FileBrowser>

</template>

<script>
import {mapGetters} from "vuex";
import FileBrowser from "../../fileManager/components/FileBrowser";

export default {
name: "IIssueFiles",
    components: {FileBrowser},
    computed: {
        ...mapGetters({
            issue: 'issues/issue'
        }),
    }
}
</script>

<style scoped>

</style>
