<template>
    <div>
        <v-btn
            color="primary"
            class="mr-2"
            outlined
            small
            @click="fileUploadDialog = true"
        >
            <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('file') }}
        </v-btn>
        <v-dialog
            v-model="fileUploadDialog"
            max-width="400"
        >
            <v-card
                    @drop.prevent="handleFileDrop"
                    @dragover.prevent="dragover = true"
                    @dragenter.prevent="dragover = true"
                    @dragleave.prevent="dragover = false"
                    :class="{ 'grey': dragover }"
            >
                <v-card-title class="headline">{{ $lang('file upload') }}</v-card-title>

                <v-card-text
                >
                    <v-file-input
                            v-model="selectedFiles"
                            :label="$lang('select file')"
                            multiple
                            show-size
                            autofocus
                    ></v-file-input>
                    <p>{{$lang('select a file or drag and drop it in.')}}</p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="closeFileUploadDialog"
                    >
                        {{ $lang('close') }}
                    </v-btn>
                    <v-btn
                            color="primary"
                            text
                            @click="submitForm"
                    >
                        upload
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {mapActions} from "vuex";
import Bus from "@/bus";

export default {
name: "FileUpload",
    data() {
        return {
            fileUploadDialog: false,
            errors: [],
            dragover: false,
            selectedFiles: [],
        }
    },

    props: [
        'endpoint'
    ],

    methods : {
        ...mapActions({
            fetchObjects: 'fileManager/fetchObjects',
        }),
        cancel () {
            this.createFolderDialog = false
        },

        closeFileUploadDialog () {
            this.selectedFiles = []
            this.fileUploadDialog = false
        },

        handleFileDrop(event) {
            const droppedFiles = event.dataTransfer.files;
            this.selectedFiles = Array.from(droppedFiles);
            this.dragover= false
        },

        submitForm(){
            const self = this
            this.selectedFiles.forEach(file => {
                let formData = new FormData();
                formData.append('file', file);

                axios.post(`${this.endpoint}/fileUpload`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(data){
                    self.fetchObjects(self.endpoint)
                    self.selectedFiles = []
                    Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('upload successful')});
                })
                    .catch(function(){
                        Bus.$emit('showAlert', {color : 'error', 'message' : self.$lang('error on upload')});
                    });
                }

            );


        },
    }
}
</script>

<style scoped>
.drop-area {
    border: 2px dashed gray;
    padding: 20px;
    text-align: center;
    margin-top: 20px;
}
</style>
