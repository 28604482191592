import { Locations, Location } from '../views'

export default [
    {
        path: '/masterData/locations',
        component: Locations,
        name: 'locations',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/masterData/locations/:locationId/show',
        component: Location,
        name: 'locations.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },
]

