export const setWorkplacesData = (state, data) => {
    state.workplaces = data
}

export const setWorkplaceData = (state, data) => {
    state.workplace = data
}

export const SET_ACTIVE_WORKPLACES_DATA = (state, data) => {
    state.activeWorkplaces = data
}

export const addWorkplace = (state, data) => {
    state.workplaces.push(data)
}

export const updateWorkplaceData = (state, data) => {
    Object.assign(state.workplaces[data.index], data.data)
}

export const removeWorkplace = (state, item) => {
    const index = _.indexOf(state.workplaces, item)
    state.workplaces.splice(index, 1)
}

export const setCreateWorkplaceDialog = (state, data) => {
    state.showCreateWorkplaceDialog = data
}
