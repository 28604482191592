<template>
    <v-tabs
        v-model="selectedTab"
    >

        <v-tab href="#log">{{ $lang('log')}}</v-tab>
        <v-tab href="#createOrderManually" v-if="_.includes(me.permissions, 'create manual kanban orders')">{{ $lang('create order manually')}}</v-tab>

        <v-tab-item value="log">
            <KanbanCircuitActivityLogs v-if="kanbanCircuit"> </KanbanCircuitActivityLogs>
        </v-tab-item>

        <v-tab-item value="createOrderManually"  v-if="_.includes(me.permissions, 'create manual kanban orders')">
            <v-card>
                <v-card-text>
                    <v-btn
                        block
                        outlined
                        color="primary"
                        @click="setShowCreateManualKanbanOrderDialog(true)"

                    >
                        {{ $lang('create order manually')}}
                    </v-btn>
                </v-card-text>
            </v-card>

        </v-tab-item>
    </v-tabs>
</template>

<script>

import ProcurementKanbanOrderInfo from "./ProcurementKanbanOrderInfo";
import DeleteKanbanOrder from "./DeleteKanbanOrder";
import {mapActions, mapGetters} from "vuex";

import KanbanCircuitActivityLogs from "./KanbanCircuitActivityLogs";
export default {
name: "ProcurementKanbanCircuitTabs",
    components: {KanbanCircuitActivityLogs,DeleteKanbanOrder, ProcurementKanbanOrderInfo},
    computed: {
        ...mapGetters({
            me: 'auth/user',
            kanbanCircuit: 'procurementKanban/kanbanCircuit',
            tab: 'procurementKanban/selectedTab'
        }),
        selectedTab: {
            get() {
                return this.tab
            },
            set(value) {
                this.setSelectedTab(value)
            }
        },
    },

    methods: {
        ...mapActions({
            setSelectedTab: 'procurementKanban/setSelectedTab',
            setShowCreateManualKanbanOrderDialog: 'procurementKanban/setShowCreateManualKanbanOrderDialog',
        }),
    }
}
</script>

<style scoped>

</style>
