<template>
    <div>
        <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
        ></v-progress-linear>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../../bus";

export default {
    name: "KanbanCircuitsExportTable",
    data() {
        return {
            loading: true,
            datatableExists: true,
            defaultState: null,
            tableId: 'KanbanCircuitsExportTable'
        }
    },

    computed: {
        ...mapGetters({
            me: 'auth/user',
            activeFilters: 'tableFilters/activeFilters'
        }),
    },

    methods: {
        loadDatatable() {
            const self = this
            if (this.datatableExists) {
                axios.get(`/api/procurement/kanbanExport/kanbanCircuitExports`).then(function (response) {
                    self.webixId.parse(webix.copy(response.data))
                })
            }
        }
    },

    mounted() {
        const self = this

        this.datatableExists = true

        this.webixId = webix.ui({
            container: this.$el,
            $scope: this,
            view: "datatable",
            height: window.innerHeight - 200,
            id: self.tableId,
            headermenu:{
                width:250,
            },
            headerRowHeight: 40,
            resizeColumn: true,
            dragColumn:true,
            select:true,
            columns: [
                {
                    id: "name",
                    header: [self.$lang('name'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 150,
                    sort: "string",
                    adjust: true
                },
                {
                    id: "number",
                    header: [self.$lang('number'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 100,
                    sort: "string",
                    adjust: true
                },
                {
                    id: "part_name",
                    header: [self.$lang('part name'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 150,
                    sort: "string",
                    adjust: true
                },
                {
                    id: "part_number",
                    header: [self.$lang('part number'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 100,
                    sort: "string",
                    adjust: true
                },
                {
                    id: "description",
                    header: [self.$lang('description'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 150,
                    sort: "string",
                    adjust: true
                },

                {
                    id: "storage_location_name",
                    header: [self.$lang('storage location'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.storage_location_name
                    }
                },

                {
                    id: "location_name",
                    header: [self.$lang('location'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.location_name
                    }
                },

                {
                    id: "workplace_name",
                    header: [self.$lang('workplace'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.workplace_name
                    }
                },

                {
                    id: "qta_percentage",
                    header: [self.$lang('qta percentage'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.qta_percentage
                    }
                },

                {
                    id: "lta_percentage",
                    header: [self.$lang('lta percentage'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.lta_percentage
                    }
                },

                {
                    id: "supplier_name",
                    header: [self.$lang('supplier'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.supplier_name
                    }
                },

                {
                    id: "supplier_info",
                    header: [self.$lang('supplier info'), {content: "textFilter"}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                },

                {
                    id: "stock_unit",
                    header: [self.$lang('unit'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true
                },
                {
                    id: "settlement_unit",
                    header: [self.$lang('settlement unit'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    adjust: true
                },
                {
                    id: "settlement_factor",
                    header: [self.$lang('settlement factor'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true,
                    template: function (obj) {
                        return parseFloat(obj.settlement_factor).toFixed(3)
                    }
                },

                {
                    id: "start_date",
                    map: "(date)#start_date#",
                    header: [self.$lang('start date'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: true,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.start_date)
                    }
                },

                {
                    id: "end_date",
                    map: "(date)#end_date#",
                    header: [self.$lang('end date'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: true,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.end_date)
                    }
                },

                {
                    id: "start_stock_quantity",
                    header: [self.$lang('start stock quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true
                },

                {
                    id: "end_stock_quantity",
                    header: [self.$lang('end stock quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true
                },

                {
                    id: "stock_quantity_change",
                    header: [self.$lang('stock quantity change'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true
                },

                {
                    id: "start_settlement_quantity",
                    header: [self.$lang('start settlement quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true,
                    template: function (obj) {
                        return obj.start_settlement_quantity.toFixed(3)
                    }
                },

                {
                    id: "end_settlement_quantity",
                    header: [self.$lang('end settlement quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true,
                    template: function (obj) {
                        return obj.end_settlement_quantity.toFixed(3)
                    }
                },

                {
                    id: "settlement_quantity_change",
                    header: [self.$lang('settlement quantity change'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true,
                    template: function (obj) {
                        return obj.settlement_quantity_change.toFixed(3)
                    }
                },

                {
                    id: "stock_inventory_quantity",
                    header: [self.$lang('stock inventory quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true
                },

                {
                    id: "stock_withdrawal_quantity",
                    header: [self.$lang('stock withdrawal quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true
                },

                {
                    id: "stock_ordered_quantity",
                    header: [self.$lang('stock ordered quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true
                },

                {
                    id: "stock_received_quantity",
                    header: [self.$lang('stock received quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true
                },

                {
                    id: "settlement_inventory_quantity",
                    header: [self.$lang('settlement inventory quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true,
                    template: function (obj) {
                        return obj.settlement_inventory_quantity.toFixed(3)
                    }
                },

                {
                    id: "settlement_withdrawal_quantity",
                    header: [self.$lang('settlement withdrawal quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true,
                    template: function (obj) {
                        return obj.settlement_withdrawal_quantity.toFixed(3)
                    }
                },

                {
                    id: "settlement_ordered_quantity",
                    header: [self.$lang('settlement ordered quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true,
                    template: function (obj) {
                        return obj.settlement_ordered_quantity.toFixed(3)
                    }
                },


                {
                    id: "settlement_received_quantity",
                    header: [self.$lang('settlement received quantity'), {content: "textFilter"}],
                    minWidth: 100,
                    sort: "string",
                    css: {'text-align': 'right'},
                    adjust: true,
                    template: function (obj) {
                        return obj.settlement_received_quantity.toFixed(3)
                    }
                },
            ],

            on: {
                onBeforeLoad() {
                    if(!self.defaultState) {
                        self.defaultState = this.getState()
                    }
                },

                onAfterLoad: function () {
                    self.loading = false
                    if (self.tableId in self.activeFilters) {
                        this.setState(JSON.parse(self.activeFilters[self.tableId].filter_data))
                    }
                },
            },


        })

        this.loadDatatable()

        Bus.$on('filterTable:' + self.tableId, function (data) {
            if(self.datatableExists) {
                $$(self.webixId).setState(JSON.parse(data))
            }
        });

        Bus.$on('resetFilterTable:' + self.tableId, function () {
            if(self.datatableExists) {
                $$(self.webixId).setState(self.defaultState)
            }
        });




    },

    destroyed: function () {
        this.datatableExists = false
        webix.$$(this.webixId).destructor();
    }
}
</script>

<style scoped>

</style>
