<template>
    <div class="container">
        <Bar :chart-data="chartData" :chart-options="options"/>
    </div>
</template>
<script>
import { Bar } from 'vue-chartjs'
import { Chart, LineController, LineElement, BarController, BarElement,  PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from 'chart.js';

Chart.register(LineController, LineElement, BarController, BarElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend);

export default {
    name: "KanbanSupplierOnTimeDeliveryPerformanceChart",

    components: { Bar },

    props: {
        height: Number,
        filteredDashboardData: Array
    },

        data () {
            return {
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            label: this.$lang('num deliveries on time'),
                            borderWidth: 1,
                            borderColor: 'rgba(21, 101, 192, 0.7)',
                            backgroundColor: 'rgba(21, 101, 192, 0.2)',
                            data: []
                        },

                        {
                            label: this.$lang('num of late deliveries'),
                            borderWidth: 1,
                            borderColor: 'rgba(244, 67, 54, 0.7)',
                            backgroundColor: 'rgba(244, 67, 54, 0.2)',
                            data: []
                        },

                    ]
                },
                options: {

                    scales: {
                        yAxes: {
                            ticks: {
                                min: 0,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.$lang('number quantity')
                            }
                        }
                    },
                },
                responsive: true,
                maintainAspectRatio: false
            }
        },

        methods: {
            render() {
                const hash = {};
                this.filteredDashboardData.forEach(o => {

                    if(o.received_at) {

                        if(hash[o.supplier_name]!== undefined) {
                            hash[o.supplier_name] = {
                                numOrdersOnTime: this.checkAndAddOrderOnTime(o, hash) ? hash[o.supplier_name].numOrdersOnTime + 1 : hash[o.supplier_name].numOrdersOnTime,
                                numOrdersDelayed: !this.checkAndAddOrderOnTime(o, hash) ? hash[o.supplier_name].numOrdersDelayed + 1 : hash[o.supplier_name].numOrdersDelayed,
                            }
                        }
                        else {
                            hash[o.supplier_name] = {
                                numOrders: 1,
                                numOrdersOnTime: this.checkAndAddOrderOnTime(o, hash) ?  1 : 0,
                                numOrdersDelayed: !this.checkAndAddOrderOnTime(o, hash) ? 1 : 0,
                            }
                        }

                    }
                })

                const suppliers = Object.keys(hash).map(name => ({ name: name, summary: hash[name] }))

                this.chartData.labels = _.map(suppliers, 'name')

                this.chartData.datasets[0].data = _.map(suppliers, 'summary.numOrdersOnTime')
                this.chartData.datasets[1].data = _.map(suppliers, 'summary.numOrdersDelayed')
            },

            checkAndAddOrderOnTime(o, hash) {
                return o.received_at && o.delivery_on_time
            }
        },

        mounted() {
            this.render()
        },

        watch: {
            filteredDashboardData: {
                handler: function() {
                    this.render()
                },
                deep: true
            },
        },
    }
</script>

<style scoped>

</style>



