<template>
    <div v-if="costCenter">
        <v-toolbar flat color="white" dense >
                <v-btn
                    small
                    outlined
                    color="primary"
                    to="/masterData/costCenters"
                    class="mr-2"
                >
                    <v-icon class="mr-1">fas fa-list</v-icon>
                </v-btn>
            <v-toolbar-title >{{ costCenter.name }}</v-toolbar-title>
        </v-toolbar>

        <CostCenterTabs ></CostCenterTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import CostCenterTabs from "../components/CostCenterTabs";

    export default {
        name: "CostCenter",
        components: {
            CostCenterTabs
        },
        props: [
            'costCenterId'
        ],
        computed: {
            ...mapGetters({
                costCenter: 'costCenters/costCenter',
            }),
        },
        created() {
            this.fetchCostCenter(this.costCenterId)
        },

        methods: {
            ...mapActions({
                fetchCostCenter: 'costCenters/fetchCostCenter',
            }),
        }
    }
</script>

<style scoped>

</style>
