export const SET_SUPPLIERS_DATA = (state, data) => {
    state.suppliers = data
}

export const SET_ACTIVE_SUPPLIERS_DATA = (state, data) => {
    state.activeSuppliers = data
}

export const setSupplierData = (state, data) => {
    state.supplier = data
}

export const addSupplier = (state, data) => {
    state.suppliers.push(data)
}

export const updateSupplierData = (state, data) => {
    Object.assign(state.suppliers[data.index], data.data)
}

export const removeSupplier = (state, item) => {
    const index = _.indexOf(state.suppliers, item)
    state.suppliers.splice(index, 1)
}

export const setCreateSupplierDialog = (state, data) => {
    state.showCreateSupplierDialog = data
}
