<template>
    <div v-if="product">
        <v-toolbar flat color="white" dense >
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        outlined
                        color="primary"
                        to="/masterData/products"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="mr-1">fas fa-list</v-icon>
                    </v-btn>
                </template>
                <span>Alle Produkte</span>
            </v-tooltip>
            <v-toolbar-title >{{ product.name }}</v-toolbar-title>
        </v-toolbar>

        <ProductTabs ></ProductTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ProductTabs from "../components/ProductTabs";

    export default {
        name: "Product",
        components: {
            ProductTabs
        },
        props: [
            'productId'
        ],
        computed: {
            ...mapGetters({
                product: 'masterProducts/product',
            }),
        },
        created() {
            this.fetchProduct(this.productId)
        },

        methods: {
            ...mapActions({
                fetchProduct: 'masterProducts/fetchProduct',
            }),
        }
    }
</script>

<style scoped>

</style>
