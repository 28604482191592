import { Departments, Department } from '../views'
import {Project} from "../../../projects/views";

export default [
    {
        path: '/masterData/departments',
        component: Departments,
        name: 'departments',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/masterData/departments/:departmentId/show',
        component: Department,
        name: 'departments.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },
]

