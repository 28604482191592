<template>
    <div>
        <CompanyPlansTable></CompanyPlansTable>
        <EditCompanyPlanDialog></EditCompanyPlanDialog>
    </div>
</template>

<script>
import CompanyPlansTable from "./CompanyPlansTable";
import EditCompanyPlanDialog from "./EditCompanyPlanDialog";
export default {
    name: "CompanyPlans",
    components: {EditCompanyPlanDialog, CompanyPlansTable}
}
</script>

<style scoped>

</style>
