<template>
    <v-card
        class="mt-2"
    >
        <v-card-text>
            <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                    <v-list dense
                        style="font-size: 1rem"
                        class="font-weight-medium"
                    >
                        <v-list-item>
                            <v-list-item-content>{{ $lang('part number') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.part_number }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('part name') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.part_name }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('supplier part number') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.supplier_part_number}}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('quantity') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.quantity}} {{kanbanOrder.unit}}</v-list-item-content>
                        </v-list-item>
<!--                        <v-list-item>-->
<!--                            <v-list-item-content>{{ $lang('supplier part name') }}:</v-list-item-content>-->
<!--                            <v-list-item-content class="align-end">{{ kanbanOrder.supplier_part_name}}</v-list-item-content>-->
<!--                        </v-list-item>-->

                        <v-list-item>
                            <v-list-item-content>{{ $lang('ordered') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.created_at }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('ordered') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.creator_name }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('location') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.location_name}}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('delivery date') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.delivery_date}}</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                    <v-list dense
                        style="font-size: 1rem"
                        class="font-weight-medium"
                    >

                        <v-list-item>
                            <v-list-item-content>{{ $lang('supplier reference') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.supplier_reference}}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('customer reference') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.customer_reference}}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('delivery reference') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.delivery_reference}}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('confirmed') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.confirmed_at}}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('confirmed') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.confirmer_name}}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('shipped') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.shipped_at}}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>{{ $lang('shipped') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.shipper_name}}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{ $lang('received') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.received_at}}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>{{ $lang('received') }}:</v-list-item-content>
                            <v-list-item-content class="align-end">{{ kanbanOrder.receiver_name}}</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
name: "ProcurementKanbanOrderInfo",
    computed: {
        ...mapGetters({
            kanbanOrder: 'procurementKanban/kanbanOrder',
        }),
    },
}
</script>

<style scoped>

</style>
