<template>
    <div>
        <v-toolbar flat color="white">

            <v-spacer></v-spacer>

            <v-select
                style="max-width: 40%"
                class="mt-2"
                v-if="suppliers"
                v-model="supplierFilter"
                :items="suppliers"
                :label="$lang('suppliers')"
                item-text="name"
                item-value="id"
                multiple
            ></v-select>

        </v-toolbar>
        <v-row>
            <v-col
                cols="12"
                md="4"
                >
                <v-card
                    min-height="300px"
                >
                    <v-card-title
                        class="grey lighten-2 pb-1 pt-1 "
                    >
                        {{$lang('num orders / supplier')}}
                    </v-card-title>
                    <v-card-text>
                        <KanbanNumOrdersPerSupplierChart :height="300" :filtered-dashboard-data="filteredDashboardData" v-if="filteredDashboardData"></KanbanNumOrdersPerSupplierChart>
                    </v-card-text>
                </v-card>

            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <v-card
                    min-height="300px"
                >
                    <v-card-title
                        class="grey lighten-2 pb-1 pt-1"
                    >
                        {{$lang('time until confirmation')}}
                    </v-card-title>
                    <v-card-text>
                        <KanbanSupplierTimeUntilConfirmationChart :height="300" :filtered-dashboard-data="filteredDashboardData" v-if="filteredDashboardData"></KanbanSupplierTimeUntilConfirmationChart>
                    </v-card-text>
                </v-card>

            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <v-card
                    min-height="300px"
                >
                    <v-card-title
                        class="grey lighten-2 pb-1 pt-1"
                    >
                        {{$lang('time until shipment')}}
                    </v-card-title>
                    <v-card-text>
                        <KanbanSupplierTimeUntilShipmentChart :height="300" :filtered-dashboard-data="filteredDashboardData" v-if="filteredDashboardData"></KanbanSupplierTimeUntilShipmentChart>

                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="4"
            >
                <v-card
                    min-height="310px"
                    class="mb-1"
                >
                    <v-card-title
                        class="grey lighten-2 pb-1 pt-1 "
                    >
                        {{$lang('delivery reliability')}}
                    </v-card-title>
                    <v-card-text>
                        <KanbanSupplierOnTimeDeliveryPerformanceChart :height="300" :filtered-dashboard-data="filteredDashboardData" v-if="filteredDashboardData"></KanbanSupplierOnTimeDeliveryPerformanceChart>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>


    </div>
</template>

<script>

import {mapGetters} from "vuex";
import KanbanNumOrdersPerSupplierChart from "@/app/procurement/dashboard/charts/KanbanNumOrdersPerSupplierChart.vue";
import KanbanSupplierTimeUntilConfirmationChart
    from "@/app/procurement/dashboard/charts/KanbanSupplierTimeUnitilConfirmationChart.vue";
import KanbanSupplierTimeUntilShipmentChart
    from "@/app/procurement/dashboard/charts/KanbanSupplierTimeUntilShipmentChart.vue";
import KanbanSupplierOnTimeDeliveryPerformanceChart
    from "@/app/procurement/dashboard/charts/KanbanSupplierOnTimeDeliveryPerformanceChart.vue";




export default {
name: "SupplierDashboard",
    components: {
        KanbanSupplierOnTimeDeliveryPerformanceChart,
        KanbanSupplierTimeUntilShipmentChart,
        KanbanSupplierTimeUntilConfirmationChart,
        KanbanNumOrdersPerSupplierChart

    },
    data () {
        return {
            filteredDashboardData: null,
            dashboardData: null,
            suppliers: null,
            supplierFilter: []
        }
    },

    computed: {
        ...mapGetters({
            me: 'auth/user',
        }),
    },

    methods: {
        fetchData() {
            const self = this
            axios.get(`/api/procurement/dashboard`).then(function (response){
                self.dashboardData = response.data
                self.setSuppliers(response.data)
                self.filterData(response.data)
            })
        },

        setSuppliers(data) {
            const suppliers = [];
            _.each(data,function(value, key){
                suppliers.push({
                    name: value.supplier_name,
                    id: value.supplier_id
                })
            })

            this.suppliers = _.uniqBy(suppliers, 'id')
        },

        filterData(data) {
            const self = this
            this.filteredDashboardData = _.filter(data, function(o) {
                if (self.supplierFilter.length) {
                    if (!_.includes(self.supplierFilter, o.supplier_id)) {
                        return false
                    }
                }
                return true
            })

        }
    },

    mounted() {
        this.fetchData()
        var channel = this.$pusher.subscribe(`kanbanCircuit.${this.me.company.id}`);

        channel.bind('updateKanbanCircuitItem', () => {
            this.fetchData()
        });
    },

    watch: {
        supplierFilter: {
            handler: function () {
                this.filterData(this.dashboardData)
            },
        },
    }
}
</script>

<style scoped>

</style>
