<template>
    <div>
        <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
        ></v-progress-linear>
    </div>

</template>

<script>
import Bus from "../../../bus";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "LeadTimeChangeDashboardTable",

    data() {
        return {
            loading: true,
            enableSelect: false,
            list: '',
            datatableExists: true,
            defaultState: null,
            filter: null,
        }
    },

    props: [
        'tableId'
    ],

    computed: {
        ...mapGetters({
            user: 'auth/user',
            activeFilters: 'tableFilters/activeFilters',
        })
    },

    methods: {
        ...mapActions({
            fetchProject: 'projects/fetchProject',
            fetchIssue: 'issues/fetchIssue',
            fetchTodo: 'todos/fetchTodo',
            actionShowIssueDialog: 'issues/setIssueDialog',
            actionShowTodoDialog: 'todos/setTodoDialog',
            actionShowPotentialDialog: 'potentials/setPotentialDialog',
            setShowTaskContextMenu: 'projects/setShowTaskContextMenu',
            setActiveFilter: 'tableFilters/setActiveFilter'
        }),

        loadDatatable() {
            const self = this
            if (this.datatableExists) {
                axios.get(`/api/leadTimeChangeDashboard`).then(function (response) {
                    self.webixId.parse(webix.copy(response.data))
                })
            }
        }
    },

    mounted() {
        const self = this

        this.datatableExists = true

        this.webixId = webix.ui({
            container: this.$el,
            $scope: this,
            view: "datatable",
            height: window.innerHeight - 200,
            id: self.tableId,
            headermenu: true,
            resizeColumn: true,
            select: "row",
            dragColumn: true,
            headerRowHeight: 40,
            footer: false,
            columns: [

                {
                    id: "created_at",
                    map: "(date)#created_at#",
                    header: [self.$lang('created'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.created_at)
                    }
                },

                {
                    id: "user_name",
                    header: [self.$lang('user'), {
                        content: "multiSelectFilter",
                        suggest: {fitMaster: false, width: 100}
                    }],
                    adjust: true,
                    sort: "string"
                },

                {
                    id: "project_name",
                    header: [self.$lang('project'), {content: "textFilter"}],
                    minWidth: 200,
                    sort: "string",
                    template: "#project_link#"
                },

                {
                    id: "issue_name",
                    header: [self.$lang('issue'), {content: "textFilter"}],
                    minWidth: 200,
                    sort: "string",
                    template: "#issue_link#"
                },

                {
                    id: "todo_name",
                    header: [self.$lang('todo'), {content: "textFilter"}],
                    minWidth: 200,
                    sort: "string",
                    template: "#todo_link#"
                },

                {
                    id: "previous_start_date",
                    map: "(date)#previous_start_date#",
                    header: [self.$lang('previous start date'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.previous_start_date)
                    }
                },

                {
                    id: "new_start_date",
                    map: "(date)#new_start_date#",
                    header: [self.$lang('new start date'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.new_start_date)
                    }
                },


                {
                    id: "previous_end_date",
                    map: "(date)#previous_end_date#",
                    header: [self.$lang('previous end date'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.previous_end_date)
                    }
                },

                {
                    id: "new_end_date",
                    map: "(date)#new_end_date#",
                    header: [self.$lang('new end date'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.new_end_date)
                    }
                },

                {
                    id: "reason",
                    header: [self.$lang('reason'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 300,
                    sort: "string"
                },

            ],
            onClick: {
                "project_link": function (event, row, target) {
                    let record = this.getItem(row.row)

                    self.$router.push({name: 'projects.show', params: {projectId: record.project_id}})
                },
                "issue_link": function (event, row, target) {
                    let record = this.getItem(row.row)

                    self.fetchIssue(record.issue_id).then(function () {
                        self.actionShowIssueDialog(true)
                    })
                },

                "todo_link": function (event, row, target) {
                    let record = this.getItem(row.row)

                    self.fetchTodo(record.todo_id).then(function () {
                        self.actionShowTodoDialog(true)
                    })
                },
            },
            on: {
                onBeforeLoad: function () {
                    self.loading = true
                },

                onAfterLoad: function () {
                    self.loading = false
                    if (!self.defaultState) {
                        self.defaultState = this.getState()
                    }

                    if (self.tableId in self.activeFilters) {
                        this.setState(JSON.parse(self.activeFilters[self.tableId].filter_data))
                    }

                    if (self.selectedItemId && this.exists(self.selectedItemId)) {
                        this.select(self.selectedItemId)
                        this.showItem(self.selectedItemId)
                    }
                },
            },
        }),
            self.loadDatatable()

        Bus.$on('project:updated', function () {
            self.loadDatatable()
        });


        Bus.$on('issue:updated', function () {
            self.loadDatatable()

        });

        Bus.$on('todo:updated', function () {
            self.loadDatatable()

        })

        Bus.$on('filterTable:' + self.tableId, function (data) {
            self.filter = data
            if (self.datatableExists) {
                if (!self.defaultState) {
                    self.defaultState = $$(self.webixId).getState()
                }
                $$(self.webixId).setState(JSON.parse(data))
            }
        });

        Bus.$on('resetFilterTable:' + self.tableId, function () {
            if (self.datatableExists) {
                $$(self.webixId).setState(self.defaultState)
            }

        });
    },

    destroyed: function () {
        this.datatableExists = false
        webix.$$(this.webixId).destructor();
    }
}
</script>

<style>

</style>
