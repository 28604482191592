<template>
    <v-card>
        <v-card-title>
            <strong>
                {{ $lang('delete issue') }}
            </strong>
        </v-card-title>


        <v-card-title>
            {{ $lang('do you really want to delete this issue?') }}
        </v-card-title>
        <v-card-text>
            <v-switch
                v-model="showConfirmDeleteIssue"
                :label="$lang('yes, please delete!')"
            ></v-switch>
        </v-card-text>


        <v-card-actions>
            <v-btn
                v-if="showConfirmDeleteIssue"
                color="error"
                @click="deleteIssue"
            >
                {{ $lang('delete') }}
            </v-btn>
        </v-card-actions>


    </v-card>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "DeleteIssue",
        data() {
            return {
                showConfirmDeleteIssue: false,
            }
        },
        computed: {
            ...mapGetters({
                issue: 'issues/issue'
            }),
        },

        methods: {
            ...mapActions({
                actionDeleteIssue: 'issues/deleteIssue',
            }),

            deleteIssue() {
                this.showConfirmDeleteIssue = false
                this.actionDeleteIssue({
                    id: this.issue.id,
                    message: this.$lang('issue successfully deleted')
                })
           }

        }
    }
</script>

<style scoped>

</style>
