export const workplaces = (state) => {
    return state.workplaces
}

export const activeWorkplaces = (state) => {
    return state.activeWorkplaces
}

export const workplace = (state) => {
    return state.workplace
}

export const showCreateWorkplaceDialog = (state) => {
    return state.showCreateWorkplaceDialog
}
