<template>
<div>
    <v-toolbar flat color="white" >
        <v-toolbar-title >
        {{ $lang('notes') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            class="mr-2"
            small
            :outlined="notesFilter !== 'all'"
            color="primary"
            @click="setNoteFilter('all')"
        >
            {{ $lang('all') }}
        </v-btn>
        <v-btn
            class="mr-2"
            small
            :outlined="notesFilter !== 'open'"
            color="primary"
            @click="setNoteFilter('open')"
        >
            {{ $lang('open') }}
        </v-btn>
        <v-btn
            class="mr-2"
            small
            :outlined="notesFilter !== 'closed'"
            color="primary"
            @click="setNoteFilter('closed')"
        >
            {{ $lang('closed') }}
        </v-btn>
        <v-spacer></v-spacer>

    </v-toolbar>
    <NotesTable></NotesTable>
</div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import NotesTable from "../components/NotesTable";

    export default {
        name: "Notes",
        components: {NotesTable},
        computed: {
            ...mapGetters({
                notesFilter: 'notes/notesFilter',
            })
        },
        methods: {
            ...mapActions({
                setNoteFilter: 'notes/setNotesFilter'
            }),
        }
    }
</script>

<style scoped>

</style>
