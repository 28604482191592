export const SET_COST_CENTERS_DATA = (state, data) => {
    state.costCenters = data
}
export const SET_ACTIVE_COST_CENTERS_DATA = (state, data) => {
    state.activeCostCenters = data
}
export const setCostCenterData = (state, data) => {
    state.costCenter = data
}

export const addCostCenter = (state, data) => {
    state.costCenters.push(data)
}

export const updateCostCenterData = (state, data) => {
    Object.assign(state.costCenters[data.index], data.data)
}

export const removeCostCenter = (state, item) => {
    const index = _.indexOf(state.costCenters, item)
    state.costCenters.splice(index, 1)
}

export const setCreateCostCenterDialog = (state, data) => {
    state.showCreateCostCenterDialog = data
}
