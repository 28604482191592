<template>
    <v-row justify="center">
        <v-dialog
            persistent
            :value="showCreateIssueDialog"
            :width="issueDialogWidth"
            @click:outside="confirmCloseDialog = true"
            @keydown.esc="confirmCloseDialog = true"
        >
            <v-card >

                <v-card-title>
                    <span class="headline">{{ $lang('create issue') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="issueDialogWidth > 600"
                        small
                        outlined
                        color="primary"
                        class="mr-2"
                        @click="setIssueDialogWidth(430)"
                    >
                        {{ $lang('small')}}
                    </v-btn>
                    <v-btn
                        v-else
                        small
                        outlined
                        color="primary"
                        class="mr-2"
                        @click="setIssueDialogWidth(1200)"
                    >
                        {{ $lang('wide')}}
                    </v-btn>
                    <v-btn
                        small
                        outlined
                        color="primary"
                        @click="closeDialog"
                    >
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-card-text>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            :label="$lang('title')"
                            v-model="form.title"
                            required
                            autofocus
                            :error-messages="errors.title"
                        >
                        </v-text-field>

                        <Editor  :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event"></Editor>

                        <v-row
                            class="pb-0"
                        >
                            <v-col
                                cols="12"
                                md="4"
                                class="pb-0"
                            >

                                <v-select
                                    v-model="form.assignee_id"
                                    :items="permittedAssigneeUsers"
                                    :label="$lang('responsible')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.assignee_id = null"
                                    item-text="full_name"
                                    item-value="id"
                                    :error-messages="errors.assignee_id"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                                class="pb-0"
                            >
                                <v-select
                                    v-model="form.hardness_degree"
                                    :items="hardnessDegrees"
                                    :item-disabled="disableHardnessDegreeItem"
                                    :label="$lang('hardness degree')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.hardness_degree = null"
                                    item-text="name"
                                    item-value="value"
                                    :error-messages="errors.hardness_degree"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                                class="pb-0"
                            >
                                <v-select
                                    v-model="form.priority"
                                    :items="priorities"
                                    :label="$lang('prio')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.priority = null"
                                    item-text="name"
                                    item-value="slug"
                                    :error-messages="errors.priority"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row
                            class="pb-0"
                        >
                            <v-col
                                cols="12"
                                md="12"
                                class="pb-0 pt-0"
                            >
                                <v-select
                                    v-model="form.user_ids"
                                    :items="permittedUsers"
                                    :label="$lang('team')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.user_ids = []"
                                    item-text="full_name"
                                    item-value="id"
                                    multiple
                                    :error-messages="errors.user_ids"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                md="4"
                                class="pb-0 pt-0"
                            >
                                <v-select
                                    v-model="form.workplace_id"
                                    :items="activeWorkplaces"
                                    :label="$lang('workplace')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.workplace_id = null"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.workplace_id"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                                class="pb-0 pt-0"
                            >
                                <v-select
                                    v-model="form.cost_center_id"
                                    :items="activeCostCenters"
                                    :label="$lang('cost center')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.cost_center_id = null"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.cost_center_id"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                                class="pb-0 pt-0"
                            >
                                <v-select
                                    v-model="form.product_id"
                                    :items="activeProducts"
                                    :label="$lang('product')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.product_id = null"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.product_id"
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                                md="4"
                                class="pb-0 pt-0"
                            >
                                <v-dialog
                                    ref="start_date_dialog"
                                    v-model="start_date_modal"
                                    :return-value.sync="picker_start_date"
                                    persistent
                                    width="290px"
                                    :disabled="disableDatePicker"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.start_date"
                                            :label="$lang('start')"
                                            append-outer-icon="fas fa-times"
                                            @click:append-outer="picker_start_date = null"
                                            append-icon="fas fa-calendar-alt"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="errors.start_date"
                                            :disabled="disableDatePicker"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-if="issueDateLimits"
                                        v-model="picker_start_date"
                                        scrollable
                                        @change="$refs.start_date_dialog.save(picker_start_date)"
                                        :min="issueDateLimits.min_start_date"
                                        :max="issueDateLimits.max_start_date"
                                        :first-day-of-week="1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="picker_start_date = ''">{{ $lang('delete') }}</v-btn>
                                        <v-btn text color="primary" @click="start_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.start_date_dialog.save(picker_start_date)">{{ $lang('ok') }}</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                                class="pb-0 pt-0"
                            >
                                <v-dialog
                                    ref="end_date_dialog"
                                    v-model="end_date_modal"
                                    :return-value.sync="picker_end_date"
                                    persistent
                                    width="290px"
                                    :disabled="disableDatePicker"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.end_date"
                                            :label="$lang('end')"
                                            append-outer-icon="fas fa-times"
                                            @click:append-outer="picker_end_date = null"
                                            append-icon="fas fa-calendar-alt"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="errors.end_date"
                                            :disabled="disableDatePicker"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-if="issueDateLimits"
                                        v-model="picker_end_date"
                                        scrollable
                                        @change="$refs.end_date_dialog.save(picker_end_date)"
                                        :min="issueDateLimits.min_end_date"
                                        :max="issueDateLimits.max_end_date"
                                        :first-day-of-week="1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="picker_end_date = ''">{{ $lang('delete') }}</v-btn>
                                        <v-btn text color="primary" @click="end_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.end_date_dialog.save(picker_end_date)">{{ $lang('ok') }}</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                                class="pt-0 pt-0"
                            >
                                <v-select
                                    v-model="form.issue_tag_ids"
                                    :items="issueTags"
                                    :label="$lang('issue tags')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.issue_tag_ids = null"
                                    item-text="name"
                                    item-value="id"
                                    multiple
                                    :error-messages="errors.issue_tag_ids"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" value="!loading" text @click="submit">{{ $lang('save') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="closeDialog">{{$lang('close')}}</v-btn>

                </v-card-actions>
            </v-card>
            <v-dialog
                v-model="confirmCloseDialog"
                width="800"
            >
                <v-card>
                    <v-card-title
                        primary-title
                        class="justify-center"
                    >
                        {{$lang('close dialog')}}
                    </v-card-title>
                    <v-card-text
                        class="text-center"
                    >
                        <strong>
                            {{$lang('do you really want to close without saving?')}}
                        </strong>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="primary"
                            text
                            @click="saveAndClose"
                        >
                            {{$lang('save and close')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="secondary"
                            text
                            @click="closeWithoutSaving"
                        >
                            {{$lang('close without saving')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="confirmCloseDialog = false"
                        >
                            {{$lang('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
    </v-row>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import Editor from "../../../components/Editor";
    import Bus from "../../../bus";
    import {solexToIssue} from "../solexes/vuex/getters";

    export default {
        name: "CreateIssueDialog",
        components: {
            Editor: Editor
        },
        props: [
            'endpoint'
        ],
        data() {
            return {
                loading: false,
                modal: false,
                errors: [],
                confirmCloseDialog: false,
                picker_start_date:'',
                picker_end_date:'',
                start_date_modal: false,
                end_date_modal: false,
                disableDatePicker: false,
                form: {
                    title: '',
                    body: '',
                    tokens: [],
                    assignee_id: '',
                    start_date: '',
                    end_date: '',
                    hardness_degree: 0,
                    issue_tag_ids: '',
                    workplace_id: '',
                    cost_center_id: '',
                    product_id: '',
                    priority: 'low',
                    user_ids: []
                },
                priorities: [
                    {slug: 'low', name: this.$lang('low')},
                    {slug: 'mid', name: this.$lang('mid')},
                    {slug: 'high', name: this.$lang('high')},
                ],
                permittedAssigneeUsers: [],
                permittedUsers: []
            }
        },

        computed: {
            ...mapGetters({
                users: 'users/users',
                showCreateIssueDialog: 'issues/showCreateIssueDialog',
                createIssueEndpoint: 'issues/createIssueEndpoint',
                activeUsers: 'users/activeUsers',
                issueTags: 'issueTags/issueTags',
                issueDateLimits: 'issues/issueDateLimits',
                activeWorkplaces: 'workplaces/activeWorkplaces',
                activeCostCenters: 'costCenters/activeCostCenters',
                activeProducts: 'masterProducts/activeProducts',
                me: 'auth/user',
                hardnessDegrees: 'hardnessDegrees/hardnessDegrees',
                issueDialogWidth: 'issues/issueDialogWidth',
                solexToIssue: 'solexes/solexToIssue',
                solex: 'solexes/solex',
                project: 'projects/project'
            }),

        },


        methods: {
            ...mapActions({
                store: 'issues/store',
                actionShowCreateIssueDialog: 'issues/setCreateIssueDialog',
                fetchIssueTags: 'issueTags/fetchIssueTags',
                fetchWorkplaces: 'workplaces/fetchWorkplaces',
                fetchCostCenters: 'costCenters/fetchCostCenters',
                fetchProducts: 'masterProducts/fetchProducts',
                fetchIssueDateLimits: 'issues/fetchIssueDateLimits',
                setIssueDialogWidth: 'issues/setIssueDialogWidth',
                setSolexToIssue: 'solexes/setSolexToIssue',
            }),

            disableHardnessDegreeItem(hardnessDegreeItem) {
                if(hardnessDegreeItem.value === 2 || hardnessDegreeItem.value === 3) {
                    return this.me.id !== this.form.assignee_id
                }
                if(hardnessDegreeItem.value === 4 || hardnessDegreeItem.value === 5) {
                    return this.me.id !== this.project.project_leader_id
                }
                return false
            },

            submit () {
                const self = this
                this.loading = true
                this.errors = []
                this.form.tokens= []
                this.store({
                    endpoint: this.endpoint,
                    payload: this.form,
                    context: this,
                }).then(() => {
                    this.loading = false
                    if(this.errors.length === 0) {
                        this.dialog = false
                        this.form.body = ''
                        Bus.$emit('issue:created')
                        this.actionShowCreateIssueDialog({value: false, endpoint: null}).then(function () {
                            Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('issue successfully created') });
                        })
                    }
                })
            },

            saveAndClose() {
                this.confirmCloseDialog = false
                this.submit()
            },

            closeWithoutSaving() {
                this.$refs.form.reset()
                this.errors = []
                this.closeDialog()
            },

            closeDialog () {
                this.actionShowCreateIssueDialog({value: false, endpoint: null})
            },

            formatDate (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            },


        },

        mounted() {
            const self = this
            this.fetchIssueTags()
            this.fetchWorkplaces()
            this.fetchCostCenters()
            this.fetchProducts()
            if(this.createIssueEndpoint) {
                this.fetchIssueDateLimits(this.createIssueEndpoint)
            }

            if(this.solexToIssue) {
                this.form.title = this.solex.title
                this.form.body = `<strong>${this.$lang('why')}</strong> \n${this.solex.why_body} \n<strong>${this.$lang('what for')}</strong> \n${this.solex.what_for_body} \n<strong>${this.$lang('what')}</strong> \n${this.solex.what_body}`
                this.form.assignee_id = this.solex.assignee.id
                this.form.user_ids = this.solex.user_ids
                this.form.start_date = moment().format('DD.MM.YYYY')
                this.form.end_date = moment().add('days', this.project.solex_settings.lead_time).format('DD.MM.YYYY')
                this.setSolexToIssue(null)
                this.disableDatePicker = true
            }
        },

        watch: {
            picker_start_date (val) {
                this.form.start_date = this.formatDate(this.picker_start_date)
            },

            picker_end_date (val) {
                this.form.end_date = this.formatDate(this.picker_end_date)
            },

            project: {
                handler: function (project) {
                    if(project) {
                        this.permittedAssigneeUsers = this.activeUsers.filter(user => this.project.permitted_user_ids.includes(user.id));
                        this.permittedUsers = this.activeUsers.filter(user => this.project.permitted_user_ids.includes(user.id))
                    }
                },
                immediate: true
            }
        },
    }
</script>

<style scoped>

</style>
