<template>
    <div>
        <v-dialog
            v-model="dialog"
            max-width="600"
        >
            <v-card
                v-if="companyPlan"
            >
                <v-card-title class="headline"> {{ $lang('edit company plan') }}  &nbsp;<span class="blue--text"> {{companyPlan.plan}}</span> </v-card-title>

                <v-card-text>
                    <v-form
                        ref="form"
                    >

                        <v-switch
                            v-model="form.active"
                            :label="$lang('active')"
                        ></v-switch>

                        <v-dialog
                            ref="start_date_dialog"
                            v-model="start_date_modal"
                            :return-value.sync="picker_start_date"
                            persistent
                            width="290px"

                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="form.start_date"
                                    :label="$lang('start')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="picker_start_date = null"
                                    append-icon="fas fa-calendar-alt"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="errors.start_date"
                                ></v-text-field>
                            </template>
                            <v-date-picker

                                v-model="picker_start_date"
                                scrollable
                                @change="$refs.start_date_dialog.save(picker_start_date)"
                                :first-day-of-week="1"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="picker_start_date = ''">{{ $lang('delete') }}</v-btn>
                                <v-btn text color="primary" @click="start_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                <v-btn text color="primary" @click="$refs.start_date_dialog.save(picker_start_date)">{{ $lang('ok') }}</v-btn>
                            </v-date-picker>
                        </v-dialog>

                        <v-dialog
                            ref="end_date_dialog"
                            v-model="end_date_modal"
                            :return-value.sync="picker_end_date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="form.end_date"
                                    :label="$lang('end')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="picker_end_date = null"
                                    append-icon="fas fa-calendar-alt"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="errors.end_date"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="picker_end_date"
                                scrollable
                                @change="$refs.end_date_dialog.save(picker_end_date)"
                                :first-day-of-week="1"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="picker_end_date = ''">{{ $lang('delete') }}</v-btn>
                                <v-btn text color="primary" @click="end_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                <v-btn text color="primary" @click="$refs.end_date_dialog.save(picker_end_date)">{{ $lang('ok') }}</v-btn>
                            </v-date-picker>
                        </v-dialog>

                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="closeDialog"
                    >
                        {{ $lang('cancel') }}
                    </v-btn>

                    <v-btn
                        color="primary"
                        text
                        @click="submit"
                    >
                        {{ $lang('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../../bus";

export default {
    name: "EditCompanyPlanDialog",
    data() {
        return {

            errors: [],
            picker_start_date:'',
            picker_end_date:'',
            start_date_modal: false,
            end_date_modal: false,
            form: {
                active: '',
                start_date: '',
                end_date: '',
            }
        }
    },

    computed: {
        ...mapGetters({
            dialog: 'adminCompanies/showEditCompanyPlanDialog',
            companyPlan: 'adminCompanies/companyPlan'
        }),
    },



    methods: {
        ...mapActions({
            update: 'adminCompanies/updateCompanyPlan',
            setShowCompanyPlanDialog: 'adminCompanies/setShowEditCompanyPlanDialog'
        }),

        submit () {
            this.errors = []
            this.update({
                id: this.companyPlan.id,
                payload: this.form,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    Bus.$emit('companyPlan:updated');
                    this.$refs.form.reset()
                    this.closeDialog()
                }
            })
        },

        closeDialog() {
            this.setShowCompanyPlanDialog(false)
        },

        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
    },
    watch: {
        companyPlan: {
            handler() {
                this.form = webix.copy(this.companyPlan)
            }
        },

        picker_start_date (val) {
            this.form.start_date = this.formatDate(this.picker_start_date)
        },

        picker_end_date (val) {
            this.form.end_date = this.formatDate(this.picker_end_date)
        },
    },
}
</script>

<style scoped>

</style>
