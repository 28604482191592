import { Products, Product } from '../views'
import {Project} from "../../../projects/views";
import {Location, Locations} from "../../locations/views";

export default [
    {
        path: '/masterData/products',
        component: Products,
        name: 'masterData.products',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/masterData/products/:productId/show',
        component: Product,
        name: 'masterData.products.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },
]


