export const setProductsData = (state, data) => {
    state.products = data
}

export const SET_ACTIVE_PRODUCTS_DATA = (state, data) => {
    state.activeProducts = data
}

export const setProductData = (state, data) => {
    state.product = data
}

export const addProduct = (state, data) => {
    state.products.push(data)
}

export const updateProductData = (state, data) => {
    Object.assign(state.products[data.index], data.data)
}

export const removeProduct = (state, item) => {
    const index = _.indexOf(state.products, item)
    state.products.splice(index, 1)
}

export const setCreateProductDialog = (state, data) => {
    state.showCreateProductDialog = data
}
