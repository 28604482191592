export const SET_LANG_DATA = (state, data) => {
    state.lang = data
}

export const SET_SUPPORTED_LANGS_DATA = (state, data) => {
    state.supportedLangs = data
}

export const SET_TRANSLATABLE_STRINGS_DATA = (state, data) => {
    state.translatableStrings = data
}

export const SET_CUSTOM_TRANSLATIONS_DATA = (state, data) => {
    state.customTranslations = data
}

export const SET_SUPPORTED_LOCALS_DATA = (state, data) => {
    state.supportedLocals = data
}

export const SET_LOCAL = (state, data) => {
    state.local = data
}
