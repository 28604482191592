<template>
    <div>
        <v-card>
            <v-card-text>
                <image-input v-model="avatar">
                    <div slot="activator">
                        <v-avatar size="150px" v-ripple v-if="!avatar && !avatarURL" class="grey lighten-3 mb-3">
                            <span>{{ $lang('click to add image')}}</span>
                        </v-avatar>
                        <v-avatar size="150px" v-ripple v-if="avatar" class="mb-3" style="height: 150px; min-width: 150px; width: 150px;">
                            <img :src="avatar.imageURL" alt="avatar">
                        </v-avatar>

                        <v-avatar size="150px"  >
                            <img :src="avatarURL" v-if="avatarURL && !avatar" alt="avatar" style="height: 150px; min-width: 150px; width: 150px;">
                        </v-avatar>
                    </div>
                </image-input>
                <v-slide-x-transition>
                    <div v-if="avatar && saved === false">
                        <v-btn class="primary" @click="uploadImage" :loading="saving">{{ $lang('save image')}}</v-btn>
                    </div>
                </v-slide-x-transition>
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
import ImageInput from "./ImageInput";
import {mapActions, mapGetters} from "vuex";
export default {
    name: 'app',
    data () {
        return {
            avatar: null,
            saving: false,
            saved: false,
            avatarURL: null
        }
    },

    components: {
        ImageInput: ImageInput
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
    },

    methods: {
        ...mapActions ({
            fetchMe: 'auth/fetchMe'
        }),

        uploadImage() {
            const self = this
            this.saving = true

            axios.post('/api/users/uploadAvatar', this.avatar.formData, { headers: {
                    'Content-Type': 'multipart/form-data'
                }}).then(function (){
                self.fetchMe()
                self.savedAvatar()
            })

        },

        savedAvatar() {
            this.saving = false
            this.saved = true
        }
    },

    mounted() {
       if(this.user) {
           this.avatarURL = this.user.avatar
       }
    },

    watch:{
        avatar: {
            handler: function() {
                this.saved = false
            },
            deep: true
        }
    },
}
</script>

<style scoped>
    img {
        height: 150px; min-width: 150px; width: 150px;
    }

</style>
