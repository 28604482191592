export const divisions = (state) => {
    return state.divisions
}

export const division = (state) => {
    return state.division
}

export const showCreateDivisionDialog = (state) => {
    return state.showCreateDivisionDialog
}
