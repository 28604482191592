<template>
    <v-dialog
        width="1200"
        v-model="showProcurementKanbanOrderDialog"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
        v-if="kanbanOrder"
    >
        <v-toolbar flat color="white" dense >

            <v-toolbar-title >{{ $lang('kanban order')}} {{ kanbanOrder.order_number }}</v-toolbar-title>

            <v-spacer></v-spacer>
            <span
                v-html="kanbanOrder.status_label"
            >
            </span>
            <v-spacer></v-spacer>

            <v-btn
                v-if="kanbanOrder.status === 'shipped'"
                small
                outlined
                color="primary"
                @click="goodsReceiptDialog= true"
            >
                {{$lang('goods receipt')}}
            </v-btn>

            <v-btn
                class="ml-2"
                small
                outlined
                color="primary"
                @click="closeDialog"
            >
                <v-icon >fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>

        <ProcurementKanbanOrderTabs></ProcurementKanbanOrderTabs>


        <v-dialog
            v-model="goodsReceiptDialog"
            :fullscreen="$vuetify.breakpoint.mobile"
            @click:outside="shipKanbanOrderDialog = false"
            width="500"
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{ $lang('goods receipt')}}
                </v-card-title>
                <v-card-title>
                    {{$lang('do you want to post the goods receipt')}}
                </v-card-title>
                <v-card-text>

                    <v-switch
                        v-model="goodsReceipt"
                        :label="$lang('goods receipt')"
                    >
                    </v-switch>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary"
                                class="mt-2"
                                block
                                :disabled="loading || !goodsReceipt"
                                text
                                @click="submitGoodsReceipt"
                            >
                                {{$lang('book')}}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="secondary"
                                @click="goodsReceiptDialog = false"
                                class="mt-2"
                                block
                                :disabled="loading"
                                text
                            >
                                {{$lang('cancel')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>


    import {mapActions, mapGetters} from "vuex";

    import Bus from "../../../../bus";
    import ProcurementKanbanOrderTabs from "./ProcurementKanbanOrderTabs";


    export default {
        name: "ProcurementKanbanOrderDialog",
        components: {ProcurementKanbanOrderTabs},
        data() {
            return {
                errors: [],
                loading: false,
                goodsReceipt: false,
                goodsReceiptDialog: false
            }
        },

        computed: {
            ...mapGetters({
                kanbanOrder: 'procurementKanban/kanbanOrder',
                showProcurementKanbanOrderDialog: 'procurementKanban/showKanbanOrderDialog'
            }),
        },

        methods: {
            ...mapActions({
                setShowKanbanOrderDialog: 'procurementKanban/setShowKanbanOrderDialog',
                postGoodsReceipt: 'procurementKanban/postGoodsReceipt'
            }),

            submitGoodsReceipt () {
                const self = this
                this.postGoodsReceipt({
                    id: this.kanbanOrder.id,
                    context: this,
                }).then(() => {
                    if(this.errors.length === 0) {
                        self.goodsReceiptDialog = false
                        Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('goods receipt successfully done') })
                    }
                })

            },



            closeDialog () {
                this.setShowKanbanOrderDialog(false)
            },

        },


    }
</script>

<style scoped>

</style>
