export const fetchLocations = ({ commit }) => {
    return axios.get('/api/masterData/locations').then((response) => {
        commit('setLocationsData', response.data)
    })
}

export const fetchLocation = ({ commit}, id) => {
    return axios.get(`/api/masterData/locations/${id}`).then((response) => {
        commit('setLocationData', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/masterData/locations', payload).then((response) => {
        commit('setLocationData', response.data)
        dispatch('fetchLocations')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/masterData/locations/${payload.id}`, payload).then((response) => {
        commit('setLocationData', response.data)
        dispatch('fetchLocations')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, {id, context} ) => {
    return axios.delete(`/api/masterData/locations/${id}`).catch((error) => {
        context.error = true
    })
}

export const setCreateLocationDialog = ({ dispatch, state, commit }, value) => {
    commit('setCreateLocationDialog', value)
}
