export const solexes = (state) => {
    return state.solexes
}

export const solex = (state) => {
    return state.solex
}

export const showCreateSolexDialog = (state) => {
    return state.showCreateSolexDialog
}

export const showSolexDialog = (state) => {
    return state.showSolexDialog
}

export const createSolexEndpoint = (state) => {
    return state.createSolexEndpoint
}

export const solexDialogWidth= (state)   => {
    return state.solexDialogWidth
}

export const selectedTab = (state) => {
    return state.selectedTab
}

export const solexToIssue = (state) => {
    return state.solexToIssue
}
