<template>
    <v-tabs >
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="edit">
            <EditDivision></EditDivision>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteDivision ></DeleteDivision>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import EditDivision from "./EditDivision";
import DeleteDivision from "./DeleteDivision";
    export default {
        name: "DivisionTab",

        components: {
            EditDivision,
            DeleteDivision
        },

        methods: {

        },

    }
</script>

<style scoped>

</style>
