export const notifications = (state) => {
    return state.notifications
}

export const notification = (state) => {
    return state.notification
}

export const notificationsFilter = (state)   => {
    return state.notificationsFilter
}
