export const projectTypes = (state) => {
    return state.projectTypes
}

export const projectType = (state) => {
    return state.projectType
}

export const showCreateProjectTypeDialog = (state) => {
    return state.showCreateProjectTypeDialog
}
