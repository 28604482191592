<template>
    <v-col
        md="12"
        cols="12"
    >
        <v-list dense>
            <v-list-item>
                <v-list-item-content>{{ $lang('title') }}:</v-list-item-content>
                <v-list-item-content class="align-end">{{ todo.title }}</v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>{{ $lang('description') }}:</v-list-item-content>
                <v-list-item-content class="align-end" v-html="todo.body"></v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>{{ $lang('responsible') }}:</v-list-item-content>
                <v-list-item-content class="align-end">{{ todo.assignee.full_name}}</v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>{{ $lang('created') }}:</v-list-item-content>
                <v-list-item-content class="align-end">{{ todo.creator.full_name}}</v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>{{ $lang('created') }}:</v-list-item-content>
                <v-list-item-content class="align-end">{{ todo.created_at}}</v-list-item-content>
            </v-list-item>


            <v-list-item>
                <v-list-item-content>{{ $lang('start') }}:</v-list-item-content>
                <v-list-item-content class="align-end">{{ todo.start_date}}</v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>{{ $lang('end') }}:</v-list-item-content>
                <v-list-item-content class="align-end">{{ todo.end_date}}</v-list-item-content>
            </v-list-item>

        </v-list>
    </v-col>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "TodoInfo",
        computed: {
            ...mapGetters({
                todo: 'todos/todo'
            }),
        },

    }
</script>

<style>
    img {
        max-width: 60%;
        height: auto;
    }
</style>
