<template>
    <div v-if="company">
        <v-toolbar flat color="white" dense >

            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        text
                        color="primary"
                        to="/admin/companies"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                        Firma:
                    </v-btn>
                </template>
                <span>Alle Firmen</span>
            </v-tooltip>
            <v-toolbar-title >
                {{ company.name }}
            </v-toolbar-title>
        </v-toolbar>
        <CompanyTabs></CompanyTabs>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CompanyTabs from "../components/CompanyTabs";

export default {
name: "Company",
    components: {CompanyTabs},
    props: [
        'companyId'
    ],

    computed: {
        ...mapGetters({
             company: 'adminCompanies/company',
        }),
    },

    methods: {
        ...mapActions({
            fetchCompany: 'adminCompanies/fetchCompany',
        }),
    },

    mounted() {
        this.fetchCompany(this.companyId)
    }
}
</script>

<style scoped>

</style>
