<template>
    <div v-if="company">
        <v-toolbar flat color="white" dense >

            <v-toolbar-title >
                {{ company.name }}
            </v-toolbar-title>


        </v-toolbar>

        <company-tabs></company-tabs>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CompanyTabs from "../components/CompanyTabs";


export default {
name: "User",
    components: {CompanyTabs},


    computed: {
        ...mapGetters({
            company: 'companyAdminCompany/company',
        }),
    },

    methods: {
        ...mapActions({
            fetchCompany: 'companyAdminCompany/fetchCompany',
        }),
    },

    mounted() {
        this.fetchCompany()
    }
}
</script>

<style scoped>

</style>
