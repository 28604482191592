<template>
        <v-list dense>
            <v-list-item-group
                v-model="selectedItem"
                color="primary"
            >
                <v-list-item
                    v-for="(product, i) in products"
                    :key="product.id"
                    @click="fetchProduct(product.id)"
                >
                    <v-list-item-content>
                        <v-list-item-title v-text="product.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <small>
                            <span v-html="product.active_label"></span>
                        </small>

                    </v-list-item-action>
                </v-list-item>
            </v-list-item-group>

        </v-list>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
name: "ProductList",
    data () {
        return {
            selectedItem: 0
        }
    },
    computed: {
        ...mapGetters({
            products: 'products/products',
            product: 'products/product',
            selectedProduct: 'products/selectedProduct'
        }),
    },

    methods: {
        ...mapActions({
            fetchProduct: 'products/fetchProduct',
        }),
    },
    mounted() {

    },
}
</script>

<style scoped>

</style>
