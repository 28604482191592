<template>
    <div>
        <v-toolbar flat color="white" >
            <v-toolbar-title >{{ $lang('projects')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <TableFilterDropDown table-id="projects" key="projects" ></TableFilterDropDown>
            <v-btn
                v-if="_.includes(me.permissions, 'create project')"
                small
                outlined
                color="primary"
                @click="showCreateProjectDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('project')}}
            </v-btn>

        </v-toolbar>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>

        <ProjectsTable table-id="projects"></ProjectsTable>


    </div>
</template>

<script>

    import ProjectsTable from "../components/ProjectsTable";
    import {mapActions, mapGetters} from "vuex";
    import TableFilterDropDown from "../../helpers/tableFilters/components/TableFilterDropDown";
    export default {
        name: "Projects",
        components: {
            TableFilterDropDown,
            ProjectsTable
        },
        data() {
            return {
                loading: true
            }
        },

        computed: {
            ...mapGetters({
                me: 'auth/user',
                projects: 'projects/projects',
                project: 'projects/project',
                issues: 'issues/issues',
            }),
        },

        methods: {
            ...mapActions({
                showCreateProjectDialog: 'projects/setCreateProjectDialog',
                fetchProjects: 'projects/fetchProjects'
            }),
        },

        mounted() {
            const self = this
            if(!this.projects.length) {
                this.fetchProjects().then(function (){
                    self.loading = false
                })
            }
            else {
                this.loading = false
            }
        }

    }
</script>

<style scoped>

</style>
