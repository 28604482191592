<template>
    <v-card>
        <v-col style="height: 300px" class="ml-2 mr-2">
            <v-row>
                <strong>
                    {{ $lang('do you really want to delete this note?')}}
                </strong>
            </v-row>

            <v-row>
                <v-btn
                    color="error"
                    @click="showConfirmDeleteNote = true"
                >
                    {{ $lang('delete') }}
                </v-btn>
            </v-row>

            <v-row>
                <v-btn
                    v-if="showConfirmDeleteNote"
                    color="error"
                    class="mt-10"
                    @click="deleteNote"
                >
                    {{ $lang('delete')}}
                </v-btn>
            </v-row>
        </v-col>

    </v-card>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "DeleteNote",
        data() {
            return {
                showConfirmDeleteNote: false,
            }
        },
        computed: {
            ...mapGetters({
                note: 'notes/note'
            }),
        },

        methods: {
            ...mapActions({
                actionDeleteNote: 'notes/deleteNote',
            }),

            deleteNote() {
                this.showConfirmDeleteNote = false
                this.actionDeleteNote(this.note.id)
           }

        }
    }
</script>

<style scoped>

</style>
