<template>
    <v-card>
        <v-card-title
            class="headline grey lighten-2"
            primary-title
        >
            Profilbild bearbeiten
        </v-card-title>

        <v-card-text  class="mt-2">
        <div class="example-avatar">
            <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
                <h3>Drop files to upload</h3>
            </div>
            <div class="avatar-upload"  v-show="!edit">
                <div class="text-center p-2">
                    <label for="avatar">
                        <template v-if="files.length">
                            <img :src="files[0].url "  class="rounded-circle" style="max-height:300px"/>
                        </template>
                        <template v-else-if="user.media.length">
                            <img v-auth-image="`api/media/${user.media[0].id}`" width="100%">
                        </template>
                        <template v-else>
                            <img src="'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm"  class="rounded-circle" />-->
                        </template>
                    </label>
                </div>
                <div class="text-center p-2">
                    <file-upload
                        extensions="gif,jpg,jpeg,png,webp"
                        accept="image/png,image/gif,image/jpeg,image/webp"
                        name="avatar"
                        class="btn btn-primary"
                        :post-action="`/api/account/${user.id}/avatar`"
                        :headers="{'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN')}"
                        :drop="!edit"
                        v-model="files"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="upload">
                        Bild Hochladen
                    </file-upload>
                </div>
            </div>

            <div class="avatar-edit" v-show="files.length && edit">
                <div class="avatar-edit-image" v-if="files.length">
                    <img ref="editImage" :src="files[0].url" />
                </div>
            </div>
        </div>
        </v-card-text>


        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="files.length && edit"
                color="primary"
                text
                @click.prevent="editSave"
            >
                {{ $lang('save') }}
            </v-btn>
            <v-btn
                v-if="files.length && edit"
                color="grey"
                text
                @click.prevent="$refs.upload.clear"
            >
                {{ $lang('reset') }}
            </v-btn>
            <v-btn
                color="grey"
                text
                @click.prevent="closeDialog"
            >
                Schliessen
            </v-btn>
        </v-card-actions>

    </v-card>
</template>



<script>
    import Cropper from 'cropperjs'
    import FileUpload from 'vue-upload-component'
    import { mapGetters, mapActions } from 'vuex'
    import localforage from 'localforage'
    export default {
        components: {
            FileUpload,
        },

        data() {
            return {
                files: [],
                edit: false,
                cropper: false,
                token: "X-XSRF-TOKEN: eyJpdiI6IlY4emxVQjhoMUxMNXJXMlZBNzV1c3c9PSIsInZhbHVlIjoiejhwWWI4YlJSWHlKSVZkUStxNk1HaXpiT2FISW9zbnBUNkM0YlV0WTE2cVVjNzQveWpPMkhCdjlMc2hwMmk5dCIsIm1hYyI6ImI0NjY1ZWFhYjA5YjBjMjA0YmFiMDExNjgwZGY3OGY1NTNmMjM1YzBhMTlkNzU1ZDk5MjAyNjEyOWJlZWI4NDgifQ==",
            }
        },


        computed: {
            ...mapGetters({
                user: 'auth/user'
            })


        },

        mounted() {
            const self = this

            localforage.getItem('authtoken').then(function(value) {
                self.token = value
            })

        },

        watch: {
            edit(value) {
                if (value) {
                    this.$nextTick(function () {
                        if (!this.$refs.editImage) {
                            return
                        }
                        let cropper = new Cropper(this.$refs.editImage, {
                            aspectRatio: 1 / 1,
                            viewMode: 1,
                        })
                        this.cropper = cropper
                    })
                } else {
                    if (this.cropper) {
                        this.cropper.destroy()
                        this.cropper = false
                    }
                }
            },
        },

        methods: {
            ...mapActions({
                fetchUser: 'auth/fetchUser',
            }),

            closeDialog() {
                this.$emit('close-dialog')
            },
            editSave() {
                const self = this

                this.edit = false

                let oldFile = this.files[0]

                let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
                let arr = new Uint8Array(binStr.length)
                for (let i = 0; i < binStr.length; i++) {
                    arr[i] = binStr.charCodeAt(i)
                }

                let file = new File([arr], oldFile.name, { type: oldFile.type })

                this.$refs.upload.update(oldFile.id, {
                    file,
                    type: file.type,
                    size: file.size,
                    active: true,
                })
                setTimeout(function(){
                    self.fetchUser()
                }, 2000);


            },


            alert(message) {
                alert(message)
            },

            inputFile(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    this.$nextTick(function () {
                        this.edit = true
                    })
                }
                if (!newFile && oldFile) {
                    this.edit = false
                }
            },

            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
                        this.alert('Your choice is not a picture')
                        return prevent()
                    }
                }

                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                    newFile.url = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.url = URL.createObjectURL(newFile.file)
                    }
                }
            }
        }
    }
</script>
<style>
    .example-avatar .avatar-upload .rounded-circle {
        width: 200px;
        height: 200px;
    }
    .example-avatar .text-center .btn {
        margin: 0 .5rem

    }
    .example-avatar .avatar-edit-image {
        max-width: 100%
    }


    .example-avatar .drop-active {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: 9999;
        opacity: .6;
        text-align: center;
        background: #000;
    }

    .example-avatar .drop-active h3 {
        margin: -.5em 0 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 40px;
        color: #fff;
        padding: 0;
    }
</style>
