import Bus from "../../../bus";

export const getNotes = ({ commit }) => {
    return axios.get('/api/notes').then((response) => {
        commit('setNotesData', response.data)
    })
}

export const getNote = ({ commit }, id) => {
    return axios.get(`/api/notes/${id}`).then((response) => {
        commit('setNoteData', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { endpoint, payload, context }) => {
    return axios.post(endpoint, payload).then((response) => {
        dispatch('getNotes')
        commit('setNoteData', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { id,  payload, context }) => {
    return axios.patch(`/api/notes/${id}`, payload).then((response) => {
        dispatch('getNotes')
        commit('setNoteData', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const deleteNote = ({ state, dispatch, commit }, id) => {
    return axios.delete(`/api/notes/${id}`).then((response) => {
        dispatch('getNotes')
        commit('SET_SHOW_NOTE_DIALOG', false)
        Bus.$emit('showAlert', {color : 'success', 'message' : `Notiz <strong>${state.note.title} </strong> gelöscht!`});
    })
}

export const closeNote = ({ state, dispatch, commit }, id) => {
    return axios.patch(`/api/notes/${id}/close`).then((response) => {
        dispatch('getNotes')
        commit('setNoteData', response.data)
    })
}

export const reopenNote = ({ state, dispatch, commit }, id) => {
    return axios.patch(`/api/notes/${id}/reopen`).then((response) => {
        dispatch('getNotes')
        commit('setNoteData', response.data)
    })
}

export const setShowNoteDialog = ({ dispatch, commit }, trueOrFalse) => {
    commit('SET_SHOW_NOTE_DIALOG', trueOrFalse)
}

export const setNotesFilter= ({ dispatch, commit }, value) => {
    commit('SET_NOTES_FILTER', value)
}
