<template>
    <v-dialog
        v-model="showNotificationDialog"
        width="800"
        @click:outside="setShowNotificationDialog(false)"
    >
        <v-card>
            <v-card-title>
                Meldung
                <v-spacer></v-spacer>
                <v-chip
                    color="primary"
                    v-if="!notification.read_at"
                >
                    Offen
                </v-chip>
                <v-chip
                    color="light"
                    v-else
                >
                    Gelesen
                </v-chip>
                <v-btn
                    class="ml-2"
                    small
                    outlined
                    color="primary"
                    @click="setShowNotificationDialog(false)"
                >
                    <v-icon class="mr-1">fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <template
                    v-if="notification.object_type === 'project' && project"
                    >
                    <ProjectTabs></ProjectTabs>
                </template>
                <template
                    v-if="notification.object_type === 'issue' && issue"
                >
                    <IssueTabs></IssueTabs>
                </template>
                <template
                    v-if="notification.object_type === 'todo' && todo"
                >
                    <TodoTabs></TodoTabs>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="light"
                    text
                    @click="setShowNotificationDialog(false)"
                >
                    {{ $lang('close')}}
                </v-btn>
                <v-btn
                    v-if="!notification.read_at"
                    color="primary"
                    text
                    @click="markAsReadNotification(notification.id)"
                >
                    {{ $lang('read')}}
                </v-btn>
                <v-btn
                    v-else
                    color="primary"
                    text
                    @click="markAsUnreadNotification(notification.id)"
                >
                    {{ $lang('reopen')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import ProjectTabs from "../../projects/components/ProjectTabs";
    import IssueTabs from "../../issues/components/IssueTabs";
    import TodoTabs from "../../todos/components/TodoTabs";


    export default {
        name: "NotificationDialog",
        components: {ProjectTabs, IssueTabs, TodoTabs},
        data() {
            return {


            }
        },

        computed: {
            ...mapGetters({
                notifications: 'notifications/notifications',
                notification: 'notifications/notification',
                showNotificationDialog: 'notifications/showNotificationDialog',
                project: 'projects/project',
                issue: 'issues/issue',
                todo: 'todos/todo'
            }),
        },

        methods: {
            ...mapActions({
                setShowNotificationDialog: 'notifications/setShowNotificationDialog',
                markAsReadNotification: 'notifications/markAsReadNotification',
                markAsUnreadNotification: 'notifications/markAsUnreadNotification',
                fetchProject: 'projects/fetchProject',
                fetchIssue: 'issues/fetchIssue',
                fetchTodo: 'todos/fetchTodo'
            }),
        },

        mounted() {
            if (this.notification.object_type === 'project') {
                this.fetchProject(this.notification.object_id)
            }

            if (this.notification.object_type === 'issue') {
                this.fetchIssue(this.notification.object_id)
            }

            if (this.notification.object_type === 'todo') {
                this.fetchTodo(this.notification.object_id)
            }
        }
    }

</script>

<style scoped>

</style>
