export default {
    issues: {},
    issue: {},
    createIssueEndpoint: '',
    showCreateIssueDialog: false,
    showIssueDialog: false,
    issuesFilter: 'open',
    issueDateLimits: null,
    issueDialogWidth: 1200,
    selectedTab: 'edit',
}
