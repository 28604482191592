<template>
    <v-card>
        <v-card-text>
            <v-form
                ref="form"
            >
                <v-text-field
                    :label="$lang('title')"
                    v-model="form.title"
                    required
                    autofocus
                    :error-messages="errors.title"
                >
                </v-text-field>

                <Editor :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event"></Editor>

                <v-row>
                    <v-col
                        cols="12"
                        md="6">

                        <v-select
                            v-model="form.assignee_id"
                            :items="permittedAssigneeUsers"
                            :item-disabled="disabledAssigneeUsers"
                            :label="$lang('responsible')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.assignee_id = null"
                            item-text="full_name"
                            item-value="id"
                            :error-messages="errors.assignee_id"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6">

                        <v-select
                            v-model="form.priority"
                            :items="priorities"
                            :label="$lang('prio')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.priority = null"
                            item-text="name"
                            item-value="slug"
                            :error-messages="errors.priority"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="6">
                        <v-dialog
                            ref="start_date_dialog"
                            v-model="start_date_modal"
                            :return-value.sync="picker_start_date"
                            priorities
                            width="290px"

                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="form.start_date"
                                    :label="$lang('start')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="picker_start_date = null"
                                    append-icon="fas fa-calendar-alt"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="end_start_modal = false"
                                    :error-messages="errors.start_date"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-if="todoDateLimits"
                                v-model="picker_start_date"
                                scrollable
                                @change="$refs.start_date_dialog.save(picker_start_date)"
                                :min="todoDateLimits.min_start_date"
                                :max="todoDateLimits.max_start_date"
                                :first-day-of-week="1"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="picker_start_date = ''">{{ $lang('delete') }}</v-btn>
                                <v-btn text color="primary" @click="start_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                <v-btn text color="primary" @click="$refs.start_date_dialog.save(picker_start_date)">{{ $lang('ok') }}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6">
                        <v-dialog
                            ref="end_date_dialog"
                            v-model="end_date_modal"
                            :return-value.sync="picker_end_date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="form.end_date"
                                    :label="$lang('end')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="picker_end_date = 'null'"
                                    append-icon="fas fa-calendar-alt"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="end_date_modal = false"
                                    :error-messages="errors.end_date"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-if="todoDateLimits"
                                v-model="picker_end_date"
                                scrollable
                                @change="$refs.end_date_dialog.save(picker_end_date)"
                                :min="todoDateLimits.min_end_date"
                                :max="todoDateLimits.max_end_date"
                                :first-day-of-week="1"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="picker_end_date = ''">{{ $lang('delete') }}</v-btn>
                                <v-btn text color="primary" @click="end_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                <v-btn text color="primary" @click="$refs.end_date_dialog.save(picker_end_date)">{{ $lang('ok') }}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-if="form.lead_time_change"
                            class="mb-4"
                            v-model="form.reason"
                            :label="$lang('reason')"
                            :error-messages="errors.reason"
                        >

                        </v-text-field>
                    </v-col>

                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="12"
                    >
                        <v-slider
                            :label="$lang('progress')"
                            v-model="form.progress"
                            thumb-label="always"
                            step="25"
                        ></v-slider>
                    </v-col>
                </v-row>

            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="submit">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import Editor from "../../../components/Editor";
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";
    export default {
        name: "EditTodo",

        components: {
            Editor: Editor
        },
        data() {
            return {
                previous_start_date: '',
                previous_end_date:'',
                picker_start_date:'',
                picker_end_date:'',
                start_date_modal: false,
                end_date_modal: false,
                errors: [],
                form: {
                    title: '',
                    body: '',
                    tokens: [],
                    assignee_id: '',
                    start_date:'',
                    end_date:'',
                    priority: '',
                    progress: '',
                    lead_time_change: false,
                    reason:''
                },
                priorities: [
                    {slug: 'low', name: this.$lang('low')},
                    {slug: 'mid', name: this.$lang('mid')},
                    {slug: 'high', name: this.$lang('high')},
                ],
                permittedAssigneeUsers: [],
            }
        },
        computed: {
            ...mapGetters({
                project: 'projects/project',
                todo: 'todos/todo',
                users : 'users/users',
                activeUsers: 'users/activeUsers',
                todoDateLimits: 'todos/todoDateLimits'
            }),
        },

        created() {
            this.permittedAssigneeUsers = this.activeUsers.filter(user => this.project.permitted_user_ids.includes(user.id));
        },

        methods: {
            ...mapActions({
                update: 'todos/update',
                fetchIssue: 'issues/fetchIssue',
                fetchTodoDateLimits: 'todos/fetchTodoDateLimits'
            }),

            disableUserItem(user) {
                return !user.active
            },

            disabledAssigneeUsers (user)
            {
                return !this.project.permitted_user_ids.includes(user.id)
            },

            submit () {
                this.errors= []
                this.update({
                    id: this.todo.id,
                    payload: this.form,
                    context: this,
                }).then(() => {
                    if(this.errors.length === 0) {
                        Bus.$emit('todo:updated')
                        Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('todo successfully changed') })
                    }

                })
            },

            formatDate (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            }
        },

        mounted() {
            if(this.todo) {
                this.fetchTodoDateLimits(`/api/todos/${this.todo.id}`)
            }
        },

        watch: {
            todo: {
                handler: function() {
                    this.$nextTick(() => {
                        this.form = this.todo
                        this.previous_start_date = this.todo.start_date
                        this.previous_end_date = this.todo.end_date
                    })
                },
                immediate: true
            },

            'form.start_date': {
                handler: function() {
                    if(this.todo.lead_time_settings.track_todos) {
                        this.form.lead_time_change = this.previous_end_date !== this.form.end_date || this.previous_start_date !== this.form.start_date;
                    }
                }
            },

            'form.end_date': {
                handler: function() {
                    if(this.todo.lead_time_settings.track_todos) {
                        this.form.lead_time_change = this.previous_end_date !== this.form.end_date || this.previous_start_date !== this.form.start_date;
                    }
                }
            },

            picker_start_date (val) {
                this.form.start_date = this.formatDate(this.picker_start_date)
            },

            picker_end_date (val) {
                this.form.end_date = this.formatDate(this.picker_end_date)
            },
        },
    }
</script>

<style scoped>
    img {
        max-width: 90%;
        height: auto;
    }
</style>
