<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ $lang('issue tags') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="showCreateIssueTagDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon>{{ $lang('issue tags') }}
            </v-btn>
        </v-toolbar>

        <IssueTagsTable></IssueTagsTable>
        <CreateIssueTagDialog></CreateIssueTagDialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import IssueTagsTable from "../components/IssueTagsTable";
    import CreateIssueTagDialog from "../components/CreateIssueTagDialog";
    export default {
        name: "IssueTags",
        components: {
            IssueTagsTable,
            CreateIssueTagDialog
        },

        methods: {
            ...mapActions({
                showCreateIssueTagDialog: 'issueTags/setCreateIssueTagDialog',
            }),
        },
    }
</script>

<style scoped>

</style>
