<template>
    <div class="container">
        <Bar :chart-data="chartData" :chart-options="options"/>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import { Bar } from 'vue-chartjs'
import { Chart, LineController, LineElement, BarController, BarElement,  PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from 'chart.js';

Chart.register(LineController, LineElement, BarController, BarElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend);

let number_format = {}
export default {
    name: "BruttoPotentialWithHardnessDegree",

    components: { Bar },

    computed: {
        ...mapGetters({
            me: 'auth/user',
        })
    },

    created() {
        number_format = this.me.number_format
    },

    data () {
        return {
            chartData: {
                labels: [
                    this.$lang('HG-0'),
                    this.$lang('HG-1'),
                    this.$lang('HG-2'),
                    this.$lang('HG-3'),
                    this.$lang('HG-4'),
                    this.$lang('HG-5'),
                    this.$lang('total')],
                datasets: [
                    {
                        label: this.$lang('potential'),
                        borderWidth: 1,
                        borderColor: 'rgba(50, 168, 82, 0.7)',
                        backgroundColor: 'rgba(50, 168, 82, 0.2)',
                        data: []
                    },
                    {
                        label: this.$lang('invest'),
                        borderWidth: 1,
                        borderColor: 'rgba(21, 101, 192, 0.7)',
                        backgroundColor: 'rgba(21, 101, 192, 0.2)',
                        data: []
                    },
                    {
                        label: this.$lang('lost potential'),
                        borderWidth: 1,
                        borderColor: 'rgba(244, 67, 54, 0.7)',
                        backgroundColor: 'rgba(244, 67, 54, 0.2)',

                        data: []
                    },
                ]
            },
            options: {

                scales: {
                    y: {
                        ticks: {
                            min: 0,
                            precision: 0,
                            callback: function (value, index, values) {
                                return webix.Number.format(value, {
                                    groupSize: number_format.group_size,
                                    groupDelimiter: _.replace(number_format.group_delimiter, '&nbsp;', ' '),
                                    decimalSize: 0,
                                    decimalDelimiter: _.replace(number_format.decimal_delimiter, '&nbsp;', ' ')
                                });
                            }
                        }
                    },
                    x: {
                        scaleLabel: {
                            display: true,
                            labelString: this.$lang('hardness degree')
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return webix.Number.format(context.raw, {
                                    groupSize: number_format.group_size,
                                    groupDelimiter: _.replace(number_format.group_delimiter, '&nbsp;', ' '),
                                    decimalSize: 0,
                                    decimalDelimiter: _.replace(number_format.decimal_delimiter, '&nbsp;', ' ')
                                })
                            }
                        },

                    },
                }

            },
            responsive: true,
            maintainAspectRatio: false
        }
        },

        props:  [
            'filteredIssuesData',
            'height'
        ],


        methods: {

            num() {
                return 'value';
            },
            render() {

                let potentialsByHardnessDegree = []
                let investByHardnessDegree = []
                let lostPotentialByHardnessDegree = []

                potentialsByHardnessDegree[0] = 0
                potentialsByHardnessDegree[1] = 0
                potentialsByHardnessDegree[2] = 0
                potentialsByHardnessDegree[3] = 0
                potentialsByHardnessDegree[4] = 0
                potentialsByHardnessDegree[5] = 0
                potentialsByHardnessDegree[6] = 0

                investByHardnessDegree[0] = 0
                investByHardnessDegree[1] = 0
                investByHardnessDegree[2] = 0
                investByHardnessDegree[3] = 0
                investByHardnessDegree[4] = 0
                investByHardnessDegree[5] = 0
                investByHardnessDegree[6] = 0

                lostPotentialByHardnessDegree[0] = 0
                lostPotentialByHardnessDegree[1] = 0
                lostPotentialByHardnessDegree[2] = 0
                lostPotentialByHardnessDegree[3] = 0
                lostPotentialByHardnessDegree[4] = 0
                lostPotentialByHardnessDegree[5] = 0
                lostPotentialByHardnessDegree[6] = 0


                _.each(_.flatten(this.filteredIssuesData),function(value, key){
                    potentialsByHardnessDegree[value.hardness_degree] += value.total_potential
                    potentialsByHardnessDegree[6] += value.total_potential
                    investByHardnessDegree[value.hardness_degree] += value.total_invest
                    investByHardnessDegree[6] += value.total_invest
                    lostPotentialByHardnessDegree[value.hardness_degree] += value.lost_potential
                    lostPotentialByHardnessDegree[6] += value.lost_potential
                });
                this.chartData.datasets[0].data = potentialsByHardnessDegree
                this.chartData.datasets[1].data = investByHardnessDegree
                this.chartData.datasets[2].data = lostPotentialByHardnessDegree
            }

        },

    watch: {
            filteredIssuesData: {
                handler: function() {
                    this.render()
                },
                deep: true,
                immediate: true
            },
        },
    }
</script>

<style scoped>

</style>



