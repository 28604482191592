
export default {
    kanbanCircuits: null,
    kanbanChartsData: {
        chartData: {
            labels: [],
            datasets: []
        }
    }
}

