export const costCenters = (state) => {
    return state.costCenters
}

export const activeCostCenters = (state) => {
    return state.activeCostCenters
}

export const costCenter = (state) => {
    return state.costCenter
}

export const showCreateCostCenterDialog = (state) => {
    return state.showCreateCostCenterDialog
}
