export const locations = (state) => {
    return state.locations
}

export const location = (state) => {
    return state.location
}

export const showCreateLocationDialog = (state) => {
    return state.showCreateLocationDialog
}
