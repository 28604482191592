export const updateNotificationTimes = ({ dispatch, state, commit }, { payload}) => {
    return axios.patch(`/api/users/updateNotificationTimes`, payload).then((response) => {
        //commit('setUserData', response.data.data, {root: true})
    }).catch((error) => {

    })
}

export const updatePassword = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/users/updatePassword`, payload).then((response) => {
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const updateLanguage = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/users/updateLanguage`, payload).then((response) => {
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}


export const updateNumberFormat = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/users/updateNumberFormat`, payload).then((response) => {
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}
