export const SET_KANBAN_CIRCUITS_DATA = (state, data) => {
    state.kanbanCircuits = data
}

export const ADD_KANBAN_CIRCUIT = (state, data) => {
    state.kanbanCircuits.push(data)
}

export const UPDATE_KANBAN_CIRCUITS_DATA = (state, data) => {
    Vue.set(state.kanbanCircuits, state.kanbanCircuits.findIndex(circuits => circuits.id === data.id), data)
}

export const SET_KANBAN_CIRCUIT_DATA = (state, data) => {
    state.kanbanCircuit = data
}

export const SET_CREATE_KANBAN_CIRCUIT_DIALOG = (state, data) => {
    state.showCreateKanbanCircuitDialog = data
}

export const SET_KANBAN_CIRCUIT_DIALOG = (state, data) => {
    state.showKanbanCircuitDialog = data
}
