export const projects = (state) => {
    return state.projects
}

export const project = (state) => {
    return state.project
}

export const showCreateProjectDialog = (state) => {
    return state.showCreateProjectDialog
}

export const copyProject = (state) => {
    return state.copyProject
}

export const selectedTab = (state) => {
    return state.selectedTab
}

export const treeFilter = (state) => {
    return state.treeFilter
}

export const tasks = (state) => {
    return state.gantt.tasks
}

export const task = (state) => {
    return state.gantt.task
}

export const event = (state) => {
    return state.gantt.event
}

export const showTaskContextMenu = (state) => {
    return state.gantt.showTaskContextMenu
}

export const ganttOpenAll = (state) => {
    return state.gantt.openAll
}

export const ganttMoveProject = (state) => {
    return state.gantt.moveProject
}

export const ganttScale = (state) => {
    return state.gantt.scale
}

export const ganttRange = (state) => {
    return state.gantt.range
}

export const ganttSearch = (state) => {
    return state.gantt.search
}

export const ganttShowPotentials = (state) => {
    return state.gantt.showPotentials
}

export const resetGantt = (state) => {
    return state.gantt.reset
}

export const changedTasks = (state) => {
    return state.gantt.changedTasks
}

export const projectDateLimits = (state) => {
    return state.projectDateLimits
}

