export const lang = (state) => {
    return state.lang
}

export const supportedLangs = (state) => {
    return state.supportedLangs
}

export const translatableStrings = (state) => {
    return state.translatableStrings
}

export const customTranslations = (state) => {
    return state.customTranslations
}

export const supportedLocals = (state) => {
    return state.supportedLocals
}

export const local = (state) => {
    return state.local
}
