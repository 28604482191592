<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "NotificationsTable",

        data() {
            return {
                loading: true,
            }
        },

        computed: {
            ...mapGetters({
                notifications: 'notifications/notifications',
                notification: 'notifications/notification',
                notificationsFilter: 'notifications/notificationsFilter'
            })
        },

        created() {
            const self = this
            this.fetchNotifications().then(function (){
                self.parseData()
            })
        },

        methods: {
            ...mapActions({
                fetchNotifications: 'notifications/fetchNotifications',
                fetchNotification: 'notifications/getNotification',
                markAsReadNotification: 'notifications/markAsReadNotification',
                markAsUnreadNotification: 'notifications/markAsUnreadNotification',
                gotoNotificationObject: 'notifications/gotoNotificationObject'
            }),

            parseData: function () {
                const notifications = webix.copy(this.notifications)
                this.loading = true
                $$(this.webixId).clearAll()
                $$(this.webixId).parse(webix.copy(notifications))
            },

            filterTable(filter) {
                if(filter === 'all') {
                    this.webixId.filter(function(obj){
                        return true
                    });
                }

                if(filter === 'open') {
                    this.webixId.filter(function(obj){
                        return !obj.read_at
                    });
                }

                if(filter === 'closed') {
                    this.webixId.filter(function(obj){
                        return obj.read_at
                    });
                }
            }
        },

        mounted() {
            const self = this

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: 600,
                id: 'notifications',

                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                columns: [
                    {id: "read_label", header: [self.$lang('status'), { content:"multiSelectFilter", suggest: {fitMaster: false, width: 220} }], minWidth: 150, adjust: true , sort: "string", template: function (obj){
                            return obj.read_label
                        }},
                    {id: "message_link", header: [ self.$lang('notification'),{ content:"textFilter" }],fillspace: true, minWidth: 200,  sort: "string", template: "#message_link#"},
                    {id:"read_at", map:"(date)#read_at#", header:[self.$lang('read'), { content:"dateRangeFilter"}], sort: "date", adjust: true , template: function (obj){
                            return webix.i18n.parseFormatStr(obj.read_at_as_date)
                        }},
                    {id:"created_at", map:"(date)#created_at#", header:[self.$lang('created'), { content:"dateRangeFilter"}], sort: "date", adjust: true , template: function (obj){
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }},
                    {id: "action", header: " ", adjust: true , template: function (obj){
                            return obj.action_read
                        }},
                ],
                onClick:{
                    "title_link" : function  (event, column, target) {
                        self.fetchNotification(column).then(function (){
                            self.gotoNotificationObject(self.notification)
                        })

                    },
                    "mark_as_read" : function  (event, column, target) {
                        self.markAsReadNotification(column)
                    },
                    "mark_as_unread" : function  (event, column, target) {
                        self.markAsUnreadNotification(column)
                    },
                },
                on: {
                    onAfterSelect: function(){

                    },
                    onItemDblClick: function () {

                    },

                    onAfterLoad: function () {
                        if (self.notification && this.exists(self.notification.id)) {
                            this.select(self.notification.id)
                            this.showItem(self.notification.id)
                        }

                        self.loading = false
                        //self.filterTable(self.notificationsFilter)
                    },

                },
            })

        },

        watch: {
            notifications: function (notifications) {
                this.parseData(notifications)
            },

            notificationsFilter (filter) {
                this.filterTable(filter)
            }
        },
        destroyed:function(){
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style scoped>

</style>
