<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>

</template>

<script>

    import Bus from "../../../bus";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "IssuesTable",
        data() {
            return {
                loading: true,
                datatableExists: true,
                defaultState: null,
                enableSelect: false,
                tableData: []
            }
        },

        props: {
            endpoint: {
                type: String
            },
            tableId: {
                type: String
            },
            dragRows: {
                type: Boolean
            },
            height: {
                type: Number,
                default: 600
            }
        },

        computed: {
            ...mapGetters({
                issue: 'issues/issue',
                issuesFilter: 'issues/issuesFilter',
                user: 'auth/user',
                showIssueDialog: 'issues/showIssueDialog',
                activeFilters: 'tableFilters/activeFilters'
            })
        },

        methods: {
            ...mapActions({
                fetchIssue: 'issues/fetchIssue',
                fetchSingleIssue: 'issues/fetchSingleIssue',
                setIssue: 'issues/setIssue',
                actionShowIssueDialog: 'issues/setIssueDialog',
                setIssuesFilter: 'issues/setIssuesFilter',
                updateOrder: 'issues/updateOrder',
                fetchProject: 'projects/fetchProject'
            }),
            loadDatatable() {
                const self = this
                // axios.get(this.endpoint).then(function (response){
                //     self.tableData = webix.copy(response.data)
                // })

                axios.get(this.endpoint).then(function (response){
                    self.webixId.parse(webix.copy(response.data))
                })
            }
        },

        mounted() {
            const self = this

            this.datatableExists = true

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: window.innerHeight - 200,
                id: self.tableId,
                headermenu:{
                    width:250,
                },
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                footer:false,
                drag:self.dragRows,
                columns: [

                    {
                        id: "issue_number",
                        header: [self.$lang('no.'),{content: "textFilter"}],
                        width: 50,
                        sort: "int"
                    },

                    {
                        id: "priority_text",
                        header: [self.$lang('prio'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 100}
                        }],
                        minWidth: 70,
                        adjust: false,
                        css: {'text-align': 'center'},
                        sort: "string",
                        template: function (obj) {
                            return obj.priority_text
                        }
                    },

                    {
                        id: "title",
                        header: [self.$lang('title'), {content: "textFilter"}],
                        footer: self.$lang('total'),
                        fillspace: true,
                        minWidth: 200,
                        sort: "string",
                        template: "#title_link#"
                    },

                    {
                        id: "project_name",
                        header: [self.$lang('project'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 400}
                        }],
                        minWidth: 200,
                        adjust: false,
                        sort: "string",
                    },

                    {
                        id: "assignee_label",
                        header: [self.$lang('responsible'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 200}
                        }],
                        minWidth: 150,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.assignee_label
                        }
                    },

                    {
                        id: "user_labels",
                        header: [self.$lang('team'), {
                            content: "textFilter",
                        }],
                        minWidth: 150,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.user_labels
                        }
                    },

                    {
                        id: "total_potential",
                        header: [self.$lang('potential') + " " + self.user.company.currency, {content: "textFilter"}],
                        footer: {content: "summColumn"},
                        adjust: false,
                        sort: "int",
                        css:{'text-align':'right'},
                        template: function (obj) {
                            return self.$num(obj.total_potential, 0)
                        }
                    },

                    {
                        id: "total_invest",
                        header: [self.$lang('invest') + " " + self.user.company.currency, {content: "textFilter"}],
                        footer: {content: "summColumn"},
                        adjust: false,
                        sort: "int",
                        css:{'text-align':'right'},
                        template: function (obj) {
                            return self.$num(obj.total_invest, 0)
                        }
                    },

                    {
                        id: "lost_potential",
                        header: [self.$lang('lost potential') + " " + self.user.company.currency, {content: "textFilter"}],
                        footer: {content: "summColumn"},
                        adjust: false,
                        sort: "int",
                        css:{'text-align':'right'},
                        template: function (obj) {
                            return self.$num(obj.lost_potential, 0)
                        }
                    },

                    {
                        id: "issue_tag_labels",
                        header: [self.$lang('issue tags'), {content: "textFilter"}],
                        minWidth: 50,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.issue_tag_labels
                        }
                    },

                    {
                        id: "hardness_degree_label",
                        header: [self.$lang('HG'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 50,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.hardness_degree_label
                        }
                    },
                    {
                        id: "due_date_status_label",
                        header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 50,
                        adjust: false,
                        sort: "string",
                        css: {'text-align': 'center'},
                        template: function (obj) {
                            return obj.due_date_status_label
                        }
                    },

                    {
                        id: "start_date",
                        map: "(date)#start_date#",
                        header: [self.$lang('start'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.start_date)
                        }
                    },

                    {
                        id: "end_date",
                        map: "(date)#end_date#",
                        header: [self.$lang('end'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.end_date)
                        }
                    },

                    {
                        id: "creator_name",
                        header: [self.$lang('creator'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 200}
                            }
                        ],
                        adjust: false,
                        sort: "string"
                    },

                    {
                        id: "created_at",
                        map: "(date)#created_at#",
                        header: [self.$lang('created'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }
                    }
                ],
                onClick:{
                    "title_link" : function  (event, row, target) {
                        self.fetchSingleIssue(row.row).then(function () {
                            self.fetchProject(self.issue.issueable_id).then(function (){
                                self.actionShowIssueDialog(true)
                            })
                        })
                    }
                },
                on: {
                    onSelectChange: function(){
                        if (self.enableSelect) {
                            self.fetchSingleIssue(this.getSelectedId(true))
                        }
                    },

                    onItemDblClick: function (row) {
                        self.fetchSingleIssue(row.row).then(function () {
                            self.fetchProject(self.issue.issueable_id).then(function (){
                                self.actionShowIssueDialog(true)
                            })
                        })
                    },

                    onBeforeLoad() {
                        if(!self.defaultState) {
                            self.defaultState = this.getState()
                        }
                    },

                    onAfterLoad: function () {
                        self.loading = false
                        if (self.tableId in self.activeFilters) {
                            this.setState(JSON.parse(self.activeFilters[self.tableId].filter_data))
                        }

                        if (self.issue && this.exists(self.issue.id)) {
                            this.select(self.issue.id)
                            this.showItem(self.issue.id)
                        }
                    },

                    onAfterDrop: function () {
                        let orderedIds = []
                        this.eachRow(function(row){
                            orderedIds.push(row)
                        });
                        self.updateOrder({orderedIds: orderedIds}).then(function (){
                            Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('order successfully changed')});
                        })
                    }
                },
            })

            Bus.$on('project:updated', function () {
                if(self.datatableExists) {
                    self.loadDatatable()
                }
            });

            Bus.$on('gantt:updated', function () {
                if(self.datatableExists) {
                   self.loadDatatable()
                }
            });

            Bus.$on('issue:updated', function () {
                this.enableSelect = false
                if(self.datatableExists) {
                    self.loadDatatable()
                }
                this.enableSelect = true
            });

            Bus.$on('issue:created', function () {
                if(self.datatableExists) {
                    self.loadDatatable()
                }
            });

            Bus.$on('issue:deleted', function (id) {
                if(self.datatableExists) {
                    self.webixId.remove(id)
                }
            });

            Bus.$on('todo:updated', function () {
                if(self.datatableExists) {
                    self.loadDatatable()
                }
            });

            Bus.$on('todo:created', function () {
                if(self.datatableExists) {
                    self.loadDatatable()
                }
            });

            Bus.$on('potential:updated', function () {
                if(self.datatableExists) {
                    self.loadDatatable()
                }
            });

            Bus.$on('potential:created', function () {
                if(self.datatableExists) {
                    self.loadDatatable()
                }
            });

            Bus.$on('filterTable:' + self.tableId, function (data) {
                if(self.datatableExists) {
                    $$(self.webixId).setState(JSON.parse(data))
                }
            });

            Bus.$on('resetFilterTable:' + self.tableId, function () {
                if(self.datatableExists) {
                    $$(self.webixId).setState(self.defaultState)
                }
            });

            this.loadDatatable()
        },

        watch: {
            tableData: {
                handler: function (data) {
                    this.webixId.parse(data)
                },
            }
        },

        destroyed:function(){
            this.datatableExists = false
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style scoped>

</style>
