

export const fetchSolex = ({ dispatch, commit }, id) => {
    return axios.get(`/api/solexes/${id}`).then((response) => {
        commit('SET_SOLEX_DATA', response.data)
    })
}

export const setSolexDialog = ({ dispatch, state, commit }, value) => {
    commit('SET_SOLEX_DIALOG', value)
}

export const setCreateSolexDialog = ({ dispatch, state, commit }, {value, endpoint}) => {
    commit('SET_CREATE_SOLEX_ENDPOINT', endpoint)
    commit('SET_CREATE_SOLEX_DIALOG', value)
}

export const setSolexDialogWidth = ({ dispatch, commit }, value) => {
    commit('SET_SOLEX_DIALOG_WIDTH', value)
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post(state.createSolexEndpoint, payload).then((response) => {
        commit('SET_SOLEX_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { id, payload, context }) => {
    return axios.patch(`/api/solexes/${id}`, payload).then((response) => {
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const deleteSolex = ({ dispatch, state, commit }, id) => {
    return axios.delete(`/api/solexes/${id}`).then((response) => {
       // commit('SET_SOLEX_DIALOG', false)
    }).catch((error) => {

    })
}

export const setSelectedTab = ({ dispatch, state, commit}, value) => {
    commit('SET_SELECTED_TAB', value)
}

export const setSolexToIssue = ({ dispatch, state, commit}, value) => {
    commit('SET_SOLEX_TO_ISSUE', value)
}

export const requestSolexToIssue = ({ dispatch, state, commit}, id) => {
    return axios.patch(`/api/solexes/${id}/requestSolexToIssue`).then((response) => {
        commit('SET_SOLEX_DATA', response.data)
    })
}

export const approveSolexToIssue = ({ dispatch, state, commit}, id) => {
    return axios.patch(`/api/solexes/${id}/approveSolexToIssue`).then((response) => {
        commit('SET_SOLEX_DATA', response.data)
    })
}

export const reworkSolex = ({ dispatch, state, commit}, id) => {
    return axios.patch(`/api/solexes/${id}/reworkSolex`).then((response) => {
        commit('SET_SOLEX_DATA', response.data)
    })
}
