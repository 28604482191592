import { KanbanOrders } from '../views'

export default [
    {
        path: '/sales/kanbanOrders',
        component: KanbanOrders,
        name: 'sales.kanbanOrders',
        meta: {
            guest: false,
            needsAuth: true
        }
    },


]
