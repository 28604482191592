export const companies = (state) => {
    return state.companies
}

export const company = (state) => {
    return state.company
}

export const companyPlan = (state) => {
    return state.companyPlan
}

export const showEditCompanyPlanDialog = (state) => {
    return state.showEditCompanyPlanDialog
}
