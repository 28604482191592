import { Users, User} from '../views'


export default [
    {
        path: '/companyAdmin/users',
        component: Users,
        name: 'companyAdmin.users',
        meta: {
            guest: false,
            needsAuth: true,
            role: 'Company Admin'
        }
    },
    {
        path: '/companyAdmin/users/:userId/show',
        component: User,
        name: 'companyAdmin.users.show',
        meta: {
            guest: false,
            needsAuth: true,
            role: 'Company Admin'
        },
        props: true
    }

]

