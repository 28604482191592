<template>
    <div>
        <v-card-text>
            <v-row>
                <v-col
                    md="6"
                    cols="12"
                >
                    <v-form
                        ref="form"
                    >
                        <v-select
                            v-model="language"
                            :items="supportedLangs"
                            :label="$lang('language')"
                            :error-messages="errors.lang"
                        ></v-select>

                    </v-form>

                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col
                    md="6"
                    cols="12"
                >
                    <v-btn
                        color="primary"
                        text
                        @click="submit"
                    >
                        {{ $lang('save') }}
                    </v-btn>
                </v-col>
            </v-row>

        </v-card-text>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";

export default {
name: "Language",
    data () {
        return {
            loading: false,
            language: '',
            local:[],
            errors: [],
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            supportedLangs: 'lang/supportedLangs',
            supportedLocals: 'lang/supportedLocals'
        }),

    },

    mounted() {
        this.language = this.user.lang
        this.local = this.user.local
    },

    methods: {
        ...mapActions({
            updateLanguage: 'account/updateLanguage',
        }),

        submit () {
            this.loading = true
            this.errors = []
            this.updateLanguage({
                payload: {
                    language: this.language,
                    local: this.local
                },
                context: this,
            }).then(() => {
                this.loading = false
                if(this.errors.length === 0) {
                    Bus.$emit('showAlert', {color: 'success', 'message': this.$lang('language successfully changed')});
                    location.reload()
                }
            })
        },
    }
}
</script>

<style >

</style>
