<template>
    <div
        v-if="me.companyPlans.includes('kanban')"
    >
        <v-btn
            color="primary"
            small
            @click="setShowScannerDialog(true)"
            class="mr-2"
        >
            <v-icon>
                fas fa-qrcode
            </v-icon>
        </v-btn>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
    name: "ScanComponent",
    computed: {
        ...mapGetters({
            me: 'auth/user',
        }),

        showScannerButton: function () {
            if(this.$companyPlan('kanban')) {
                return true
            }
        }
    },

    methods: {
        ...mapActions({
            setShowScannerDialog: 'scanner/setScannerDialog'
        }),
    },
}
</script>

<style scoped>

</style>
