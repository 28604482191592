import products from './products/routes'
import projects from './projects/routes'
import projectTypes from './masterData/projectTypes/routes'
import locations from './masterData/locations/routes'
import divisions from './masterData/divisions/routes'
import departments from './masterData/departments/routes'
import workplaces from './masterData/workplaces/routes'
import costCenters from './masterData/costCenters/routes'
import issueTags from './masterData/issueTags/routes'

import masterProducts from './masterData/products/routes'

import notes from './notes/routes'
import notifications from './notifications/routes'
import lists from './lists/routes'

import fileManager from './fileManager/routes'

import account from './account/routes'

import adminUsers from './admin/users/routes'
import adminCompanies from './admin/companies/routes'

import adminCalculations from './admin/calculations/routes'
import adminLang from './admin/lang/routes'


import companyAdminUsers from './companyAdmin/users/routes'
import companyAdminCompany from './companyAdmin/company/routes'

import controlling from './controlling/routes'

import suppliers from './masterData/suppliers/routes'
import kanbanCircuits from './masterData/kanbanCircuits/routes'
import kanbanExport from "./procurement/kanbanExport/routes";
import procurementKanban from './procurement/kanban/routes'
import procurementDashboard from './procurement/dashboard/routes'
import salesKanbanOrders from './sales/kanbanOrders/routes'
import salesKanbanCircuits from './sales/kanbanCircuits/routes'

import mobileDashboard from './mobileDashboard/routes'

export default [
    ...products,
    ...projects,
    ...projectTypes,
    ...locations,
    ...divisions,
    ...departments,
    ...workplaces,
    ...costCenters,
    ...issueTags,
    ...masterProducts,

    ...notes,
    ...notifications,
    ...lists,
    ...fileManager,
    ...account,
    ...adminUsers,
    ...adminCompanies,
    ...adminLang,
    ...controlling,

    ...companyAdminUsers,
    ...companyAdminCompany,
    ...adminCalculations,

    ...suppliers,
    ...kanbanCircuits,
    ...kanbanExport,
    ...procurementKanban,
    ...procurementDashboard,
    ...salesKanbanOrders,
    ...salesKanbanCircuits,


    ...mobileDashboard
]
