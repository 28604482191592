<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "DashboardTodosTable",

        data() {
            return {
                loading: true,
                enableSelect: false,
                datatableExists: true,
                defaultState: null,
                currentFilter: null
            }
        },

        props: {
            issues: {
                type: Array
            },
            endpoint: {
                type: String
            },
            tableId: {
                type: String
            },
            dragRows: {
                type: Boolean
            },
        },

        computed: {
            ...mapGetters({
                todo: 'todos/todo',
                todos: 'todos/todos',
                issue: 'issues/issue',
                issueDialogWidth: 'issues/issueDialogWidth',
                todoDialogWidth: 'todos/todoDialogWidth',
                activeFilters: 'tableFilters/activeFilters'
            })
        },

        methods: {
            ...mapActions({
                fetchTodo: 'todos/fetchTodo',
                fetchIssue: 'issues/fetchIssue',
                fetchProject: 'projects/fetchProject',
                actionShowTodoDialog: 'todos/setTodoDialog',
                actionShowIssueDialog: 'issues/setIssueDialog',
                setTodo: 'todos/setTodo',
                updateOrder: 'todos/updateOrder'
            }),

            parseData: function() {
                const todos = _.map(webix.copy(this.issues), 'todos')
                this.loading = true
                this.webixId.clearAll()
                this.webixId.parse(_.flatten(todos))
                if(this.tableId in this.activeFilters ) {
                    this.webixId.setState(JSON.parse(this.activeFilters[this.tableId].filter_data))
                }
            },
        },

        mounted() {
            const self = this

            this.datatableExists = true

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: window.innerHeight - 200,
                id: self.tableId,
                headermenu:{
                    width:250,
                },
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                drag:self.dragRows,
                headerRowHeight:40,
                columns: [

                    {id: "todo_number", header: [self.$lang('no.'), {content: "textFilter"}], width: 50, sort: "int"},

                    {
                        id: "title",
                        header: [self.$lang('title'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string",
                        template: "#title_link#"
                    },

                    {
                        id: "issue_name",
                        header: [self.$lang('issue'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string",
                        template: "#issue_link#"
                    },

                    {
                        id: "assignee_label",
                        header: [self.$lang('responsible'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 250}
                        }],
                        minWidth: 150,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.assignee_label
                        }
                    },


                    {
                        id: "progress",
                        header: [self.$lang('progress'), {content: "textFilter"}],
                        sort: "string",
                        adjust: false,
                        css: {'text-align': 'center'},
                        template: function (obj) {
                            return obj.progress_bar
                        }
                    },

                    {
                        id: "due_date_status_label",
                        header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 50,
                        adjust: false,
                        sort: "string",
                        css: {'text-align': 'center'},
                        template: function (obj) {
                            return obj.due_date_status_label
                        }
                    },

                    {
                        id: "start_date",
                        map: "(date)#start_date#",
                        header: [self.$lang('start'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.start_date)
                        }
                    },

                    {
                        id: "end_date",
                        map: "(date)#end_date#",
                        header: [self.$lang('end'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.end_date)
                        }
                    },

                    {
                        id: "closed_at",
                        map: "(date)#closed_at#",
                        header: [self.$lang('closed'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.closed_at)
                        }
                    },

                    {
                        id: "closer_label",
                        header: [self.$lang('closed'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 200}
                        }],
                        minWidth: 150,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.closer_label
                        }
                    },
                    {
                        id: "priority_label",
                        header: [self.$lang('prio'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 100}
                        }],
                        minWidth: 70,
                        adjust: false,
                        css: {'text-align': 'center'},
                        sort: "string",
                    },
                    {
                        id: "creator_name",
                        header: [self.$lang('creator'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 100}}],
                        adjust: false,
                        sort: "string"
                    },
                    {
                        id: "created_at",
                        map: "(date)#created_at#",
                        header: [self.$lang('created'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }
                    }
                ],
                onClick:{
                    "title_link" : function  (event, row, target) {
                        self.fetchTodo(row.row).then(function () {
                            self.fetchIssue(self.todo.todoable_id).then(function () {
                                self.fetchProject(self.issue.issueable_id).then(function (){
                                    self.actionShowTodoDialog(true)
                                })
                            })
                        })
                    },
                    "issue_link" : function  (event, row, target) {
                        self.fetchTodo(row.row).then(function () {
                            self.fetchIssue(self.todo.todoable_id).then(function () {
                                self.actionShowIssueDialog(true)
                            })
                        })
                    }
                },
                on: {
                    onSelectChange: function(){
                        self.fetchTodo(this.getSelectedId(true))
                    },

                    onItemDblClick: function (row) {
                        self.fetchTodo(this.getSelectedId(true)).then(function () {
                            self.fetchIssue(self.todo.todoable_id).then(function () {
                                self.fetchProject(self.issue.issueable_id).then(function (){
                                    self.actionShowTodoDialog(true)
                                })
                            })
                        })
                    },

                    onBeforeLoad() {
                        if(!self.defaultState) {
                            self.defaultState = this.getState()
                        }
                    },

                    onAfterLoad: function () {
                        self.loading = false

                        if(!self.defaultState) {
                            self.defaultState = this.getState()
                        }

                        if (self.todo && this.exists(self.todo.id)) {
                            this.select(self.todo.id)
                            this.showItem(self.todo.id)
                        }
                    },

                    onAfterFilter: function () {
                        webix.storage.local.put(self.tableId, this.getState());
                    },

                },
            })

            Bus.$on('filterTable:' + self.tableId, function (data) {
                if(self.datatableExists) {
                    if(!self.defaultState) {
                        self.defaultState = $$(self.webixId).getState()
                    }
                    $$(self.webixId).setState(JSON.parse(data))
                }
            });

            Bus.$on('resetFilterTable:' + self.tableId, function () {
                if(self.datatableExists) {
                    $$(self.webixId).setState(self.defaultState)
                    self.currentFilter = self.defaultState
                }
            });



        },

        watch: {
            issues: {
                handler: function (issues) {

                    if(issues) {
                        this.$nextTick(() => {
                            this.enableSelect = false
                            this.loading = true
                            this.parseData()
                            if (this.todo && $$(this.webixId).exists(this.todo.id)) {
                                $$(this.webixId).select(this.todo.id)
                                $$(this.webixId).showItem(this.todo.id)
                            }
                            this.enableSelect = true
                        })
                    }
                },
                immediate: true,
                deep: true
            },
        },

        destroyed:function(){
            this.datatableExists = false
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style>
progress[value] {
    -webkit-appearance: none;
    appearance: none;

    width: 120px;
    height: 15px;
}
progress[value]::-webkit-progress-bar {
    background-color: lightgrey !important;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
    border-radius: 2px;
    background-color: rgba(30, 136, 229, 0.8) !important;
}
</style>
