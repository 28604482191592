<template>
    <v-dialog
        persistent
        v-model="showTodoDialog"
        :width="todoDialogWidth"
        @click:outside="confirmCloseDialog = true"
        @keydown.esc="confirmCloseDialog = true"
    >
        <v-toolbar flat color="white" dense >

            <v-toolbar-title >{{ todo.title}} </v-toolbar-title>
            <v-spacer></v-spacer>
            <template
                v-if="todoDialogWidth > 600"
            >

                <v-btn
                    small
                    outlined
                    color="primary"
                    class="mr-2"
                    @click="setTodoDialogWidth(430)"
                >
                    {{ $lang('small')}}
                </v-btn>


                <v-btn
                    v-if="_.includes(todo.user_permissions, 'edit')"
                    small
                    outlined
                    color="primary"
                    class="mr-2"
                    @click="showCreateTodoDialog({value: true, endpoint: `/api/todos/${todo.id}/todos`})"
                >
                    <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('todo')}}
                </v-btn>
            </template>

            <template
                v-else
            >
                <v-menu
                    offset-y
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            small
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                        >
                            {{$lang('menu')}}
                        </v-btn>
                    </template>
                    <v-list
                        class="text-center"
                    >
                        <v-list-item
                            v-if="_.includes(todo.user_permissions, 'edit')"
                            @click="showCreateTodoDialog({value: true, endpoint: `/api/todos/${todo.id}/todos`})"
                        >
                            <v-list-item-title><v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('todo')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="setTodoDialogWidth(1200)"
                        >
                            <v-list-item-title>{{ $lang('wide') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <v-btn
                small
                outlined
                color="primary"
                @click="closeDialog"
            >
                <v-icon class="mr-1">fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>
        <TodoTabs></TodoTabs>
        <v-dialog
            v-model="confirmCloseDialog"
            width="600"
        >
            <v-card>
                <v-card-title
                    primary-title
                    class="justify-center"
                >
                    {{$lang('close dialog')}}
                </v-card-title>
                <v-card-text
                    class="text-center"
                >
                    <strong>
                        {{$lang('do you really want to close without saving?')}}
                    </strong>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="closeDialog"
                    >
                        {{$lang('close without saving')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="confirmCloseDialog = false"
                    >
                        {{$lang('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import TodoTabs from "./TodoTabs";
    import TableFilterDropDown from "../../helpers/tableFilters/components/TableFilterDropDown";


    export default {
        name: "TodoDialog",
        components: {TableFilterDropDown, TodoTabs},
        data() {
            return {
                confirmCloseDialog: false,
            }
        },

        computed: {
            ...mapGetters({
                todos: 'todos/todos',
                todo: 'todos/todo',
                showTodoDialog: 'todos/showTodoDialog',
                todoDialogWidth: 'todos/todoDialogWidth',
                selectedTab: 'todos/selectedTab',
            }),
        },

        methods: {
            ...mapActions({
                actionShowTodoDialog: 'todos/setTodoDialog',
                showCreateTodoDialog: 'todos/setCreateTodoDialog',
                fetchIssue: 'issues/fetchIssue',
                setTodoDialogWidth: 'todos/setTodoDialogWidth',
            }),

            closeDialog () {
                this.actionShowTodoDialog(false)
            },

            updateParent(id) {
                axios.get(`/api/todos/${id}`).then((response) => {

                    if (response.data.todoable_type === 'todo') {
                        //this.updateParent(response.data.todoable_id)
                    }
                    else {
                        if (response.data.todoable_type === 'issue') {
                            //this.fetchIssue(response.data.todoable_id)
                        }
                    }
                })

            }
        },



    }

</script>

<style scoped>

</style>
