<template>
    <v-tabs >
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="edit">
            <EditLocation></EditLocation>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteLocation ></DeleteLocation>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import EditLocation from "./EditLocation";
import DeleteLocation from "./DeleteLocation";
    export default {
        name: "LocationTab",

        components: {
            EditLocation,
            DeleteLocation
        },

        methods: {

        },

    }
</script>

<style scoped>

</style>
