<template>
    <v-tabs >
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="edit">
            <EditIssueTag></EditIssueTag>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteIssueTag ></DeleteIssueTag>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import EditIssueTag from "./EditIssueTag";
import DeleteIssueTag from "./DeleteIssueTag";
    export default {
        name: "ProjectTab",

        components: {
            EditIssueTag,
            DeleteIssueTag
        },

        methods: {

        },

    }
</script>

<style scoped>

</style>
