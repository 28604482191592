<template>
    <div>

    </div>
</template>
<script>
    import  { mapGetters, mapActions} from 'vuex';
    export default {
        name: "KanbanCircuitsTable",

        computed: {
            ...mapGetters({
                kanbanCircuit: 'kanbanCircuits/kanbanCircuit',
                kanbanCircuits: 'kanbanCircuits/kanbanCircuits'
            })
        },

        methods: {
            ...mapActions({
                fetchKanbanCircuit: 'kanbanCircuits/fetchKanbanCircuit',
                setKanbanCircuitDialog: 'kanbanCircuits/setKanbanCircuitDialog',
            }),
        },

        mounted:function(){
            const self = this
            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: window.innerHeight - 200,
                id: 'kanbanCircuits',
                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                columns: [
                    {
                        id: "name",
                        header: [self.$lang('name'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "number",
                        header: [self.$lang('number'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "part_name",
                        header: [self.$lang('part name'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "part_number",
                        header: [self.$lang('part number'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "unit",
                        header: [self.$lang('unit'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 50,
                        sort: "string",
                        css: {'text-align': 'right'}
                    },
                    {
                        id: "lot_size",
                        header: [self.$lang('lot size'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 50,
                        sort: "string",
                        css: {'text-align': 'right'}
                    },
                    {
                        id: "lot_item_quantity",
                        header: [self.$lang('lot item quantity'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 50,
                        sort: "string",
                        css: {'text-align': 'right'}
                    },
                    {
                        id: "lot_item_unit",
                        header: [self.$lang('lot item unit'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 50,
                        sort: "string",
                        css: {'text-align': 'right'}
                    },
                    {
                        id: "withdrawal_lot_size",
                        header: [self.$lang('withdrawal lot size'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 50,
                        sort: "string",
                        css: {'text-align': 'right'}
                    },
                    {
                        id: "delivery_time",
                        header: [self.$lang('delivery time'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 50,
                        sort: "string",
                        css: {'text-align': 'right'}
                    },
                    {
                        id: "storage_location",
                        header: [self.$lang('storage location'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 100,
                        sort: "string"
                    },
                    {
                        id: "stock",
                        header: [self.$lang('stock'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 50,
                        sort: "string",
                        css: {'text-align': 'right'}
                    },
                    {
                        id: "safety_stock",
                        header: [self.$lang('safety stock'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 50,
                        sort: "string",
                        css: {'text-align': 'right'}
                    },
                    {
                        id: "reporting_stock",
                        header: [self.$lang('reporting stock'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 50,
                        sort: "string",
                        css: {'text-align': 'right'}
                    },
                    {
                        id: "supplier_name",
                        header: [self.$lang('supplier'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 150,
                        adjust: true,
                        sort: "string",
                        template: function (obj) {
                            return obj.supplier_name
                        }
                    },
                    {
                        id: "supplier_info",
                        header: [self.$lang('supplier info'), {content: "textFilter"}],
                        minWidth: 150,
                        adjust: true,
                        sort: "string",
                    },

                    {
                        id: "supplier_part_number",
                        header: [self.$lang('supplier part number'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 100,
                        adjust: true,
                        sort: "string"
                    },

                    {
                        id: "active_label",
                        header: [self.$lang('active'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        adjust: false,
                        sort: "string",
                        css: {'text-align': 'right'},
                        template: function (obj) {
                            return obj.active_label
                        }
                    },
                    {
                        id: "creator_name",
                        header: [self.$lang('creator'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 100}}],
                        adjust: true,
                        sort: "string"
                    },
                    {
                        id: "created_at",
                        map: "(date)#created_at#",
                        header: [self.$lang('created'), {content: "dateRangeFilter"}],
                        adjust: true,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }
                    }
                ],
                on: {
                    onSelectChange: function(){
                        self.fetchKanbanCircuit(this.getSelectedId(true))
                    },
                    onItemDblClick: function (row) {
                        self.fetchKanbanCircuit(row.row).then(function () {
                            self.setKanbanCircuitDialog(true)
                        })
                    },
                    onAfterLoad: function () {
                        if (self.kanbanCircuit && this.exists(self.kanbanCircuit.id)) {
                            this.select(self.kanbanCircuit.id)
                            this.showItem(self.kanbanCircuit.id)
                        }
                    },
                },
            })
        },

        watch: {
            kanbanCircuits: function () {
                $$(this.webixId).clearAll()
                $$(this.webixId).parse(webix.copy(this.kanbanCircuits));
            }
        },

        destroyed:function(){
            webix.$$(this.webixId).destructor();
        }
    }

</script>

<style >
    .webix_multilist .wxi-checkbox-blank, .webix_multilist .wxi-checkbox-marked {
        font-size: 24px;
        width: 24px;
        vertical-align: middle;
        margin-right: 4px;
        color: #1565C0 ;
    }
</style>
