export const issues = (state) => {
    return state.issues
}

export const issue = (state) => {
    return state.issue
}

export const showCreateIssueDialog = (state) => {
    return state.showCreateIssueDialog
}

export const showIssueDialog = (state) => {
    return state.showIssueDialog
}

export const createIssueEndpoint = (state) => {
    return state.createIssueEndpoint
}

export const issuesFilter = (state)   => {
    return state.issuesFilter
}

export const issueDateLimits= (state)   => {
    return state.issueDateLimits
}

export const issueDialogWidth= (state)   => {
    return state.issueDialogWidth
}

export const selectedTab = (state) => {
    return state.selectedTab
}
