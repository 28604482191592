<template>
    <div v-if="projectType">
        <v-toolbar flat color="white" dense >
            <v-btn
                small
                outlined
                color="primary"
                to="/masterData/projectTypes"
                class="mr-2"

            >
                <v-icon class="mr-1">fas fa-list</v-icon>
            </v-btn>
            <v-toolbar-title >{{ projectType.name }}</v-toolbar-title>
        </v-toolbar>

        <ProjectTypeTabs ></ProjectTypeTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ProjectTypeTabs from "../components/ProjectTypeTabs";

    export default {
        name: "Project",
        components: {
            ProjectTypeTabs
        },
        props: [
            'projectTypeId'
        ],
        computed: {
            ...mapGetters({
                projectType: 'projectTypes/projectType',
            }),
        },
        created() {
            this.fetchProjectType(this.projectTypeId)
        },

        methods: {
            ...mapActions({
                fetchProjectType: 'projectTypes/fetchProjectType',
            }),
        }
    }
</script>

<style scoped>

</style>
