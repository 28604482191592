<template>
    <div
        class="text-center ml-2 mr-2"
    >
        <v-menu offset-y
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    dark
                    v-on="on"
                    small
                >
                    {{user.company.name}}
                </v-btn>
            </template>

            <v-list>
                <v-list-item
                    v-for="(item, index) in user.companies"
                    :key="index"
                    @click="changeCompany(item.id)"
                >
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "SelectCompanyDropDown",
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/user',
            }),

        },
        methods: {
            ...mapActions({
                setCompany: 'auth/setCompany',
            }),
            changeCompany(id) {
                this.setCompany(id)
            }
        }
    }
</script>

<style scoped>

</style>
