<template>
    <div>
        <v-card-text>
            <v-row>
                <v-col
                    md="6"
                    cols="12"
                >
                    <v-form
                        ref="form"
                    >
                        <div
                            class="mb-2">
                            <strong>{{$lang('example')}}: {{exampleNumber}}</strong>
                        </div>

                        <v-select
                            v-model="number_format.group_size"
                            :items="[1,2,3,4,5]"
                            :label="$lang('group size')"
                            :error-messages="errors['number_format.group_size']"
                        ></v-select>
                        <v-select
                            v-model="number_format.group_delimiter"
                            :items="delimiters"
                            :label="$lang('group separator')"
                            item-text="text"
                            item-value="value"
                            :error-messages="errors['number_format.group_delimiter']"
                        ></v-select>
                        <v-select
                            v-model="number_format['decimal_delimiter']"
                            :items="delimiters"
                            :label="$lang('decimal separator')"
                            item-text="text"
                            item-value="value"
                            :error-messages="errors['number_format.decimal_delimiter']"
                        ></v-select>
                    </v-form>

                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col
                    md="6"
                    cols="12"
                >
                    <v-btn
                        color="primary"
                        text
                        @click="submit"
                    >
                        {{ $lang('save') }}
                    </v-btn>
                </v-col>
            </v-row>

        </v-card-text>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";

export default {
name: "NumberFormat",
    data () {
        return {
            loading: false,

            number_format: {
                group_size: 3,
                group_delimiter: "'",
                decimal_delimiter: "."
            },
            errors: [],
            delimiters: [
                {value: "'", text: "«   '    » " + this.$lang('apostrophe')},
                {value: ".", text: "«   .    » " + this.$lang('point')},
                {value: ",", text: "«   ,    » " + this.$lang('comma')},
                {value: "&nbsp;", text: "«        » " + this.$lang('space')},
            ]
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),

        exampleNumber: function () {
            const self = this
            return webix.Number.format(1234567.89, {
                groupSize: self.number_format.group_size,
                groupDelimiter: _.replace(self.number_format.group_delimiter, '&nbsp;', ' '),
                decimalSize : 2,
                decimalDelimiter : _.replace(self.number_format.decimal_delimiter, '&nbsp;', ' ')
            });
        }

    },

    mounted() {
        this.language = this.user.lang
        if(this.user.number_format) {
            this.number_format = this.user.number_format
        }

    },

    methods: {
        ...mapActions({
            updateNumberFormat: 'account/updateNumberFormat',
        }),

        submit () {

            this.loading = true
            this.errors = []
            this.updateNumberFormat({
                payload: {
                    language: this.language,
                    number_format: this.number_format
                },
                context: this,
            }).then(() => {
                this.loading = false
                if(this.errors.length === 0) {
                    Bus.$emit('showAlert', {color: 'success', 'message': this.$lang('number format successfully changed')});
                    location.reload()
                }
            })
        },
    }
}
</script>

<style >

</style>
