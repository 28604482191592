<template>
    <div >
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>

</template>

<script>
import Bus from "../../../bus";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "UserDashboardTable",

    data() {
        return {
            loading: true,
            enableSelect: false,
            list: '',
            datatableExists: true,
            defaultState: null,
            filter: null,
            selectedItemId: null
        }
    },

    props: [
        'tableId'
    ],

    computed: {
        ...mapGetters({
            user: 'auth/user',
            project: 'projects/project',
            issue: 'issues/issue',
            todo: 'todos/todo',
            treeFilter: 'projects/treeFilter',
            todos: 'todos/todos',
            activeFilters: 'tableFilters/activeFilters',
            userId:'lists/userId'
        })
    },

    methods: {
        ...mapActions({
            fetchProject: 'projects/fetchProject',
            fetchIssue: 'issues/fetchIssue',
            fetchPotential: 'potentials/fetchPotential',
            setIssue: 'issues/setIssue',
            setTodos: 'todos/setTodos',
            setTodo: 'todos/setTodo',
            fetchTodo: 'todos/fetchTodo',
            actionShowIssueDialog: 'issues/setIssueDialog',
            actionShowTodoDialog: 'todos/setTodoDialog',
            actionShowPotentialDialog: 'potentials/setPotentialDialog',
            setIssuesFilter: 'issues/setIssuesFilter',
            setTask: 'projects/setTask',
            setShowTaskContextMenu: 'projects/setShowTaskContextMenu',
            setActiveFilter: 'tableFilters/setActiveFilter'
        }),

        loadDatatable() {
            const self = this
            if(this.datatableExists) {
                let endpoint = `/api/userDashboard/${this.user.id}`
                if(this.userId) {
                   endpoint = `/api/userDashboard/${this.userId}`
                }
                axios.get(endpoint).then(function (response){
                    self.webixId.parse(webix.copy(response.data))
                })
            }
        }
    },

    mounted() {

        const self = this

        this.datatableExists = true

        this.webixId = webix.ui({
            container: this.$el,
            $scope: this,
            view:"datatable",
            height: window.innerHeight - 200,
            id: self.tableId,
            headermenu: true,
            resizeColumn: true,
            select: "row",
            dragColumn:true,
            headerRowHeight:40,
            footer:false,
            columns: [
                {
                    id: "number",
                    header: [self.$lang('no.'), {content: "textFilter"}],
                    sort: "int",
                },
                {
                    id: "data_type_label",
                    header: [self.$lang('type'), {content: "textFilter"}],
                    sort: "string",
                },
                {
                    id: "data_type_text",
                    header: [self.$lang('type'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 180}}],
                    sort: "string",
                },
                {
                    id: "title",
                    header: [self.$lang('title'), {content: "textFilter"}],
                    footer: self.$lang('total'),
                    fillspace: true,
                    minWidth: 200,
                    sort: "string",
                    adjust: false,
                    template: "#title_link#"
                },
                {
                    id: "parent_type_text",
                    header: [self.$lang('parent type'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 180}}],
                    sort: "string",
                    adjust: false,
                },
                {
                    id: "parent_link",
                    header: [self.$lang('parent title'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string",
                    adjust: false,
                    template: "#parent_link#"
                },
                {
                    id: "assignee_label",
                    header: [self.$lang('responsible'), {
                        content: "multiSelectFilter",
                        suggest: {fitMaster: false, width: 200}
                    }],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        if(obj.assignee_label) {
                            return obj.assignee_label
                        }
                        return ''
                    }
                },
                {
                    id: "total_potential",
                    header: [self.$lang('potential')   + " " + self.user.company.currency, {content: "textFilter"}],
                    footer: {content: "summColumn"},
                    adjust: false,
                    sort: "int",
                    css:{'text-align':'right'},
                    template: function (obj) {
                        return self.$num(obj.total_potential, 0)
                    }
                },
                {
                    id: "total_invest",
                    header: [self.$lang('invest')   + " " + self.user.company.currency, {content: "textFilter"}],
                    footer: {content: "summColumn"},
                    adjust: false,
                    sort: "int",
                    css:{'text-align':'right'},
                    template: function (obj) {
                        return self.$num(obj.total_invest, 0)
                    }
                },
                {
                    id: "lost_potential",
                    header: [self.$lang('lost potential')   + " " + self.user.company.currency, {content: "textFilter"}],
                    footer: {content: "summColumn"},
                    adjust: false,
                    sort: "int",
                    css:{'text-align':'right'},
                    template: function (obj) {
                        return self.$num(obj.lost_potential, 0)
                    }
                },
                {
                    id: "hardness_degree_label",
                    header: [self.$lang('HG'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 100}}],
                    minWidth: 50,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        if(obj.hardness_degree_label) {
                            return obj.hardness_degree_label
                        }
                        return ''
                    }
                },
                {
                    id: "progress",
                    header: [self.$lang('progress'), {content: "textFilter"}],
                    sort: "string",
                    adjust: false,
                    css: {'text-align': 'center'},
                    template: function (obj) {
                        if(obj.progress_bar) {
                            return obj.progress_bar
                        }
                        return ''
                    }
                },
                {
                    id: "due_date_status_label",
                    header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 180}}],
                    minWidth: 50,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        if(obj.due_date_status_label) {
                            return obj.due_date_status_label
                        }
                        return ''
                    }
                },
                {
                    id: "start_date",
                    map: "(date)#start_date#",
                    header: [self.$lang('start'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        if(obj.start_date) {
                            return webix.i18n.parseFormatStr(obj.start_date)
                        }
                        return ''
                    }
                },
                {
                    id: "end_date",
                    map: "(date)#end_date#",
                    header: [self.$lang('end'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.end_date)
                    }
                },
                {
                    id: "creator_name",
                    header: [self.$lang('creator'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 100}}],
                    adjust: false,
                    sort: "string"
                },
                {
                    id: "created_at",
                    map: "(date)#created_at#",
                    header: [self.$lang('created'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.created_at)
                    }
                }
            ],
            onClick:{
                "title_link" : function  (event, row, target) {
                    let record = this.getItem(row.row)
                    switch (record.data_type) {
                        case 'issue':
                            self.fetchIssue(row.row).then(function () {
                                self.fetchProject(self.issue.issueable_id).then(function (){
                                    self.actionShowIssueDialog(true)
                                })
                            })
                            break
                        case 'todo':
                            self.fetchTodo(row.row).then(function () {
                                self.fetchIssue(self.todo.todoable_id).then(function () {
                                    self.fetchProject(self.issue.issueable_id).then(function (){
                                        self.actionShowTodoDialog(true)
                                    })
                                })
                            })
                            break
                        case 'potential':
                            self.fetchPotential(row.row).then(function () {
                                self.actionShowPotentialDialog(true)
                            })
                    }
                },
                "project" : function  (event, row, target) {
                    let item = this.getItem(row.row)
                    self.$router.push({name: 'projects.show', params: {projectId: item.parent_id}})
                },
                "issue" : function  (event, row, target) {
                    let item = this.getItem(row.row)
                    self.fetchIssue(item.parent_id).then(function () {
                        self.fetchProject(self.issue.issueable_id).then(function (){
                            self.actionShowIssueDialog(true)
                        })
                    })
                },
                "todo" : function  (event, row, target) {
                    let item = this.getItem(row.row)
                    self.fetchTodo(item.parent_id).then(function () {
                        self.fetchIssue(self.todo.todoable_id).then(function () {
                            self.fetchProject(self.issue.issueable_id).then(function (){
                                self.actionShowTodoDialog(true)
                            })
                        })
                    })
                }
            },
            on: {
                onAfterSelect: function(selection){
                     self.selectedItemId = selection.id
                },
                onBeforeLoad: function (){
                    self.loading = true
                },

                onAfterLoad: function () {
                    self.loading = false
                    if(!self.defaultState) {
                        self.defaultState = this.getState()
                    }

                    if (self.tableId in self.activeFilters) {
                        this.setState(JSON.parse(self.activeFilters[self.tableId].filter_data))
                    }

                    if (self.selectedItemId && this.exists(self.selectedItemId)) {
                        this.select(self.selectedItemId)
                        this.showItem(self.selectedItemId)
                    }
                },
            },
        })

        self.loadDatatable()

        Bus.$on('project:updated', function () {
            self.loadDatatable()
        });

        Bus.$on('gantt:updated', function () {
            self.loadDatatable()
        });

        Bus.$on('issue:updated', function () {
            self.loadDatatable()
        });

        Bus.$on('issue:created', function () {
            self.loadDatatable()
        });

        Bus.$on('issue:deleted', function (id) {
            self.loadDatatable()
        });

        Bus.$on('todo:updated', function () {
            self.loadDatatable()
        });

        Bus.$on('todo:created', function () {
            self.loadDatatable()

        });

        Bus.$on('filterTable:' + self.tableId, function (data) {
            self.filter = data
            if(self.datatableExists) {
                if(!self.defaultState) {
                    self.defaultState = $$(self.webixId).getState()
                }
                $$(self.webixId).setState(JSON.parse(data))
            }
        });

        Bus.$on('resetFilterTable:' + self.tableId, function () {
            if(self.datatableExists) {
                $$(self.webixId).setState(self.defaultState)
            }

        });

        self.loadDatatable()
    },

    watch: {
        userId: {
            handler: function(value) {
                this.$nextTick(() => {
                    if (value && this.datatableExists) {
                        this.webixId.clearAll()
                        this.loadDatatable()
                    }
                })
            },
            immediate: true
        },
    },

    destroyed:function(){
        this.datatableExists = false
        webix.$$(this.webixId).destructor();
    }
}
</script>
