<template>
    <div>
        <v-card v-if="user.has_relations">
            <v-card-title >
                Benutzer<span class="blue--text text--darken-3">&nbsp;{{user.full_name}}&nbsp;</span> kann nicht gelöscht werden
            </v-card-title>
            <v-card-text>
                Der Benutzer <span class="blue--text text--darken-3">{{user.full_name}} </span> hat Verbindungen zu anderen Objekten und kann deshalb nicht gelöscht werden.
            </v-card-text>
        </v-card>
        <v-card v-else-if="user.id === me.id">
            <v-card-title >
                Sie können sich nicht selber löschen!
            </v-card-title>

        </v-card>
        <v-card v-else>
            <v-col style="height: 300px" class="ml-2 mr-2">
                <v-row>
                    <strong>
                        Wollen Sie den Benutzer <span class="blue--text text--darken-3">{{user.full_name}}</span> wirklich löschen?
                    </strong>
                </v-row>

                <v-row>
                    <v-btn
                        color="error"
                        @click="showConfirmDeleteUser = true"
                    >
                        {{ $lang('delete') }}
                    </v-btn>
                </v-row>

                <v-row>
                    <v-btn
                        v-if="showConfirmDeleteUser"
                        color="error"
                        class="mt-10"
                        @click="deleteUser"
                    >
                        <span class="blue--text text--darken-3 mr-1">{{user.full_name}} </span> unwiederruflich löschen!
                    </v-btn>
                </v-row>
            </v-col>

        </v-card>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "DeleteUser",
        data() {
            return {
                showConfirmDeleteUser: false,
            }
        },
        computed: {
            ...mapGetters({
                me: 'auth/user',
                user: 'adminUsers/user',
            }),
        },

        methods: {
            ...mapActions({
                actionDeleteUser: 'adminUsers/deleteUser',
            }),

            deleteUser() {
                const self = this
                this.showConfirmDeleteUser = false
                this.actionDeleteUser(this.user.id).then(function (){
                    Bus.$emit('showAlert', {color : 'success', 'message' : `Benutzer erfolgreich gelöscht!`})
                    self.$router.push({ name: 'admin.users'})
                })
           }

        }
    }
</script>

<style scoped>

</style>
