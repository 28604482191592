<template>
    <div>
        <v-card>
            <v-card-title>
                {{$lang('lead time changes')}}
            </v-card-title>

            <v-card-text>

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                {{$lang('date')}}
                            </th>
                            <th class="text-left">
                                {{$lang('user')}}
                            </th>
                            <th class="text-left">
                                {{$lang('previous start date')}}
                            </th>
                            <th class="text-left">
                                {{$lang('previous end date')}}
                            </th>
                            <th class="text-left">
                                {{$lang('new start date')}}
                            </th>
                            <th class="text-left">
                                {{$lang('new end date')}}
                            </th>

                            <th class="text-left">
                                {{$lang('reason')}}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="leadTimeChange in leadTimeChanges"
                            :key="leadTimeChange.id"
                        >
                            <td>{{ leadTimeChange.created_at }}</td>
                            <td>{{ leadTimeChange.user_name }}</td>
                            <td>{{ leadTimeChange.previous_start_date }}</td>
                            <td>{{ leadTimeChange.previous_end_date }}</td>
                            <td>{{ leadTimeChange.new_start_date }}</td>
                            <td>{{ leadTimeChange.new_end_date }}</td>
                            <td>{{ leadTimeChange.reason }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "LeadTimeChanges",

    props: {
        leadTimeChanges: {
            type: [Object, Array],
            required: true
        }
    }
}
</script>

<style scoped>

</style>
