export const SET_PRODUCTS_DATA = (state, data) => {
    state.products = data
}

export const SET_PRODUCT_DATA = (state, data) => {
    state.product = data
}

export const SET_SELECTED_PRODUCT = (state, data) => {
    state.selectedProduct = data
}
