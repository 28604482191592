import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {}

import "vue-wysiwyg/dist/vueWysiwyg.css";

import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify)


export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    lang: {
        locales: { de, en },
        current: 'en',
    }
})
