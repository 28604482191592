<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ $lang('project types') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="showCreateProjectTypeDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('project type') }}
            </v-btn>
        </v-toolbar>

        <ProjectTypesTable></ProjectTypesTable>
        <CreateProjectTypeDialog></CreateProjectTypeDialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ProjectTypesTable from "../components/ProjectTypesTable";
    import CreateProjectTypeDialog from "../components/CreateProjectTypeDialog";
    export default {
        name: "ProjectTypes",
        components: {
            ProjectTypesTable,
            CreateProjectTypeDialog
        },

        methods: {
            ...mapActions({
                showCreateProjectTypeDialog: 'projectTypes/setCreateProjectTypeDialog',
            }),
        },
    }
</script>

<style scoped>

</style>
