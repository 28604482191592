export const fetchProjects = ({ commit }) => {

    return axios.get('/api/projects').then((response) => {
        commit('SET_PROJECTS_DATA', response.data)
        let issues = _.map(response.data, 'issues')
        commit('issues/SET_ISSUES_DATA', _.flatten(issues), {root: true})
    })
}

export const fetchProject = ({ commit }, id) => {
    return axios.get(`/api/projects/${id}`).then((response) => {
         commit('SET_PROJECT_DATA', response.data)
         commit('UPDATE_PROJECTS_DATA', response.data)
        commit('issues/SET_ISSUES_DATA', response.data.issues, {root: true})
    })
}

export const setProject = ({ state, commit }, id) => {
    let project = state.projects.find(project => project.id === id)
    commit('SET_PROJECT_DATA', project)
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/projects', payload).then((response) => {
        commit('SET_PROJECT_DATA', response.data)
        commit('ADD_PROJECT', response.data)
    }).catch(function (error) {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, context, index }) => {
    return axios.patch(`/api/projects/${payload.id}`, payload).then((response) => {
        commit('UPDATE_PROJECTS_DATA', response.data)
        commit('SET_PROJECT_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const updatePermissions = ({ dispatch, state, commit }, { id, payload}) => {
    return axios.post(`/api/projects/${id}/permissions`, payload).then((response) => {
        commit('SET_PROJECT_DATA', response.data)
        commit('UPDATE_PROJECTS_DATA', response.data)
    }).catch((error) => {
        //context.errors = error.response.data.errors
    })
}

export const updateSolexSettings = ({ dispatch, state, commit }, { id, payload}) => {
    return axios.post(`/api/projects/${id}/solexSettings`, payload).then((response) => {
        commit('SET_PROJECT_DATA', response.data)
        commit('UPDATE_PROJECTS_DATA', response.data)
    }).catch((error) => {
        //context.errors = error.response.data.errors
    })
}

export const updateLeadTimeSettings = ({ dispatch, state, commit }, { id, payload}) => {
    return axios.post(`/api/projects/${id}/leadTimeSettings`, payload).then((response) => {
        commit('SET_PROJECT_DATA', response.data)
        commit('UPDATE_PROJECTS_DATA', response.data)
    })
}

export const updateFunctions= ({ dispatch, state, commit }, { id, payload}) => {
    return axios.patch(`/api/projects/${id}/updateFunctions`, payload).then((response) => {
        commit('SET_PROJECT_DATA', response.data)
        commit('UPDATE_PROJECTS_DATA', response.data)
    }).catch((error) => {
        //context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, { project, payload }) => {
    return axios.delete(`/api/projects/${project.id}`, payload).then((response) => {
        commit('REMOVE_PROJECT', project)
    }).catch((error) => {
        //context.errors = error.response.data.errors
    })
}

export const setCopyProject = ({ dispatch, state, commit }, value) => {
    commit('SET_COPY_PROJECT', value)
}

export const setCreateProjectDialog = ({ dispatch, state, commit }, value) => {
    commit('setCreateProjectDialog', value)
}

export const setSelectedTab = ({ dispatch, state, commit}, value) => {
    commit('SET_SELECTED_TAB', value)
}

export const toggleTreeFilterTodos = ({ state, commit}) => {
    commit('SET_TREE_FILTER_TODOS', !state.treeFilter.todos)
}
export const toggleTreeFilterPotentials = ({ state, commit}) => {
    commit('SET_TREE_FILTER_POTENTIALS', !state.treeFilter.potentials)
}

export const toggleTreeFilterExpandAll = ({ state, commit}) => {
    commit('SET_TREE_FILTER_EXPAND_ALL', !state.treeFilter.expandAll)
}



export const fetchTasks = ({ commit }, endpoint) => {
    return axios.get(endpoint).then((response) => {
        commit('SET_TASKS_DATA', response.data)
    })
}

export const setGanttOpenAll = ({ state, commit}) => {
    commit('SET_GANTT_OPEN_ALL', !state.gantt.openAll)
}

export const setGanttMoveProject = ({ state, commit}) => {
    commit('SET_GANTT_MOVE_PROJECT', !state.gantt.moveProject)
}

export const setGanttScale = ({ state, commit}, value) => {
    commit('SET_GANTT_SCALE', value)
}

export const setGanttRange = ({ state, commit}, value) => {
    commit('SET_GANTT_RANGE', value)
}

export const setGanttSearch= ({ state, commit}, value) => {
    commit('SET_GANTT_SEARCH', value)
}

export const setGanttShowPotentials= ({ state, commit}) => {
    commit('SET_GANTT_SHOW_POTENTIALS', !state.gantt.showPotentials)
}

export const resetGantt = ({ state, commit}, value) => {
    commit('RESET_GANTT', value)
}

export const setChangedTasks = ({ state, commit}, value) => {
    commit('SET_CHANGED_TASKS', value)
}

export const setTask = ({ state, commit}, value) => {
    commit('SET_TASK_DATA', value)
}


export const setShowTaskContextMenu = ({ state, commit}, event) => {
    commit('SET_SHOW_TASK_CONTEXT_MENU', !state.gantt.showTaskContextMenu)
    commit('SET_EVENT', event)
}

export const updateGanttTasks = ({ dispatch, state, commit }, { project, payload}) => {
    return axios.patch(`/api/projects/${project.id}/gantt/updateTasks`, payload).then((response) => {
        dispatch('projects/fetchProject', project.id, {root: true})
    }).catch((error) => {

    })
}

export const fetchProjectDateLimits = ({ commit }, endpoint) => {
    return axios.get(`${endpoint}/dateLimits`).then((response) => {
        commit('SET_PROJECT_DATE_LIMITS', response.data)
    })
}
