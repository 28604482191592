<template>
    <div
        @mouseover="mouseover = true"
        @mouseout="mouseover = false"
        @drop.prevent="drop($event)"
    >
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>

        <vue-editor id="editor" ref="editor" v-model="content" :use-custom-image-handler="true" @image-added ="added" @image-removed ="deleted" :key="componentKey" />

    </div>
</template>

<script>
import Bus from "../bus";
export default {
    name: "Editor",
    data() {
        return {
            loading: false,
            componentKey: 0,
            tokens: [],
            mouseover: false
        }
    },
    props: [
        'body'
    ],

    computed: {
        content: {
            get(){
                return this.body;
            },
            set(value){
                this.$emit('update:body', value)
            }
        },
    },

    methods: {

        drop(event) {
            const self = this
            this.loading = true

            var file = event.dataTransfer.files[0]

            if (file !== null && (file.type ==='image/jpeg' || file.type ==='image/png') ) {
                self.getBase64(file);
                Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('image successfully added')});
            } else {
                Bus.$emit('showAlert', {color : 'error', 'message' : this.$lang('only images allowed')});
                self.loading = false
            }
        },


        added: function (file, Editor, cursorLocation, resetUploader) {
            const self = this
            this.loading =true

            let formData = new FormData();
            formData.append("file", file);


            axios({
                url: '/api/media/temporaryUploadMedia',
                method: "POST",
                data: formData
            })
                .then(result => {
                    this.tokens.push(result.data.token)
                    let url = result.data.data[0].url; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                    this.loading = false
                    Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('image successfully added')});
                })
                .catch(err => {
                    Bus.$emit('showAlert', {color : 'error', 'message' : this.$lang('upload error')});
                });

        },

        deleted: function (url) {
            //todo: delete Image

        },
        getBase64: function(file) {
            const self = this
            var reader = new FileReader();

            reader.readAsDataURL(file);


            reader.onload = function () {

                axios.post('/api/media/temporaryUploadBase64Media',{
                    file : reader.result
                }).then(function (response) {
                    self.content += `<img src=\"${response.data.data[0].url}\">`
                    self.tokens.push(response.data.token)
                    self.componentKey += 1;
                    self.loading = false
                    Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('image successfully added')});
                })
            };
        }
    },
    watch: {
        tokens: function (value) {
            this.$emit('update:tokens', value)
        }
    },

    mounted() {
        const self = this
        window.addEventListener("drag", function (thePasteEvent) {

        }, false);

        window.addEventListener("paste", function (thePasteEvent) {
            if(self.mouseover) {
                self.loading = true

                var file = thePasteEvent.clipboardData.items[0].getAsFile();
                if (file !== null) {
                    thePasteEvent.preventDefault()
                    self.getBase64(file);
                }
            }
            self.loading = false
        }, false);

    }
}
</script>

<style >
.p {
    color: black
}
</style>
