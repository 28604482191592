 <template>
    <v-tabs
        v-model="selectedTab"
    >
        <template>
            <v-tab href="#info" active-class="">{{ $lang('info')}}</v-tab>
            <v-tab href="#gantt">{{ $lang('gantt')}}</v-tab>
            <v-tab href="#treeTable">{{ $lang('structure')}}</v-tab>
            <v-tab href="#issues">{{ $lang('issues') }}</v-tab>
            <v-tab href="#solex"
                v-if="project.solex_settings.allow_solex && $companyPlan('solex')"
            >{{ $lang('solex') }}</v-tab>
            <v-tab href="#files">{{ $lang('files')}}</v-tab>
            <v-tab href="#edit" v-if="_.includes(project.user_permissions, 'admin')">{{ $lang('edit')}}        </v-tab>
            <v-tab href="#leadTimeChanges" v-if="project.lead_time_settings.track_project">{{ $lang('lead time changes')}}</v-tab>
            <v-tab href="#settings" v-if="_.includes(project.user_permissions, 'admin')">{{ $lang('settings')}}</v-tab>
        </template>
        <v-spacer>  </v-spacer>
        <template
            v-if="tab === 'gantt'"
        >
            <v-switch
                class="mt-2 mr-2"
                v-model="showPotentials"
                label="$"
            ></v-switch>

            <v-text-field
                dense
                filled
                :placeholder="$lang('search')"
                v-model="search"
                clearable
            >
            </v-text-field>
        </template>

        <v-tab-item value="gantt">
            <Gantt v-if="project.id" :endpoint="`/api/projects/${project.id}/gantt`"></Gantt>
        </v-tab-item>

        <v-tab-item value="treeTable" >
            <ProjectsTreeTable key="treeTable" table-id="projectsTreeTable" :endpoint="`/api/projects/${project.id}/treeTables`" v-if="project.id"></ProjectsTreeTable>
        </v-tab-item>

        <v-tab-item value="issues" >
            <IssuesTable table-id="projectIssues" :drag-rows="true" :endpoint="`/api/projects/${project.id}/issues`" v-if="project.id"></IssuesTable>
        </v-tab-item>


        <v-tab-item value="solex">
            <SolexesTable table-id="solexesTable" :endpoint="`/api/projects/${project.id}/solexes`" v-if="project.id"></SolexesTable>
        </v-tab-item>

        <v-tab-item value="files">
            <ProjectFiles></ProjectFiles>
        </v-tab-item>

        <v-tab-item value="info">
            <ProjectInfo></ProjectInfo>
        </v-tab-item>

        <v-tab-item value="edit">
            <EditProject></EditProject>
        </v-tab-item>

        <v-tab-item value="leadTimeChanges">
            <LeadTimeChanges :lead-time-changes="project.lead_time_changes"></LeadTimeChanges>
        </v-tab-item>

        <v-tab-item value="settings">
            <ProjectSettings></ProjectSettings>
        </v-tab-item>

    </v-tabs>
</template>

<script>


    import IssuesTable from "../../issues/components/IssuesTable";
    import {mapGetters, mapActions} from "vuex";
    import EditProject from "./EditProject";
    import ProjectSettings from "./ProjectSettings";
    import ProjectInfo from "./ProjectInfo";
    import ProjectsTreeTable from "./ProjectsTreeTable";
    import ProjectFiles from "./ProjectFiles";
    import Gantt from "./Gantt";
    import SolexesTable from "../../issues/solexes/components/SolexTable";
    import LeadTimeChanges from "../../../components/LeadTimeChanges";
    export default {
        name: "ProjectTabs",
        data() {
            return {

            }
        },
        props: [

        ],
        components: {
            LeadTimeChanges,
            SolexesTable,
            Gantt,
            ProjectFiles,
            ProjectsTreeTable,
            ProjectInfo,
            ProjectSettings,
            EditProject,
            IssuesTable

        },
        computed: {
            ...mapGetters({
                project: 'projects/project',
                tab: 'projects/selectedTab',
                ganttSearch: 'projects/ganttSearch',
                ganttShowPotentials: 'projects/ganttShowPotentials'
            }),
            selectedTab: {
                get() {
                    return this.tab
                },
                set(value) {
                    this.setSelectedTab(value)
                }
            },
            search: {
                get() {
                    return this.ganttSearch
                },
                set(value) {
                    this.setGanttSearch(value)
                }
            },

            showPotentials: {
                get() {
                    return this.ganttShowPotentials
                },
                set() {
                    this.setShowPotentials()
                }
            }
        },
        methods: {
            ...mapActions({
                setSelectedTab: 'projects/setSelectedTab',
                setGanttSearch: 'projects/setGanttSearch',
                setShowPotentials: 'projects/setGanttShowPotentials'
            })
        },

        watch: {
            selectedTab: {
                handler: function (value) {
                    this.setSelectedTab(value)
                },
                immediate: true
            }
        }

    }
</script>

<style scoped>

</style>
