<template>
    <v-card>
        <v-alert
            v-if="alert"
            dense
            text
            type="success"
        >
            {{ $lang('roles successfully updated')}}
        </v-alert>
        <v-card-text>
            <v-switch
                v-for="role in roles"
                v-model="role_ids"
                :value="role.id"
                :label="role.name"
                :disabled="me.id === user.id && role.name === 'Company Admin'"
            ></v-switch>
        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
name: "UserRoles",
    data () {
        return {
            alert: false,
            role_ids: []
        }
    },

    computed: {
        ...mapGetters({
            user: 'companyAdminUsers/user',
            roles: 'companyAdminRoles/roles',
            me: 'auth/user'
        }),
    },

    methods: {
        ...mapActions({
            fetchRoles: 'companyAdminRoles/fetchRoles',
            updateRoles: 'companyAdminUsers/updateRoles'
        }),

        save () {
            this.errors = []
            this.updateRoles({
                payload: { roleIds : this.role_ids},
                id: this.user.id,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.showAlert()
                }
            })
        },

        showAlert() {
            this.alert = true
            setTimeout(this.hideAlert, 3000)
        },

        hideAlert() {
            this.alert = false
        }
    },

    mounted() {
        this.role_ids = this.user.role_ids
        this.fetchRoles()
    }
}
</script>

<style scoped>

</style>
