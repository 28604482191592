<template>
    <v-card>
            <v-card-text>
                <v-form
                    ref="form"
                >
                    <v-text-field
                        :label="$lang('title')"
                        v-model="form.title"
                        required
                        autofocus
                        :error-messages="errors.title"
                    >
                    </v-text-field>

                    <Editor  :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event"></Editor>

                    <v-row
                        class="pb-0"
                    >
                        <v-col
                            cols="12"
                            md="4"
                            class="pb-0"
                        >

                            <v-select
                                v-model="form.assignee_id"
                                :items="permittedAssigneeUsers"
                                :item-disabled="disabledAssigneeUsers"
                                :label="$lang('responsible')"
                                append-outer-icon="fas fa-times"
                                @click:append-outer="form.assignee_id = null"
                                item-text="full_name"
                                item-value="id"
                                :error-messages="errors.assignee_id"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            md="4"
                            class="pb-0"
                        >
                            <v-select
                                v-model="form.hardness_degree"
                                :items="hardnessDegrees"
                                :item-disabled="disableHardnessDegreeItem"
                                :label="$lang('hardness degree')"
                                append-outer-icon="fas fa-times"
                                @click:append-outer="form.hardness_degree = null"
                                item-text="name"
                                item-value="value"
                                :error-messages="errors.hardness_degree"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            md="4"
                            class="pb-0"
                        >
                            <v-select
                                v-model="form.priority"
                                :items="priorities"
                                :label="$lang('prio')"
                                append-outer-icon="fas fa-times"
                                @click:append-outer="form.priority = null"
                                item-text="name"
                                item-value="slug"
                                :error-messages="errors.priority"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pb-0"
                    >
                        <v-col
                            cols="12"
                            md="12"
                            class="pb-0 pt-0"
                        >
                            <v-select
                                v-model="form.user_ids"
                                :items="permittedUsers"
                                :label="$lang('team')"
                                append-outer-icon="fas fa-times"
                                @click:append-outer="form.user_ids = []"
                                item-text="full_name"
                                item-value="id"
                                multiple
                                :error-messages="errors.user_ids"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="4"
                            class="pb-0 pt-0"
                        >
                            <v-select
                                v-model="form.workplace_id"
                                :items="activeWorkplaces"
                                :label="$lang('workplace')"
                                append-outer-icon="fas fa-times"
                                @click:append-outer="form.workplace_id = null"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors.workplace_id"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            md="4"
                            class="pb-0 pt-0"
                        >
                            <v-select
                                v-model="form.cost_center_id"
                                :items="activeCostCenters"
                                :label="$lang('cost center')"
                                append-outer-icon="fas fa-times"
                                @click:append-outer="form.cost_center_id = null"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors.cost_center_id"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            md="4"
                            class="pb-0 pt-0"
                        >
                            <v-select
                                v-model="form.product_id"
                                :items="activeProducts"
                                :label="$lang('product')"
                                append-outer-icon="fas fa-times"
                                @click:append-outer="form.product_id = null"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors.product_id"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pb-0"
                    >
                        <v-col
                            cols="12"
                            md="4"
                            class="pb-0 pt-0"
                        >
                            <v-dialog
                                ref="start_date_dialog"
                                v-model="start_date_modal"
                                :return-value.sync="picker_start_date"
                                persistent
                                width="290px"

                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.start_date"
                                        :label="$lang('start')"
                                        append-outer-icon="fas fa-times"
                                        @click:append-outer="picker_start_date = null"
                                        append-icon="fas fa-calendar-alt"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="errors.start_date"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-if="issueDateLimits"
                                    v-model="picker_start_date"
                                    scrollable
                                    @change="$refs.start_date_dialog.save(picker_start_date)"
                                    :min="issueDateLimits.min_start_date"
                                    :max="issueDateLimits.max_start_date"
                                    :first-day-of-week="1"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="picker_start_date = ''">{{ $lang('delete') }}</v-btn>
                                    <v-btn text color="primary" @click="start_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                    <v-btn text color="primary" @click="$refs.start_date_dialog.save(picker_start_date)">{{ $lang('ok') }}</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col
                            cols="12"
                            md="4"
                            class="pb-0 pt-0"
                        >
                            <v-dialog
                                ref="end_date_dialog"
                                v-model="end_date_modal"
                                :return-value.sync="picker_end_date"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.end_date"
                                        :label="$lang('end')"
                                        append-outer-icon="fas fa-times"
                                        @click:append-outer="picker_end_date = null"
                                        append-icon="fas fa-calendar-alt"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="errors.end_date"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-if="issueDateLimits"
                                    v-model="picker_end_date"
                                    scrollable
                                    @change="$refs.end_date_dialog.save(picker_end_date)"
                                    :min="issueDateLimits.min_end_date"
                                    :max="issueDateLimits.max_end_date"
                                    :first-day-of-week="1"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="picker_end_date = ''">{{ $lang('delete') }}</v-btn>
                                    <v-btn text color="primary" @click="end_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                    <v-btn text color="primary" @click="$refs.end_date_dialog.save(picker_end_date)">{{ $lang('ok') }}</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col
                            cols="12"
                            md="4"
                            class="pt-0 pt-0"
                        >
                            <v-select
                                v-model="form.issue_tag_ids"
                                :items="issueTags"
                                :label="$lang('issue tags')"
                                append-outer-icon="fas fa-times"
                                @click:append-outer="form.issue_tag_ids = null"
                                item-text="name"
                                item-value="id"
                                multiple
                                :error-messages="errors.issue_tag_ids"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="12"
                            class="pt-0 pt-0"
                        >
                            <v-text-field
                                v-if="form.lead_time_change"
                                v-model="form.reason"
                                :label="$lang('reason')"
                                :error-messages="errors.reason"
                            >

                            </v-text-field>
                        </v-col>

                    </v-row>
                </v-form>
            </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="submit">{{ $lang('save') }}</v-btn>
            <v-btn color="blue darken-1" text @click="submitAndClose">{{ $lang('save and close') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import Editor from "../../../components/Editor";
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "EditIssue",

        components: {Editor},

        data() {
            return {
                modal: false,
                closeAfterSubmit: false,
                previous_start_date: '',
                previous_end_date:'',
                picker_start_date:'',
                picker_end_date:'',
                start_date_modal: false,
                end_date_modal: false,
                errors: [],
                form: {
                    title: '',
                    body: '',
                    assignee_id: '',
                    start_date:'',
                    end_date:'',
                    tokens:[],
                    hardness_degree: 0,
                    issue_tag_ids: '',
                    workplace_id: '',
                    cost_center_id: '',
                    priority: 'low',
                    user_ids: [],
                    lead_time_change: false,
                    reason:''
                },
                priorities: [
                    {slug: 'low', name: this.$lang('low')},
                    {slug: 'mid', name: this.$lang('mid')},
                    {slug: 'high', name: this.$lang('high')},
                ],
                permittedAssigneeUsers: [],
                permittedUsers: []
            }
        },

        computed: {
            ...mapGetters({
                project: 'projects/project',
                issue: 'issues/issue',
                users: 'users/users',
                activeUsers: 'users/activeUsers',
                issueTags: 'issueTags/issueTags',
                issueDateLimits: 'issues/issueDateLimits',
                activeWorkplaces: 'workplaces/activeWorkplaces',
                activeCostCenters: 'costCenters/activeCostCenters',
                activeProducts: 'masterProducts/activeProducts',
                me: 'auth/user',
                hardnessDegrees: 'hardnessDegrees/hardnessDegrees'
            }),

        },

        created() {
            this.fetchIssueTags()
            this.fetchWorkplaces()
            this.fetchCostCenters()
            this.fetchProducts()
            this.permittedAssigneeUsers = [this.activeUsers.find(user => user.id === this.issue.assignee_id), ...this.activeUsers.filter(user => user.id !== this.issue.assignee_id && this.project.permitted_user_ids.includes(user.id))];
            this.permittedUsers =  [this.activeUsers.find(user =>  this.form.user_ids.includes(user.id)), ...this.activeUsers.filter(user => user.id !== this.issue.assignee_id && this.project.permitted_user_ids.includes(user.id))]
        },

        methods: {
            ...mapActions({
                update: 'issues/update',
                actionShowIssueDialog: 'issues/setIssueDialog',
                fetchIssueTags: 'issueTags/fetchIssueTags',
                fetchWorkplaces: 'workplaces/fetchWorkplaces',
                fetchCostCenters: 'costCenters/fetchCostCenters',
                fetchProducts: 'masterProducts/fetchProducts',
                fetchIssueDateLimits: 'issues/fetchIssueDateLimits'
            }),


            disableUserItem(user) {
                return !user.active
            },

            disableHardnessDegreeItem(hardnessDegreeItem) {
                if(hardnessDegreeItem.value === 2 || hardnessDegreeItem.value === 3) {
                    return this.me.id !== this.form.assignee_id
                }
                if(hardnessDegreeItem.value === 4 || hardnessDegreeItem.value === 5) {
                    return this.me.id !== this.issue.project_leader_id
                }
                return false
            },

            disabledAssigneeUsers (user)
            {
                return !this.project.permitted_user_ids.includes(user.id)
            },

            submit () {
                this.errors = []
                this.update({
                    id: this.issue.id,
                    payload: this.form,
                    context: this,
                }).then(() => {
                    if(this.errors.length === 0) {
                        this.form.tokens = []
                        Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('issue successfully changed') })
                        Bus.$emit('issue:updated');
                        if (this.closeAfterSubmit ) {
                            this.closeAfterSubmit = false
                            this.actionShowIssueDialog(false)
                        }
                    }
                })
            },

            submitAndClose() {
                this.closeAfterSubmit = true
                this.submit()
            },

            formatDate (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            }
        },

        mounted() {
            if(this.issue) {
                this.fetchIssueDateLimits(`/api/issues/${this.issue.id}`)
            }
        },

        watch: {
            issue: {
                immediate: true,
                handler() {
                    this.form = webix.copy(this.issue)
                    this.previous_start_date = this.issue.start_date
                    this.previous_end_date = this.issue.end_date
                }
            },

            'form.start_date': {
                handler: function() {
                    if(this.issue.lead_time_settings.track_issues) {
                        this.form.lead_time_change = this.previous_end_date !== this.form.end_date || this.previous_start_date !== this.form.start_date;
                    }
                }
            },

            'form.end_date': {
                handler: function() {
                    if(this.issue.lead_time_settings.track_issues) {
                        this.form.lead_time_change = this.previous_end_date !== this.form.end_date || this.previous_start_date !== this.form.start_date;
                    }
                }
            },

            picker_start_date (val) {
                this.form.start_date = this.formatDate(this.picker_start_date)
            },

            picker_end_date (val) {
                this.form.end_date = this.formatDate(this.picker_end_date)
            },
        },
    }
</script>

<style scoped>
    img {
        max-width: 90%;
        height: auto;
    }
</style>
