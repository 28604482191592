<template>
    <v-tabs
        v-model="tab"
    >
        <v-tab href="#info">Info</v-tab>
        <v-tab href="#edit"
               v-if="_.includes(potential.user_permissions, 'edit')"
        >{{ $lang('edit') }}</v-tab>
        <v-tab href="#delete"
               v-if="_.includes(potential.user_permissions, 'edit')"
        >{{ $lang('delete') }}</v-tab>

        <v-tab-item value="info">
            <PotentialInfo></PotentialInfo>
        </v-tab-item>

        <v-tab-item value="edit">
            <EditPotential></EditPotential>
        </v-tab-item>

        <v-tab-item
            value="delete"
        >
            <DeletePotential></DeletePotential>
        </v-tab-item>

    </v-tabs>
</template>

<script>

    import PotentialInfo from "./PotentialInfo";
    import DeletePotential from "./DeletePotential";
    import EditPotential from "./EditPotential";
    import {mapGetters} from "vuex";

    export default {
        name: "PotentialTabs",
        components: {EditPotential, DeletePotential, PotentialInfo},
        data () {
            return {
                tab: 'info'
            }
        },
        computed: {
            ...mapGetters({
                potential: 'potentials/potential'
            }),
        },

    }
</script>

<style scoped>

</style>
