<template>
    <div>
        <v-switch
            v-model="project.lead_time_settings.track_project"
            :label="$lang('track project')"
        ></v-switch>
        <v-switch
            v-model="project.lead_time_settings.track_issues"
            :label="$lang('track issues')"
        ></v-switch>

        <v-switch
            v-model="project.lead_time_settings.track_todos"
            :label="$lang('track todos')"
        ></v-switch>


        <v-divider>

        </v-divider>
        <v-btn
            class="mt-2"
            color="blue darken-1"
            text
            @click="submit"
        >{{ $lang('save') }}</v-btn>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";

export default {
    name: "LeadTimeSettings",

    computed: {
        ...mapGetters({
            project: 'projects/project',
        }),

    },

    methods: {
        ...mapActions({
            updateLeadTimeSettings: 'projects/updateLeadTimeSettings',
            fetchProject: 'projects/fetchProject',
        }),

        submit () {
            this.updateLeadTimeSettings({
                id: this.project.id,
                payload: {lead_time_settings: this.project.lead_time_settings},
            }).then(() => {
                const self = this
                self.fetchProject(self.project.id)
                Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('lead time settings successfully changed')})
            })
        },
    }

}
</script>

<style scoped>

</style>
