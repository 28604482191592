<template>
    <div>
        <v-navigation-drawer
            v-if="companyPlansLoaded"
            v-model="drawer"
            app
            :clipped="$vuetify.breakpoint.lgAndUp"
        >

            <v-list

                dense
            >

<!--                <v-list-item-->
<!--                    link-->
<!--                    to="/mobileDashboard"-->
<!--                    v-if="$companyPlan('kanban')"-->
<!--                >-->
<!--                    <v-list-item-icon>-->
<!--                        <v-icon>fas fa-tachometer-alt</v-icon>-->
<!--                    </v-list-item-icon>-->
<!--                    <v-list-item-title>-->
<!--                        {{ $lang('mobile dashboard') }}-->

<!--                    </v-list-item-title>-->
<!--                </v-list-item>-->

                <v-list-item
                    link
                    to="/dashboard"
                    v-if="$companyPlan('project')"
                >
                    <v-list-item-icon>
                        <v-icon>fas fa-chart-line</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $lang('dashboard') }}

                    </v-list-item-title>
                </v-list-item>

<!--                <v-list-item-->
<!--                    link-->
<!--                    to="/products"-->
<!--                    v-if="$companyPlan('project')"-->
<!--                >-->
<!--                    <v-list-item-icon>-->
<!--                        <v-icon>fas fa-atom</v-icon>-->
<!--                    </v-list-item-icon>-->
<!--                    <v-list-item-title>-->
<!--                        {{ $lang('products') }}-->

<!--                    </v-list-item-title>-->
<!--                </v-list-item>-->

                <v-list-item
                    link
                    to="/notes"
                >
                    <v-list-item-icon>
                        <v-icon>far fa-sticky-note</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $lang('notes') }}
                        <template v-if="notes">
                            <v-chip v-if="openNotes.length > 0" style="float: right;" color="primary" x-small>
                                {{ openNotes.length}}
                            </v-chip>
                        </template>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    link
                    to="/notifications"
                >
                    <v-list-item-icon>
                        <v-icon>fas fa-envelope-open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $lang('notifications') }}
                        <template v-if="unreadNotifications">
                            <v-chip v-if="unreadNotifications.length > 0" style="float: right;" color="primary" x-small>
                                {{ unreadNotifications.length}}
                            </v-chip>
                        </template>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    link
                    to="/lists"
                    v-if="$companyPlan('project')"
                >
                    <v-list-item-icon>
                        <v-icon>far fa-list-alt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $lang('lists') }}

                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    link
                    to="/projects"
                    v-if="$companyPlan('project')"
                >
                    <v-list-item-icon>
                        <v-icon>fas fa-project-diagram</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $lang('projects') }}

                    </v-list-item-title>
                </v-list-item>

                <v-list-item
                    v-if="!_.isEmpty(project) && $companyPlan('project')"
                    link
                    :to="`/projects/${project.id}/show`"
                >
                    <v-list-item-icon>
                        <v-icon>
                            fas fa-grip-lines
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ project.name}}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    link
                    to="/fileManager"
                    v-if="$companyPlan('project')"
                >
                    <v-list-item-icon>
                        <v-icon>far fa-folder-open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $lang('file manager') }}

                    </v-list-item-title>
                </v-list-item>

                <v-list-group
                    prepend-icon="fas fa-exchange-alt"
                    v-if="$companyPlan('kanban')"
                >
                    <template
                        v-slot:activator
                    >
                        <v-list-item-title>{{$lang('procurement')}}</v-list-item-title>
                    </template>

                    <v-list-item
                        link
                        to="/procurement/kanbanCircuits"
                    >
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{$lang('kanban circuits')}}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/procurement/kanbanOrders"
                    >
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{$lang('kanban orders')}}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        v-if="$companyPlan('kanbanCircuitsExport')"
                        link
                        to="/procurement/kanbanExport"
                    >
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{$lang('kanban export')}}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/procurement/dashboard"
                    >
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{$lang('procurement dashboard')}}
                        </v-list-item-title>
                    </v-list-item>

                </v-list-group>

                <v-list-group
                    prepend-icon="fas fa-share-square"
                    :value="false"
                    v-if="$companyPlan('supplier')"
                >
                    <template v-slot:activator>
                        <v-list-item-title>{{$lang('sales')}}</v-list-item-title>
                    </template>

                    <v-list-item
                        link
                        to="/sales/kanbanOrders"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{$lang('kanban orders')}}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        link
                        to="/sales/kanbanCircuits"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{$lang('kanban circuits')}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-group
                    prepend-icon="fas fa-cogs"
                    :value="false"
                    v-if="_.includes(me.roles, 'Master Data Admin')"
                    :slot-scope="{masterData}"
                >
                    <template v-slot:activator>
                        <v-list-item-title>
                            {{ masterData}}
                        </v-list-item-title>
                    </template>



                    <v-list-item
                        link
                        to="/masterData/projectTypes"
                        v-if="$companyPlan('project')"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('project types') }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/masterData/locations"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('locations') }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/masterData/divisions"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('divisions') }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/masterData/departments"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('departments') }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        link
                        to="/masterData/workplaces"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('workplaces') }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/masterData/costCenters"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('cost centers') }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/masterData/products"
                        v-if="$companyPlan('project')"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('products') }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        link
                        to="/masterData/issueTags"
                        v-if="$companyPlan('project')"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('issue tags') }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="$companyPlan('kanban')"
                        link
                        to="/masterData/suppliers"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('suppliers') }}

                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="$companyPlan('kanban')"
                        link
                        to="/masterData/kanbanCircuits"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{$lang('kanban circuits')}}

                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                    prepend-icon="fas fa-user-cog"
                    :value="false"
                    v-if="_.includes(me.roles, 'Company Admin')"
                    :slot-scope="{companyAdmin}"
                >
                    <template v-slot:activator >
                        <v-list-item-title>
                            {{ companyAdmin }}
                        </v-list-item-title>
                    </template>



                    <v-list-item
                        link
                        to="/companyAdmin/users"
                    >
                        <v-list-item-icon>


                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('users') }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/companyAdmin/company"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('company') }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-divider
                    v-if="_.includes(me.roles, 'Admin')"
                ></v-divider>

                <v-list-group
                    prepend-icon="fas fa-users-cog"
                    :value="false"
                    v-if="_.includes(me.roles, 'Admin')"
                    :slot-scope="{Admin}"
                >
                    <template v-slot:activator>
                        <v-list-item-title>
                            {{ $lang('admin') }}
                        </v-list-item-title>
                    </template>

                    <v-list-item
                        link
                        to="/admin/users"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('users') }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/admin/companies"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('companies') }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        link
                        to="/admin/calculations"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('calculation') }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="downloadLangFrontend()"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('export lang frontend') }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="downloadLangValidation()"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $lang('export lang validation') }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

            </v-list>
        </v-navigation-drawer>
        <v-app-bar
            :clipped-left="$vuetify.breakpoint.lgAndUp"
            app
            :color="env !== 'production' ? 'red' : 'blue darken-3'"
            dense
            dark
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>7APP</v-toolbar-title>

            <v-spacer></v-spacer>
            <ScanComponent></ScanComponent>
            <HeaderNotificationsDropDown></HeaderNotificationsDropDown>
            <CreateNoteDialog endpoint="/api/notes" ></CreateNoteDialog>
            <SelectCompanyDropDown></SelectCompanyDropDown>
            <AvatarDropDown>    </AvatarDropDown>

        </v-app-bar>
    </div>
</template>

<script>
    import CreateNoteDialog from "../../notes/components/CreateNoteDialog";
    import {mapActions, mapGetters} from "vuex";
    import SelectCompanyDropDown from "./SelectCompanyDropDown";
    import AvatarDropDown from "./AvatarDropDown";
    import HeaderNotificationsDropDown from "../../notifications/components/HeaderNotificationsDropDown";
    import ScanComponent from "../../scanner/components/ScanComponent";


    export default {
        name: "AppBar",
        components: {ScanComponent, HeaderNotificationsDropDown, AvatarDropDown, SelectCompanyDropDown, CreateNoteDialog},
        data: () => ({
            drawer: true,
            loading: true
        }),

        computed: {
            ...mapGetters({
                notes: 'notes/notes',
                menuDrawer: 'layout/menuDrawer',
                project: 'projects/project',
                notifications: 'notifications/notifications',
                me: 'auth/user',
                environment: 'app/environment',
                companyPlansLoaded: 'masterData/companyPlansLoaded'
            }),



            env() {
                return this.environment
            },

           Admin () {
                return this.$lang('admin')
            },

            companyAdmin () {
                return this.$lang('company admin')
            },

            masterData () {
                return this.$lang('master data')
            },

            openNotes() {
                return this.notes.filter(item => {
                    return !item.closed_at
                })
            },

            unreadNotifications() {
                return this.notifications.filter(item => {
                    return !item.read_at
                })
            }
        },

        methods: {
            ...mapActions({
                fetchNotes: 'notes/getNotes',
            }),
            downloadLangFrontend() {
                window.open('/lang/getFrontend', '_blank')
            },
            downloadLangValidation() {
                window.open('/lang/getValidation', '_blank')
            }


        },

        mounted() {
            this.fetchNotes()


        },

        watch: {
            companyPlansLoaded: {
                handler: function (value) {

                },
                immediate: true
            },
        }
    }
</script>

<style scoped>

</style>
