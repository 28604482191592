import Bus from "../../../bus";

export const fetchIssue = ({ dispatch, commit }, id) => {
    return axios.get(`/api/issues/${id}`).then((response) => {
        commit('SET_ISSUE_DATA', response.data)
    })
}

export const fetchSingleIssue = ({ dispatch, commit }, id) => {
    return axios.get(`/api/issues/${id}`).then((response) => {
        commit('SET_ISSUE_DATA', response.data)
    })
}


export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post(state.createIssueEndpoint, payload).then((response) => {
        commit('SET_ISSUE_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const setIssue = ({ state, commit }, id) => {
    let issue = state.issues.find(issues => issues.id === id)
    commit('SET_ISSUE_DATA', issue)
}

export const update = ({ dispatch, state, commit }, { payload, context, id }) => {
    return axios.patch(`/api/issues/${id}`, payload).then((response) => {
        commit('SET_ISSUE_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const deleteIssue = ({ dispatch, state, commit }, { id, message }) => {
    return axios.delete(`/api/issues/${id}`).then((response) => {
        commit('SET_ISSUE_DIALOG', false)
        Bus.$emit('issue:deleted', id);
        Bus.$emit('showAlert', {color : 'success', 'message' : message});
    }).catch((error) => {

    })
}

export const updateOrder = ({ dispatch, state, commit }, orderIds) => {
    return axios.patch(`/api/issues/updateOrder`, orderIds).then((response) => {
        commit('UPDATE_ISSUES_DATA', response.data)
    }).catch((error) => {

    })
}

export const setIssueDialog = ({ dispatch, state, commit }, value) => {
    if(state.issue)
        commit('SET_ISSUE_DIALOG', value)
}

export const setCreateIssueDialog = ({ dispatch, state, commit }, {value, endpoint}) => {
    commit('SET_CREATE_ISSUE_ENDPOINT', endpoint)
    commit('SET_CREATE_ISSUE_DIALOG', value)
}

export const setIssuesFilter= ({ dispatch, commit }, value) => {
    commit('SET_ISSUES_FILTER', value)
}

export const fetchIssueDateLimits = ({ commit }, endpoint) => {
    return axios.get(`${endpoint}/dateLimits`).then((response) => {
        commit('SET_ISSUE_DATE_LIMITS', response.data)
    })
}

export const setIssueDialogWidth = ({ dispatch, commit }, value) => {
    commit('SET_ISSUE_DIALOG_WIDTH', value)
}

export const setSelectedTab = ({ dispatch, state, commit}, value) => {
    commit('SET_SELECTED_TAB', value)
}
