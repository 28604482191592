<template>
    <div>

        <v-btn
            color="secondary"
            class="mr-2"
            outlined
            small
            @click="createFolderDialog = true"
        >
            <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('folder') }}
        </v-btn>
        <v-dialog
            v-model="createFolderDialog"
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">{{ $lang('create folder') }}</v-card-title>

                <v-card-text>
                    <v-form>
                        <v-text-field
                            v-model="name"
                            :label="$lang('name')"
                            autofocus
                            :error-messages="errors.name"
                        >

                        </v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="cancel"
                    >
                        {{ $lang('cancel') }}
                    </v-btn>

                    <v-btn
                        color="primary"
                        text
                        @click="submit"
                    >
                        {{ $lang('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Bus from "../../../bus";
import {mapActions} from "vuex";

export default {
name: "CreateFolder",
    data() {
        return {
            createFolderDialog: false,
            errors: [],
            name: ''
        }
    },

    props: [
        'endpoint'
    ],

    methods : {
        ...mapActions({
            storeFolder: 'fileManager/storeFolder',
        }),
        cancel () {
            this.createFolderDialog = false
        },

        submit () {
            this.errors = []
            this.storeFolder({
                payload: {
                    name: this.name
                },
                endpoint: this.endpoint,
                context: this,
            }).then(() => {
                if(this.errors.length === 0) {
                    this.createFolderDialog = false
                    this.name = ''

                    Bus.$emit('showAlert', {color: 'success', 'message': 'Status erfolgreich gespeichert'});
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
