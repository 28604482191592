<template>
    <v-tabs >
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="edit">
            <EditWorkplace></EditWorkplace>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteWorkplace ></DeleteWorkplace>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import EditWorkplace from "./EditWorkplace";
import DeleteWorkplace from "./DeleteWorkplace";
    export default {
        name: "WorkplaceTab",

        components: {
            EditWorkplace,
            DeleteWorkplace
        },

        methods: {

        },

    }
</script>

<style scoped>

</style>
