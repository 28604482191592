<template>
    <div class="container">
        <Bar :chart-data="chartData" :chart-options="options"/>
    </div>
</template>
<script>
import { Bar } from 'vue-chartjs'
import { Chart, LineController, LineElement, BarController, BarElement,  PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from 'chart.js';

Chart.register(LineController, LineElement, BarController, BarElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend);
export default {
    name: "KanbanNumOrdersPerSupplierChart",

    components: { Bar },

    props: {
        height: Number,
        filteredDashboardData: Array
    },

        data () {
            return {
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            label: this.$lang('num orders'),
                            borderWidth: 1,
                            borderColor: 'rgba(21, 101, 192, 0.7)',
                            backgroundColor: 'rgba(21, 101, 192, 0.2)',
                            data: []
                        },

                    ]
                },
                options: {

                    scales: {
                        y: {
                            ticks: {
                                min: 0,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.$lang('number quantity')
                            }
                        },
                    },

                },
                responsive: true,
                maintainAspectRatio: false
            }
        },

        methods: {
            render() {
                const hash = {};
                this.filteredDashboardData.forEach(o => {
                    hash[o.supplier_name] = hash[o.supplier_name] + 1 || 1;
                })

                const suppliers = Object.keys(hash).map(name => ({ name: name, num: hash[name] }))

                this.chartData.labels = _.map(suppliers, 'name')
                this.chartData.datasets[0].data = _.map(suppliers, 'num')
            }
        },

        mounted() {
            this.render()
        },

        watch: {
            filteredDashboardData: {
                handler: function() {
                    this.render()
                },
                deep: true
            },
        },
    }
</script>

<style scoped>

</style>



