import { Company } from '../views'


export default [
    {
        path: '/companyAdmin/company',
        component: Company,
        name: 'companyAdmin.company',
        meta: {
            guest: false,
            needsAuth: true,
            role: 'Company Admin'
        }
    },


]

