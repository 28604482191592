<template>
    <v-dialog
        persistent
        v-model="showIssueDialog"
        :width="issueDialogWidth"
        @click:outside="confirmCloseDialog = true"
        @keydown.esc="confirmCloseDialog = true"
        v-bind:style="{ position: 'relativ',  left: '10px'}"
    >
        <v-toolbar flat color="white" dense v-if="issue">

            <v-toolbar-title >{{ issue.title }} </v-toolbar-title>

            <v-spacer></v-spacer>
            <template
                v-if="issueDialogWidth > 600"
            >
                <TableFilterDropDown table-id="issueTodos" key="issueTodos" v-if="selectedTab === 'todos'"></TableFilterDropDown>

                <v-btn
                    small
                    outlined
                    color="primary"
                    class="mr-2"
                    v-if="issueDialogWidth > 600"
                    @click="setIssueDialogWidth(430)"
                >
                    {{ $lang('small')}}
                </v-btn>


                <v-btn
                    v-if="_.includes(issue.user_permissions, 'edit')"
                    small
                    outlined
                    color="primary"
                    class="mr-2"
                    @click="createTodo()"
                >
                    <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('todo')}}
                </v-btn>
                <v-btn
                    v-if="_.includes(issue.user_permissions, 'edit')"
                    small
                    outlined
                    color="primary"
                    class="mr-2"
                    @click="createPotential()"
                >
                    <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('potential')}}
                </v-btn>
            </template>
            <template
                v-else
            >
                <v-menu
                    offset-y
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            small
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                        >
                            {{$lang('menu')}}
                        </v-btn>
                    </template>
                    <v-list
                        class="text-center"
                    >
                        <v-list-item
                            v-if="_.includes(issue.user_permissions, 'edit')"
                            @click="showCreateTodoDialog({value: true, endpoint: `/api/issues/${issue.id}/todos`})"
                        >
                            <v-list-item-title><v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('todo')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="_.includes(issue.user_permissions, 'edit')"
                            @click="showCreatePotentialDialog({value: true, endpoint: `/api/issues/${issue.id}/potentials`})"
                        >
                            <v-list-item-title><v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('potential')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="setIssueDialogWidth(1200)"
                        >
                            <v-list-item-title>{{ $lang('wide') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <v-btn
                small
                outlined
                color="primary"
                @click="closeDialog"
            >
                <v-icon >fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>

        <template v-if="!editing">
            <IssueTabs></IssueTabs>
        </template>
        <v-dialog
            v-model="confirmCloseDialog"
            width="600"
        >
            <v-card>
                <v-card-title
                    primary-title
                    class="justify-center"
                >
                    {{$lang('close dialog')}}
                </v-card-title>
                <v-card-text
                    class="text-center"
                >
                    <strong>
                        {{$lang('do you really want to close without saving?')}}
                    </strong>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="closeDialog"
                    >
                        {{$lang('close without saving')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="confirmCloseDialog = false"
                    >
                        {{$lang('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>


    import {mapActions, mapGetters} from "vuex";
    import IssueTabs from "./IssueTabs";
    import EditIssue from "./EditIssue";
    import TableFilterDropDown from "../../helpers/tableFilters/components/TableFilterDropDown";

    export default {
        name: "IssueDialog",
        components: {EditIssue, IssueTabs, TableFilterDropDown},
        data() {
            return {
                editing: false,
                confirmCloseDialog: false,
            }
        },

        computed: {
            ...mapGetters({
                issue: 'issues/issue',
                showIssueDialog: 'issues/showIssueDialog',
                issueDialogWidth: 'issues/issueDialogWidth',
                selectedTab: 'issues/selectedTab',
            }),
        },

        methods: {
            ...mapActions({
                actionShowIssueDialog: 'issues/setIssueDialog',
                showCreateTodoDialog: 'todos/setCreateTodoDialog',
                showCreatePotentialDialog: 'potentials/setCreatePotentialDialog',
                setIssueDialogWidth: 'issues/setIssueDialogWidth',
                setSelectedTab: 'issues/setSelectedTab'
            }),

            createPotential(){
                this.setSelectedTab('potentials')
                this.showCreatePotentialDialog({value: true, endpoint: `/api/issues/${this.issue.id}/potentials`})
            },

            createTodo() {
                this.setSelectedTab('todos')
                this.showCreateTodoDialog({value: true, endpoint: `/api/issues/${this.issue.id}/todos`})
            },

            closeDialog () {
                this.actionShowIssueDialog(false)
            }
        },
    }
</script>

<style scoped>

</style>
