export const fetchProjectTypes = ({ commit }) => {
    return axios.get('/api/masterData/projectTypes').then((response) => {
        commit('setProjectTypesData', response.data)
    })
}

export const fetchProjectType = ({ commit }, id) => {
    return axios.get(`/api/masterData/projectTypes/${id}`).then((response) => {
        commit('setProjectTypeData', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/masterData/projectTypes', payload).then((response) => {
        commit('setProjectTypeData', response.data)
        dispatch('fetchProjectTypes')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/masterData/projectTypes/${payload.id}`, payload).then((response) => {
        commit('setProjectTypeData', response.data)
        dispatch('fetchProjectTypes')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, {id, context} ) => {
    return axios.delete(`/api/masterData/projectTypes/${id}`).catch((error) => {
        context.error = true
    })
}

export const setCreateProjectTypeDialog = ({ dispatch, state, commit }, value) => {
    commit('setCreateProjectTypeDialog', value)
}
