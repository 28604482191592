export const notes = (state) => {
    return state.notes
}

export const note = (state) => {
    return state.note
}

export const showNoteDialog = (state)   => {
    return state.showNoteDialog
}

export const notesFilter = (state)   => {
    return state.notesFilter
}
