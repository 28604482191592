<template>
    <v-card>
        <v-card-title>
            <strong>
                {{ $lang('delete todo') }}
            </strong>
        </v-card-title>


        <v-card-title>
            {{ $lang('do you really want to delete this todo?') }}
        </v-card-title>
        <v-card-text>
            <v-switch
                v-model="showConfirmDeleteTodo"
                :label="$lang('yes, please delete!')"
            ></v-switch>
        </v-card-text>


        <v-card-actions>
            <v-btn
                v-if="showConfirmDeleteTodo"
                color="error"
                @click="deleteTodo"
            >
                {{ $lang('delete') }}
            </v-btn>
        </v-card-actions>


    </v-card>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "DeleteTodo",
        data() {
            return {
                showConfirmDeleteTodo: false,
            }
        },
        computed: {
            ...mapGetters({
                todo: 'todos/todo'
            }),
        },

        methods: {
            ...mapActions({
                actionDeleteTodo: 'todos/deleteTodo',
            }),

            deleteTodo() {
                this.showConfirmDeleteTodo = false
                this.actionDeleteTodo({
                    id: this.todo.id,
                    message: this.$lang('todo successfully deleted')
                })
           }

        }
    }
</script>

<style scoped>

</style>
