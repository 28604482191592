<template>
    <v-dialog
        width="600"
        v-model="showCreateManualKanbanOrderDialog"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
        v-if="kanbanCircuit"

    >
        <v-card
            :loading="loading"
        >
            <v-card-title>
                {{$lang('create manual kanban orders')}}
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-2"
                    small
                    outlined
                    color="primary"
                    @click="closeDialog"
                >
                    <v-icon >fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>

                        <tbody>
                        <tr>
                            <td>{{ $lang('part name')}}</td>
                            <td>{{ kanbanCircuit.part_name }}</td>
                        </tr>
                        <tr>
                            <td>{{ $lang('part number')}}</td>
                            <td>{{ kanbanCircuit.part_number }}</td>
                        </tr>
                        <tr>
                            <td>{{ $lang('supplier')}}</td>
                            <td>{{ kanbanCircuit.supplier_name }}</td>
                        </tr>
                        <tr>
                            <td>{{ $lang('stock')}}</td>
                            <td>{{ kanbanCircuit.stock }} {{ kanbanCircuit.unit }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-divider></v-divider>
                <v-row
                    class="pt-2"
                >
                    <v-col>
                        <v-text-field
                            v-model="manualOrderQuantity"
                            :disabled="loading"
                            :error-messages="errors.manualOrderQuantity"
                        >

                        </v-text-field>
                    </v-col>

                    <v-col>
                        <v-btn
                            class="mt-2"
                            color="primary"
                            @click="manualOrderQuantity +=  1"
                            block
                            :disabled="loading"
                            style="touch-action: none"
                        >
                            plus
                        </v-btn>
                    </v-col>
                    <v-col>

                        <v-btn
                            class="mt-2"
                            color="primary"
                            @click="manualOrderQuantity -= 1"
                            block
                            :disabled="loading || manualOrderQuantity <= 0"
                            style="touch-action: none"
                        >
                            minus
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="submit"
                    class="mt-2"
                    :disabled="loading || manualOrderQuantity <= 0"
                    text
                >
                    {{$lang('submit order')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    @click="closeDialog"
                    class="mt-2"
                    :disabled="loading"
                    text
                >
                    {{$lang('cancel')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


    import {mapActions, mapGetters} from "vuex";

    import Bus from "../../../../bus";
    import ProcurementKanbanOrderTabs from "./ProcurementKanbanOrderTabs";


    export default {
        name: "ProcurementCreateManualKanbanOrderDialog",
        components: {},
        data() {
            return {
                errors: [],
                loading: false,
                manualOrderQuantity: 0
            }
        },

        computed: {
            ...mapGetters({
                kanbanOrder: 'procurementKanban/kanbanOrder',
                kanbanCircuit: 'procurementKanban/kanbanCircuit',
                showCreateManualKanbanOrderDialog: 'procurementKanban/showCreateManualKanbanOrderDialog'
            }),
        },

        methods: {
            ...mapActions({
                setShowCreateManualKanbanOrderDialog: 'procurementKanban/setShowCreateManualKanbanOrderDialog',
                storeKanbanOrder: 'procurementKanban/storeKanbanOrder'
            }),

            submit () {
                const self = this
                this.loading = true
                this.errors = []
                this.storeKanbanOrder({
                    kanbanCircuitId: this.kanbanCircuit.id,
                    payload: {
                        manualOrderQuantity: this.manualOrderQuantity,
                    },
                    context: this
                }).then(() => {
                    self.loading = false
                    if(this.errors.length === 0) {
                        this.manualOrderQuantity = 0
                        Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('kanban order successfully created')})
                        this.setShowCreateManualKanbanOrderDialog(false)
                    }
                })

                this.loading = false
            },

            closeDialog () {
                this.setShowCreateManualKanbanOrderDialog(false)
            },

        },


    }
</script>

<style scoped>

</style>
