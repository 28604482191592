export const fetchDepartments = ({ commit }) => {
    return axios.get('/api/masterData/departments').then((response) => {
        commit('setDepartmentsData', response.data)
    })
}

export const fetchDepartment = ({ commit }, id) => {
    return axios.get(`/api/masterData/departments/${id}`).then((response) => {
        commit('setDepartmentData', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/masterData/departments', payload).then((response) => {
        commit('setDepartmentData', response.data)
        dispatch('fetchDepartments')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/masterData/departments/${payload.id}`, payload).then((response) => {
        commit('setDepartmentData', response.data)
        dispatch('fetchDepartments')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, {id, context} ) => {
    return axios.delete(`/api/masterData/departments/${id}`).catch((error) => {
        context.error = true
    })
}

export const setCreateDepartmentDialog = ({ dispatch, state, commit }, value) => {
    commit('setCreateDepartmentDialog', value)
}
