<template>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    dark
                    v-on="on"
                    small
                ><v-icon>fas fa-file-medical</v-icon></v-btn>
            </template>
            <v-card >

                <v-card-title>
                    <span class="headline">{{ $lang('create note') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        color="secondary"
                        @click="dialog= false"
                    >
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            :label="$lang('title')"
                            v-model="form.title"
                            required
                            autofocus
                            :error-messages="errors.title"
                        >
                        </v-text-field>

                        <Editor  :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event" :key="'timeLineEditor'" v-if="!$vuetify.breakpoint.mobile"></Editor>
                        <MobileEditor  :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event" :key="'timeLineEditor'" v-if="$vuetify.breakpoint.mobile"></MobileEditor>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="submit">{{ $lang('save') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="dialog=false">{{ $lang('close') }}</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

</template>

<script>
    import {mapActions} from "vuex";
    import Editor from "../../../components/Editor";
    import Bus from "../../../bus";
    import MobileEditor from "../../../components/MobileEditor";

    export default {
        name: "CreateNoteDialog",
        components: {
            MobileEditor,
            Editor: Editor
        },
        props: [
            'endpoint'
        ],
        data() {
            return {
                dialog: false,
                errors: [],
                form: {
                    title: '',
                    body: '',
                    tokens: [],
                },
            }
        },

        methods: {
            ...mapActions({
                store: 'notes/store',
            }),

            submit () {
                this.store({
                    endpoint: this.endpoint,
                    payload: this.form,
                    context: this,
                }).then(() => {
                    this.dialog = false
                    this.form.body = ''
                    this.form.tokens= []
                    this.$refs.form.reset()
                    this.$refs.form.resetValidation()
                    Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('note successfully created') });
                })

            },


        },
        mounted() {
            const self = this

        }
    }
</script>

<style scoped>

</style>
