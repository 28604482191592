<template>
    <v-tabs >
        <v-tab href="#edit">{{$lang('edit')}}</v-tab>

        <v-tab-item value="edit">
            <EditSupplier></EditSupplier>
        </v-tab-item>


    </v-tabs>
</template>

<script>
import EditSupplier from "./EditSupplier";

    export default {
        name: "SupplierTab",

        components: {
            EditSupplier
        },

    }
</script>

<style scoped>

</style>
