<template>
    <v-tabs
        v-model="selectedTab"
    >
        <v-tab href="#userDashboardTable">{{ $lang('my activities') }}</v-tab>
        <v-tab href="#issues">{{ $lang('issues') }}</v-tab>
        <v-tab href="#todos" >{{ $lang('todos')}}</v-tab>
        <v-tab href="#solex" v-if="$companyPlan('solex')">{{ $lang('solex')}}</v-tab>
        <v-tab href="#leadTimeChangeDashboardTable" >{{ $lang('lead time changes')}}</v-tab>

        <v-tab-item value="userDashboardTable" >
            <UserDashboardTable table-id="UserDashboardTable" ></UserDashboardTable>
        </v-tab-item>

        <v-tab-item value="issues" >
            <IssuesTable table-id="listIssues" endpoint="/api/issues"></IssuesTable>
        </v-tab-item>

        <v-tab-item value="todos">
            <TodosTable table-id="listTodos" endpoint="/api/todos"></TodosTable>
        </v-tab-item>

        <v-tab-item value="solex" v-if="$companyPlan('solex')">
            <SolexesTable table-id="listSolexes"    endpoint="/api/solexes"></SolexesTable>
        </v-tab-item>

        <v-tab-item value="leadTimeChangeDashboardTable" >
            <LeadTimeChangeDashboardTable table-id="leadTimeChangeDashboardTable"  ></LeadTimeChangeDashboardTable>
        </v-tab-item>

    </v-tabs>
</template>

<script>

    import {mapGetters, mapActions} from "vuex";
    import IssuesTable from "../../issues/components/IssuesTable";
    import TodosTable from "../../todos/components/TodosTable";
    import UserDashboardTable from "./UserDashboardTable";
    import SolexesTable from "../../issues/solexes/components/SolexTable";
    import LeadTimeChangeDashboardTable from "./LeadTimeChangeDashboardTable";

    export default {
        name: "ListTabs",
        data() {
            return {
                selectedTab: 'userDashboard'
            }
        },
        components: {
            LeadTimeChangeDashboardTable,
            SolexesTable,
            UserDashboardTable,
            TodosTable,
            IssuesTable
        },

        computed: {
            ...mapGetters({
                projects: 'projects/projects',
                userId:'lists/userId'
            }),
            issues: function() {
                let issues = _.map(this.projects, 'issues')
                return _.flatten(issues)
            },
        },

        methods: {
            ...mapActions({
                setTodos: 'todos/setTodos',
                setSelectedTab: 'lists/setSelectedTab'
            })

        },

        watch: {
            selectedTab: {
                handler: function (value) {
                    this.setSelectedTab(value)
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
