import { KanbanOrders, KanbanCircuits } from '../views'

export default [
    {
        path: '/procurement/kanbanOrders',
        component: KanbanOrders,
        name: 'procurement.kanbanOrders',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/procurement/kanbanCircuits',
        component: KanbanCircuits,
        name: 'procurement.kanbanCircuits',
        meta: {
            guest: false,
            needsAuth: true
        }
    },

]
