export const tableFilters = (state) => {
    return state.tableFilters
}

export const activeFilters = (state) => {
    return state.activeFilters
}

export const showFilterDialog = (state) => {
    return state.showFilterDialog
}

