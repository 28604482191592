<template>
    <div>
        <v-card
            min-height="300px"
        >
            <v-card-text>
                <image-input v-model="avatar">
                    <div slot="activator">
                        <v-img
                            max-width="352"
                            v-ripple v-if="!avatar && !avatarURL" class="mb-3">
                            <v-btn
                                color="primary"
                                block
                            >
                                {{$lang('click to add image')}}
                            </v-btn>
                        </v-img>
                        <v-img
                            max-width="352"
                            v-ripple v-if="avatar" class="mb-3" style="height: 300px; min-width: 300px; width: 300px;">
                            <img :src="avatar.imageURL" alt="avatar">
                        </v-img>

                        <v-img
                        >
                            <img :src="avatarURL" v-if="avatarURL && !avatar" alt="avatar" style="height: 300px; min-width: 300px; width: 300px;">
                        </v-img>
                    </div>
                </image-input>
                <v-slide-x-transition>
                    <div v-if="avatar && saved === false">
                        <v-btn
                            class="primary"
                            @click="uploadImage"
                            :loading="saving"
                        >
                            {{$lang('save image')}}
                        </v-btn>
                    </div>
                </v-slide-x-transition>
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
import ImageInput from "./ImageInput";
import {mapActions, mapGetters} from "vuex";
export default {
    name: 'KanbanCircuitImage',
    data () {
        return {
            avatar: null,
            saving: false,
            saved: false,
            avatarURL: null
        }
    },

    components: {
        ImageInput: ImageInput
    },

    computed: {
        ...mapGetters({
            kanbanCircuit: 'kanbanCircuits/kanbanCircuit',
        }),
    },

    methods: {
        ...mapActions ({

        }),

        uploadImage() {
            const self = this
            this.saving = true

            axios.post(`/api/masterData/kanbanCircuits/${this.kanbanCircuit.id}/uploadImage`, this.avatar.formData, { headers: {
                    'Content-Type': 'multipart/form-data'
                }}).then(function (){

                self.savedAvatar()
            })

        },

        savedAvatar() {
            this.saving = false
            this.saved = true
        }
    },

    mounted() {
       if(this.kanbanCircuit) {
           this.avatarURL = this.kanbanCircuit.image_url
           axios.get('https://voest-api.7app.ch/storage/937/myself_400_400.png')
       }
    },

    watch:{
        avatar: {
            handler: function() {
                this.saved = false
            },
            deep: true
        }
    },
}
</script>

<style scoped>
    img {
        height: 150px; min-width: 150px; width: 150px;
    }

</style>
