<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <strong>
                    {{$lang('permissions')}}
                </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <ProjectPermissions></ProjectPermissions>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
            v-if="$companyPlan('solex')"
        >
            <v-expansion-panel-header>
                <strong>
                    {{$lang('solex settings')}}
                </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <SolexSettings></SolexSettings>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
            <v-expansion-panel-header>
                <strong>
                    {{$lang('lead time settings')}}
                </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <LeadTimeSettings></LeadTimeSettings>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
            <v-expansion-panel-header>
                <strong>
                    {{$lang('delete project')}}
                </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <DeleteProject></DeleteProject>
            </v-expansion-panel-content>
        </v-expansion-panel>

    </v-expansion-panels>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ProjectPermissions from "./ProjectPermissions";
    import ProjectFunctions from "./ProjectFunctions";
    import DeleteProject from "./DeleteProject";
    import SolexSettings from "./SolexSettings";
    import LeadTimeSettings from "./LeadTimeSettings";

    export default {
        name: "ProjectSettings",
        components: {LeadTimeSettings, SolexSettings, DeleteProject, ProjectFunctions, ProjectPermissions},
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters({

            }),

        },

        created() {

        },

        methods: {
            ...mapActions({

            }),

        }

    }
</script>

<style scoped>

</style>
