<template>
    <v-dialog
        v-model="showCreateDivisionDialog"
        width="500"
        @click:outside="closeDialog"

    >
        <v-card>
            <v-card-title
                class="headline grey lighten-2"
                primary-title
            >
                {{ $lang('create division')}}
            </v-card-title>
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-select
                            v-model="form.location_id"
                            :items="locations"
                            :label="$lang('location')"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors.location_id"
                        ></v-select>
                        <v-color-picker class="ma-2"
                            v-model="form.color"
                            :label="$lang('color')"
                            show-swatches
                            hide-canvas
                            hide-inputs
                            width="400"
                        >
                        </v-color-picker>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">{{ $lang('cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="loading">{{ $lang('save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Bus from "../../../../bus";
    export default {
        name: "CreateDivisionDialog",

        data() {
            return {
                loading: false,
                errors: [],
                form: {
                    name: '',
                    color: '',
                    location_id: ''
                }
            }
        },

        computed: {
            ...mapGetters({
                locations: 'locations/locations',
                showCreateDivisionDialog: 'divisions/showCreateDivisionDialog',
            }),
        },

        methods: {
            ...mapActions({
                store: 'divisions/store',
                fetchLocations: 'locations/fetchLocations',
                actionShowCreateDivisionDialog: 'divisions/setCreateDivisionDialog',
            }),

            save () {
                this.loading = true
                this.errors = []
                this.store({
                    payload: this.form,
                    context: this
                }).then(() => {
                    this.loading = false
                    if(this.errors.length === 0) {
                        this.$refs.form.reset()
                        this.closeDialog()
                        Bus.$emit('division:update');
                    }
                })
            },

            closeDialog () {
                this.actionShowCreateDivisionDialog(false)
            }
        },

        created() {
            this.fetchLocations()
        }
    }
</script>

<style scoped>

</style>
