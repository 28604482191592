<template>
    <v-tabs>


        <v-tab href="#kanban">{{ $lang('kanban')}}</v-tab>
        <v-tab href="#supplier" >{{ $lang('supplier')}}</v-tab>

        <v-tab-item value="kanban">
            <KanbanDashboard></KanbanDashboard>
        </v-tab-item>

        <v-tab-item value="supplier" >
            <SupplierDashboard></SupplierDashboard>

        </v-tab-item>
    </v-tabs>
</template>

<script>


import {mapActions, mapGetters} from "vuex";
import SupplierDashboard from "./SupplierDashboard";
import KanbanDashboard from "./KanbanDashboard";


export default {
name: "ProcurementDashboardTabs",
    components: {KanbanDashboard, SupplierDashboard},
    computed: {
        ...mapGetters({
            me: 'auth/user',

        }),

    },

    methods: {
        ...mapActions({

        }),
    }
}
</script>

<style scoped>

</style>
