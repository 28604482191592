<template>
    <v-dialog
        width="1200"
        v-model="showSalesKanbanOrderDialog"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
        v-if="kanbanOrder"
    >
        <v-toolbar flat color="white" dense >

            <v-toolbar-title >{{ kanbanOrder.order_number }} {{kanbanOrder.part_name}}</v-toolbar-title>

            <v-spacer></v-spacer>
            <span
                v-html="kanbanOrder.status_label"
            >
            </span>
            <v-spacer></v-spacer>

            <v-btn
                v-if="kanbanOrder.status === 'partial delivery' && _.includes(me.permissions, 'close kanban order')"
                small
                outlined
                color="primary"
                @click="closeKanbanOrderDialog = true"
            >
                {{$lang('close order')}}
            </v-btn>

            <v-btn
                v-if="kanbanOrder.status === 'new' && _.includes(me.permissions, 'confirm kanban order')"
                small
                outlined
                color="primary"
                @click="confirmKanbanOrderDialog = true"
            >
                {{$lang('confirm')}}
            </v-btn>

            <v-btn
                v-if="kanbanOrder.status === 'confirmed' && _.includes(me.permissions, 'ship kanban order')"
                small
                outlined
                color="primary"
                @click="shipKanbanOrderDialog = true"
            >
                {{$lang('ship')}}
            </v-btn>

            <v-btn
                class="ml-2"
                small
                outlined
                color="primary"
                @click="closeDialog"
            >
                <v-icon >fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>

        <SalesKanbanOrderInfo></SalesKanbanOrderInfo>
        <v-dialog
            v-model="confirmKanbanOrderDialog"
            :fullscreen="$vuetify.breakpoint.mobile"
            @click:outside="confirmKanbanOrderDialog = false"
            width="600"
        >
            <v-card
                style="overflow: hidden;"
            >
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{ $lang('confirm order')}}
                </v-card-title>
                <v-card-title>
                    {{$lang('do you want to confirm this order on', '__date__', kanbanOrder.delivery_date)}}
                </v-card-title>
                <v-card-text>
                    <v-form
                        ref="confirmForm"
                    >
                        <v-switch
                            v-model="confirm"
                            :label="$lang('confirm')"
                        >
                        </v-switch>
                        <v-text-field
                            v-model="form.supplier_reference"
                            :label="$lang('supplier reference')"
                        >

                        </v-text-field>

                        <v-text-field
                            v-model="form.customer_reference"
                            :label="$lang('customer reference')"
                        >
                        </v-text-field>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                :key="confirm"
                                color="primary"
                                class="mt-2"
                                block
                                :disabled="loading || !confirm"
                                text
                                @click="confirmOrder"
                            >
                                {{$lang('confirm')}}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="secondary"
                                @click="confirmKanbanOrderDialog = false"
                                class="mt-2"
                                block
                                :disabled="loading"
                                text
                            >
                                {{$lang('cancel')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="shipKanbanOrderDialog"
            :fullscreen="$vuetify.breakpoint.mobile"
            @click:outside="shipKanbanOrderDialog = false"
            width="600"
        >

            <v-card
                style="overflow: hidden;"
            >
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{ $lang('ship order')}}
                </v-card-title>
                <v-card-title>
                    {{$lang('do you want to ship this order')}}
                </v-card-title>
                <v-card-text>
                    <v-form
                        ref="shipForm"
                    >
                        <v-switch
                            v-model="ship"
                            :label="$lang('ship')"
                        >
                        </v-switch>
                        <v-switch
                            v-model="form.goods_withdrawal"
                        >
                            <template v-slot:label>
                                {{ $lang('post goods withdrawal')}} ({{$lang('stock')}}: {{kanbanOrder.supplier_stock }} | {{$lang('after')}}: {{kanbanOrder.supplier_stock - kanbanOrder.quantity}})
                            </template>
                        </v-switch>
                        <v-text-field
                            v-model="form.supplier_reference"
                            :label="$lang('supplier reference')"
                        >

                        </v-text-field>

                        <v-text-field
                            v-model="form.customer_reference"
                            :label="$lang('customer reference')"
                        >
                        </v-text-field>

                        <v-text-field
                            v-model="form.delivery_reference"
                            :label="$lang('delivery reference')"
                        >
                        </v-text-field>
                    </v-form>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                :key="ship"
                                color="primary"
                                class="mt-2"
                                block
                                :disabled="loading || !ship"
                                text
                                @click="shipOrder"
                            >
                                {{$lang('ship')}}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="secondary"
                                @click="shipKanbanOrderDialog = false"
                                class="mt-2"
                                block
                                :disabled="loading"
                                text
                            >
                                {{$lang('cancel')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="closeKanbanOrderDialog"
            :fullscreen="$vuetify.breakpoint.mobile"
            @click:outside="closeKanbanOrderDialog = false"
            width="600"
            persistent

        >

            <v-card
                style="overflow: hidden;"
            >
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{ $lang('close order')}}
                </v-card-title>
                <v-card-title>
                    {{$lang('do you want to close this order')}}
                </v-card-title>
                <v-card-text>
                    <v-form
                        ref="closeForm"
                    >
                        <v-switch
                            v-model="close"
                            :label="$lang('close partial delivered order')"
                        >
                        </v-switch>

                    </v-form>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                :key="ship"
                                color="primary"
                                class="mt-2"
                                block
                                :disabled="loading || !close"
                                text
                                @click="closeOrder"
                            >
                                {{$lang('close order')}}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="secondary"
                                @click="closeKanbanOrderDialog = false"
                                class="mt-2"
                                block
                                :disabled="loading"
                                text
                            >
                                {{$lang('cancel')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>


    import {mapActions, mapGetters} from "vuex";
    import SalesKanbanOrderInfo from "./SalesKanbanOrderInfo";
    import Bus from "../../../../bus";


    export default {
        name: "SalesKanbanOrderDialog",
        components: {SalesKanbanOrderInfo},
        data() {
            return {
                errors: [],
                loading: false,
                confirmKanbanOrderDialog: false,
                shipKanbanOrderDialog: false,
                closeKanbanOrderDialog: false,
                confirm: false,
                ship: false,
                close: false,
                form: {
                    goods_withdrawal: true,
                    supplier_reference: '',
                    customer_reference: '',
                    delivery_reference: ''
                }
            }
        },


        computed: {
            ...mapGetters({
                me: 'auth/user',
                kanbanOrder: 'salesKanbanOrders/kanbanOrder',
                showSalesKanbanOrderDialog: 'salesKanbanOrders/showKanbanOrderDialog'
            }),
        },


        methods: {
            ...mapActions({
                setShowKanbanOrderDialog: 'salesKanbanOrders/setShowKanbanOrderDialog',
                confirmKanbanOrder: 'salesKanbanOrders/confirmKanbanOrder',
                shipKanbanOrder: 'salesKanbanOrders/shipKanbanOrder',
                closeKanbanOrder: 'salesKanbanOrders/closeKanbanOrder'
            }),

            confirmOrder () {
                const self = this
                this.confirmKanbanOrder({
                    id: this.kanbanOrder.id,
                    payload: this.form,
                    context: this,
                }).then(() => {
                    if(this.errors.length === 0) {
                        self.confirm = false
                        self.confirmKanbanOrderDialog = false
                        Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('kanban order successfully confirmed  ') })
                    }
                })

            },

            shipOrder () {
                const self = this
                this.shipKanbanOrder({
                    id: this.kanbanOrder.id,
                    payload: this.form,
                    context: this,
                }).then(() => {
                    if(this.errors.length === 0) {
                        self.ship = false
                        self.shipKanbanOrderDialog = false
                        Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('kanban order successfully shipped') })
                    }
                })

            },

            closeOrder () {
                const self = this
                this.closeKanbanOrder({
                    id: this.kanbanOrder.id,
                    context: this,
                }).then(() => {
                    if(this.errors.length === 0) {
                        self.close = false
                        self.closeKanbanOrderDialog = false
                        Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('kanban order successfully closed') })
                    }
                })

            },

            closeDialog () {
                this.setShowKanbanOrderDialog(false)
            },

        },

        watch: {

            kanbanOrder: {
                handler: function () {
                    if(this.kanbanOrder) {
                        this.form.customer_reference = this.kanbanOrder.customer_reference
                        this.form.supplier_reference = this.kanbanOrder.supplier_reference
                    }
                },
                immediate: true,
            },
        }



    }
</script>

<style scoped>

</style>
