<template>
    <v-dialog
        persistent
        v-model="showPotentialDialog"
        width="1200"
        @click:outside="confirmCloseDialog = true"
        @keydown.esc="confirmCloseDialog = true"
    >
        <v-toolbar flat color="white" dense >
            <v-toolbar-title >{{ potential.title}} </v-toolbar-title>
            <v-spacer></v-spacer>
            <span
            v-html="potential.type_label"
            >

            </span>
            <v-spacer></v-spacer>
            <h3>
                <template
                    v-if="potential.potential > 0"
                >
                    {{$num(potential.potential)}} {{ company.currency}}
                </template>

                <template
                    v-if="potential.invest > 0"
                >
                    {{$num(potential.invest)}} {{ company.currency}}
                </template>
            </h3>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="closeDialog"
            >
                <v-icon class="mr-1">fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>
        <PotentialTabs></PotentialTabs>

        <v-dialog
            v-model="confirmCloseDialog"
            width="600"
        >
            <v-card>
                <v-card-title
                    primary-title
                    class="justify-center"
                >
                    {{$lang('close dialog')}}
                </v-card-title>
                <v-card-text
                    class="text-center"
                >
                    <strong>
                        {{$lang('do you really want to close without saving?')}}
                    </strong>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="closeDialog"
                    >
                        {{$lang('close without saving')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="confirmCloseDialog = false"
                    >
                        {{$lang('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";
    import PotentialTabs from "./PotentialTabs";

    export default {
        name: "PotentialDialog",
        components: {PotentialTabs},
        data() {
            return {
                confirmCloseDialog: false,
            }
        },

        computed: {
            ...mapGetters({
                potentials: 'potentials/potentials',
                potential: 'potentials/potential',
                showPotentialDialog: 'potentials/showPotentialDialog',
                company: 'companyAdminCompany/company'
            }),
        },

        methods: {
            ...mapActions({
                actionShowPotentialDialog: 'potentials/setShowPotentialDialog',
            }),

            closeDialog () {
                this.actionShowPotentialDialog(false)
            }
        },

    }

</script>

<style scoped>

</style>
