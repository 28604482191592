<template>
    <v-tabs
        v-model="tab"
    >
        <v-tab href="#edit" v-if="editable">{{ $lang('edit')}}</v-tab>
        <v-tab href="#info">{{ $lang('info')}} {{tab}}</v-tab>
        <v-tab href="#delete" v-if="deletable">{{ $lang('delete')}}</v-tab>

        <v-tab-item value="edit">
            <EditSolex></EditSolex>
        </v-tab-item>

        <v-tab-item value="info">
            <SolexInfo></SolexInfo>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteSolex></DeleteSolex>
        </v-tab-item>
    </v-tabs>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import SolexInfo from "./SolexInfo";
    import DeleteSolex from "./DeleteSolex";
    import EditSolex from "./EditSolex";
    export default {
        name: "SolexTabs",
        components: {EditSolex, DeleteSolex, SolexInfo},
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/user',
                solex: 'solexes/solex',
                selectedTab: 'solexes/selectedTab'
            }),
            tab: {
                get(){
                    return this.selectedTab
                },
                set(value){
                    this.setSelectedTab(value)
                }
            },
            editable() {
                if(this.user.id !== this.solex.creator_id && this.user.id !== this.solex.project_leader_id) return false

                if(this.solex.status === 'transformed' || this.solex.status === 'approved') return false

                if(this.solex.status === 'checking' && this.user.id !== this.solex.project_leader_id) return false

                return true
            },

            deletable () {
                if(this.user.id !== this.solex.creator_id && this.user.id !== this.solex.project_leader_id) return false

                if(this.solex.status === 'transformed' && this.solex.issue ) return false

                return true
            }
        },

        methods: {
        ...mapActions({
                setSelectedTab: 'solexes/setSelectedTab'
            }),
        },

        watch: {
            solex: {
                immediate: true,
                deep: true,
                handler() {
                    if (!this.editable){
                        this.setSelectedTab('info')
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
