export const setProjectTypesData = (state, data) => {
    state.projectTypes = data
}

export const setProjectTypeData = (state, data) => {
    state.projectType = data
}

export const addProjectType = (state, data) => {
    state.projectTypes.push(data)
}

export const updateProjectTypeData = (state, data) => {
    Object.assign(state.projectTypes[data.index], data.data)
}

export const removeProjectType = (state, item) => {
    const index = _.indexOf(state.projectTypes, item)
    state.projectTypes.splice(index, 1)
}

export const setCreateProjectTypeDialog = (state, data) => {
    state.showCreateProjectTypeDialog = data
}
