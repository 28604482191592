import { Account } from '../views'

export default [
    {
        path: '/account',
        component: Account,
        name: 'account',
        meta: {
            guest: false,
            needsAuth: true
        }
    },


]
