import { CostCenters, CostCenter } from '../views'
import {Project} from "../../../projects/views";

export default [
    {
        path: '/masterData/costCenters',
        component: CostCenters,
        name: 'costCenters',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/masterData/costCenters/:costCenterId/show',
        component: CostCenter,
        name: 'costCenters.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },
]

