<template>
    <div
    >
        <v-dialog
            v-model="showScannerDialog"
            :fullscreen="$vuetify.breakpoint.mobile"
            @click:outside="closeDialog"
            width="500"
            hide-overlay
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Scanner
                </v-card-title>
                <v-card-text>
                    <qrcode-stream
                        v-if="showScan && showScannerDialog"
                        @decode="onDecode"
                    ></qrcode-stream>
                    <ScanActionSwitch v-if="!showScan && scanString"></ScanActionSwitch>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="secondary"
                        @click="closeDialog"
                        class="mt-2"
                        block
                    >
                        {{$lang('cancel')}}
                    </v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import ScanActionSwitch from "./ScanActionSwitch";
export default {
    name: "Scanner",
    data () {
        return {
            showScan: true,
        }
    },

    components: {
        ScanActionSwitch,
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture
    },

    computed: {
        ...mapGetters({
            me: 'auth/user',
            scanString: 'scanner/scanString',
            showScannerDialog: 'scanner/showScannerDialog'
        }),
    },

    mounted() {
        // this.showScan = false
        // this.setScanString({type: "kanbanCard", kanbanCircuitId: "8acc1d5d-2887-41fd-9d40-5a3108a0043f"})
    },

    methods: {
        ...mapActions({
            setScanString: 'scanner/setScanString',
            setShowScannerDialog: 'scanner/setScannerDialog'
        }),

        closeDialog () {
            this.showScan = true
            this.setShowScannerDialog(false)
            this.setScanString('')
        },

        onDecode (decodedString) {
            console.log(JSON.parse(decodedString))
            this.showScan = false
            this.setScanString(JSON.parse(decodedString))
        },
    },

    watch: {
        me: {
            handler: function (me) {
                this.showScanner =  _.includes(me.companyPlans, 'kanban')
            },
            deep: true,
            immediate: true
        },
    }



}
</script>

<style scoped>

</style>
