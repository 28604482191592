
export const fetchUsers = ({ commit }) => {
    return axios.get(`/api/users`).then((response) => {
        commit('SET_USERS_DATA', response.data)
        let activeUsers = response.data.filter((users)=> {
            return users.active;
        })
        commit('SET_ACTIVE_USERS_DATA', activeUsers)

        let inactiveUsers = response.data.filter((users)=> {
            return !users.active;
        })
        commit('SET_INACTIVE_USERS_DATA', inactiveUsers)
    })
}
