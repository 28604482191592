<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProcurementKanbanOrderDialog from "./ProcurementKanbanOrderDialog";
import Bus from "../../../../bus";

export default {
    name: "ProcurementKanbanOrdersTable",
    components: {ProcurementKanbanOrderDialog},
    data() {
        return {
            datatableExists: true,
            defaultState: null,
            loading: true
        }
    },


    props: {
        tableId: {
            type: String
        },
    },

    computed: {
        ...mapGetters({
            kanbanOrders: 'procurementKanban/kanbanOrders',
            me: 'auth/user',
            activeFilters: 'tableFilters/activeFilters'
        }),
    },

    methods: {
        ...mapActions({
            fetchKanbanOrders: 'procurementKanban/fetchKanbanOrders',
            fetchKanbanOrder: 'procurementKanban/fetchKanbanOrder',
            setShowKanbanOrderDialog: 'procurementKanban/setShowKanbanOrderDialog'
        }),

        loadDatatable() {
            const self = this
            if (this.datatableExists) {
                axios.get('/api/procurement/kanban/kanbanOrders').then(function (response) {
                    self.webixId.parse(webix.copy(response.data))
                })
            }
        }
    },

    mounted() {
        const self = this

        this.datatableExists = true

        this.webixId = webix.ui({
            container: this.$el,
            $scope: this,
            view: "datatable",
            height: window.innerHeight - 200,
            id: self.tableId,
            headermenu: true,
            resizeColumn: true,
            select: "row",
            dragColumn: true,
            headerRowHeight: 40,
            columns: [
                {
                    id: "status_label",
                    header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.status_label
                    }
                },
                {
                    id: "created_manually_label",
                    header: [self.$lang('created manually'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.created_manually_label
                    }
                },
                {
                    id: "order_number_link",
                    header: [self.$lang('number'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "location_name",
                    header: [self.$lang('location'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.location_name
                    }
                },
                {
                    id: "supplier_reference",
                    header: [self.$lang('supplier reference'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "customer_reference",
                    header: [self.$lang('customer reference'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string"
                },

                {
                    id: "delivery_reference",
                    header: [self.$lang('delivery reference'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 100,
                    sort: "string"
                },
                {
                    id: "part_name",
                    header: [self.$lang('part name'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "part_number",
                    header: [self.$lang('part number'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string"
                },

                {
                    id: "unit",
                    header: [self.$lang('unit'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "quantity",
                    header: [self.$lang('quantity'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "delivery_date",
                    map: "(date)#delivery_date#",
                    header: [self.$lang('delivery date'), {content: "dateRangeFilter"}],
                    adjust: true,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.delivery_date)
                    }
                },
                {
                    id: "created_at",
                    map: "(date)#created_at#",
                    header: [self.$lang('created'), {content: "dateRangeFilter"}],
                    adjust: true,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.created_at)
                    }
                },
                {
                    id: "creator_name",
                    header: [self.$lang('created'), {content: "textFilter"}],
                    fillspace: false,
                    adjust: true,
                    sort: "string"
                },
                {
                    id: "confirmed_at",
                    map: "(date)#confirmed_at#",
                    header: [self.$lang('confirmed'), {content: "dateRangeFilter"}],
                    adjust: true,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.confirmed_at)
                    }
                },
                {
                    id: "confirmer_name",
                    header: [self.$lang('confirmed'), {content: "textFilter"}],
                    fillspace: false,
                    adjust: true,
                    sort: "string"
                },
                {
                    id: "shipped_at",
                    map: "(date)#shipped_at#",
                    header: [self.$lang('shipped'), {content: "dateRangeFilter"}],
                    adjust: true,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.shipped_at)
                    }
                },
                {
                    id: "shipper_name",
                    header: [self.$lang('shipped'), {content: "textFilter"}],
                    fillspace: false,
                    adjust: true,
                    sort: "string"
                },

                {
                    id: "received_at",
                    header: [self.$lang('received'), {content: "textFilter"}],
                    fillspace: false,
                    adjust: true,
                    sort: "string"
                },

                {
                    id: "receiver_name",
                    header: [self.$lang('received'), {content: "textFilter"}],
                    fillspace: false,
                    adjust: true,
                    sort: "string"
                },
                {
                    id: "supplier_name",
                    header: [self.$lang('supplier'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.supplier_name
                    }
                },
            ],
            onClick: {
                "order_number_link": function (event, row, target) {
                    self.fetchKanbanOrder(row.row).then(function () {
                        self.setShowKanbanOrderDialog(true)
                    })
                }
            },

            on: {
                onAfterLoad: function () {
                    if(!self.defaultState) {
                        self.defaultState = $$(self.webixId).getState()
                    }
                    self.loading = false
                    if(self.tableId in self.activeFilters ) {
                        this.setState(JSON.parse(self.activeFilters[self.tableId].filter_data))
                    }
                },
            },

        })

        this.loadDatatable()

        Bus.$on('filterTable:' + self.tableId, function (data) {
            if (self.datatableExists) {
                if(!self.defaultState) {
                    self.defaultState = $$(self.webixId).getState()
                }
                $$(self.webixId).setState(JSON.parse(data))
            }
        });

        Bus.$on('resetFilterTable:' + self.tableId, function () {
            self.loadDatatable()
        });

        var channel = this.$pusher.subscribe('kanbanOrders.' + this.me.company.id)
        channel.bind('kanbanOrderCreated', (data) => {
            self.loadDatatable()
        });

        channel.bind('kanbanOrderUpdated', (data) => {
            self.loadDatatable()
        });

        channel.bind('kanbanOrderDeleted', (data) => {
            if (self.datatableExists) {
                self.webixId.remove(data.kanban_order_id)
            }
        });
    },

    destroyed: function () {
        this.datatableExists = false
        webix.$$(this.webixId).destructor();
    }
}
</script>

<style scoped>

</style>
