import Bus from "../../../../bus";

export const fetchUsers = ({ commit }) => {
    return axios.get(`/api/companyAdmin/users`).then((response) => {
        commit('SET_USERS_DATA', response.data)
        let activeUsers = response.data.filter((users)=> {
            return users.active;
        })
        commit('SET_ACTIVE_USERS_DATA', activeUsers)
    })
}

export const fetchUser = ({ commit }, id) => {
    return axios.get(`/api/companyAdmin/users/${id}`).then((response) => {
        commit('SET_USER_DATA', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload,  context}) => {
    return axios.post(`/api/companyAdmin/users`, payload).then((response) => {
        commit('SET_USER_DATA', response.data)
        dispatch('fetchUsers')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, id, context}) => {
    return axios.patch(`/api/companyAdmin/users/${id}`, payload).then((response) => {
        commit('SET_USER_DATA', response.data)
        //todo: fetch Roles
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const updatePermissions = ({ dispatch, state, commit }, { payload, id, context}) => {
    return axios.post(`/api/companyAdmin/users/${id}/permissions`, payload).then((response) => {
        commit('SET_USER_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const updateRoles= ({ dispatch, state, commit }, { payload, id, context}) => {
    return axios.post(`/api/companyAdmin/users/${id}/roles`, payload).then((response) => {
        commit('SET_USER_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const resetPassword= ({ dispatch, state, commit }, { payload, id, context}) => {
    return axios.patch(`/api/companyAdmin/users/${id}/resetPassword`, payload).then((response) => {
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const deleteUser = ({ state, dispatch, commit }, id) => {
    return axios.delete(`/api/companyAdmin/users/${id}`).then((response) => {
        dispatch('fetchUsers')
    })
}
