<template>
    <v-card>
        <v-card-text>
            <v-alert
                v-if="formAlert"
                dense
                text
                type="success"
            >
                <strong>{{ form.full_name}}</strong> erfolgreich geändert
            </v-alert>
            <v-form
                ref="form"
            >
                <v-text-field
                    v-model="form.name"
                    :label="$lang('name')"
                    :error-messages="errors.name"
                    autofocus
                ></v-text-field>
                <v-text-field
                    v-model="form.street"
                    label="Adresse"
                    :error-messages="errors.street"
                ></v-text-field>
                <v-text-field
                    v-model="form.zip"
                    label="Plz"
                    :error-messages="errors.zip"
                ></v-text-field>
                <v-text-field
                    v-model="form.city"
                    label="Ort"
                    :error-messages="errors.city"
                ></v-text-field>
                <v-switch
                    v-model="form.active"
                    :label="$lang('active')"
                ></v-switch>
                <v-radio-group
                    v-model="form.name_format"
                    row
                >
                    <v-radio
                        label="Max Muster"
                        value="first_name|last_name|&nbsp;"
                    ></v-radio>
                    <v-radio
                        label="Muster Max"
                        value="last_name|first_name|&nbsp;"
                    ></v-radio>
                    <v-radio
                        label="Muster, Max"
                        value="last_name|first_name|,&nbsp;"
                    ></v-radio>
                </v-radio-group>
                <v-radio-group
                    v-model="form.currency"
                    row
                >
                    <v-radio
                        label="€"
                        value="€"
                    ></v-radio>
                    <v-radio
                        label="CHF"
                        value="CHF"
                    ></v-radio>
                    <v-radio
                        label="$"
                        value="$"
                    ></v-radio>
                    <v-radio
                        label="PLN"
                        value="PLN"
                    ></v-radio>
                </v-radio-group>
            </v-form>

        </v-card-text>

        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
name: "EditCompany",
    data() {
        return {
            formAlert: false,
            errors: [],
            form: {
                name: '',
                street:'',
                zip: '',
                city: '',
                active: '',
                name_format: '',
                currency: ''
            }
        }
    },
    computed: {
        ...mapGetters({
            company: 'adminCompanies/company',
        }),
    },

    methods: {
        ...mapActions({
            update: 'adminCompanies/update',
        }),

        save () {
            this.errors = []
            this.update({
                payload: this.form,
                id: this.company.id,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.showAlert()
                }
            })
        },

        showAlert() {
            this.formAlert = true
            setTimeout(this.hideAlert, 3000)
        },

        hideAlert() {
            this.formAlert = false
        }
    },

    mounted() {
        this.form = this.company
    }
}
</script>

<style scoped>

</style>
