export const fetchKanbanCircuits = ({ commit }) => {
    return axios.get('/api/procurement/kanbanDashboard/getKanbanCircuits').then((response) => {
        commit('SET_KANBAN_CIRCUITS_DATA', response.data)
    })
}

export const fetchKanbanChartsData = ({ commit }, payload) => {
    return axios.post('/api/procurement/kanbanDashboard/getKanbanChartsData', payload).then((response) => {
        commit('SET_KANBAN_CHARTS_DATA', response.data)
    })
}

