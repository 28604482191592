<template>
    <v-card>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-card-title>
            {{ project.name }}
        </v-card-title>
        <v-card-text>

            <v-row>
                <v-col
                    cols="12"
                    md="3"

                >
                    <v-simple-table
                        style="font-size: 1rem"
                        class="font-weight-medium "
                    >
                        <tr>
                            <td>
                                {{$lang('description')}}:
                            </td>
                            <td>
                                {{project.description}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{$lang('project type')}}:
                            </td>
                            <td
                                v-html="project.project_type_label"
                            >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{$lang('project leader')}}:
                            </td>
                            <td
                                v-html="project.project_leader_name"
                            >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{$lang('creator')}}:
                            </td>
                            <td
                                v-html="project.creator_name"
                            >
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{$lang('created')}}:
                            </td>
                            <td
                                v-html="project.created_at"
                            >
                            </td>
                        </tr>
                    </v-simple-table>
                </v-col>
                <v-col
                    cols="12"
                    md="3"
                >
                    <v-simple-table
                        style="font-size: 1rem"
                        class="font-weight-medium "
                    >
                        <tr>
                            <td>
                                {{$lang('roi')}}:
                            </td>
                            <td
                                class="text-right"
                            >
                                {{$num(project.roi, 2)}} {{$lang('years')}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{$lang('potential')}}:
                            </td>
                            <td
                                class="text-right"
                            >
                                {{$num(project.total_potential, 0)}} {{company.currency}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{$lang('invest')}}:
                            </td>
                            <td
                                class="text-right"
                            >
                                {{$num(project.total_invest, 0)}} {{company.currency}}
                            </td>
                        </tr>
                    </v-simple-table>
                </v-col>
            </v-row>


        </v-card-text>



        <v-row >
            <v-col
                cols="12"
                md="6"
            >
                <v-card
                    class="mb-2 ml-1"
                >
                    <v-card-title
                        class="pb-0"
                    >
                        {{ $lang('saving potential') }} {{ user.company.currency}}
                    </v-card-title>
                    <v-card-text
                    >
                        <BruttoPotentialWithHardnessDegree :filtered-issues-data="issues" :height="150" ></BruttoPotentialWithHardnessDegree>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
                <v-card
                    class="mb-2  mr-1"
                >
                    <v-card-title
                        class="pb-0"
                    >
                        {{$lang('issues' )}}
                    </v-card-title>
                    <v-card-text
                    >
                        <IssuesAndTodosWithStatus :filtered-issues-data="issues" :height="150" ></IssuesAndTodosWithStatus>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


    </v-card>
</template>

<script>
import {mapGetters} from "vuex";


import Timeline from "../../timeline/components/Timeline";
import BruttoPotentialWithHardnessDegree from "@/app/controlling/charts/BruttoPotentialWithHardnessDegree.vue";
import IssuesAndTodosWithStatus from "@/app/controlling/charts/IssuesAndTodosWithStatus.vue";

export default {
name: "ProjectInfo",
    data() {
        return {
            issues: null,
            loading: true
        }
    },

    components: {
        IssuesAndTodosWithStatus,
        BruttoPotentialWithHardnessDegree,
        Timeline,
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
            project: 'projects/project',
            company: 'companyAdminCompany/company',
            tab: 'projects/selectedTab'
        }),
    },

    mounted() {
        this.issues = this.project.issues
        this.loading = false
    },

    watch: {
        project: {
            handler: function() {
                this.issues = this.project.issues
            }
        },
    },
}
</script>

<style scoped>

</style>
