<template>
    <div class="container">
        <Bar :chart-data="chartData" :chart-options="options"/>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import { Bar } from 'vue-chartjs'
import { Chart, LineController, LineElement, BarController, BarElement,  PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from 'chart.js';

Chart.register(LineController, LineElement, BarController, BarElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend);

let number_format = {}
export default {
    name: "HoursPotentialWithHardnessDegree",

    components: { Bar},

    computed: {
        ...mapGetters({
            me: 'auth/user',
        }),
    },

    created() {
        number_format = this.me.number_format
    },

    data() {
        return {
            chartData: {
                labels: [
                    this.$lang('HG-0'),
                    this.$lang('HG-1'),
                    this.$lang('HG-2'),
                    this.$lang('HG-3'),
                    this.$lang('HG-4'),
                    this.$lang('HG-5'),
                    this.$lang('total')],
                datasets: [
                    {
                        label: this.$lang('staff'),
                        borderWidth: 1,
                        borderColor: 'rgba(50, 168, 82, 0.7)',
                        backgroundColor: 'rgba(50, 168, 82, 0.2)',
                        data: []
                    },
                    {
                        label: this.$lang('machines'),
                        borderWidth: 1,
                        borderColor: 'rgba(21, 101, 192, 0.7)',
                        backgroundColor: 'rgba(21, 101, 192, 0.2)',
                        data: []
                    }
                ]
            },
            options: {

                scales: {
                    y: {
                        ticks: {
                            min: 0,
                            precision: 0,
                            callback: function (value, index, values) {
                                return webix.Number.format(value, {
                                    groupSize: number_format.group_size,
                                    groupDelimiter: _.replace(number_format.group_delimiter, '&nbsp;', ' '),
                                    decimalSize: 0,
                                    decimalDelimiter: _.replace(number_format.decimal_delimiter, '&nbsp;', ' ')
                                });
                            }
                        }
                    },
                    x: {
                        scaleLabel: {
                            display: true,
                            labelString: this.$lang('hardness degree')
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return webix.Number.format(context.raw, {
                                    groupSize: number_format.group_size,
                                    groupDelimiter: _.replace(number_format.group_delimiter, '&nbsp;', ' '),
                                    decimalSize: 0,
                                    decimalDelimiter: _.replace(number_format.decimal_delimiter, '&nbsp;', ' ')
                                })
                            }
                        },

                    },
                }
            },
            responsive: true,
            maintainAspectRatio: false
        }
    },

    props: [
        'filteredIssuesData',
        'height'
    ],

    methods: {

        num() {
            return 'value';
        },

        render() {
            let staffHoursPotentialByHardnessDegree = []
            let machineHoursPotentialByHardnessDegree = []

            staffHoursPotentialByHardnessDegree[0] = 0
            staffHoursPotentialByHardnessDegree[1] = 0
            staffHoursPotentialByHardnessDegree[2] = 0
            staffHoursPotentialByHardnessDegree[3] = 0
            staffHoursPotentialByHardnessDegree[4] = 0
            staffHoursPotentialByHardnessDegree[5] = 0
            staffHoursPotentialByHardnessDegree[6] = 0

            machineHoursPotentialByHardnessDegree[0] = 0
            machineHoursPotentialByHardnessDegree[1] = 0
            machineHoursPotentialByHardnessDegree[2] = 0
            machineHoursPotentialByHardnessDegree[3] = 0
            machineHoursPotentialByHardnessDegree[4] = 0
            machineHoursPotentialByHardnessDegree[5] = 0
            machineHoursPotentialByHardnessDegree[6] = 0


            _.each(_.flatten(this.filteredIssuesData), function (value, key) {
                staffHoursPotentialByHardnessDegree[value.hardness_degree] += value.total_staff_hours_potential
                staffHoursPotentialByHardnessDegree[6] += value.total_staff_hours_potential
                machineHoursPotentialByHardnessDegree[value.hardness_degree] += value.total_machine_hours_potential
                machineHoursPotentialByHardnessDegree[6] += value.total_machine_hours_potential
            });
            this.chartData.datasets[0].data = staffHoursPotentialByHardnessDegree
            this.chartData.datasets[1].data = machineHoursPotentialByHardnessDegree
        }

    },

    watch: {
        filteredIssuesData: {
            handler: function() {
                this.render()
            },
            deep: true,
            immediate: true
        }
    },
}
</script>

<style scoped>

</style>



