export const showControllingSelectFilter = (state) => {
    return state.showControllingSelectFilter
}

export const dashboardIssuesData = (state) => {
    return state.dashboardIssuesData
}

export const defaultFilterData = (state) => {
    return state.defaultFilterData
}

export const dashboardFilters = (state) => {
    return state.dashboardFilters
}

export const activeFilters = (state) => {
    return state.activeFilters
}

export const filtering = (state) => {
    return state.filtering
}


export const currentFilter = (state) => {
    return state.currentFilter
}
