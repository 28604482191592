<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title>   {{$lang('procurement dashboard')}}</v-toolbar-title>

            <v-spacer></v-spacer>
        </v-toolbar>
        <ProcurementDashboardTabs></ProcurementDashboardTabs>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import ProcurementDashboardTabs from "../components/ProcurementDashboardTabs";
export default {
name: "ProcurementDashboard",
    components: {
        ProcurementDashboardTabs
    },
    data () {
        return {

        }
    },

    computed: {
        ...mapGetters({
            me: 'auth/user',
        }),
    },



}
</script>

<style scoped>

</style>
