export const setIssueTagsData = (state, data) => {
    state.issueTags = data
}

export const SET_ACTIVE_ISSUE_TAGS_DATA = (state, data) => {
    state.activeIssueTags = data
}

export const setIssueTagData = (state, data) => {
    state.issueTag = data
}

export const addIssueTag = (state, data) => {
    state.issueTags.push(data)
}

export const updateIssueTagData = (state, data) => {
    Object.assign(state.issueTags[data.index], data.data)
}

export const removeIssueTag = (state, item) => {
    const index = _.indexOf(state.issueTags, item)
    state.issueTags.splice(index, 1)
}

export const setCreateIssueTagDialog = (state, data) => {
    state.showCreateIssueTagDialog = data
}
