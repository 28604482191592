<template>
    <div>
        <v-toolbar flat color="white" >
            <v-toolbar-title >
                {{$lang('kanban circuits')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <TableFilterDropDown table-id="SalesKanbanCircuitsTable" key="SalesKanbanCircuitsTable" ></TableFilterDropDown>
        </v-toolbar>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <KanbanCircuitsTable></KanbanCircuitsTable>
        <KanbanCircuitDialog></KanbanCircuitDialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import TableFilterDropDown from "../../../helpers/tableFilters/components/TableFilterDropDown";
import KanbanCircuitsTable from "../components/KanbanCircuitsTable";
import KanbanCircuitDialog from "../components/KanbanCircuitDialog";

export default {
name: "KanbanCircuits",
    components: {KanbanCircuitDialog, KanbanCircuitsTable, TableFilterDropDown},
    data () {
        return {
            loading: true
        }
    },

    computed: {
        ...mapGetters({

        }),
    },

    methods: {
        ...mapActions({

        }),
    },
    mounted() {
    this.loading = false
    const self = this

    },

    watch: {

    }
}
</script>

<style scoped>

</style>
