export const products = (state) => {
    return state.products
}

export const product = (state) => {
    return state.product
}

export const selectedProduct = (state) => {
    return state.selectedProduct
}
