export const fetchMasterData = ({ dispatch, commit }) => {
    return axios.get(`/api/masterData/getMasterData`).then((response) => {
        commit('lang/SET_TRANSLATABLE_STRINGS_DATA', response.data.translatable_strings, {root: true})
        commit('lang/SET_CUSTOM_TRANSLATIONS_DATA', response.data.custom_translations, {root: true})
        commit('lang/SET_SUPPORTED_LANGS_DATA', response.data.supported_langs, {root: true})
        commit('lang/SET_SUPPORTED_LOCALS_DATA', response.data.supported_locals, {root: true})
        commit('hardnessDegrees/SET_HARDNESS_DEGREES_DATA', response.data.hardness_degree_items, {root: true})
        commit('companyAdminCompany/SET_COMPANY_DATA', response.data.company, {root: true})
        commit('users/SET_USERS_DATA', response.data.users, {root: true})
        commit('users/SET_ACTIVE_USERS_DATA', response.data.active_users, {root: true})
        commit('users/SET_INACTIVE_USERS_DATA', response.data.inactive_users, {root: true})
        commit('locations/setLocationsData', response.data.locations, {root: true})
        commit('divisions/setDivisionsData', response.data.divisions, {root: true})
        commit('departments/setDepartmentsData', response.data.departments, {root: true})
        commit('workplaces/setWorkplacesData', response.data.workplaces, {root: true})
        commit('costCenters/SET_COST_CENTERS_DATA', response.data.cost_centers, {root: true})
        commit('projectTypes/setProjectTypesData', response.data.project_types, {root: true})
        commit('products/SET_PRODUCTS_DATA', response.data.products, {root: true})
        commit('issueTags/setIssueTagData', response.data.issue_tags, {root: true})
        commit('app/SET_ENVIRONMENT', response.data.environment, {root: true})
        commit('suppliers/SET_SUPPLIERS_DATA', response.data.suppliers, {root: true})
        commit('networkSuppliers/SET_NETWORK_SUPPLIERS_DATA', response.data.network_suppliers, {root: true})
        commit('lang/SET_LANG_DATA', response.data.translations, {root: true})
        commit('lang/SET_LOCAL', response.data.local, {root: true})
        window.translations = response.data.translations

        dispatch('setCompanyPlans', response.data.companyPlans).then(function (){
            commit('SET_COMPANY_PLANS_LOADED', true)
        })
    })
}


export const setCompanyPlans = ({ dispatch, commit }, data) => {
    window.companyPlans = data
}



