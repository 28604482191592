<template>
    <div>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-btn
                            color="primary"
                            @click="downloadCard(1)"
                            block
                        >
                            {{$lang('download kanban card')}}
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>

    </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
name: "KanbanCard",
    data () {
        return {
            typeLoad: {
                type_1: false,
                type_2: true
            }

        }
    },
    components: {

    },
    computed: {
        ...mapGetters({
            kanbanCircuit: 'kanbanCircuits/kanbanCircuit',
        }),
    },

    methods: {
        downloadCard(type) {
            const self = this
            axios({
                url: `${process.env.VUE_APP_BASE_URL}/api/masterData/kanbanCircuits/${this.kanbanCircuit.id}/getPdf?type=${type}`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.kanbanCircuit.name}.pdf`);
                document.body.appendChild(link);
                link.click();
            });
        },
    },


    watch: {
        typeLoad: {
            handler: function () {
                if(this.typeLoad.type_1 && this.typeLoad.type_2) {
                    this.loading = false
                }
            },
            deep: true
        },
    },
}
</script>

<style scoped>

</style>
