<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                md="2"
            >
                <v-card height='100%'>
                    <ProductList></ProductList>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <v-card height='100%'>
                    <ProductInfo></ProductInfo>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                md="7"
            >
                <v-card height='100%'>

                </v-card>

            </v-col>
        </v-row>
        <ProductIssuesTable :issues="product.issues" table-id="productIssues" :drag-rows="false"  v-if="product"></ProductIssuesTable>
<!--        <IssuesTable table-id="productIssues" :issues="product.issues" :drag-rows="false" v-if="product"></IssuesTable>-->
<!--        <BruttoPotentialWithTimeline :filtered-issues-data="product.issues" :height="150"></BruttoPotentialWithTimeline>-->
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import IssuesTable from "../../issues/components/IssuesTable";

import ProductInfo from "./ProductInfo";
import ProductList from "../components/ProductList";

import ProductIssuesTable from "../tables/ProductIssuesTable";

export default {
name: "Product",
    data () {
        return {
            productKey: 0
        }
    },
    components: {
        ProductIssuesTable,
        ProductList, ProductInfo, IssuesTable},
    computed: {
        ...mapGetters({
            product: 'products/product'
        }),
    },
    watch: {

        product: {
            handler: function () {
                const self = this
                self.productKey = self.productKey + 1
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
