import store from '../vuex'
import localforage from 'localforage'

const beforeEach = ((to, from, next) => {
    if(to.meta.role) {
        if (!_.includes(store.state.auth.user.roles, to.meta.role)) {
            next({ name: 'home' })
        }
    }

    store.dispatch('auth/checkAuthenticated').then(() => {

        if (to.meta.guest) {
            next({ name: 'notes' })
            return
        }

        next()
    }).catch(() => {
        if (to.meta.needsAuth) {
            localforage.setItem('intended', to.name)
            next({ name: 'login' })
            return
        }

        next()
    })
})

export default beforeEach
