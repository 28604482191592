<template>
    <v-tabs >
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="edit">
            <EditProjectType></EditProjectType>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteProjectType ></DeleteProjectType>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import EditProjectType from "./EditProjectType";
import DeleteProjectType from "./DeleteProjectType";
    export default {
        name: "ProjectTab",

        components: {
            EditProjectType,
            DeleteProjectType
        },

        methods: {

        },

    }
</script>

<style scoped>

</style>
