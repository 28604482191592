<template>
    <div>
        <v-toolbar flat color="white" >
            <v-toolbar-title >
                <template v-if="!product">
                   {{ $lang('products')}}
                </template>
                <template
                    v-else
                    >
                    {{product.name}}
                </template>
            </v-toolbar-title>
            <v-spacer></v-spacer>

        </v-toolbar>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <Product v-if="products.length"></Product>
        <template
            v-else
        >
            <h3
                class="grey--text ml-4 mt-4"

            >
                {{ $lang('you do not have any products yet.')}}

            </h3>

        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProductList from "../components/ProductList";
import Product from "../components/Product";


import Bus from "../../../bus";

export default {
name: "Products",
    data () {
        return {
            loading: true
        }
    },
    components: {Product, ProductList},
    computed: {
        ...mapGetters({
            products: 'products/products',
            product: 'products/product',
            issues: 'issues/issues',
        }),
    },

    methods: {
        ...mapActions({
            fetchProducts: 'products/fetchProducts',
            fetchProduct: 'products/fetchProduct',
        }),
    },
    mounted() {
    const self = this
        this.fetchProducts().then(function (){
            if(!self.product && self.products.length) {
                self.fetchProduct(self.products[0].id)
            }
            self.loading = false
        })

        Bus.$on('issue:updated', function () {
            self.fetchProducts()
            self.fetchProduct(self.product.id).then(function (){
                self.loading = false
            })
        });
    },

    watch: {
        issues: {
            handler: function () {
                const self = this
                this.fetchProducts()
                this.fetchProduct(this.product.id).then(function (){
                    self.loading = false
                })
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
