<template>
    <div v-if="workplace">
        <v-toolbar flat color="white" dense >
                <v-btn
                    small
                    outlined
                    color="primary"
                    to="/masterData/workplaces"
                    class="mr-2"
                >
                    <v-icon class="mr-1">fas fa-list</v-icon>
                </v-btn>
            <v-toolbar-title >{{ workplace.name }}</v-toolbar-title>
        </v-toolbar>

        <WorkplaceTabs ></WorkplaceTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import WorkplaceTabs from "../components/WorkplaceTabs";

    export default {
        name: "Workplace",
        components: {
            WorkplaceTabs
        },
        props: [
            'workplaceId'
        ],
        computed: {
            ...mapGetters({
                workplace: 'workplaces/workplace',
            }),
        },
        created() {
            this.fetchWorkplace(this.workplaceId)
        },

        methods: {
            ...mapActions({
                fetchWorkplace: 'workplaces/fetchWorkplace',
            }),
        }
    }
</script>

<style scoped>

</style>
