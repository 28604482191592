<template>
    <div>
        <v-col
            md="12"
            cols="12"
        >
            <v-list dense>
                <v-list-item>
                    <v-list-item-content>{{ $lang('created')}}</v-list-item-content>
                    <v-list-item-content class="align-end" >
                        {{ potential.created_at}}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>{{ $lang('creator')}}</v-list-item-content>
                    <v-list-item-content class="align-end" >
                        {{ potential.creator_full_name}}
                    </v-list-item-content>
                </v-list-item>

            </v-list>
            <div v-html="potential.body" style="min-height: 200px">
            </div>
        </v-col>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "PotentialInfo",
        computed: {
            ...mapGetters({
                potential: 'potentials/potential'
            }),
        },

    }
</script>

<style>
    img {
        max-width: 60%;
        height: auto;
    }
</style>
