<template>
    <v-dialog
        v-model="showCreateKanbanCircuitDialog"
        width="1200"
        @click:outside="closeDialog"
    >
        <v-card>
            <v-card-title
                class="headline grey lighten-2"
                primary-title
            >
                {{$lang('kanban circuit')}}
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                    >
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.name"
                                    :label="$lang('kanban circuit name')"
                                    :error-messages="errors.name"
                                    autofocus
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.number"
                                    :label="$lang('kanban circuit number')"
                                    :error-messages="errors.number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.part_name"
                                    :label="$lang('kanban part name')"
                                    :error-messages="errors.part_name"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.part_number"
                                    :label="$lang('kanban part number')"
                                    :error-messages="errors.part_number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    v-model="form.supplier_id"
                                    :items="activeSuppliers"
                                    :label="$lang('supplier')"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.supplier_id"
                                ></v-select>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.supplier_part_number"
                                    :label="$lang('supplier part number')"
                                    :error-messages="errors.supplier_part_number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >

                                <v-text-field
                                    v-model="form.lot_size"
                                    :label="$lang('purchasing lot size')"
                                    :error-messages="errors.lot_size"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.withdrawal_lot_size"
                                    :label="$lang('withdrawal lot size')"
                                    :error-messages="errors.withdrawal_lot_size"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.stock"
                                    :label="$lang('stock')"
                                    :error-messages="errors.stock"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >

                                <v-text-field
                                    v-model="form.unit"
                                    :label="$lang('unit')"
                                    :error-messages="errors.unit"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.reporting_stock"
                                    :label="$lang('reporting stock')"
                                    :error-messages="errors.reporting_stock"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.safety_stock"
                                    :label="$lang('safety stock')"
                                    :error-messages="errors.safety_stock"
                                ></v-text-field>

                            </v-col>
                        </v-row>
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.lot_item_quantity"
                                    :label="$lang('lot item quantity')"
                                    :error-messages="errors.lot_item_quantity"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >

                                <v-text-field
                                    v-model="form.lot_item_unit"
                                    :label="$lang('lot item unit')"
                                    :error-messages="errors.lot_item_unit"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.settlement_unit"
                                    :label="$lang('settlement unit')"
                                    :error-messages="errors.settlement_unit"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >

                                <v-text-field
                                    v-model="form.settlement_factor"
                                    :label="$lang('settlement factor')"
                                    :error-messages="errors.settlement_factor"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.storage_location"
                                    :label="$lang('storage location')"
                                    :error-messages="errors.storage_location"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="form.delivery_time"
                                    :label="$lang('delivery time') + ' '+'(' + $lang('days')+ ')' "
                                    :error-messages="errors.delivery_time"
                                ></v-text-field>


                            </v-col>
                        </v-row>
                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    v-model="form.location_id"
                                    :items="locations"
                                    :label="$lang('location')"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.location_id"
                                ></v-select>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    v-model="form.workplace_id"
                                    :items="workplaces"
                                    :label="$lang('workplace')"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.workplace_id"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row
                                dense
                        >
                            <v-col
                                    cols="12"
                                    md="6"
                            >
                                <v-text-field
                                        v-model="form.description"
                                        :label="$lang('description')"
                                        :error-messages="errors.description"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12"
                                    md="6"
                            >
                                <v-text-field
                                        v-model="form.supplier_info"
                                        :label="$lang('supplier info')"
                                        :error-messages="errors.supplier_info"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                                dense
                        >
                            <v-col
                                    cols="12"
                                    md="6"
                            >
                                <v-text-field
                                        v-model="form.qta_percentage"
                                        :label="$lang('qta percentage')"
                                        :error-messages="errors.qta_percentage"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                    cols="12"
                                    md="6"
                            >
                                <v-text-field
                                        disabled
                                        v-model="form.lta_percentage"
                                        :label="$lang('lta percentage')"
                                        :error-messages="errors.lta_percentage"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row
                            dense
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-switch
                                    v-model="form.allow_under_delivery"
                                    :label="$lang('allow under delivery')"
                                ></v-switch>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-switch
                                    v-model="form.allow_over_delivery"
                                    :label="$lang('allow over delivery')"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">{{$lang('cancel')}}</v-btn>
                <v-btn color="blue darken-1" text @click="submit">{{$lang('save')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "CreateKanbanCircuitDialog",

        data() {
            return {
                errors: [],
                form: {
                    name: '',
                    number: '',
                    part_name: '',
                    part_number: '',
                    supplier_id:'',
                    supplier_part_number: '',
                    unit: '',
                    lot_size: '',
                    lot_item_quantity: '',
                    lot_item_unit: '',
                    settlement_unit: '',
                    settlement_factor: '',
                    delivery_time: '',
                    safety_stock: '',
                    storage_location: '',
                    stock: '',
                    location_id: '',
                    workplace_id: '',
                    allow_goods_receipt_quantity_change: 0,
                    allow_under_delivery: false,
                    allow_over_delivery: false,
                    description: '',
                    supplier_info: '',
                    qta_percentage: '50',
                    lta_percentage: '50'
                }
            }
        },

        computed: {
            ...mapGetters({
                showCreateKanbanCircuitDialog: 'kanbanCircuits/showCreateKanbanCircuitDialog',
                activeSuppliers: 'suppliers/activeSuppliers',
                locations: 'locations/locations',
                workplaces: 'workplaces/workplaces'
            }),
        },

        methods: {
            ...mapActions({
                store: 'kanbanCircuits/store',
                setCreateKanbanCircuitDialog: 'kanbanCircuits/setCreateKanbanCircuitDialog',
                fetchSuppliers: 'suppliers/fetchSuppliers',
                fetchLocations: 'locations/fetchLocations',
                fetchWorkplaces: 'workplaces/fetchWorkplaces'
            }),

            submit () {
                this.errors = []
                this.store({
                    payload: this.form,
                    context: this
                }).then(() => {
                    const self = this
                    if(this.errors.length === 0) {
                        this.$refs.form.reset()
                        Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('kanban circuit successfully created')})
                        this.setCreateKanbanCircuitDialog(false)
                    }
                })
            },

            closeDialog () {
                this.$refs.form.reset()
                this.setCreateKanbanCircuitDialog(false)
            }
        },

        mounted() {
            if(!this.activeSuppliers) {
                this.fetchSuppliers()
            }

            if(!this.locations) {
                this.fetchLocations()
            }

            if(!this.workplaces) {
                this.fetchWorkplaces()
            }
        },

        watch: {
            'form.qta_percentage': function (value) {
                this.form.lta_percentage = 100 - value
            }
        },
    }
</script>

<style scoped>

</style>
