<template>
    <v-timeline-item
        fill-dot
        color="primary"
        large
    >

        <template v-slot:icon>

            <v-avatar
                size="50px"
                item
                v-if="timelineItem.user.avatar"
            >
                <img :src="timelineItem.user.avatar" class="avatar">
            </v-avatar>

            <span
                v-else
                class="text--white"
            >{{ timelineItem.user | avatar }}</span>
        </template>


        <v-card v-if="!editing">
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-card-title class="subtitle-2 pt-1 pb-1">
                {{ timelineItem.user.full_name}}
                <v-spacer></v-spacer>
                <span class="text-right">
                     {{ timelineItem.created_at}}
                </span>

            </v-card-title>

            <v-card-text class="pt-1 pb-1" >
                <v-row>
                    <v-col
                        cols="12"
                        md="8"
                        class="pt-1 pb-1"
                        >
                        <span class="comment" v-html="timelineItem.body">

                        </span>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                        class="text-right pt-1 pb-1"
                    >

                        <template v-if="timelineItem.owner">
                            <button class="btn btn-ghost-secondary float-right" @click.prevent="destroy"><i class="fas fa-times"></i></button>
                            <button class="btn btn-ghost-secondary float-right  mr-2" @click.prevent="editing = true"><i class="fas fa-edit"></i></button>
                        </template>

                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
        <v-dialog
            v-model="imageDialog"
            max-width="800"
        >
            <v-card>

                <v-card-text>
                    <v-img :src="this.imageUrl" ></v-img>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="imageDialog = false"
                    >
                        Schliessen
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>

        <EditComment v-if="editing" :comment="timelineItem"></EditComment>

    </v-timeline-item>
</template>

<script>
import bus from "../../../bus";
import EditComment from "./EditComment";
import axios from "axios";

export default {
name: "TimelineItem",
    components: {EditComment},
    data() {
        return {
            editing: false,
            loading: false,
            imageDialog: false,
            imageUrl: '',
        }
    },
    props: [
        'timelineItem'
    ],
    filters: {
        avatar: function (value) {
            return value.first_name.charAt(0).toUpperCase() + value.last_name.charAt(0).toUpperCase()
        }
    },

    methods: {
          async destroy () {
                if (confirm('Wollen Sie diesen Kommentar wirklich löschen??')) {
                    this.loading = true
                    await axios.delete(`/api/comments/${this.timelineItem.id}`)
                    bus.$emit('comment:deleted', this.timelineItem)
                    this.loading = false
                }
            },
    },

    mounted () {
        const self = this
        bus.$on('comment:edit-cancelled', (comment) => {
            if (comment.id === this.timelineItem.id) {
                this.editing = false
            }
        })

        $('body').unbind().on('click','img',function(evt){
            self.imageUrl = evt.currentTarget.currentSrc
            self.imageDialog = true
        })
    }
}
</script>

<style>
.comment>p {
    margin-bottom: 2px;
}
.avatar {
    height: 50px; min-width: 50px; width: 50px;
}
</style>
