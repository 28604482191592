<template>
    <div>

    </div>
</template>
<script>
    import  { mapGetters, mapActions} from 'vuex';
    import Bus from "../../../../bus";
    export default {
        data (){
            return {
                datatableExists: true
            }
        },

        computed: {
            ...mapGetters({
                supplier: 'suppliers/supplier',
                suppliers: 'suppliers/suppliers'
           })
        },

        methods: {
            ...mapActions({
                fetchSupplier: 'suppliers/fetchSuppliers',
            }),
        },

        mounted:function(){
            const self = this

            this.datatableExists = true

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: 600,
                id: 'suppliers',
                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                columns: [
                    //{id: "id", header: ["ID", {content: "textFilter"}], width: 50, sort: "int"},
                    {
                        id: "name",
                        header: ["Name", {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "street",
                        header: ["Strasse", {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "zip",
                        header: ["Plz.", {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "city",
                        header: ["Ort", {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "active_label",
                        header: ["Aktiv", {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 150,
                        adjust: true,
                        sort: "string",
                        template: function (obj) {
                            return obj.active_label
                        }
                    },
                    {
                        id: "created_at",
                        map: "(date)#created_at#",
                        header: ["Erstellt am", {content: "dateRangeFilter"}],
                        adjust: true,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }
                    }
                ],
                on: {
                    onSelectChange: function(){
                        //self.fetchSupplier(this.getSelectedId(true))
                    },
                    onItemDblClick: function (id) {
                        self.$router.push({ name: 'suppliers.show', params: { supplierId: id} })
                    },
                    onAfterLoad: function () {
                        if (self.supplier && this.exists(self.supplier.id)) {
                            this.select(self.supplier.id)
                            this.showItem(self.supplier.id)
                        }
                    },
                }
            }),

            Bus.$on('supplier:created', function () {
                if(self.datatableExists) {
                    self.webixId.parse(self.suppliers)
                }
            });

            Bus.$on('supplier:updated', function () {
                if(self.datatableExists) {
                    self.webixId.parse(self.suppliers)
                }
            });
        },


        watch: {
            suppliers: {
                handler: function () {
                    this.$nextTick(() => {
                        this.webixId.parse(this.suppliers)
                    })
                },
                immediate: true
            },
        },

        destroyed:function(){
            this.datatableExists = false
            webix.$$(this.webixId).destructor();
        }


    }
</script>

<style >
    .webix_multilist .wxi-checkbox-blank, .webix_multilist .wxi-checkbox-marked {
        font-size: 24px;
        width: 24px;
        vertical-align: middle;
        margin-right: 4px;
        color: #1565C0 ;
    }
</style>
