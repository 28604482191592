<template>
    <div>
        <v-simple-table
            :height="tableHeight"
            fixed-header
        >
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">{{$lang('name')}}</th>
                    <th class="text-left">{{$lang('read')}}</th>
                    <th class="text-left">{{ $lang('edit') }}</th>
                    <th class="text-left">{{ $lang('create folders') }}</th>
                    <th class="text-left">{{ $lang('upload files') }}</th>
                    <th class="text-left">{{ $lang('edit files/folders') }}</th>
                    <th class="text-left">{{$lang('gantt dates')}}</th>
                    <th class="text-left">{{$lang('admin')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in users" :key="user.id">
                    <td>{{ user.full_name }}</td>
                    <td>
                        <v-switch
                        v-model="view_permission_user_ids"
                        :value="user.id"
                        ></v-switch>
                    </td>
                    <td>
                        <v-switch
                            v-model="edit_permission_user_ids"
                            :value="user.id"
                        ></v-switch>
                    </td>
                    <td>
                        <v-switch
                            v-model="create_folder_permission_user_ids"
                            :value="user.id"
                        ></v-switch>
                    </td>
                    <td>
                        <v-switch
                            v-model="upload_file_permission_user_ids"
                            :value="user.id"
                        ></v-switch>
                    </td>
                    <td>
                        <v-switch
                            v-model="edit_file_folder_permission_user_ids"
                            :value="user.id"
                        ></v-switch>
                    </td>
                    <td>
                        <v-switch
                            v-model="gantt_dates_permission_user_ids"
                            :value="user.id"
                        ></v-switch>
                    </td>
                    <td>
                        <v-switch
                            v-model="admin_permission_user_ids"
                            :disabled="me.id === user.id"
                            :value="user.id"
                        ></v-switch>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-divider>

        </v-divider>
        <v-btn
            class="mt-2"
            color="blue darken-1"
            text
            @click="submit"
        >{{ $lang('save') }}</v-btn>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "ProjectPermissions",

        data() {
            return {
                tableHeight: window.innerHeight - 300,
                view_permission_user_ids: [],
                edit_permission_user_ids: [],
                create_folder_permission_user_ids: [],
                upload_file_permission_user_ids: [],
                edit_file_folder_permission_user_ids: [],
                gantt_dates_permission_user_ids: [],
                admin_permission_user_ids: []
            }
        },

        computed: {
            ...mapGetters({
                project: 'projects/project',
                users: 'users/users',
                me: 'auth/user'
            }),

        },

        created() {
            this.view_permission_user_ids = this.project.view_permission_user_ids
            this.edit_permission_user_ids = this.project.edit_permission_user_ids
            this.create_folder_permission_user_ids = this.project.create_folder_permission_user_ids
            this.upload_file_permission_user_ids = this.project.upload_file_permission_user_ids
            this.edit_file_folder_permission_user_ids = this.project.edit_file_folder_permission_user_ids
            this.gantt_dates_permission_user_ids = this.project.gantt_dates_permission_user_ids
            this.admin_permission_user_ids = this.project.admin_permission_user_ids
        },

        methods: {
            ...mapActions({
                updatePermissions: 'projects/updatePermissions',
                fetchProject: 'projects/fetchProject',
            }),
            submit () {

                this.updatePermissions({
                    id: this.project.id,
                    payload: {
                        permissions: {
                            view_permission_user_ids: this.view_permission_user_ids,
                            edit_permission_user_ids: this.edit_permission_user_ids,
                            create_folder_permission_user_ids: this.create_folder_permission_user_ids,
                            upload_file_permission_user_ids: this.upload_file_permission_user_ids,
                            edit_file_folder_permission_user_ids: this.edit_file_folder_permission_user_ids,
                            gantt_dates_permission_user_ids: this.gantt_dates_permission_user_ids,
                            admin_permission_user_ids: this.admin_permission_user_ids
                        }
                    },
                }).then(() => {
                    const self = this
                    self.fetchProject(self.project.id)
                    Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('permissions successfully changed')})
                })
            },
        }

    }
</script>

<style scoped>

</style>
