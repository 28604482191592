import { Notifications } from '../views'

export default [
    {
        path: '/notifications',
        component: Notifications,
        name: 'notifications',
        meta: {
            guest: false,
            needsAuth: true
        }
    },


]
