<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ $lang('products') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="showCreateProductDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon>
                {{ $lang('product') }}
            </v-btn>
        </v-toolbar>

        <ProductsTable></ProductsTable>
        <CreateProductDialog></CreateProductDialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ProductsTable from "../components/ProductsTable";
    import CreateProductDialog from "../components/CreateProductDialog";
    export default {
        name: "Products",
        components: {
            ProductsTable,
            CreateProductDialog
        },

        methods: {
            ...mapActions({
                showCreateProductDialog: 'masterProducts/setCreateProductDialog',
            }),
        },
    }
</script>

<style scoped>

</style>
