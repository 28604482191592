<template>
    <v-dialog
        v-model="showCreateProductDialog"
        width="500"
        @click:outside="closeDialog"

    >
        <v-card>
            <v-card-title
                class="headline grey lighten-2"
                primary-title
            >
                {{ $lang('create product')}}
            </v-card-title>
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-text-field
                            v-model="form.sku"
                            :label="$lang('number')"
                            :error-messages="errors.sku"
                        ></v-text-field>
                        <v-select
                            v-model="form.responsible_id"
                            :items="activeUsers"
                            :label="$lang('responsible')"
                            item-text="full_name"
                            item-value="id"
                            :error-messages="errors.responsible_id"
                        ></v-select>
                        <v-text-field
                            v-model="form.current_manufacturing_costs"
                            :label="$lang('current costs')"
                            :error-messages="errors.current_manufacturing_costs"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.target_manufacturing_costs"
                            :label="$lang('target costs')"
                            :error-messages="errors.target_manufacturing_costs"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.production_amount_per_year"
                            :label="$lang('quantity p.a.')"
                            :error-messages="errors.production_amount_per_year"
                        ></v-text-field>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">{{ $lang('cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="loading">{{ $lang('save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Bus from "../../../../bus";
    export default {
        name: "CreateProductDialog",

        data() {
            return {
                loading: false,
                errors: [],
                form: {
                    name: '',
                    sku:'',
                    current_manufacturing_costs: '',
                    target_manufacturing_costs: '',
                    production_amount_per_year: '',
                    responsible_id: ''
                }
            }
        },

        computed: {
            ...mapGetters({
                activeUsers: 'users/activeUsers',
                showCreateProductDialog: 'masterProducts/showCreateProductDialog',
            }),
        },

        methods: {
            ...mapActions({
                store: 'masterProducts/store',
                fetchUsers: 'users/fetchUsers',
                actionShowCreateProductDialog: 'masterProducts/setCreateProductDialog',
            }),

            save () {
                this.loading = true
                this.errors = []
                this.store({
                    payload: this.form,
                    context: this
                }).then(() => {
                    this.loading = false
                    if(this.errors.length === 0) {
                        this.$refs.form.reset()
                        this.closeDialog()
                        Bus.$emit('product:update');
                    }
                })
            },

            closeDialog () {
                this.actionShowCreateProductDialog(false)
            }
        },

        created() {
            this.fetchUsers()
        }
    }
</script>

<style scoped>

</style>
