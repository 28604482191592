<template>
    <v-card>
        <v-card-text>
            <v-alert
                v-if="formAlert"
                dense
                text
                type="success"
            >
                <strong>{{ form.full_name}}</strong> erfolgreich geändert
            </v-alert>
            <v-form
                ref="form"
            >
                <v-text-field
                    v-model="form.first_name"
                    label="Vorname"
                    :error-messages="errors.first_name"
                    autofocus
                ></v-text-field>
                <v-text-field
                    v-model="form.last_name"
                    label="Nachname"
                    :error-messages="errors.last_name"
                    autofocus
                ></v-text-field>
                <v-radio-group v-model="form.is_email">
                    <v-radio
                        label="Email"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="Benutzername"
                        :value="0"
                    ></v-radio>
                </v-radio-group>
                <v-text-field
                    v-model="form.email"
                    :label="form.is_email ? 'Email' : 'Benutzername'"
                    :error-messages="errors.email"
                    :autofocus="false"
                ></v-text-field>
                <v-select
                    v-model="form.company_id"
                    :items="companies"
                    label="Firma"
                    item-text="name"
                    item-value="id"
                    :error-messages="errors.company_id"
                ></v-select>
                <v-select
                    v-model="form.lang"
                    :items="supportedLangs"
                    label="Sprache"
                    :error-messages="errors.lang"
                ></v-select>
                <v-switch
                    v-model="form.active"
                    :label="$lang('active')"
                ></v-switch>
            </v-form>

        </v-card-text>

        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
name: "EditUser",
    data() {
        return {
            formAlert: false,
            errors: [],
            form: {
                first_name: '',
                last_name:'',
                is_email: '',
                email: '',
                company_id: '',
                active: '',
                lang:''
            }
        }
    },

    computed: {
        ...mapGetters({
            user: 'adminUsers/user',
            companies: 'adminCompanies/companies',
            supportedLangs: 'lang/supportedLangs'
        }),
    },

    methods: {
        ...mapActions({
            update: 'adminUsers/update',
            fetchCompanies: 'adminCompanies/fetchCompanies',
        }),

        save () {
            this.errors = []
            this.update({
                payload: this.form,
                id: this.user.id,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.showAlert()
                }
            })
        },

        showAlert() {
            this.formAlert = true
            setTimeout(this.hideAlert, 3000)
        },

        hideAlert() {
            this.formAlert = false
        }
    },

    mounted() {
        this.fetchCompanies()
        this.form = this.user

    }
}
</script>

<style scoped>

</style>
