export const SET_SOLEX_DATA = (state, data) => {
    state.solex = data
}

export const SET_CREATE_SOLEX_DIALOG = (state, data) => {
    state.showCreateSolexDialog = data
}

export const SET_SOLEX_DIALOG = (state, data) => {
    state.showSolexDialog = data
}

export const SET_CREATE_SOLEX_ENDPOINT = (state, data) => {
    state.createSolexEndpoint = data
}

export const SET_SOLEX_DIALOG_WIDTH= (state, value) => {
    state.solexDialogWidth = value
}

export const SET_SELECTED_TAB = (state, value) => {
    state.selectedTab = value
}

export const SET_SOLEX_TO_ISSUE = (state, value) => {
    state.solexToIssue = value
}
