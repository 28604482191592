export const fetchObjects = ({ commit }, endpoint) => {
    return axios.get(endpoint).then((response) => {
        commit('SET_OBJECTS_DATA', response.data)
    })
}

export const storeFolder = ({ dispatch, state, commit }, { payload, endpoint, context }) => {
    return axios.post(endpoint, payload).then((response) => {
        dispatch('fetchObjects', `/api/fileManager/${response.data.id}`)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const updateObject = ({ dispatch, state, commit }, { endpoint, payload, context }) => {
    return axios.patch(endpoint, payload).then((response) => {

    }).catch((error) => {

    })
}

export const deleteObject = ({ dispatch, state, commit }, endpoint) => {
    return axios.delete(endpoint).then((response) => {

    }).catch((error) => {

    })
}

export const archiveObject = ({ dispatch, state, commit }, { endpoint, payload, context }) => {
    return axios.patch(endpoint, payload).then((response) => {

    }).catch((error) => {

    })
}

export const retrieveObject = ({ dispatch, state, commit }, { endpoint, payload, context }) => {
    return axios.patch(endpoint, payload).then((response) => {

    }).catch((error) => {

    })
}
