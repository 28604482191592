<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>

</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import * as router from "vue-router";
import Bus from "../../../bus";

export default {
    data() {
        return {
            loading: true,
            projectsData: '',
            enableSelect: false,
            defaultState: null,
            datatableExists: true,
        }
    },
    props: [
        'tableId'
    ],
    computed: {
        ...mapGetters({
            user: 'auth/user',
            project: 'projects/project',
            projects: 'projects/projects',
            activeFilters: 'tableFilters/activeFilters'
        }),

    },

    methods: {
        ...mapActions({
            fetchProject: 'projects/fetchProject',
            setProject: 'projects/setProject'
        }),

        parseData: function () {
            const projects = webix.copy(this.projects)
            this.loading = true
            $$(this.webixId).clearAll()
            $$(this.webixId).parse(projects)
        },
    },

    watch: {
        projects: {
            handler: function () {
                this.$nextTick(() => {
                    this.parseData()
                })
            },
            immediate: true
        },

        project: {
            handler: function (project) {
                this.enableSelect = false
                this.$nextTick(() => {
                    if (project && $$(this.webixId).exists(project.id)) {
                        $$(this.webixId).select(project.id)
                        $$(this.webixId).showItem(project.id)
                    }
                    this.enableSelect = true
                })

            },
            immediate: true
        },


        text: {
            handler: function (text) {

                var searchText = text.toString().toLowerCase();

                if (!searchText)
                    return $$("project").filter();

                $$("project").filter(function (obj) {

                    if (obj.name.toString().toLowerCase().indexOf(searchText) != -1) {
                        return true
                    }
                });
            }
        },
    },


    mounted: function () {
        const self = this

        this.datatableExists = true

        this.webixId = webix.ui({
            container: this.$el,
            $scope: this,
            view: "datatable",
            height: window.innerHeight - 200,
            id: self.tableId,
            headermenu:{
                width:250,
            },
            resizeColumn: true,
            select: "row",
            dragColumn: true,
            headerRowHeight: 40,
            drag: true,
            footer: false,
            columns: [
                {id: "project_number", header: [self.$lang('no.'), {content: "textFilter"}], width: 50, sort: "int"},

                {
                    id: "project_status_label",
                    header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        return obj.project_status_label
                    }
                },
                {
                    id: "project_type_label",
                    header: [self.$lang('type'), {content: "multiSelectFilter"}],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        return obj.project_type_label
                    }
                },
                {
                    id: "name_link",
                    header: [self.$lang('name'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string"
                },

                {
                    id: "total_potential",
                    header: [self.$lang('potential')  + " " + self.user.company.currency, {content: "textFilter"}],
                    footer: {content: "summColumn"},
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return self.$num(obj.total_potential, 0)
                    }
                },

                {
                    id: "total_invest",
                    header: [self.$lang('invest')  + " " + self.user.company.currency, {content: "textFilter"}],
                    footer: {content: "summColumn"},
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return self.$num(obj.total_invest, 0)
                    }
                },
                {
                    id: "roi",
                    header: [self.$lang('roi') , {content: "textFilter"}],
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return self.$num(obj.roi, 2)
                    }
                },

                {
                    id: "due_date_status_label",
                    header: ["", {content: "multiSelectFilter", suggest: {fitMaster: false, width: 70}}],
                    minWidth: 50,
                    adjust: false,
                    sort: "string",
                    template: function (obj) {
                        return obj.due_date_status_label
                    }
                },

                {
                    id: "start_date",
                    map: "(date)#start_date#",
                    header: [self.$lang('start'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.start_date)
                    }
                },

                {
                    id: "end_date",
                    map: "(date)#end_date#",
                    header: [self.$lang('end'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.end_date)
                    }
                },
                {
                    id: "issue_labels",
                    header: [self.$lang('issues') ,  {  inputConfig: { disabled: true}, content:"multiSelectFilter"}],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return obj.issue_labels
                    }
                },
                {
                    id: "project_leader_name",
                    header: [self.$lang('project leader'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 100}}],
                    adjust: false,
                    sort: "string"
                },

                {
                    id: "location_name",
                    header: [self.$lang('location'), {content: "multiSelectFilter"}],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return obj.location_name
                    }
                },

                {
                    id: "location_label",
                    header: [self.$lang('location'), {content: "multiSelectFilter"}],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return obj.location_label
                    }
                },

                {
                    id: "division_name",
                    header: [self.$lang('division'), {content: "multiSelectFilter"}],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return obj.division_name
                    }
                },

                {
                    id: "division_label",
                    header: [self.$lang('division'), {content: "multiSelectFilter"}],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return obj.division_label
                    }
                },

                {
                    id: "department_name",
                    header: [self.$lang('department'), {content: "multiSelectFilter"}],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return obj.department_name
                    }
                },

                {
                    id: "department_label",
                    header: [self.$lang('department'), {content: "multiSelectFilter"}],
                    minWidth: 150,
                    adjust: false,
                    sort: "string",
                    css: {'text-align': 'right'},
                    template: function (obj) {
                        return obj.department_label
                    }
                },

                {
                    id: "creator_name",
                    header: [self.$lang('creator'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 100}}],
                    adjust: false,
                    sort: "string"
                },
                {
                    id: "created_at",
                    map: "(date)#created_at#",
                    header: [self.$lang('created'), {content: "dateRangeFilter"}],
                    sort: "date",
                    adjust: false,
                    template: function (obj) {
                        return webix.i18n.parseFormatStr(obj.created_at)
                    }
                }
            ],

            onClick: {
                "name_link": function (event, row, target) {
                    self.$router.push({name: 'projects.show', params: {projectId: row.row}})
                }
            },

            on: {
                onSelectChange: function (id) {
                    if (self.enableSelect) {
                        self.setProject(this.getSelectedId(true)[0].id)
                    }
                },

                onItemDblClick: function (row) {
                    self.$router.push({name: 'projects.show', params: {projectId: row.row}})
                },

                onAfterLoad: function () {
                    self.loading = false

                    if(!self.defaultState) {
                        self.defaultState = this.getState()
                    }

                    if(self.tableId in self.activeFilters ) {
                        this.setState(JSON.parse(self.activeFilters[self.tableId].filter_data))
                    }
                },

            },

        })

        Bus.$on('filterTable:' + self.tableId, function (data) {
            self.filter = data
            if(self.datatableExists) {
                if(!self.defaultState) {
                    self.defaultState = $$(self.webixId).getState()
                }
                $$(self.webixId).setState(JSON.parse(data))
            }
        });

        Bus.$on('resetFilterTable:' + self.tableId, function () {
            if(self.datatableExists) {
                $$(self.webixId).setState(self.defaultState)
            }
        });

    },

    destroyed: function () {
        this.datatableExists = false
        webix.$$(this.webixId).destructor();
    }


}
</script>

<style>
.webix_multilist .wxi-checkbox-blank, .webix_multilist .wxi-checkbox-marked {
    font-size: 24px;
    width: 24px;
    vertical-align: middle;
    margin-right: 4px;
    color: #1565C0;
}
</style>
