export const SET_NOTIFICATIONS_DATA = (state, data) => {
    state.notifications = data
}

export const pushNotificationsData = (state, data) => {
    state.notifications.push(data)
}

export const UPDATE_NOTES_DATA = (state, data) => {
    Vue.set(state.notifications, state.notifications.findIndex(notifications => notifications.id === data.id), data)
}

export const setNotificationData = (state, data) => {
    state.notification = data
}

export const SET_NOTES_FILTER = (state, value) => {
    state.notificationsFilter = value
}
