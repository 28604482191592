<template>
<div>
    <v-card>
        <v-card-title>
            Kommentar bearbeiten
        </v-card-title>
        <v-card-text>
            <Editor  :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event"></Editor>
        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="patch">{{ $lang('save') }}</v-btn>
            <v-btn color="blue darken-1" text @click="cancel">{{ $lang('cancel') }}</v-btn>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
import Editor from "../../../components/Editor";
import axios from "axios";
import bus from "../../../bus";
export default {
name: "EditComment",
    components: {Editor},
    data() {
        return {
            errors: [],
            form: {
                body: '',
                tokens: [],
            },
        }
    },
    props: [
        'comment'
    ],

    methods: {
        async patch () {
            let comment = await axios.patch(`/api/comments/${this.comment.id}`, this.form)

            bus.$emit('comment:edited', comment.data.data)

            this.cancel()
        },
        cancel () {
            bus.$emit('comment:edit-cancelled', this.comment)
        }
    },
    mounted() {
        this.form.body = this.comment.body
    }
}
</script>

<style scoped>

</style>
