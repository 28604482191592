<template>
    <v-tabs >
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="edit">
            <EditCostCenter></EditCostCenter>
        </v-tab-item>

        <v-tab-item value="delete">
            <DeleteCostCenter ></DeleteCostCenter>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import EditCostCenter from "./EditCostCenter";
import DeleteCostCenter from "./DeleteCostCenter";
    export default {
        name: "CostCenterTab",

        components: {
            EditCostCenter,
            DeleteCostCenter
        },

        methods: {

        },

    }
</script>

<style scoped>

</style>
