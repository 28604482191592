import { Divisions, Division } from '../views'
import {Project} from "../../../projects/views";

export default [
    {
        path: '/masterData/divisions',
        component: Divisions,
        name: 'divisions',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/masterData/divisions/:divisionId/show',
        component: Division,
        name: 'divisions.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },
]

