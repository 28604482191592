import { KanbanExport} from '../views'

export default [
    {
        path: '/procurement/kanbanExport',
        component: KanbanExport,
        name: 'procurement.kanbanExport',
        meta: {
            guest: false,
            needsAuth: true
        }
    }
]

