<template>
    <div>

    </div>
</template>
<script>
    import  { mapGetters, mapActions} from 'vuex';
    import Bus from "../../../../bus";
    export default {

        data() {
            return {
                datatableExists: true,
            }
        },

        computed: {
            ...mapGetters({
                projectType: 'projectTypes/projectType',
                projectTypes: 'projectTypes/projectTypes'
            })
        },

        methods: {
            ...mapActions({
                fetchProjectType: 'projectTypes/fetchProjectTypes',
            }),
        },

        mounted:function(){
            const self = this

            this.datatableExists = true

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: 600,
                id: 'projectTypes',
                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                columns: [
                    {id: "name", header: [ self.$lang('name'),{ content:"textFilter" }],fillspace: true, minWidth: 200,  sort: "string"},
                    {id: "label", header: [self.$lang('label'), { content:"multiSelectFilter", suggest: {fitMaster: false, width: 200} }], minWidth: 150, adjust: true , sort: "string", template: function (obj){
                            return obj.label
                        }},
                    {id: "active_label", header: [self.$lang('active'), { content:"multiSelectFilter", suggest: {fitMaster: false, width: 200} }], minWidth: 150, adjust: true , sort: "string", template: function (obj){
                            return obj.active_label
                        }},
                    {id:"created_at", map:"(date)#created_at#", header:[self.$lang('created'), { content:"dateRangeFilter"}], sort: "date",  adjust: true , template: function (obj){
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }}
                ],
                on: {
                    onSelectChange: function(){
                        self.fetchProjectType(this.getSelectedId(true))
                    },
                    onItemDblClick: function (id) {
                        self.$router.push({ name: 'projectTypes.show', params: { projectTypeId: id} })
                    },
                    onAfterLoad: function () {
                        if (self.projectType && this.exists(self.projectType.id)) {
                            this.select(self.projectType.id)
                            this.showItem(self.projectType.id)
                        }
                    },
                },

            })

            Bus.$on('projectType:update', function () {
                if(self.datatableExists) {
                    self.webixId.parse(self.projectTypes)
                }
            });

        },

        watch: {
            projectTypes: {
                handler: function () {
                    this.$nextTick(() => {
                        this.webixId.parse(this.projectTypes)
                    })
                },
                immediate: true
            },
        },

        destroyed:function(){
            this.datatableExists = false
            webix.$$(this.webixId).destructor();
        }


    }
</script>

<style >
    .webix_multilist .wxi-checkbox-blank, .webix_multilist .wxi-checkbox-marked {
        font-size: 24px;
        width: 24px;
        vertical-align: middle;
        margin-right: 4px;
        color: #1565C0 ;
    }
</style>
