import { Dashboard } from '../views'

export default [
    {
        path: '/',
        component: Dashboard,
        name: 'dashboard',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/dashboard',
        component: Dashboard,
        name: 'dashboard',
        meta: {
            guest: false,
            needsAuth: true
        }
    },


]
