<template>
    <div id="editorContainer">
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>

        <vue-editor id="editor" ref="editor" v-model="content" :use-custom-image-handler="true" @image-added ="added" @image-removed ="deleted" :key="componentKey" :editor-toolbar="customToolbar" />

    </div>
</template>

<script>
    export default {
        name: "MobileEditor",
        data() {
            return {
                loading: false,
                componentKey: 0,
                tokens: [],
                customToolbar: [
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                    ["image"]
                ]
            }
        },
        props: [
            'body'
        ],

        computed: {
            content: {
                get(){
                    return this.body;
                },
                set(value){
                    this.$emit('update:body', value)
                }
            },
        },

        methods: {
            added: function (file, Editor, cursorLocation, resetUploader) {
                this.loading =true

                let formData = new FormData();
                formData.append("file", file);


                axios({
                    url: '/api/media/temporaryUploadMedia',
                    method: "POST",
                    data: formData
                })
                    .then(result => {
                        this.tokens.push(result.data.token)
                        let url = result.data.data[0].url; // Get url from response
                        Editor.insertEmbed(cursorLocation, "image", url);
                        resetUploader();
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err); //todo create error message
                    });

            },

            deleted: function (url) {
                console.log(url)

            },
            getBase64: function(file) {
                const self = this
                var reader = new FileReader();

                reader.readAsDataURL(file);

                reader.onload = function () {

                    axios.post('/api/media/temporaryUploadBase64Media',{
                        file : reader.result
                    }).then(function (response) {
                        self.content += `<img src=\"${response.data.data[0].url}\">`
                        self.tokens.push(response.data.token)
                        self.componentKey += 1;
                        self.loading = false
                    })
                };
            }
        },
        watch: {
            tokens: function (value) {
                this.$emit('update:tokens', value)
            }
        },
        mounted() {
            const self = this

            window.addEventListener("paste", function(thePasteEvent){
                self.loading = true

                var file = thePasteEvent.clipboardData.items[0].getAsFile();
                if(file !== null) {
                    self.getBase64(file);
                }
                else {
                    self.loading = false
                }
            }, false);
        }
    }
</script>

<style scoped>
.p {
    color: black
}

#editorContainer >>> .ql-snow.ql-toolbar button {
    height: 35px !important;
    width: 35px !important;
}

#editorContainer >>> .quillWrapper .ql-snow.ql-toolbar button svg {
    width: 35px !important;
    height: 35px !important;
}
</style>
