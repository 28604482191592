import Vue from 'vue'
import App from './components/App.vue'
import vuetify from './plugins/vuetify'

import router from "./router/index";
import store from './vuex'

window._ = require('lodash');
window.Vue = require('vue');

import _ from 'lodash'
Vue.prototype._ = _

window.moment = require('moment');

import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
window.axios = require('axios');
window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
window.axios.defaults.withCredentials = true
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
Vue.use(vueFilterPrettyBytes)

import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);



import IdleVue from "idle-vue";

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1200000,
  startAtIdle: false
});


Vue.mixin({
  methods: {
    $lang: function(name, replaceString = null, value=null) {
      if(window.translations[name]) {
        if(replaceString) {
          return _.replace(window.translations[name], replaceString, value);
        }
        else {
          return window.translations[name]
        }
      }
      return '*' + name
    }
  }
});

Vue.mixin({
  methods: {
    $companyPlan: function(plan) {
      return _.includes(window.companyPlans, plan)
    }
  }
});

Vue.mixin({
  methods: {
    $num: function(value, decimal) {
      return webix.Number.format(value, {
        groupSize: window.number_format.group_size,
        groupDelimiter: _.replace(window.number_format.group_delimiter, '&nbsp;', ' '),
        decimalSize : decimal,
        decimalDelimiter : _.replace(window.number_format.decimal_delimiter, '&nbsp;', ' '),
      });
    }
  }
});

Vue.prototype.$defaultUrl = "http://localhost:8000"


Vue.use(require('vue-pusher'), {
  api_key: '1595aaee765f801eaa6b',
  options: {
    cluster: 'eu',
    encrypted: true,
  }
});

Vue.config.productionTip = false


store.dispatch('auth/me').then(() => {
  store.dispatch('lang/fetchTranslations', 'EN').then(()=>{
    new Vue({
      vuetify,
      router: router,
      store: store,
      render: h => h(App)
    }).$mount('#app')
  })
})

