<template>
    <v-card>
        <v-alert
            v-if="alert"
            dense
            text
            type="success"
        >
            Berechtigungen für <strong>{{ user.full_name}}</strong> erfolgreich geändert
        </v-alert>
        <v-card-text>
            <v-col
                md="6"
                cols="12"
            >
                <v-select
                    :items="user.companies"
                    label="Firma"
                    v-model="company_id"
                    item-text="name"
                    item-value="id"
                    :error-messages="errors.company_id"
                ></v-select>

                <v-simple-table>
                    <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Berechtigung
                        </th>
                    </tr>
                    <tr
                        v-for="permission in companyPermissions"
                    >
                        <td>
                            {{ permission.name }}
                        </td>
                        <td>
                            <v-switch
                                v-model="permission_ids"
                                :value="permission.id"
                            ></v-switch>

                        </td>
                    </tr>
                    </thead>
                </v-simple-table>
            </v-col>

        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
name: "UserPermissions",
    data () {
        return {
            alert: false,
            permission_ids: [],
            company_id: '',
            errors: [],
        }
    },

    computed: {
        ...mapGetters({
            user: 'adminUsers/user',
            permissions: 'adminPermissions/permissions',
            companyPermissions: 'adminPermissions/companyPermissions',
            me: 'auth/user'
        }),
    },

    methods: {
        ...mapActions({
            fetchUser: 'adminUsers/fetchUser',
            fetchPermissions: 'adminPermissions/fetchPermissions',
            updatePermissions: 'adminUsers/updatePermissions',
            fetchCompanyPermissions: 'adminPermissions/fetchCompanyPermissions'
        }),

        save () {
            this.errors = []
            this.updatePermissions({
                payload: {
                    permissionIds : this.permission_ids,
                    company_id: this.company_id
                },
                id: this.user.id,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.showAlert()
                    this.fetchUser(this.user.id)
                }
            })
        },

        showAlert() {
            this.alert = true
            setTimeout(this.hideAlert, 3000)
        },

        hideAlert() {
            this.alert = false
        },

        getUserCompanyPermissions() {
            const self = this
            let permissions = _.filter(this.user.permissions, function(permission) { return permission.company_id === self.company_id; });
            this.permission_ids = _.map(permissions, 'id')
        }
    },

    mounted() {
        this.fetchPermissions()
    },

    watch :{
        company_id : function (id) {
            const self = this
            this.fetchCompanyPermissions(id).then(function (){
                self.fetchPermissions(self.user.id)
                self.getUserCompanyPermissions()
            })
        }
    }
}
</script>

<style scoped>

</style>
