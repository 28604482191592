<template>
    <div>

        <v-btn
            v-for="filter in filters"
            v-if="filter.header_button"
            :color="filter.color"
            :class="activeFilterId === filter.id ? 'elevation-5' : ''"
            outlined
            small
            class="mr-2"
            @click="filterDashboard(filter)"
        >
            {{ filter.name}}
        </v-btn>

        <v-btn
            color="primary"
            outlined
            small
            class="mr-2"
            @click="resetFilter"
        >
            {{$lang('reset')}}
        </v-btn>

        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    outlined
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                >
                    {{$lang('filter')}}
                </v-btn>
            </template>

            <v-list>
                <v-list-item-group
                    v-model="activeFilterId"
                    color="primary"
                >
                    <v-list-item
                        v-for="filter in filters"
                        @click="filterDashboard(filter)"
                        :value="filter.id"
                    >
                        <v-list-item-title>{{filter.name}}</v-list-item-title>
                        <v-list-item-action
                            v-if="filter.default"
                        >
                            <v-list-item-action-text>
                                <v-icon
                                    color="green lighten-3"
                                >
                                    fas fa-check
                                </v-icon>
                            </v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-item-group>
                <v-divider></v-divider>
                <v-list-item
                    @click="createFilter"
                >
                    <v-list-item-title>{{$lang('create filter')}}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="activeFilterId"
                    @click="editFilter"
                >
                    <v-list-item-title>{{$lang('edit filter')}}</v-list-item-title>
                </v-list-item>

                <v-list-item
                    v-if="activeFilterId"
                    @click="deleteFilter"
                >
                    <v-list-item-title>{{$lang('delete filter')}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog
            v-model="createFilterDialog"
            width="400"
        >
            <v-card>
                <v-card-title class="white">
                    {{ $lang('create filter')}}
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        outlined
                        color="primary"
                        @click="createFilterDialog = !createFilterDialog"
                    >
                        <v-icon >fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                        >
                        </v-text-field>

                        <v-color-picker class="ml-0"
                                        v-model="form.color"
                                        :label="$lang('color')"
                                        show-swatches
                                        hide-canvas
                                        hide-inputs
                                        width="400"
                        >
                        </v-color-picker>

                        <v-switch
                            v-model="form.default"
                            :label="$lang('default')"
                        ></v-switch>

                        <v-switch
                            v-model="form.header_button"
                            :label="$lang('button')"
                        ></v-switch>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="createFilterDialog = !createFilterDialog">{{ $lang('cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="editFilterDialog"
            width="400"
        >
            <v-card>
                <v-card-title class="white">
                    {{ $lang('edit filter')}}
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        outlined
                        color="primary"
                        @click="editFilterDialog = !editFilterDialog"
                    >
                        <v-icon >fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-form
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                        >
                        </v-text-field>

                        <v-color-picker class="ml-0"
                                        v-model="form.color"
                                        :label="$lang('color')"
                                        show-swatches
                                        hide-canvas
                                        hide-inputs
                                        width="400"
                        >
                        </v-color-picker>

                        <v-switch
                            v-model="form.default"
                            :label="$lang('default')"
                        ></v-switch>

                        <v-switch
                            v-model="form.header_button"
                            :label="$lang('button')"
                        ></v-switch>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="editFilterDialog = !editFilterDialog">{{ $lang('cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="updateFilter">{{ $lang('update') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="activeFilter"
            v-model="deleteFilterDialog"
            width="400"
        >
            <v-card>
                <v-card-title class="white">
                    {{ $lang('delete filter')}}
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        outlined
                        color="primary"
                        @click="deleteFilterDialog = !deleteFilterDialog"
                    >
                        <v-icon >fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <strong>{{activeFilter.name}}</strong>
                    <br>
                    {{ $lang('do you really want to delete this filter?')}}

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="deleteFilterDialog = !deleteFilterDialog">{{ $lang('cancel') }}</v-btn>
                    <v-btn color="red darken-1" text @click="destroyFilter">{{ $lang('yes, please delete!') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";

export default {
    name: "DashboardFilterDropDown",

    data () {
        return {
            errors: [],
            createFilterDialog: false,
            editFilterDialog: false,
            deleteFilterDialog: false,
            activeFilterId: null,
            activeFilter: {
                id: '',
                name: ''

            },
            form: {
                name: '',
                color: '',
                default:0,
                header_button: 0,
            },
        }
    },

    props: {
        dashboardId: String
    },

    computed: {
        ...mapGetters({
            dashboardFilters: 'controlling/dashboardFilters',
            activeFilters: 'controlling/activeFilters',
            filtering: 'controlling/filtering',
            currentFilter: 'controlling/currentFilter',
            defaultFilterData: 'controlling/defaultFilterData'
        }),

        filters: function () {
            if (this.dashboardFilters) {
                if (this.dashboardFilters[this.dashboardId]) {
                    return this.dashboardFilters[this.dashboardId]
                }
            }
        },
    },

    methods: {
        ...mapActions({
            fetchDashboardFilters: 'controlling/fetchDashboardFilters',
            setActiveFilter: 'controlling/setActiveFilter',
            store: 'controlling/store',
            update: 'controlling/update',
            destroy: 'controlling/destroy',
            unsetActiveFilter: 'controlling/unsetActiveFilter',
            setFiltering: 'controlling/setFiltering',
            setCurrentFilter: 'controlling/setCurrentFilter'
        }),


        createFilter () {
            this.form = {
                name: '',
                color: '',
                default:'',
                header_button: '',
            },
                this.createFilterDialog = true
        },

        editFilter () {
            if(this.activeFilter) {
                this.form = this.activeFilter
                this.editFilterDialog = true
            }
        },

        deleteFilter () {
            if(this.activeFilter) {
                this.deleteFilterDialog = true
            }
        },

        save() {
            const self = this
            this.loading = true
            this.errors = []
            this.store({
                payload: {
                    name: this.form.name,
                    color: this.form.color,
                    default: this.form.default,
                    header_button: this.form.header_button,
                    dashboard_id: this.dashboardId,
                    filter_data: this.currentFilter
                },
                context: this
            }).then((filter) => {
                this.loading = false
                if (this.errors.length === 0) {

                    this.activeFilter = filter
                    this.activeFilterId = filter.id
                    this.$refs.form.reset()
                    this.createFilterDialog = false
                    Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('filter successfully saved') });
                }
            })
        },

        updateFilter() {
            const self = this
            this.loading = true
            this.errors = []
            this.update({
                filterId: this.activeFilter.id,
                payload: {
                    name: this.form.name,
                    color: this.form.color,
                    default: this.form.default,
                    header_button: this.form.header_button,
                    dashboard_id: this.dashboardId,
                    filter_data: this.currentFilter
                },
                context: this
            }).then(() => {
                this.loading = false
                if (this.errors.length === 0) {
                    this.editFilterDialog = false
                    Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('filter successfully changed') });
                }
            })
        },

        destroyFilter() {
            const self = this
            this.loading = true
            this.errors = []
            this.destroy({
                filterId: this.activeFilter.id,
                context: this
            }).then(() => {
                this.loading = false
                if (this.errors.length === 0) {
                    this.deleteFilterDialog = false
                    Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('filter successfully deleted') });
                    self.resetFilter()
                }
            })
        },

        setDefaultFilter() {
            const self = this
            if(this.dashboardId in this.dashboardFilters) {
                this.dashboardFilters[this.dashboardId].filter((value, index) => {
                    if (value.default) {
                        self.setCurrentFilter(webix.copy(JSON.parse(value.filter_data)))
                        self.activeFilter = value
                        self.activeFilterId = value.id
                    }
                })
            }
        },

        filterDashboard (filter) {
            this.setFiltering(true)
            const self = this

            this.activeFilter = filter
            this.activeFilterId = filter.id
            self.setCurrentFilter(JSON.parse(filter.filter_data))

            this.form = filter
        },

        resetFilter () {
            this.setFiltering(true)
            const self = this
            self.setCurrentFilter(webix.copy(self.defaultFilterData))
            this.activeFilter = null
            this.activeFilterId = null
        },
    },
    mounted() {
        const self = this

        this.setDefaultFilter()
    },


}
</script>

<style scoped>

</style>
