<template>
    <v-tabs>
        <v-tab href="#info">Info</v-tab>
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#plans">{{$lang('plans')}}</v-tab>

        <v-tab-item value="info" >
            <CompanyInfo></CompanyInfo>
        </v-tab-item>

        <v-tab-item value="edit" >
            <EditCompany></EditCompany>
        </v-tab-item>

        <v-tab-item value="plans" >
            <CompanyPlans></CompanyPlans>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import CompanyInfo from "./CompanyInfo";
import EditCompany from "./EditCompany";
import CompanyPlans from "./CompanyPlans";

export default {
    name: "CompanyTabs",
    components: {CompanyPlans, EditCompany, CompanyInfo}
}
</script>

<style scoped>

</style>
