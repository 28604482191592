<template>
    <div v-if="user">
        <v-toolbar flat color="white" dense >
                <v-btn
                    small
                    text
                    color="primary"
                    to="/companyAdmin/users"
                    class="mr-2"
                >
                    <v-icon class="mr-1">fas fa-list</v-icon>
                </v-btn>
            <v-toolbar-title >
                {{ user.full_name }}
            </v-toolbar-title>


        </v-toolbar>

        <UserTabs></UserTabs>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserTabs from "../components/UserTabs";

export default {
name: "User",
    components: {UserTabs},
    props: [
        'userId'
    ],

    computed: {
        ...mapGetters({
            user: 'companyAdminUsers/user',
        }),
    },

    methods: {
        ...mapActions({
            fetchUser: 'companyAdminUsers/fetchUser',
        }),
    },

    mounted() {
        this.fetchUser(this.userId)
    }
}
</script>

<style scoped>

</style>
