<template>
    <v-row justify="center">
        <v-dialog
            persistent
            :value="showCreateSolexDialog"
            :width="solexDialogWidth"
            @click:outside="confirmCloseDialog = true"
            @keydown.esc="confirmCloseDialog = true"
            scrollable
        >
            <v-card>

                <v-card-title>
                    <span class="headline">{{ $lang('create solex') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="solexDialogWidth > 600"
                        small
                        outlined
                        color="primary"
                        class="mr-2"
                        @click="setSolexDialogWidth(430)"
                    >
                        {{ $lang('small') }}
                    </v-btn>
                    <v-btn
                        v-else
                        small
                        outlined
                        color="primary"
                        class="mr-2"
                        @click="setSolexDialogWidth(1200)"
                    >
                        {{ $lang('wide') }}
                    </v-btn>
                    <v-btn
                        small
                        outlined
                        color="primary"
                        @click="closeDialog"
                    >
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-card-text>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            :label="$lang('title')"
                            v-model="form.title"
                            required
                            autofocus
                            :error-messages="errors.title"
                        >
                        </v-text-field>

                        <h4>{{ $lang('why') }}</h4>
                        <div
                            :style="errors.why_body ? 'border: 2px red solid' : ''"
                            :class="errors.why_body ? '' : 'mb-5'"
                        >
                            <Editor :body="form.why_body" v-on:update:body="form.why_body = $event"
                                    v-on:update:tokens="form.why_tokens = $event"></Editor>
                        </div>

                        <template
                            v-if="errors.why_body"
                            class="mb-5"
                        >
                            <span class="v-messages__message error--text v-messages">
                                {{ errors.why_body[0] }}
                            </span>
                        </template>

                        <h4>{{ $lang('what for') }}</h4>
                        <div
                            :style="errors.what_for_body ? 'border: 2px red solid' : ''"
                            :class="errors.what_for_body ? '' : 'mb-5'"
                        >
                            <Editor :body="form.what_for_body" v-on:update:body="form.what_for_body = $event"
                                    v-on:update:tokens="form.what_for_tokens = $event"></Editor>
                        </div>

                        <template
                            v-if="errors.what_for_body"
                            class="mb-5"
                        >
                            <span class="v-messages__message error--text v-messages">
                                {{ errors.what_for_body[0] }}
                            </span>
                        </template>

                        <h4>{{ $lang('what') }}</h4>
                        <div
                            :style="errors.what_body ? 'border: 2px red solid' : ''"
                            :class="errors.what_body ? '' : 'mb-5'"
                        >
                            <Editor :body="form.what_body" v-on:update:body="form.what_body = $event"
                                    v-on:update:tokens="form.what_tokens = $event"></Editor>
                        </div>

                        <template
                            v-if="errors.what_body"
                            class="mb-5"
                        >
                            <span class="v-messages__message error--text v-messages">
                                {{ errors.what_body[0] }}
                            </span>
                        </template>

                        <v-simple-table
                            class="mt-2"
                        >
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        {{ $lang('step') }}
                                    </th>

                                    <th
                                        class="text-right"
                                        width="100px"
                                    >
                                        <v-btn
                                            small
                                            outlined
                                            color="primary"
                                            @click="addStep"
                                        >
                                            <v-icon class="mr-1">fas fa-plus</v-icon>
                                            {{ $lang('step') }}
                                        </v-btn>

                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="(step, index) in form.steps"
                                    :key="step.index"
                                    :id="`row_${index}`"
                                >
                                    <td

                                    >
                                        <v-text-field
                                            v-model="form.steps[index].title"
                                            :error-messages="errors[`steps.${[index]}.title`] ? errors[`steps.${[index]}.title`][0]: null"
                                            autofocus
                                        >

                                        </v-text-field>
                                    </td>
                                    <td
                                        class="text-right"
                                    >
                                        <v-btn
                                            text
                                            color="error"
                                            @click="deleteStep(index)"
                                        >
                                            <v-icon>
                                                fas fa-trash-alt
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-divider></v-divider>
                        <v-row
                            class="mt-2"
                        >
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    v-model="form.assignee_id"
                                    :items="activeUsers"
                                    :label="$lang('responsible')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.assignee_id = null"
                                    item-text="full_name"
                                    item-value="id"
                                    :error-messages="errors.assignee_id"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    v-model="form.user_ids"
                                    :items="activeUsers"
                                    :label="$lang('team')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.user_ids = []"
                                    item-text="full_name"
                                    item-value="id"
                                    multiple
                                    :error-messages="errors.user_ids"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" value="!loading" text @click="submit">{{ $lang('save') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="closeDialog">{{ $lang('close') }}</v-btn>
                </v-card-actions>
            </v-card>
            <v-dialog
                v-model="confirmCloseDialog"
                width="800"
            >
                <v-card>
                    <v-card-title
                        primary-title
                        class="justify-center"
                    >
                        {{ $lang('close dialog') }}
                    </v-card-title>
                    <v-card-text
                        class="text-center"
                    >
                        <strong>
                            {{ $lang('do you really want to close without saving?') }}
                        </strong>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="primary"
                            text
                            @click="saveAndClose"
                        >
                            {{ $lang('save and close') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="secondary"
                            text
                            @click="closeWithoutSaving"
                        >
                            {{ $lang('close without saving') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="confirmCloseDialog = false"
                        >
                            {{ $lang('cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
    </v-row>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Editor from "../../../../components/Editor";
import Bus from "../../../../bus";

export default {
    name: "CreateSolexDialog",
    components: {
        Editor: Editor
    },
    props: [
        'endpoint'
    ],
    data() {
        return {
            loading: false,
            modal: false,
            errors: [],
            confirmCloseDialog: false,

            form: {
                title: '',
                why_body: ``,
                what_for_body: '',
                what_body: '',
                why_tokens: [],
                what_for_tokens: [],
                what_tokens: [],
                steps: []
            },

        }
    },

    computed: {
        ...mapGetters({
            users: 'users/users',
            showCreateSolexDialog: 'solexes/showCreateSolexDialog',
            createSolexEndpoint: 'solexes/createSolexEndpoint',
            activeUsers: 'users/activeUsers',
            me: 'auth/user',
            solexDialogWidth: 'solexes/solexDialogWidth',
            project: 'projects/project',
        }),

    },

    methods: {
        ...mapActions({
            actionShowCreateSolexDialog: 'solexes/setCreateSolexDialog',
            setSolexDialogWidth: 'solexes/setSolexDialogWidth',
            store: 'solexes/store'

        }),

        async addStep() {
            const self = this
            await this.addRow().then(function () {
                let numRows = self.form.steps.length

                var element = document.getElementById(`row_${numRows - 1}`);

                element.scrollIntoView()
            })
        },

        addRow() {
            return new Promise(resolve => {
                this.form.steps.push({
                    title: null,
                })
                resolve('resolved');

            });
        },

        deleteStep(index) {
            if (confirm(this.$lang('do you really want to delete this step?'))) {
                if (this.form.steps.splice(index, 1)) {
                    Bus.$emit('showAlert', {color: 'success', 'message': this.$lang('step successfully deleted')})
                }
            }
        },


        submit() {
            const self = this
            this.loading = true
            this.errors = []
            this.form.tokens = []
            this.store({
                payload: this.form,
                context: this,
            }).then(() => {
                this.loading = false
                if (this.errors.length === 0) {
                    this.dialog = false
                    this.form.body = ''
                    Bus.$emit('solex:created')
                    this.actionShowCreateSolexDialog({value: false, endpoint: null}).then(function () {
                        Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('solex successfully created')});
                    })
                }
            })
        },

        saveAndClose() {
            this.confirmCloseDialog = false
            this.submit()
        },

        closeWithoutSaving() {
            this.$refs.form.reset()
            this.errors = []
            this.closeDialog()
        },

        closeDialog() {
            this.actionShowCreateSolexDialog({value: false, endpoint: null})
        },

    },

    mounted() {
        const self = this

    },

    watch: {},
}
</script>

<style scoped>

</style>
