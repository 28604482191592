<template>
    <v-tabs>
        <v-tab href="#langDe">{{ $lang('language')  }} de</v-tab>

        <v-tab href="#langEn">{{ $lang('language')  }} en</v-tab>

        <v-tab href="#langPl">{{ $lang('language')  }} pl</v-tab>

        <v-tab href="#langRo">{{ $lang('language')  }} ro</v-tab>

        <v-tab href="#hgSettings">{{ $lang('HG')  }} {{ $lang('settings')  }}</v-tab>

        <v-tab-item value="langDe" >
            <company-lang local="de"></company-lang>
        </v-tab-item>

        <v-tab-item value="langEn" >
            <company-lang local="en"></company-lang>
        </v-tab-item>

        <v-tab-item value="langPl" >
            <company-lang local="pl"></company-lang>
        </v-tab-item>

        <v-tab-item value="langRo" >
            <company-lang local="ro"></company-lang>
        </v-tab-item>

        <v-tab-item value="hgSettings" >
            <h-g-settings></h-g-settings>
        </v-tab-item>
    </v-tabs>
</template>

<script>


import CompanyLang from "./CompanyLang";
import HGSettings from "./HGSettings";
export default {
    name: "CompanyTabs",
    components: {HGSettings, CompanyLang}
}
</script>

<style scoped>

</style>
