<template>
    <v-menu
        v-if="event "
        v-model="showMenu"
        :position-x="event.clientX"
        :position-y="event.clientY"
        absolute
        :close-on-click="true"
    >
        <v-list>
            <v-list-item
                color="primary"
                v-if="task.object_type === 'project'"
                @click="showCreateIssueDialog({value: true, endpoint: `/api/projects/${task.id}/issues`})"
            >
                <v-list-item-title
                >
                    <v-icon class="mr-1">fas fa-plus</v-icon>
                    {{ $lang('issue') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="task.object_type === 'todo'"
                @click="showCreateTodoDialog({value: true, endpoint: `/api/todos/${task.id}/todos`})"
            >
                <v-list-item-title>
                    <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('todo')}}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="task.object_type === 'issue'"
                @click="showCreateTodoDialog({value: true, endpoint: `/api/issues/${task.id}/todos`})"
            >
                <v-list-item-title>
                    <v-icon class="mr-1">fas fa-plus</v-icon>
                    {{ $lang('todo') }}
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-if="task.object_type === 'issue'"
                @click="showCreatePotentialDialog({value: true, endpoint: `/api/issues/${task.id}/potentials`})"
            >
                <v-list-item-title>
                    <v-icon class="mr-1">fas fa-plus</v-icon>
                    {{ $lang('potential') }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
name: "TaskContextMenu",
    data: () => ({
        showMenu: false
    }),

    computed: {
        ...mapGetters({
            showTaskContextMenu: 'projects/showTaskContextMenu',
            event: 'projects/event',
            task: 'projects/task'

        }),
    },

    methods: {
        ...mapActions({
            setShowTaskContextMenu: 'projects/setShowTaskContextMenu',
            showCreateIssueDialog: 'issues/setCreateIssueDialog',
            showCreateTodoDialog: 'todos/setCreateTodoDialog',
            showCreatePotentialDialog: 'potentials/setCreatePotentialDialog',
        }),

    },

    watch: {
        showTaskContextMenu: function (value) {
            if (value) {
                this.showMenu = true
            }

        },

        showMenu: function (value) {
            if (!value) {
                this.setShowTaskContextMenu()
            }
        }
    },
}
</script>

<style scoped>

</style>
