import { render, staticRenderFns } from "./Supplier.vue?vue&type=template&id=d9b464f8&scoped=true&"
import script from "./Supplier.vue?vue&type=script&lang=js&"
export * from "./Supplier.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.79.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9b464f8",
  null
  
)

export default component.exports