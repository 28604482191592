<template>
    <v-timeline-item
        fill-dot
        color="primary"
        large

    >
        <template v-slot:icon>
            <v-avatar
                size="50px"
                item
                v-if="user.avatar"
            >
                <img :src="user.avatar" class="avatar">
            </v-avatar>

            <span
                v-else
                class="text--white"
            >{{ avatar}}</span>
        </template>
        <v-card
            v-if="!createCommentForm"
            id="createComment"
        >
            <v-card-title class="subtitle-2">
                Erstellen Sie einen neuen Kommentar
                <v-spacer></v-spacer>
                <v-btn
                    small
                    outlined
                    color="primary"
                    class=""
                    depressed
                    @click="createComment"
                >
                    Kommentar erstellen
                </v-btn>
            </v-card-title>

        </v-card>

        <v-card
            v-else
        >
            <v-card-title >
                Neuen Kommentar erstellen
            </v-card-title>
            <v-card-text>
                <Editor  :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event"></Editor>
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="submit">{{ $lang('save') }}</v-btn>
                <v-btn color="blue darken-1" text @click="createComment = !createComment">Schliessen</v-btn>
            </v-card-actions>
        </v-card>
    </v-timeline-item>
</template>

<script>
import {mapGetters} from "vuex";
import Editor from "../../../components/Editor";
import axios from "axios";
import bus from "../../../bus";
import VueScrollTo from "vue-scrollto";

export default {
name: "NewComment",
    components: {Editor},
    props: [
        'endpoint'
    ],
    data() {
        return {
            createCommentForm: false,
            errors: [],
            form: {
                body: '',
                tokens: [],
            },
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        avatar: function () {
            return this.user.first_name.charAt(0).toUpperCase() + this.user.last_name.charAt(0).toUpperCase()
        }
    },
    methods: {
        async submit () {
            let comment = await axios.post(`${this.endpoint}/comments`, this.form)
            bus.$emit('comment:stored', comment.data.data)
            this.createComment = false
            this.form.body = '',
            this.tokens = []
        },

        createComment () {
            this.createCommentForm = true
            VueScrollTo.scrollTo('#createComment', 500)
        }
    }
}
</script>

<style scoped>
.avatar {
    height: 50px; min-width: 50px; width: 50px;
}
</style>
