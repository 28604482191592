export const toggleControllingSelectFilter = ({state, commit }) => {
    commit('SET_SHOW_CONTROLLING_SELECT_FILTER', !state.showControllingSelectFilter)
}

export const setDashboardIssuesData = ({ commit }, data) => {
    commit('SET_DASHBOARD_ISSUES_DATA', data)
}

export const fetchDashboardFilters = ({ commit }) => {
    return axios.get('/api/controlling/dashboardFilters').then((response) => {
        commit('SET_DASHBOARD_FILTERS_DATA', response.data)
    })
}

export const setActiveFilter = ({ commit }, data) => {
    commit('SET_ACTIVE_FILTER_DATA', data)
}

export const unsetActiveFilter = ({ state, commit }, dashboardId) => {
    if(state.activeFilters[dashboardId]) {
        commit('UNSET_ACTIVE_FILTER_DATA', dashboardId)
    }
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/controlling/dashboardFilters', payload).then((response) => {
        commit('SET_ACTIVE_FILTER_DATA', response.data)
        dispatch('fetchDashboardFilters')
        return response.data
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { filterId, payload, context }) => {
    return axios.patch(`/api/controlling/dashboardFilters/${filterId}`, payload).then((response) => {
        dispatch('fetchDashboardFilters')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, { filterId, payload, context }) => {
    return axios.delete(`/api/controlling/dashboardFilters/${filterId}`, payload).then((response) => {
        dispatch('fetchDashboardFilters')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const setFiltering = ({ dispatch, state, commit }, value) => {
    commit('SET_FILTERING', value)
}


export const setCurrentFilter = ({ commit }, data) => {
    commit('SET_CURRENT_FILTER_DATA', data)
}
