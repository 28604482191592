export const setLocationsData = (state, data) => {
    state.locations = data
}

export const setLocationData = (state, data) => {
    state.location = data
}

export const addLocation = (state, data) => {
    state.locations.push(data)
}

export const updateLocationData = (state, data) => {
    Object.assign(state.locations[data.index], data.data)
}

export const removeLocation = (state, item) => {
    const index = _.indexOf(state.locations, item)
    state.locations.splice(index, 1)
}

export const setCreateLocationDialog = (state, data) => {
    state.showCreateLocationDialog = data
}
