import { Users, User} from '../views'
import {Project} from "../../../projects/views";


export default [
    {
        path: '/admin/users',
        component: Users,
        name: 'admin.users',
        meta: {
            guest: false,
            needsAuth: true,
            role: 'Admin'
        }
    },
    {
        path: '/admin/users/:userId/show',
        component: User,
        name: 'admin.users.show',
        meta: {
            guest: false,
            needsAuth: true,
            role: 'Admin'
        },
        props: true
    }

]

