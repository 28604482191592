import { IssueTags, IssueTag } from '../views'
import {Project} from "../../../projects/views";

export default [
    {
        path: '/masterData/issueTags',
        component: IssueTags,
        name: 'issueTags',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/masterData/issueTags/:issueTagId/show',
        component: IssueTag,
        name: 'issueTags.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },
]

