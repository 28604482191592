<template>
<div>
    <v-toolbar flat color="white" >
        <v-toolbar-title >
            {{ $lang('notifications') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
                <v-btn
                    v-if="readNotifications.length"
                    class="mr-2"
                    small
                    outlined
                    color="primary"
                    @click="confirmDeleteDialog = true"
                >
                    {{$lang('delete notifications')}}
                </v-btn>
<!--        <v-btn-->
<!--            class="mr-2"-->
<!--            small-->
<!--            :outlined="notificationsFilter !== 'all'"-->
<!--            color="primary"-->
<!--            @click="setNotificationFilter('all')"-->
<!--        >-->
<!--            Alle-->
<!--        </v-btn>-->
<!--        <v-btn-->
<!--            class="mr-2"-->
<!--            small-->
<!--            :outlined="notificationsFilter !== 'open'"-->
<!--            color="primary"-->
<!--            @click="setNotificationFilter('open')"-->
<!--        >-->
<!--            Offen-->
<!--        </v-btn>-->
<!--        <v-btn-->
<!--            class="mr-2"-->
<!--            small-->
<!--            :outlined="notificationsFilter !== 'closed'"-->
<!--            color="primary"-->
<!--            @click="setNotificationFilter('closed')"-->
<!--        >-->
<!--            Geschlossen-->
<!--        </v-btn>-->


    </v-toolbar>

    <v-dialog
        v-model="confirmDeleteDialog"
        width="600"
    >
        <v-card>
            <v-card-title>
                <strong>
                    {{ $lang('delete read notifications') }}
                </strong>
            </v-card-title>


            <v-card-title>
                {{ $lang('do you really want to delete all read notifications?') }}
            </v-card-title>
            <v-card-text>
                <v-switch
                    v-model="showConfirmDeleteNotification"
                    :label="$lang('yes, please delete!')"
                ></v-switch>
            </v-card-text>


            <v-card-actions>
                <v-btn
                    v-if="showConfirmDeleteNotification"
                    color="error"
                    @click="deleteNotifications"
                >
                    {{ $lang('delete') }}
                </v-btn>
            </v-card-actions>


        </v-card>
    </v-dialog>

    <NotificationsTable></NotificationsTable>

</div>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import NotificationsTable from "../components/NotificationsTable";
    import Bus from "../../../bus";


    export default {
        name: "Notifications",
        components: {NotificationsTable},
        data() {
            return {
                messages: [],
                readNotifications: [],
                confirmDeleteDialog: false,
                showConfirmDeleteNotification: false
            }
        },
        computed: {
            ...mapGetters({
                notifications: 'notifications/notifications',
                notificationsFilter: 'notifications/notificationsFilter',
            })
        },
        created() {
            const self = this
            this.readNotifications = _.filter(this.notifications, function(notification) {
                return notification.read_at
            })
        },
        methods: {
            ...mapActions({
                deleteReadNotifications: 'notifications/deleteReadNotifications',
                setNotificationFilter: 'notifications/setNotificationsFilter'
            }),

            deleteNotifications ()  {
                const self = this
                this.deleteReadNotifications().then(function (){
                    self.confirmDeleteDialog = false
                    self.showConfirmDeleteNotification = false
                    Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('notifications successfully deleted')});
                })
            }
        },

        watch: {
            notifications: function () {
                this.readNotifications = _.filter(this.notifications, function(notification) {
                    return notification.read_at
                })
            },


        },
    }
</script>

<style scoped>

</style>
