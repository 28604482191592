<template>
    <v-container fluid>
        <v-row>
            <v-col
            class="justify-center text-center ">
                <h1
                >
                    7APP.CH
                </h1>
            </v-col>
        </v-row>
        <v-row>
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-col cols="12">

                <v-row
                    align="center"
                    justify="center"
                >
                    <v-card
                        outlined
                        tile
                        width="600px"
                    >
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-card-title>
                                {{ $lang('login') }}

                            </v-card-title>
                            <v-card-text>
                                <v-alert
                                    v-if="loginError"
                                    dense
                                    type="error"
                                >
                                    {{loginError}}
                                </v-alert>

                                <v-alert
                                    v-if="showLogoutMessage"
                                    dense
                                    type="info"
                                >
                                    {{ $lang('you have been logged out by the system. please log in again.')}}
                                </v-alert>

                                <v-text-field
                                    id="email"
                                    v-model="form.email"
                                    :rules="[v => !!v || $lang('email address is required')]"
                                    :label="$lang('email')"
                                    placeholder=" "
                                    autofocus
                                ></v-text-field>
                                <v-text-field
                                    id="password"
                                    v-model="form.password"
                                    :rules="[v => !!v || $lang('password is required')]"
                                    type="password"
                                    :label="$lang('password')"
                                    placeholder=" "
                                >
                                </v-text-field>
                            </v-card-text>
                            <v-card-actions class="text-right">
                                <v-btn color="blue darken-1" text @click="submit">
                                    {{ $lang('login') }}
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-container>


</template>

<script>
import {mapActions, mapGetters} from 'vuex'
    import Bus from "../../../bus";

    export default {
        data () {
            return {
                loading: false,
                valid: false,
                language: 'DE',
                form: {
                    email: null,
                    password: null
                },
                autofilled: false,
            }
        },

        computed: {
            ...mapGetters({
                loginError: 'auth/loginError',
                showLogoutMessage: 'auth/showLogoutMessage',
            }),
        },
        methods: {
            ...mapActions({
                login: 'auth/login',
                resetLoginError: 'auth/resetLoginError',
                fetchTranslations: 'lang/fetchTranslations'
            }),
            async submit () {
                this.resetLoginError()
                if (this.$refs.form.validate()) {
                    this.loading = true
                    await this.login(this.form)
                    this.$router.replace({ name: 'dashboard' })
                    this.loading = false
                }

            }
        },
        watch: {
            'form.email'() {
               this.autofilled = true;
            },

            language(language){
                const self = this
                this.fetchTranslations(language).then(function (){
                    self.$forceUpdate()
                })
            }
        },
        mounted() {
            // $("label\[for=\'email\'\]").addClass('v-label--active');
            // $("label\[for=\'password\'\]").addClass('v-label--active');
        }
    }
</script>
<style>
</style>
