<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                md="6"
                >
                <v-switch
                    v-model="notification_times"
                    label="05h00"
                    value="05"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="06h00"
                    value="06"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="07h00"
                    value="07"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="08h00"
                    value="08"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="09h00"
                    value="09"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="10h00"
                    value="10"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="11h00"
                    value="11"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="12h00"
                    value="12"
                ></v-switch>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <v-switch
                    v-model="notification_times"
                    label="13h00"
                    value="13"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="14h00"
                    value="14"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="15h00"
                    value="15"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="16h00"
                    value="16"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="17h00"
                    value="17"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="18h00"
                    value="18"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="19h00"
                    value="19"
                ></v-switch>
                <v-switch
                    v-model="notification_times"
                    label="20h00"
                    value="20"
                ></v-switch>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-btn
            class="mt-2"
            color="green darken-1"
            text
            @click="toggleAll"
        >{{ $lang('select all')}}</v-btn>
        <v-btn
            class="mt-2"
            color="blue darken-1"
            text
            @click="submit"
        >{{ $lang('save') }}</v-btn>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";

export default {
name: "NotificationTime",

    data () {
        return {
            notification_times: []
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
    },

    created() {
        this.notification_times = this.user.notification_times
    },

    methods: {
        ...mapActions({
            updateNotificationTimes: 'account/updateNotificationTimes',
        }),

        toggleAll() {
            if(this.notification_times.length) {
                this.notification_times = []
            }
            else {
                this.notification_times = ['05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']
            }

        },

        submit () {
            this.updateNotificationTimes({
                payload: {
                    notification_times: this.notification_times,
                },
            }).then(() => {
                Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('times successfully changed')})
            })
        },
    },

}
</script>

<style scoped>

</style>
