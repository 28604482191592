<template>
    <v-dialog
        v-model="showCreateDepartmentDialog"
        width="500"
        @click:outside="closeDialog"

    >
        <v-card>
            <v-card-title
                class="headline grey lighten-2"
                primary-title
            >
                {{ $lang('create department')}}
            </v-card-title>
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-select
                            v-model="form.division_id"
                            :items="divisions"
                            :label="$lang('division')"
                            item-text="name_with_parent_name"
                            item-value="id"
                            :error-messages="errors.division_id"
                        ></v-select>
                        <v-color-picker class="ma-2"
                            v-model="form.color"
                            :label="$lang('color')"
                            show-swatches
                            hide-canvas
                            hide-inputs
                            width="400"
                        >
                        </v-color-picker>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">{{ $lang('cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="loading">{{ $lang('save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Bus from "../../../../bus";
    export default {
        name: "CreateDepartmentDialog",

        data() {
            return {
                loading: false,
                errors: [],
                form: {
                    name: '',
                    color: '',
                    division_id: ''
                }
            }
        },

        computed: {
            ...mapGetters({
                divisions: 'divisions/divisions',
                showCreateDepartmentDialog: 'departments/showCreateDepartmentDialog',
            }),
        },

        methods: {
            ...mapActions({
                store: 'departments/store',
                fetchDivisions: 'divisions/fetchDivisions',
                actionShowCreateDepartmentDialog: 'departments/setCreateDepartmentDialog',
            }),

            save () {
                this.loading = true
                this.errors = []
                this.store({
                    payload: this.form,
                    context: this
                }).then(() => {
                    this.loading = false
                    if(this.errors.length === 0) {
                        this.$refs.form.reset()
                        this.closeDialog()
                        Bus.$emit('department:update');
                    }
                })
            },

            closeDialog () {
                this.actionShowCreateDepartmentDialog(false)
            }
        },

        created() {
            this.fetchDivisions()
        }
    }
</script>

<style scoped>

</style>
