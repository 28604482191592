<template>
    <v-card>
        <v-card-text>
            <v-alert
                v-if="formAlert"
                dense
                text
                type="success"
            >
                {{ $lang('password reset successfully done')}}
            </v-alert>
            <strong>{{ $lang('enter new password')}}</strong>

            <v-form
                ref="form"
            >
                <v-text-field
                    v-model="password"
                    :label="$lang('password') "
                    type="password"
                    :error-messages="errors.password"
                ></v-text-field>
            </v-form>

        </v-card-text>

        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
name: "ResetPassword",
    data() {
        return {
            formAlert: false,
            errors: [],
            password: ''
        }
    },
    computed: {
        ...mapGetters({
            user: 'companyAdminUsers/user',
        }),
    },

    methods: {
        ...mapActions({
            update: 'companyAdminUsers/resetPassword'
        }),

        save () {
            this.errors = []
            this.update({
                payload: {
                    password: this.password
                },
                id: this.user.id,
                context: this
            }).then(() => {
                this.password= ''
                if(this.errors.length === 0) {
                    this.showAlert()
                }
            })
        },

        showAlert() {
            this.formAlert = true
            setTimeout(this.hideAlert, 3000)
        },

        hideAlert() {
            this.formAlert = false
        }
    },

    mounted() {
        this.form = this.user

    }
}
</script>

<style scoped>

</style>
