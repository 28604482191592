<template>
<div>
    <v-toolbar flat color="white" >
        <v-toolbar-title >
            {{  this.$lang('my 7app')}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>

    <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
    ></v-progress-linear>
    <AccountTabs></AccountTabs>

</div>
</template>

<script>
    import AccountTabs from "../components/AccountTabs";

    export default {
        name: "Account",
        components: {AccountTabs},
        data () {
            return {
                loading: true
            }
        },

        mounted() {
            const self = this
            this.loading = false
        }
    }
</script>

<style scoped>

</style>
