<template>
    <v-card>
        <v-alert
            v-if="alert"
            dense
            text
            type="success"
        >
            {{ $lang('translation successfully saved. page will be reloaded.')}}
        </v-alert>

        <v-card-text

        >
            <v-col
                md="6"
                cols="12"
                v-if="translatableStrings"
            >
                <v-text-field
                    v-for="string in translatableStrings"
                    :label="string"
                    v-model="translations[string]"
                >

                </v-text-field>

            </v-col>

        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
name: "CompanyLang",
    data () {
        return {
            alert: false,
            translations: null
        }
    },

    props: [
        'local'
    ],

    computed: {
        ...mapGetters({
            customTranslations: 'lang/customTranslations',
            translatableStrings: 'lang/translatableStrings',
            lang: 'lang/lang',
        }),
    },

    methods: {
        ...mapActions({
            updateLang: 'companyAdminCompany/updateLang'
        }),

        save () {
            this.errors = []
            this.updateLang({
                payload: {
                    translations : this.translations,
                    local: this.local
                },
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.showAlert()
                   // location.reload()
                }
            })
        },

        showAlert() {
            this.alert = true
            setTimeout(this.hideAlert, 3000)
        },

        hideAlert() {
            this.alert = false
        }
    },

    watch: {
        translatableStrings: {
            handler: function () {
                const self = this
                if(this.translatableStrings && this.customTranslations[self.local]) {

                    let arr= {}

                    this.translatableStrings.forEach((value, index) => {
                        arr[value] = self.customTranslations[self.local][value]
                    });
                    self.translations = arr

                }

            },

            immediate: true
        },
    }

}
</script>

<style scoped>

</style>
