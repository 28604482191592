<template>
    <v-dialog
        v-model="showNoteDialog"
        width="1200"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
    >
        <v-toolbar flat color="white" dense >
            <v-toolbar-title >{{ note.title}} </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu
                offset-y
                class="mr-2"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        outlined
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                    >
                        <template v-if="!note.closed_at">
                            {{$lang('open')}}
                        </template>
                        <template v-else>
                            {{$lang('closed')}}
                        </template>
                    </v-btn>

                </template>
                <v-list>
                    <v-list-item
                        v-if="!note.closed_at"
                        @click="closeNote(note.id)"
                    >
                        <v-list-item-title>
                            {{$lang('close note')}}
                        </v-list-item-title>

                    </v-list-item>
                    <v-list-item
                        v-if="note.closed_at"
                        @click="reopenNote(note.id)"
                    >
                        <v-list-item-title>
                            {{$lang('reopen note')}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn
                small
                outlined
                color="primary"
                @click="closeDialog"
            >
                <v-icon class="mr-1">fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>
        <NoteTabs></NoteTabs>


    </v-dialog>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";
    import NoteTabs from "./NoteTabs";

    export default {
        name: "NoteDialog",
        components: {NoteTabs},
        data() {
            return {


            }
        },

        computed: {
            ...mapGetters({
                notes: 'notes/notes',
                note: 'notes/note',
                showNoteDialog: 'notes/showNoteDialog',
            }),
        },

        methods: {
            ...mapActions({
                actionShowNoteDialog: 'notes/setShowNoteDialog',
                actionCloseNote: 'notes/closeNote',
                actionReopenNote: 'notes/reopenNote'
            }),

            closeNote(id) {
                this.actionCloseNote(id)
            },

             reopenNote(id) {
                this.actionReopenNote(id)
             },

            closeDialog () {
                this.actionShowNoteDialog(false)
            }
        },
    }

</script>

<style scoped>

</style>
