export const suppliers = (state) => {
    return state.suppliers
}

export const activeSuppliers = (state) => {
    return state.activeSuppliers
}

export const supplier = (state) => {
    return state.supplier
}

export const showCreateSupplierDialog = (state) => {
    return state.showCreateSupplierDialog
}
