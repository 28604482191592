<template>
    <div class="container">
        <Bar :chart-data="chartData" :chart-options="options"/>
    </div>
</template>
<script>
import { Bar } from 'vue-chartjs'
import { Chart, LineController, LineElement, BarController, BarElement,  PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from 'chart.js';

Chart.register(LineController, LineElement, BarController, BarElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend);
export default {
    name: "IssuesAndTodosWithStatus",

    components: { Bar},

    data () {
        return {
            chartData: {
                labels: [
                    this.$lang('draft'),
                    this.$lang('ongoing'),
                    this.$lang('delay threatens'),
                    this.$lang('delay'),
                    this.$lang('closed'),
                    ],
                datasets: [
                    {
                        label: this.$lang('issues'),
                        borderWidth: 1,
                        borderColor: 'rgba(50, 168, 82, 0.7)',
                        backgroundColor: 'rgba(50, 168, 82, 0.2)',
                        data: []
                    },
                    {
                        label: this.$lang('todos'),
                        borderWidth: 1,
                        borderColor: 'rgba(21, 101, 192, 0.7)',
                        backgroundColor: 'rgba(21, 101, 192, 0.2)',
                        data: []
                    },
                ]
            },
            options: {

                scales: {
                    y: {
                        ticks: {
                            min: 0,
                            //stepSize: 1
                            precision: 0
                        }
                    },
                    x: {
                        scaleLabel: {
                            display: true,
                            labelString: this.$lang('state'),
                        }
                    }
                },
            },
            responsive: true,
            maintainAspectRatio: false
        }
    },

    props:  [
        'filteredIssuesData',
        'height'
    ],
    methods: {
        render() {

            let issuesData = _.flatten(this.filteredIssuesData)
            let todosData = _.flatten(_.map(issuesData, 'todos'))
            let issuesByStatus = {
                draft: 0,
                ongoing: 0,
                delay_threatens: 0,
                delay: 0,
                closed: 0
            }
            let todosByStatus = {
                draft: 0,
                ongoing: 0,
                delay_threatens: 0,
                delay: 0,
                closed: 0
            }
            _.each(issuesData, function (value, key) {

                switch (value.due_date_status) {
                    case 'draft':
                        issuesByStatus['draft']++
                        break
                    case 'ongoing':
                        issuesByStatus['ongoing']++
                        break
                    case 'checking':
                        issuesByStatus['ongoing']++
                        break
                    case 'delay threatens':
                        issuesByStatus['delay_threatens']++
                        break
                    case 'delay':
                        issuesByStatus['delay']++
                        break
                    case 'closed':
                        issuesByStatus['closed']++
                        break
                }
            });

            _.each(todosData, function (value, key) {

                switch (value.due_date_status) {
                    case 'draft':
                        todosByStatus['draft']++
                        break
                    case 'ongoing':
                        todosByStatus['ongoing']++
                        break
                    case 'checking':
                        todosByStatus['ongoing']++
                        break
                    case 'delay threatens':
                        todosByStatus['delay_threatens']++
                        break
                    case 'delay':
                        todosByStatus['delay']++
                        break
                    case 'closed':
                        todosByStatus['closed']++
                        break
                }
            });

            this.chartData.datasets[0].data = _.values(issuesByStatus)
            this.chartData.datasets[1].data = _.values(todosByStatus)

        }
    },

    watch: {
        filteredIssuesData: {
            handler: function() {
                this.render()
            },
            deep: true,
            immediate: true
        },
    },
}
</script>

<style scoped>

</style>
