import { Projects, Project } from '../views'

export default [
    {
        path: '/projects',
        component: Projects,
        name: 'projects',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/projects/:projectId/show',
        component: Project,
        name: 'projects.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },

]
