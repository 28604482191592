export const fetchKanbanCircuit = ({ commit }, kanbanCircuitId) => {
    return axios.get(`/api/procurement/kanban/kanbanCircuits/${kanbanCircuitId}`).then((response) => {
        commit('SET_KANBAN_CIRCUIT_DATA', response.data)
    })
}

export const postGoodsWithdrawal = ({ commit }, {kanbanCircuitId, payload}) => {
    return axios.post(`/api/procurement/kanban/postGoodsWithdrawal/${kanbanCircuitId}`, payload).then((response) => {
        //commit('SET_KANBAN_CIRCUIT_DATA', response.data)
    })
}

export const fetchKanbanOrders = ({ commit }) => {
    return axios.get(`/api/procurement/kanban/kanbanOrders`).then((response) => {
        commit('SET_KANBAN_ORDERS_DATA', response.data)
    })
}

export const storeKanbanOrder = ({ commit, dispatch }, {context,kanbanCircuitId, payload}) => {
    return axios.post(`/api/procurement/kanban/kanbanCircuits/${kanbanCircuitId}/kanbanOrders`, payload).then((response) => {
        dispatch('fetchKanbanCircuit', kanbanCircuitId)
    })
}

export const fetchKanbanOrder = ({ commit }, id) => {
    return axios.get(`/api/procurement/kanban/kanbanOrders/${id}`).then((response) => {
        commit('SET_KANBAN_ORDER_DATA', response.data)
    })
}

export const deleteKanbanOrder = ({ commit }, id) => {
    return axios.delete(`/api/procurement/kanban/kanbanOrders/${id}`).then((response) => {
        commit('SET_KANBAN_ORDER_DATA', null)
    })
}

export const setShowKanbanOrderDialog = ({ dispatch, state, commit }, value) => {
    commit('SET_KANBAN_ORDER_DIALOG', value)
}

export const postGoodsReceipt = ({ commit },{id, received_quantity, close_order, context}) => {
    return axios.patch(`/api/procurement/kanban/kanbanOrders/${id}/postGoodsReceipt`,{
        received_quantity: received_quantity,
        close_order: close_order
    }).then((response) => {
        commit('SET_KANBAN_ORDER_DATA', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}


export const setShowCreateManualKanbanOrderDialog = ({ dispatch, state, commit }, value) => {
    commit('SET_SHOW_CREATE_MANUAL_KANBAN_ORDER_DIALOG', value)
}

export const setShowKanbanCircuitDialog = ({ dispatch, state, commit }, value) => {
    commit('SET_SHOW_KANBAN_CIRCUIT_DIALOG', value)
}

export const setSelectedTab = ({ dispatch, state, commit}, value) => {
    commit('SET_SELECTED_TAB', value)
}
