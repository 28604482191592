<template>
    <div>
   {{langExport}}

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";


export default {
name: "LangExport",
    data () {
        return {
            langExport: ''
        }
    },


    mounted() {
        axios.get('/api/admin/lang/export').then((response) => {
            this.langExport = response.data
        })
    }
}
</script>

<style scoped>

</style>
