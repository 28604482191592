<template>
    <div>

        <v-menu
            :close-on-content-click="false"
            offset-y

        >
            <template
                v-slot:activator="{ on, attrs }"
            >
                <v-badge
                    overlap
                    :content="unreadNotifications.length"
                    class="mr-2"
                    color="error"
                    v-model="showBadge"
                >
                    <v-btn
                        color="primary"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        small
                    >
                        <v-icon>fas fa-envelope-open</v-icon>
                    </v-btn>
                </v-badge>
            </template>
            <v-list
                v-if="unreadNotifications.length"
            >
                <v-list-item
                    v-for="notification in unreadNotifications"
                    :key="notification.id"

                >

                    <v-list-item-content
                    >
                        <v-expansion-panels accordion v-if="me.companies.length > 1">
                            <v-expansion-panel

                            >
                                <v-expansion-panel-header>
                                    <span
                                        class="mr-10"
                                    >
                                         {{notification.message}}
                                    </span>

                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="3"
                                        >
                                            {{ $lang('company') }}:
                                        </v-col>
                                        <v-col
                                            cols="9"
                                        >
                                            {{ notification.company_name}}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="3"
                                        >
                                            {{ $lang('object') }}:
                                        </v-col>
                                        <v-col
                                            cols="9"
                                        >
                                            {{ $lang(notification.object_type)}}
                                        </v-col>
                                    </v-row>

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <template
                            v-else>
                            {{notification.message}}
                        </template>

                    </v-list-item-content>
                    <v-list-item-action>
                        <v-row>

                            <v-btn
                                v-if="notification.company_id === me.company.id"
                                color="primary"
                                outlined
                                small
                                @click="gotoNotificationObject(notification)"
                            >
                                {{ $lang('goto')}}
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="primary"
                                outlined
                                small
                                @click.stop="markAsReadNotification(notification.id)"
                            >
                                {{ $lang('read')}}
                            </v-btn>
                        </v-row>

                    </v-list-item-action>
                </v-list-item>

            </v-list>
            <v-list
                v-else
            >
                <v-list-item>
                    <v-list-item-content>
                        {{ $lang('you do not have any unread messages.')}}
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-menu>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "HeaderNotificationsDropDown",
    data () {
        return {
            unreadNotifications: [],
            showBadge: false
        }
    },
    computed: {
        ...mapGetters({
            notifications: 'notifications/notifications',
            me: 'auth/user'
        }),
    },

    created() {
        const self = this
        this.fetchNotifications().then(function () {
            self.unreadNotifications = _.filter(self.notifications, function(notification) {
                return !notification.read_at
            })
        })
    },

    methods: {
        ...mapActions({
            fetchNotifications: 'notifications/fetchNotifications',
            markAsReadNotification: 'notifications/markAsReadNotification',
            gotoNotificationObject: 'notifications/gotoNotificationObject'
        })
    },

    watch: {
        notifications: function () {
            this.unreadNotifications = _.filter(this.notifications, function(notification) {
                return !notification.read_at
            })
        },
        unreadNotifications: function () {
            this.showBadge = this.unreadNotifications.length > 0
        }

    },

    mounted() {

        const self = this
        var channel = this.$pusher.subscribe(`private.${this.me.id}`);

        channel.bind('newNotification', ({ log }) => {

            self.fetchNotifications().then(function () {
                self.unreadNotifications = _.filter(self.notifications, function(notification) {
                    return !notification.read_at
                })
            })


        });
    },


}
</script>

<style scoped>
.notification {
    cursor: pointer;
}
.notification:hover {
    color: #3490dc;
}

</style>
