<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>

</template>
<script>
    import  { mapGetters, mapActions} from 'vuex';
    import Bus from "../../../../bus";
    export default {
        name: "UsersTable",

        data() {
            return {
                loading: true
            }
        },

        computed: {
            ...mapGetters({
                users: 'adminUsers/users',
                user: 'adminUsers/user'
            })
        },

        methods: {
            ...mapActions({
                fetchUsers: 'adminUsers/fetchUsers',
                fetchUser: 'adminUsers/fetchUser',
            }),

            parseData: function () {
                $$(this.webixId).clearAll()
                $$(this.webixId).parse(this.users)
            },
        },

        mounted:function(){
            const self = this
            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: 600,
                id: 'users',
                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                columns: [
                    {id: "id", header: [self.$lang('id'), {content: "textFilter"}], width: 50, sort: "int"},
                    {
                        id: "first_name",
                        header: [self.$lang('first name'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "last_name",
                        header: [self.$lang('last name'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "email",
                        header: [self.$lang('email'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },
                    {
                        id: "company_name",
                        header: [self.$lang('company'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string"
                    },

                    {
                        id: "active_label",
                        header: [self.$lang('active'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 200}
                        }],
                        minWidth: 150,
                        adjust: true,
                        sort: "string",
                        template: function (obj) {
                            return obj.active_label
                        }
                    },
                    {
                        id: "created_at",
                        map: "(date)#created_at#",
                        header: [self.$lang('created'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: true,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }
                    }
                ],
                on: {
                    onSelectChange: function(){
                        self.fetchUser(this.getSelectedId(true))
                    },
                    onItemDblClick: function (id) {
                        self.$router.push({ name: 'admin.users.show', params: { userId: id} })
                    },
                    onAfterLoad: function () {
                        if (self.user && this.exists(self.user.id)) {
                            this.select(self.user.id)
                            this.showItem(self.user.id)
                        }
                        self.loading = false
                    },
                },

            })
        },

        watch: {
            users: function () {
                this.loading = true
                this.parseData()
            },

        },

        destroyed:function(){
            webix.$$(this.webixId).destructor();
        }


    }
</script>

<style >
    .webix_multilist .wxi-checkbox-blank, .webix_multilist .wxi-checkbox-marked {
        font-size: 24px;
        width: 24px;
        vertical-align: middle;
        margin-right: 4px;
        color: #1565C0 ;
    }
</style>
