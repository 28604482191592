<template>
    <v-navigation-drawer
        v-model="drawer"
        app
        :clipped="$vuetify.breakpoint.lgAndUp"
    >

        <v-list

            dense
        >
            <v-list-item
                link
                to="/notes"
            >
                <v-list-item-icon>
                    <v-icon>note</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Notizen
                    <template v-if="notes">
                        <v-chip v-if="openNotes.length > 0" style="float: right;" color="primary" x-small>
                            {{ openNotes.length}}
                        </v-chip>
                    </template>
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="/projects"
            >
                <v-list-item-icon>
                    <v-icon>fas fa-project-diagram</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Projekte

                </v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="/parts"
            >
                <v-list-item-icon>
                    <v-icon>fas fa-fan</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Artikel</v-list-item-title>
            </v-list-item>

            <v-list-item
                link
                to="/purchaseOrders"
            >
                <v-list-item-icon>
                    <v-icon>fas fa-store</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Bestellungen</v-list-item-title>
            </v-list-item>

            <v-list-item
                link
                to="/pps/planBoard"
            >
                <v-list-item-icon>
                    <v-icon>fas fa-industry</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Fertigung</v-list-item-title>
            </v-list-item>


                <v-list-group
                    prepend-icon="fas fa-cogs"
                    :value="false"
                >
                    <template v-slot:activator>
                        <v-list-item-title>Master Daten</v-list-item-title>
                    </template>



                    <v-list-item
                        link
                        to="/masterData/projectTypes"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            Projekt Typen
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/masterData/suppliers"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            Lieferanten
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/masterData/machines"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            Maschinen
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        link
                        to="/masterData/machineGroups"
                    >
                        <v-list-item-icon>

                        </v-list-item-icon>
                        <v-list-item-title>
                            Maschinen-Gruppen
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "MenuDrawer",
        data() {
            return {
                drawer: true
            }
        },
        computed: {
            ...mapGetters({
                notes: 'notes/notes',
                menuDrawer: 'layout/menuDrawer'
            }),
            openNotes() {
                return this.notes.filter(item => {
                    return !item.closed_at
                })
            },

            // drawer: {
            //     get(){
            //         return this.menuDrawer
            //     },
            //     set(){
            //         alert(this.menuDrawer)
            //         //this.actionShowHideMenuDrawer()
            //     }
            // }
        },

        methods: {
            ...mapActions({
                fetchNotes: 'notes/getNotes',
                actionShowHideMenuDrawer: 'layout/showHideMenuDrawer',
            }),
        },

        watch: {

        },
        mounted() {
            this.fetchNotes()
        }
    }
</script>

<style scoped>

</style>
