<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                md="8"
            >
                <KanbanCircuitLogChart :height="260"></KanbanCircuitLogChart>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <KanbanDashboardFilter v-if="kanbanCircuits"></KanbanDashboardFilter>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import KanbanDashboardFilter from "./KanbanDashboardFilter";
import {mapActions, mapGetters} from "vuex";
import KanbanCircuitLogChart from "@/app/procurement/dashboard/charts/KanbanCircuitLogChart.vue";
export default {
    name: "KanbanDashboard",
    components: {KanbanCircuitLogChart, KanbanDashboardFilter},

    computed: {
        ...mapGetters({
            me: 'auth/user',
            kanbanCircuits: 'procurementDashboard/kanbanCircuits'
        }),


    },

    created() {
        this.fetchKanbanCircuits()
    },

    methods: {
        ...mapActions({
            fetchKanbanCircuits: 'procurementDashboard/fetchKanbanCircuits',
        }),
    }
}
</script>

<style scoped>

</style>
