<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ $lang('workplaces')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="showCreateWorkplaceDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon>{{ $lang('workplace')}}
            </v-btn>
        </v-toolbar>

        <WorkplacesTable></WorkplacesTable>
        <CreateWorkplaceDialog></CreateWorkplaceDialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import WorkplacesTable from "../components/WorkplacesTable";
    import CreateWorkplaceDialog from "../components/CreateWorkplaceDialog";
    export default {
        name: "Workplaces",
        components: {
            WorkplacesTable,
            CreateWorkplaceDialog
        },

        methods: {
            ...mapActions({
                showCreateWorkplaceDialog: 'workplaces/setCreateWorkplaceDialog',
            }),
        },
    }
</script>

<style scoped>

</style>
