import { ProcurementDashboard} from '../views'

export default [
    {
        path: '/procurement/Dashboard',
        component: ProcurementDashboard,
        name: 'procurement.dashboard',
        meta: {
            guest: false,
            needsAuth: true
        }
    }
]

