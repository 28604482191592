<template>
    <div id="ganttWrapper">
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <div ref="gantt" class="gantt_container" :style="ganttStyles"></div>
        <TaskContextMenu></TaskContextMenu>
    </div>
</template>

<script>
import TaskContextMenu from "./TaskContextMenu";
let event = null
import {gantt} from'../../../plugins/dhtmlx-gantt/dhtmlxgantt.js'
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";
import {project} from "../vuex/getters";

export default {
    name: "Gantt",
    components: {TaskContextMenu},
    data () {
        return {
            loading: true,
            initialLoad: true,
            ganttStyles: {
                height: `${window.innerHeight - 200}px`
            },
            sortNumber: false,
            ganttExists: false
        }
    },

    props: {
        endpoint: {
            type: String
        }
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if(this.ganttExists) {
                let scrollState = custom_gantt.getScrollState()
                if (e.key === 'ArrowRight') {
                    custom_gantt.scrollTo(scrollState.x + 200, null);
                }
                if (e.key === 'ArrowLeft') {
                    custom_gantt.scrollTo(scrollState.x - 200, null);
                }
                if (e.key === 'ArrowDown') {
                    custom_gantt.scrollTo(null, scrollState.y + 200);
                }
                if (e.key === 'ArrowUp') {
                    custom_gantt.scrollTo(null, scrollState.y - 200);
                }
            }
        });
    },

    computed: {
        ...mapGetters({
            project: 'projects/project',
            tasks: 'projects/tasks',
            ganttMoveProject: 'projects/ganttMoveProject',
            ganttOpenAll: 'projects/ganttOpenAll',
            ganttScale: 'projects/ganttScale',
            range: 'projects/ganttRange',
            user: 'auth/user',
            todos: 'todos/todos',
            ganttSearch: 'projects/ganttSearch',
            showPotentials: 'projects/ganttShowPotentials'
        }),

        scale: {
            get() {
                return this.ganttScale
            },
            set(value) {
                this.setGanttScale(value)
            }
        },

    },

    methods: {
        ...mapActions({
            fetchTasks: 'projects/fetchTasks',
            fetchTodo: 'todos/fetchTodo',
            actionShowTodoDialog: 'todos/setTodoDialog',
            fetchIssue: 'issues/fetchIssue',
            actionShowIssueDialog: 'issues/setIssueDialog',
            setChangedTasks: 'projects/setChangedTasks',
            setTask: 'projects/setTask',
            setShowTaskContextMenu: 'projects/setShowTaskContextMenu',
            fetchPotential: 'potentials/fetchPotential',
            actionShowPotentialDialog: 'potentials/setShowPotentialDialog',
            setGanttScale: 'projects/setGanttScale',
        }),

        limitMoveLeft(task, limit) {
            var dur = task.end_date - task.start_date;
            task.end_date = new Date(limit.end_date);
            task.start_date = new Date(+task.end_date - dur);
        },

        limitMoveRight(task, limit) {
            var dur = task.end_date - task.start_date;
            task.start_date = new Date(limit.start_date);
            task.end_date = new Date(+task.start_date + dur);
        },

        limitResizeLeft(task, limit) {
            task.end_date = new Date(limit.end_date);
        },

        limitResizeRight(task, limit) {
            task.start_date = new Date(limit.start_date)
        },

        parseGantt() {
            if(this.ganttExists) {
                this.$nextTick(() => {

                    let sPos = custom_gantt.getScrollState()
                    custom_gantt.clearAll();
                    custom_gantt.init(this.$refs.gantt);
                    custom_gantt.parse(this.tasks);
                    custom_gantt.scrollTo(sPos.x, sPos.y)
                    this.marker()
                    if (this.initialLoad) {
                        custom_gantt.showDate(new Date())
                        this.initialLoad = false
                    }
                    this.loading = false
                    custom_gantt.sort("order", false);
                })
            }
        },

        marker() {
            const self = this
            var dateToStr = custom_gantt.date.date_to_str(custom_gantt.config.task_date);

            var id = custom_gantt.addMarker({
                start_date: new Date(),
                css: "today",
                title:dateToStr(new Date()),
                text: self.$lang('today')
            });
        },

    },

    mounted: function () {
        const self = this

        window.custom_gantt = Gantt.getGanttInstance();

        this.ganttExists = true

        if(this.ganttExists) {
            custom_gantt.plugins({
                critical_path: true,
                multiselect: true,
                drag_timeline: true,
                marker: true,
                undo: true
            });

            custom_gantt.config.drag_resize = _.includes(self.project.user_permissions, 'gantt dates')
            custom_gantt.config.drag_move = _.includes(self.project.user_permissions, 'gantt dates')
            custom_gantt.config.undo = true;
            custom_gantt.config.redo = true;

            custom_gantt.i18n.setLocale( this.user.lang.toLowerCase());
            custom_gantt.config.xml_date = "%d.%m.%Y";

            var zoomConfig = {
                levels: [
                    {
                        name:"day",
                        scale_height: 27,
                        min_column_width:80,
                        scales:[
                            {unit: "day", step: 1, format: "%d %M"}
                        ]
                    },
                    {
                        name:"week",
                        scale_height: 50,
                        min_column_width:50,
                        scales:[
                            {unit: "week", step: 1, format: function (date) {
                                    var dateToStr = gantt.date.date_to_str("%d %M");
                                    var endDate = gantt.date.add(date, 6, "day");
                                    var weekNum = gantt.date.date_to_str("%W")(date);
                                    return "#" + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
                                }},
                            {unit: "day", step: 1, format: "%j %D"}
                        ]
                    },
                    {
                        name:"month",
                        scale_height: 50,
                        min_column_width:20,
                        scales:[
                            {unit: "month", format: "%F, %Y"},
                            {unit: "week", format: "Week #%W"},
                            {unit: "day", step: 1, format: " "}
                        ]
                    },
                    {
                        name:"quarter",
                        height: 50,
                        min_column_width:5,
                        scales:[
                            {unit: "month", step: 1, format: "%M"},
                            {
                                unit: "quarter", step: 1, format: function (date) {
                                    var dateToStr = gantt.date.date_to_str("%M");
                                    var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                    return dateToStr(date) + " - " + dateToStr(endDate);
                                }
                            },
                            {unit: "day", step: 1, format: " "}
                        ]
                    },
                    {
                        name:"year",
                        scale_height: 50,
                        min_column_width: 1,
                        scales:[
                            {unit: "year", step: 1, format: "%Y"},
                        ]
                    }

                ],
                useKey: "ctrlKey",
                trigger: "wheel",
                element: function(){
                    return custom_gantt.$root.querySelector(".gantt_task");
                }
            }

            custom_gantt.ext.zoom.init(zoomConfig);
            custom_gantt.ext.zoom.attachEvent("onAfterZoom", function (level, config) {
                self.setGanttScale(config.name)
            });

            this.fetchTasks(self.endpoint).then(function (){
                self.parseGantt()
            })

            custom_gantt.config.drag_timeline = true;

            custom_gantt.config.autoscroll = true;
            custom_gantt.config.static_background = true;
            custom_gantt.config.highlight_critical_path = true;
            custom_gantt.config.start_date = new Date(moment(this.project.start_date, "DD.MM.YYYY").subtract(1, 'month').format('YYYY-MM-DD'));
            custom_gantt.config.end_date = new Date(moment(this.project.end_date, "DD.MM.YYYY").add(2, 'year').format('YYYY-MM-DD'));
            custom_gantt.config.row_height = 25;

            custom_gantt.config.columns = [
                {name:"text",       label:this.$lang('task'),  width:"250", tree:true },
                {name:"leader", label:this.$lang('responsible'), align:"center" ,width:"100", template:function(obj){
                        if(obj.user) {
                            if (obj.user.avatar) {
                                return "<img src=" + obj.user.avatar +" width='30px' style='margin-top: 2px; border-radius: 12px'>"
                            }
                            else {
                                return obj.user.full_name
                            }
                        }},
                },
                {name:"number", label:this.$lang('no.'),        width:"40"},
            ];

            custom_gantt.templates.task_class = function (st, end, item) {
                return custom_gantt.getChildren(item.id).length ? "gantt_project" : "";
            };

            custom_gantt.attachEvent("onTaskDrag", function (id, mode, task, original, e) {
                var parent = task.parent ? custom_gantt.getTask(task.parent) : null,
                    children = custom_gantt.getChildren(id),
                    modes = custom_gantt.config.drag_mode;

                var limitLeft = null,
                    limitRight = null;

                if (!(mode === modes.move || mode === modes.resize)) return;

                if (mode === modes.move) {
                    limitLeft = self.limitMoveLeft;
                    limitRight = self.limitMoveRight;

                } else if (mode === modes.resize) {
                    limitLeft = self.limitResizeLeft;
                    limitRight = self.limitResizeRight;
                }

                //check parents constraints
                if (parent && +parent.end_date < +task.end_date) {
                    limitLeft(task, parent);
                }
                if (parent && +parent.start_date > +task.start_date) {
                    limitRight(task, parent);
                }

                //check children constraints
                if(!self.ganttMoveProject) {
                    for (var i = 0; i < children.length; i++) {
                        var child = custom_gantt.getTask(children[i]);
                        if (+task.end_date < +child.end_date) {
                            limitLeft(task, child);
                        } else if (+task.start_date > +child.start_date) {
                            limitRight(task, child)
                        }
                    }
                }


            });

            custom_gantt.attachEvent("onBeforeParse", function(){
                self.loading = true
            });

            custom_gantt.attachEvent("onTaskDblClick", function(id,e){

                let task = custom_gantt.getTask(id)
                switch (task.object_type) {
                    case 'project':
                        break
                    case 'issue':
                        self.fetchIssue(id).then(function (){
                            self.actionShowIssueDialog(true)
                        })
                        break
                    case 'todo':
                        self.fetchTodo(id).then(function (){
                            self.actionShowTodoDialog(true)
                        })
                        break
                    case 'potential':
                        self.fetchPotential(id).then(function (){
                            self.actionShowPotentialDialog(true)
                        })
                }
                return false;
            });

            custom_gantt.attachEvent("onTaskDrag", function(id, mode, task, original){
                if(self.ganttMoveProject) {
                    var modes = custom_gantt.config.drag_mode;
                    if(mode === modes.move ){
                        var diff = task.start_date - original.start_date;
                        custom_gantt.eachTask(function(child){
                            child.start_date = new Date(+child.start_date + diff);
                            child.end_date = new Date(+child.end_date + diff);
                            custom_gantt.refreshTask(child.id, true);
                        },id );
                    }
                }
            });

            custom_gantt.attachEvent("onAfterTaskDrag", function(id, mode, e){
                self.setChangedTasks(custom_gantt.getTask(id))
                var modes = custom_gantt.config.drag_mode;
                if(mode === modes.move ){
                    var state = custom_gantt.getState();
                    custom_gantt.eachTask(function(child){
                        child.start_date = custom_gantt.roundDate({
                            date:child.start_date,
                            unit:state.scale_unit,
                            step:state.scale_step
                        });
                        child.end_date = custom_gantt.calculateEndDate(child.start_date,
                            child.duration, custom_gantt.config.duration_unit);
                        custom_gantt.updateTask(child.id);
                        self.setChangedTasks(custom_gantt.getTask(child.id))
                    },id );
                }
            });

            event =  custom_gantt.attachEvent("onContextMenu", function (taskId, linkId, event) {
                if(!_.includes(self.project.user_permissions, 'edit')) {
                    return false
                }
                if(taskId) {
                    self.setTask(custom_gantt.getTask(taskId))
                    self.setShowTaskContextMenu(event)
                }
                return false;
            });

            custom_gantt.templates.timeline_cell_class = function (item, date) {
                if(custom_gantt.getScale().unit === 'day') {
                    if (date.getDay() === 0 || date.getDay() === 6) {
                        return "weekend";
                    }
                    return "borderRight"
                }
                else {
                    return "borderRight"
                }
            };

            function resizeHandler() {
                self.ganttStyles.height = `${window.innerHeight - 200}px`
                custom_gantt.showDate(new Date())
            }

            window.visualViewport.addEventListener('resize', resizeHandler);

            custom_gantt.attachEvent("onBeforeTaskDisplay", function(id, task){
                if (!self.showPotentials && task.object_type === 'potential') {
                    return false
                }

                if(self.ganttSearch) {
                    return task.text.toLowerCase().indexOf(self.ganttSearch.toLowerCase()) > -1 || task.user_name.toLowerCase().indexOf(self.ganttSearch.toLowerCase()) > -1
                }
                return true
            });

        }

        Bus.$on('project:updated', function () {
            self.fetchTasks(self.endpoint).then(function () {
                if (self.ganttExists) {
                    self.parseGantt()
                    custom_gantt.showDate(new Date())
                }
            })
        });

        custom_gantt.ext.zoom.setLevel(this.scale);
    },

    watch: {
        ganttOpenAll: {
            handler: function (value) {
                const self = this
                if(value) {
                    custom_gantt.eachTask(function(task){
                        custom_gantt.open(task.id);
                    })
                }
                else {
                    custom_gantt.eachTask(function(task){
                        custom_gantt.close(task.id);
                    })
                }
            }
        },


        scale: {
            handler: function (value) {
                this.setGanttScale(value)

                custom_gantt.ext.zoom.setLevel(value);
                custom_gantt.showDate(new Date())
                if(_.includes(this.project.user_permissions, 'gantt dates')) {
                    custom_gantt.config.drag_move =  value !== 'year'
                    custom_gantt.config.drag_resize = value !== 'year'
                }
                else {
                    custom_gantt.config.drag_move =  false
                    custom_gantt.config.drag_resize = false
                }
            },
        },


        range : {
            handler: function (value) {
                if (value) {
                    custom_gantt.config.start_date = new Date(moment().subtract(3, 'month').format('YYYY-MM-DD'));
                    custom_gantt.config.end_date = new Date(moment().add(1, 'year').format('YYYY-MM-DD'));
                }
                else {
                    custom_gantt.config.start_date = new Date(moment(this.project.start_date, "DD.MM.YYYY").subtract(1, 'month').format('YYYY-MM-DD'));
                    custom_gantt.config.end_date = new Date(moment(this.project.end_date, "DD.MM.YYYY").add(3, 'month').format('YYYY-MM-DD'));
                }
                custom_gantt.init(this.$refs.gantt);
                custom_gantt.config.fit_tasks = true;
            }
        },

        ganttSearch: {
            handler: function () {
                this.parseGantt()
            }
        },

        showPotentials: {
            handler: function () {
                this.parseGantt()
            }
        }
    },

    destroyed:function(){
        this.ganttExists = false
        custom_gantt.detachEvent(event);
        custom_gantt.destructor()
    }

}
</script>

<style scoped>
@import "../../../plugins/dhtmlx-gantt/dhtmlxgantt.css";
#ganttWrapper >>>  .gantt_task_line {
    border-radius: 8px !important;
}

.gantt_container {
    height:100%;
}

#ganttWrapper >>> .critical_task {
    background: red !important;
}

#ganttWrapper >>> .gantt_task_content {
    color: black !important;
}

#ganttWrapper >>> .weekend{
    background: #f4f7f4 !important;
}

#ganttWrapper >>> .borderRight{
    border-right: 1px solid #ebebeb !important;
}

#ganttWrapper >>> .scale_weekend{
    background: #f4f7f4 !important;
    border-bottom: 1px solid #cecece!important;
}

#ganttWrapper {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}
</style>
