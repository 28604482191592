import Bus from "../../../bus";

export const fetchPotentials = ({ commit }, endpoint) => {
    return axios.get(endpoint).then((response) => {
        commit('SET_POTENTIALS_DATA', response.data)
    })
}

export const fetchPotential = ({ commit }, id) => {
    return axios.get(`/api/potentials/${id}`).then((response) => {
        commit('SET_POTENTIAL_DATA', response.data)
    })
}

export const setPotentials = ({ state, commit }, potentials) => {
    commit('SET_POTENTIALS_DATA', potentials)
}

export const setPotential= ({ state, commit }, id) => {
    let potential = state.potentials.find(potentials => potentials.id === id)
    commit('SET_POTENTIAL_DATA', potential)
}

export const setPotentialDialog = ({ dispatch, state, commit }, value) => {
    if(state.potentials)
        commit('SET_POTENTIAL_DIALOG', value)
}

export const store = ({ dispatch, state, commit }, { payload, type, context }) => {
    return axios.post(`${state.createPotentialEndpoint}/${type}`, payload).then((response) => {
        // commit('PUSH_POTENTIALS_DATA', response.data)
        // commit('SET_POTENTIAL_DATA', response.data)
        // dispatch('issues/fetchIssue', response.data.issue_id, {root: true})
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { id, type,  payload, context }) => {
    return axios.patch(`/api/potentials/${id}/${type}`, payload).then((response) => {
        // commit('UPDATE_POTENTIALS_DATA', response.data)
        // commit('SET_POTENTIAL_DATA', response.data)
        // dispatch('issues/fetchIssue', response.data.issue_id, {root: true})
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const deletePotential = ({ state, dispatch, commit }, {id, issueId}) => {
    return axios.delete(`/api/potentials/${id}`).then((response) => {
        dispatch('issues/fetchIssue', issueId, {root: true})
        commit('REMOVE_POTENTIAL', id)
        commit('SET_POTENTIAL_DIALOG', false)
        Bus.$emit('showAlert', {color : 'success', 'message' : `Potential gelöscht!`});
    })
}

export const setCreatePotentialDialog = ({ dispatch, state, commit }, {value, endpoint}) => {
    commit('SET_CREATE_POTENTIAL_ENDPOINT', endpoint)
    commit('SET_CREATE_POTENTIAL_DIALOG', value)
}

export const setShowPotentialDialog = ({ dispatch, commit }, trueOrFalse) => {
    commit('SET_POTENTIAL_DIALOG', trueOrFalse)
}
