<template>
    <v-row justify="center">
        <v-dialog
            :value="showCreateTodoDialog"
            :width="todoDialogWidth"
            @click:outside="confirmCloseDialog = true"
            @keydown.esc="confirmCloseDialog = true"
            persistent
        >
            <v-card >

                <v-card-title>
                    <span class="headline">{{ $lang('create todo')}}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="todoDialogWidth > 600"
                        small
                        outlined
                        color="primary"
                        class="mr-2"
                        @click="setTodoDialogWidth(430)"
                    >
                        {{ $lang('small')}}
                    </v-btn>
                    <v-btn
                        v-else
                        small
                        outlined
                        color="primary"
                        class="mr-2"
                        @click="setTodoDialogWidth(1200)"
                    >
                        {{ $lang('wide')}}
                    </v-btn>

                    <v-btn
                            small
                            outlined
                            color="primary"
                            @click="closeDialog"
                    >
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>

                </v-card-title>
                <v-card-text>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            :label="$lang('title')"
                            v-model="form.title"
                            required
                            autofocus
                            :error-messages="errors.title"
                        >
                        </v-text-field>

                        <Editor :body="form.body" v-on:update:body="form.body = $event" v-on:update:tokens="form.tokens = $event"></Editor>

                        <v-row>
                            <v-col
                                cols="12"
                                md="6">
                                <v-select
                                    v-model="form.assignee_id"
                                    :items="permittedAssigneeUsers"
                                    :label="$lang('responsible')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.assignee_id = null"
                                    item-text="full_name"
                                    item-value="id"
                                    :error-messages="errors.assignee_id"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6">

                                <v-select
                                    v-model="form.priority"
                                    :items="priorities"
                                    :label="$lang('prio')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.priority = null"
                                    item-text="name"
                                    item-value="slug"
                                    :error-messages="errors.priority"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                md="6">
                                <v-dialog
                                    ref="start_date_dialog"
                                    v-model="start_date_modal"
                                    :return-value.sync="picker_start_date"
                                    persistent
                                    width="290px"

                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.start_date"
                                            :label="$lang('start')"
                                            append-outer-icon="fas fa-times"
                                            @click:append-outer="picker_start_date = null"
                                            append-icon="fas fa-calendar-alt"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="errors.start_date"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-if="todoDateLimits"
                                        v-model="picker_start_date"
                                        scrollable
                                        @change="$refs.start_date_dialog.save(picker_start_date)"
                                        :min="todoDateLimits.min_start_date"
                                        :max="todoDateLimits.max_end_date"
                                        :first-day-of-week="1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="picker_start_date = ''">{{ $lang('delete') }}</v-btn>
                                        <v-btn text color="primary" @click="start_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.start_date_dialog.save(picker_start_date)">{{ $lang('ok') }}</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6">
                                <v-dialog
                                    ref="end_date_dialog"
                                    v-model="end_date_modal"
                                    :return-value.sync="picker_end_date"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.end_date"
                                            :label="$lang('end')"
                                            append-outer-icon="fas fa-times"
                                            @click:append-outer="picker_end_date = null"
                                            append-icon="fas fa-calendar-alt"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="errors.end_date"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-if="todoDateLimits"
                                        v-model="picker_end_date"
                                        scrollable
                                        @change="$refs.end_date_dialog.save(picker_end_date)"
                                        :min="todoDateLimits.min_start_date"
                                        :max="todoDateLimits.max_end_date"
                                        :first-day-of-week="1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="picker_end_date = ''">{{ $lang('delete') }}</v-btn>
                                        <v-btn text color="primary" @click="end_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.end_date_dialog.save(picker_end_date)">{{ $lang('ok') }}</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="submit">{{ $lang('save') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="closeDialog">{{ $lang('close') }}</v-btn>
                </v-card-actions>
            </v-card>
            <v-dialog
                v-model="confirmCloseDialog"
                width="800"
            >
                <v-card>
                    <v-card-title
                        primary-title
                        class="justify-center"
                    >
                        {{$lang('close dialog')}}
                    </v-card-title>
                    <v-card-text
                        class="text-center"
                    >
                        <strong>
                            {{$lang('do you really want to close without saving?')}}
                        </strong>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="primary"
                            text
                            @click="saveAndClose"
                        >
                            {{$lang('save and close')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="secondary"
                            text
                            @click="closeWithoutSaving"
                        >
                            {{$lang('close without saving')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="confirmCloseDialog = false"
                        >
                            {{$lang('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
    </v-row>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";
    import Editor from "../../../components/Editor";
    import CalculateAndShowPotential from "../../potentials/components/CalculateAndShowPotential";
    export default {
        name: "CreateTodoDialog",

        components: {
            Editor: Editor
        },

        props: [
            'endpoint'
        ],

        data() {
            return {
                modal: false,
                errors: [],
                confirmCloseDialog: false,
                picker_start_date:'',
                picker_end_date:'',
                start_date_modal: false,
                end_date_modal: false,
                form: {
                    title: '',
                    body: '',
                    tokens: [],
                    assignee_id: '',
                    due_date: '',
                    priority: 'low'
                },
                priorities: [
                    {slug: 'low', name: this.$lang('low')},
                    {slug: 'mid', name: this.$lang('mid')},
                    {slug: 'high', name: this.$lang('high')},
                ],
                permittedAssigneeUsers: []
            }
        },

        computed: {
            ...mapGetters({
                project: 'projects/project',
                showCreateTodoDialog: 'todos/showCreateTodoDialog',
                activeUsers: 'users/activeUsers',
                todo: 'todos/todo',
                createTodoEndpoint: 'todos/createTodoEndpoint',
                todoDateLimits: 'todos/todoDateLimits',
                todoDialogWidth: 'todos/todoDialogWidth'
            })
        },

        methods: {
            ...mapActions({
                store: 'todos/store',
                actionShowCreateTodoDialog: 'todos/setCreateTodoDialog',
                fetchIssue: 'issues/fetchIssue',
                fetchTodoDateLimits: 'todos/fetchTodoDateLimits',
                setTodoDialogWidth: 'todos/setTodoDialogWidth',
            }),

            submit () {
                const self = this
                this.form.tokens= []
                this.errors = []
                this.store({
                    endpoint: this.endpoint,
                    payload: this.form,
                    context: this,
                }).then(() => {
                    if(this.errors.length === 0) {
                        this.dialog = false
                        this.$refs.form.reset()
                        this.form.body = ''
                        this.picker_start_date = ''
                        this.picker_end_date = ''
                        Bus.$emit('todo:created')
                        this.actionShowCreateTodoDialog({value: false, endpoint: null}).then(function () {
                            Bus.$emit('showAlert', {color: 'success', 'message': self.$lang('todo successfully created') });
                        })
                    }
                })
            },

            saveAndClose() {
                this.confirmCloseDialog = false
                this.submit()
            },

            closeWithoutSaving() {
                this.$refs.form.reset()
                this.errors = []
                this.closeDialog()
            },

            closeDialog () {
                this.actionShowCreateTodoDialog({value: false, endpoint: null})
            },

            formatDate (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            },

            updateParent(id) {
                axios.get(`/api/todos/${id}`).then((response) => {
                    if (response.data.todoable_type === 'todo') {
                        this.updateParent(response.data.todoable_id)
                    }
                    else {
                        if (response.data.todoable_type === 'issue') {
                            this.fetchIssue(response.data.todoable_id)
                        }
                    }
                })
            }
        },

        watch: {
            picker_start_date (val) {
                this.form.start_date = this.formatDate(this.picker_start_date)
            },

            picker_end_date (val) {
                this.form.end_date = this.formatDate(this.picker_end_date)
            },

            createTodoEndpoint() {
                if(this.createTodoEndpoint) {
                    this.fetchTodoDateLimits(this.createTodoEndpoint)
                }
            },

            project: {
                handler: function (project) {
                    if(project) {
                        this.permittedAssigneeUsers = this.activeUsers.filter(user => this.project.permitted_user_ids.includes(user.id));
                    }
                },
                immediate: true
            }
        },
    }
</script>

<style scoped>

</style>
