<template>
    <v-card>
        <v-alert
            v-if="alert"
            dense
            text
            type="success"
        >
            Firmen für <strong>{{ user.full_name}}</strong> erfolgreich geändert
        </v-alert>
        <v-card-text>
            <v-switch
                v-for="company in companies"
                v-model="company_ids"
                :value="company.id"
                :label="company.name"
            ></v-switch>
        </v-card-text>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
        </v-card-actions>

    </v-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
name: "UserCompanies",
    data () {
        return {
            alert: false,
            company_ids: []
        }
    },

    computed: {
        ...mapGetters({
            user: 'adminUsers/user',
            companies: 'adminCompanies/companies'
        }),
    },

    methods: {
        ...mapActions({
            fetchCompanies: 'adminCompanies/fetchCompanies',
            updateCompanies: 'adminUsers/updateCompanies'
        }),

        save () {
            this.errors = []
            this.updateCompanies({
                payload: { companyIds : this.company_ids},
                id: this.user.id,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.showAlert()
                }
            })
        },

        showAlert() {
            this.alert = true
            setTimeout(this.hideAlert, 3000)
        },

        hideAlert() {
            this.alert = false
        }
    },

    mounted() {
        this.company_ids = this.user.company_ids
        this.fetchCompanies()
    }
}
</script>

<style scoped>

</style>
