<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ $lang('cost centers') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="showCreateCostCenterDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('cost center') }}
            </v-btn>
        </v-toolbar>

        <CostCentersTable></CostCentersTable>
        <CreateCostCenterDialog></CreateCostCenterDialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import CostCentersTable from "../components/CostCentersTable";
    import CreateCostCenterDialog from "../components/CreateCostCenterDialog";
    export default {
        name: "CostCenters",
        components: {
            CostCentersTable,
            CreateCostCenterDialog
        },

        methods: {
            ...mapActions({
                showCreateCostCenterDialog: 'costCenters/setCreateCostCenterDialog',
            }),
        },
    }
</script>

<style scoped>

</style>
