<template>
    <v-tabs>
        <v-tab href="#info">{{ $lang('info') }}</v-tab>
        <v-tab href="#edit">{{ $lang('edit') }}</v-tab>
        <v-tab href="#permissions">{{ $lang('permissions') }}</v-tab>
        <v-tab href="#roles">{{ $lang('roles') }}</v-tab>
        <v-tab href="#resetPassword">{{ $lang('reset password') }}</v-tab>
        <v-tab href="#delete">{{ $lang('delete') }}</v-tab>

        <v-tab-item value="info" >
            <UserInfo></UserInfo>
        </v-tab-item>

        <v-tab-item value="edit" >
            <EditUser></EditUser>
        </v-tab-item>


        <v-tab-item value="permissions" >
            <UserPermissions></UserPermissions>
        </v-tab-item>

        <v-tab-item value="roles" >
            <UserRoles></UserRoles>
        </v-tab-item>

        <v-tab-item value="resetPassword" >
            <ResetPassword></ResetPassword>
        </v-tab-item>

        <v-tab-item value="delete" >
            <DeleteUser></DeleteUser>
        </v-tab-item>

    </v-tabs>
</template>

<script>
import UserInfo from "./UserInfo";
import EditUser from "./EditUser";
import UserPermissions from "./UserPermissions";
import UserRoles from "./UserRoles";
import DeleteUser from "./DeleteUser"
import ResetPassword from "./ResetPassword";

export default {
name: "UserTabs",
    components: {ResetPassword, UserRoles, UserPermissions, EditUser, UserInfo, DeleteUser}
}
</script>

<style scoped>

</style>
