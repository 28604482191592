export const fetchProducts = ({ commit }) => {
    return axios.get('/api/masterData/products').then((response) => {
        commit('setProductsData', response.data)
        let products = response.data.filter((product)=> {
            return product.active;
        })
        commit('SET_ACTIVE_PRODUCTS_DATA', products)
    })
}

export const fetchProduct = ({ commit }, id) => {
    return axios.get(`/api/masterData/products/${id}`).then((response) => {
        commit('setProductData', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/masterData/products', payload).then((response) => {
        commit('setProductData', response.data)
        dispatch('fetchProducts')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/masterData/products/${payload.id}`, payload).then((response) => {
        commit('setProductData', response.data)
        dispatch('fetchProducts')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, {id, context} ) => {
    return axios.delete(`/api/masterData/products/${id}`).catch((error) => {
        context.error = true
    })
}

export const setCreateProductDialog = ({ dispatch, state, commit }, value) => {
    commit('setCreateProductDialog', value)
}
