<template>
    <div>
        <v-row
        >

            <v-col
                class="mt-2"
                cols="12"
                md="6"
            >
                <v-card
                    outlined
                    height="100%"
                >
                    <v-card-title
                        class="pt-1 pb-1"
                    >
                        {{$lang('what for')}}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <div v-html="solex.what_for_body"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                class="mt-2"
                cols="12"
                md="6"
            >
                <v-card
                    outlined
                    height="100%"
                >
                    <v-card-title
                        class="pt-1 pb-1"
                    >
                        {{$lang('why')}}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <div v-html="solex.why_body"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row
        >
            <v-col
                class="mt-2"
                cols="12"
                md="6"
            >
                <v-card
                    outlined
                    height="100%"
                >
                    <v-card-title
                        class="pt-1 pb-1"
                    >
                        {{$lang('how')}}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <ul>
                            <li
                                v-for="(step, index) in solex.steps"
                                :key="step.index"
                            >
                                {{step.title}}
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                class="mt-2"
                cols="12"
                md="6"
            >
                <v-card
                    outlined
                    height="100%"
                >
                    <v-card-title
                        class="pt-1 pb-1"
                    >
                        {{$lang('what')}}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <div v-html="solex.what_body"></div>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
        <v-row>
            <v-col
                class="mt-2"
                cols="12"
                md="6"
            >
                <h4>{{$lang('assignee')}}</h4>
                <span
                    v-html="solex.assignee_label"
                >

                </span>
            </v-col>
            <v-col
                v-if="solex.users.length"
                class="mt-2"
                cols="12"
                md="6"
            >
                <h4>{{$lang('team')}}</h4>
                <span
                    v-html="solex.user_labels"
                >

                </span>

            </v-col>
        </v-row>

    </div>


</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "SolexInfo",
    props: {
        solexId: String
    },

    computed: {
        ...mapGetters({
            solex: 'solexes/solex',
        }),
    },

    methods: {
        ...mapActions({
            fetchSolex: 'solexes/fetchSolex'
        }),
    },

    mounted() {
        if(this.solexId){
            this.fetchSolex(this.solexId)
        }
    }

}
</script>

<style scoped>

</style>
