<template>
    <div v-if="department">
        <v-toolbar flat color="white" dense >
                <v-btn
                    small
                    outlined
                    color="primary"
                    to="/masterData/departments"
                    class="mr-2"
                >
                    <v-icon class="mr-1">fas fa-list</v-icon>
                </v-btn>
            <v-toolbar-title >{{ department.name }}</v-toolbar-title>
        </v-toolbar>

        <DepartmentTabs ></DepartmentTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import DepartmentTabs from "../components/DepartmentTabs";

    export default {
        name: "Department",
        components: {
            DepartmentTabs
        },
        props: [
            'departmentId'
        ],
        computed: {
            ...mapGetters({
                department: 'departments/department',
            }),
        },
        created() {
            this.fetchDepartment(this.departmentId)
        },

        methods: {
            ...mapActions({
                fetchDepartment: 'departments/fetchDepartment',
            }),
        }
    }
</script>

<style scoped>

</style>
