<template>
    <div>
        <v-card-text>
            <v-row>
                <v-col
                    md="6"
                    cols="12"
                >
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="password"
                            :label="$lang('password')"
                            type="password"
                            :error-messages="errors.password"
                        >

                        </v-text-field>
                        <v-text-field
                            v-model="new_password"
                            :label="$lang('new password')"
                            type="password"
                            :error-messages="errors.new_password"
                        >

                        </v-text-field>
                        <v-text-field
                            v-model="new_password_confirmation"
                            :label="$lang('repeat password')"
                            type="password"
                            :error-messages="errors.new_password_confirmation"
                        >
                        </v-text-field>
                    </v-form>

                </v-col>
            </v-row>
            <v-row>
                <v-col
                    md="6"
                    cols="12"
                >
                    <v-btn
                        color="primary"
                        text
                        @click="submit"
                    >
                        {{ $lang('save') }}
                    </v-btn>
                </v-col>
            </v-row>

        </v-card-text>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";

export default {
name: "ChangePassword",
    data () {
        return {
            loading: false,
            password: '',
            new_password: '',
            new_password_confirmation: '',
            errors: []
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
    },

    methods: {

        ...mapActions({
            updatePassword: 'account/updatePassword',
        }),

        submit () {
            this.loading = true
            this.errors = []
            this.updatePassword({
                payload: {
                    password: this.password,
                    new_password: this.new_password,
                    new_password_confirmation: this.new_password_confirmation
                },
                context: this,
            }).then(() => {
                this.loading = false
                this.$refs.form.reset()
                if(this.errors.length === 0) {
                    Bus.$emit('showAlert', {color: 'success', 'message': this.$lang('password successfully changed')});
                }
            })
        },
    }
}
</script>

<style >

</style>
