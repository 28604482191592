<template>
    <div v-if="project">
        <v-toolbar flat color="white" dense >
            <v-btn
                small
                outlined
                text
                color="primary"
                to="/projects"
                class="mr-2"
            >
                {{ $lang('project')}}
            </v-btn>
            {{ project.name}}
            <v-toolbar-title >
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <template
                v-if="selectedTab === 'issues'"
                >

                <TableFilterDropDown table-id="projectIssues" key="projectIssues" ></TableFilterDropDown>

            </template>
            <template
                v-if="selectedTab === 'treeTable'"
            >
                <TreeFilter></TreeFilter>

                <TableFilterDropDown table-id="projectsTreeTable" key="projectsTreeTable" ></TableFilterDropDown>

            </template>

            <template
                v-if="selectedTab === 'solex'"
            >
                <TableFilterDropDown table-id="solexesTable" key="solexesTable" ></TableFilterDropDown>
            </template>

            <template
                v-if="selectedTab === 'gantt'"
            >
                <GanttActions></GanttActions>

            </template>

            <v-btn
                v-if="_.includes(project.user_permissions, 'edit') && $companyPlan('solex') && project.solex_settings.allow_solex"
                class="ml-2"
                small
                outlined
                color="primary"
                @click="showCreateSolexDialog({value: true, endpoint: `/api/projects/${project.id}/solexes`})"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('solex')}}
            </v-btn>
            <v-btn
                v-if="_.includes(project.user_permissions, 'edit') && !project.solex_settings.only_solex"
                class="ml-2"
                small
                outlined
                color="primary"
                @click="showCreateIssueDialog({value: true, endpoint: `/api/projects/${project.id}/issues`})"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('issue')}}
            </v-btn>
            <ProjectActionMenu></ProjectActionMenu>
        </v-toolbar>

        <ProjectTabs ></ProjectTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ProjectTabs from "../components/ProjectTabs";
    import TreeFilter from "../components/TreeFilter";
    import TableFilterDropDown from "../../helpers/tableFilters/components/TableFilterDropDown";

    import GanttActions from "../components/GanttActions";
    import ProjectActionMenu from "../components/ProjectActionMenu";
    import Bus from "../../../bus";


    export default {
        name: "Project",
        components: {
            ProjectActionMenu,
            GanttActions,
            TreeFilter,
            ProjectTabs,
            TableFilterDropDown
        },
        props: [
            'projectId'
        ],
        computed: {
            ...mapGetters({
                me: 'auth/user',
                projects: 'projects/projects',
                project: 'projects/project',
                selectedTab: 'projects/selectedTab',
                issue: 'issues/issue',
            }),
        },
        created() {
            this.fetchProject(this.projectId)
        },

        methods: {
            ...mapActions({
                setProject: 'projects/setProject',
                fetchProject: 'projects/fetchProject',
                fetchProjects: 'projects/fetchProjects',
                showCreateIssueDialog: 'issues/setCreateIssueDialog',
                showCreateSolexDialog: 'solexes/setCreateSolexDialog',
            }),
        },

        mounted() {

            const self = this

            Bus.$on('gantt:updated', function () {
                self.fetchProject(self.projectId).then(function (){
                    Bus.$emit('project:updated');
                })
            });

            Bus.$on('issue:updated', function () {
                self.fetchProject(self.project.id).then(function (){
                    Bus.$emit('project:updated');
                })
            });

            Bus.$on('issue:created', function () {
                self.fetchProject(self.projectId).then(function (){
                    Bus.$emit('project:updated');
                })
            });

            Bus.$on('todo:updated', function () {
                self.fetchProject(self.projectId).then(function (){
                    Bus.$emit('project:updated');
                })
            });

            Bus.$on('todo:created', function () {
                self.fetchProject(self.projectId).then(function (){
                    Bus.$emit('project:updated');
                })
            });

            Bus.$on('potential:updated', function () {
                self.fetchProject(self.projectId).then(function (){
                    Bus.$emit('project:updated');
                })
            });

            Bus.$on('potential:created', function () {
                self.fetchProject(self.projectId).then(function (){
                    Bus.$emit('project:updated');
                })
            });

        },

        destroyed: function () {
            Bus.$off('gantt:updated')
            Bus.$off('issue:updated')
            Bus.$off('issue:created')
            Bus.$off('todo:updated')
            Bus.$off('todo:created')
            Bus.$off('potential:updated')
            Bus.$off('potential:created')
        }


    }
</script>

<style scoped>

</style>
