<template>
    <v-dialog
        persistent
        v-model="showCreateProjectDialog"
        width="1200"
        @click:outside="confirmCloseDialog = true"
        @keydown.esc="confirmCloseDialog = true"
    >
        <v-card>
            <v-card-title
                class="headline"
            >
                <template
                    v-if="!copyProject"
                >
                    {{$lang('create project')}}
                </template>
                <template
                    v-else
                >
                    {{$lang('copy project')}}
                </template>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    color="secondary"
                    @click="closeDialog"
                >
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-text-field
                            v-model="form.description"
                            :label="$lang('description')"
                        ></v-text-field>
                        <v-row>
                            <v-col
                                cols="12"
                                md="6">
                                <v-select
                                    v-model="form.project_type_id"
                                    :items="projectTypes"
                                    :label="$lang('project type')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.project_type_id = null"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.project_type_id"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6">
                                <v-select
                                    v-model="form.project_status_id"
                                    :items="projectStatuses"
                                    :label="$lang('project status')"
                                    :item-text="item => $lang(item.name)"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.project_status_id = null"
                                    item-value="id"
                                    :error-messages="errors.project_status_id"
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    v-model="form.location_id"
                                    :items="locations"
                                    :label="$lang('location')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.location_id = null"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.location_id"
                                ></v-select>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    v-model="form.project_leader_id"
                                    :items="users"
                                    :label="$lang('project leader')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.project_leader_id = null"
                                    item-text="full_name"
                                    item-value="id"
                                    :error-messages="errors.project_leader_id"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    v-model="form.division_id"
                                    :items="filtered_divisions"
                                    :label="$lang('division')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.division_id = null"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.division_id"
                                ></v-select>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    v-model="form.department_id"
                                    :items="filtered_departments"
                                    :label="$lang('department')"
                                    append-outer-icon="fas fa-times"
                                    @click:append-outer="form.department_id = null"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors.department_id"
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-dialog
                                    ref="start_date_dialog"
                                    v-model="start_date_modal"
                                    :return-value.sync="picker_start_date"
                                    persistent
                                    width="290px"

                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.start_date"
                                            :label="$lang('start')"
                                            append-outer-icon="fas fa-times"
                                            @click:append-outer="form.start_date = null"
                                            append-icon="fas fa-calendar-alt"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="errors.start_date"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="picker_start_date"
                                        scrollable
                                        @change="$refs.start_date_dialog.save(picker_start_date)"
                                        :max="copyProject ? projectDateLimits.max_start_date : null"
                                        :first-day-of-week="1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="picker_start_date = ''">{{ $lang('delete') }}</v-btn>
                                        <v-btn text color="primary" @click="start_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.start_date_dialog.save(picker_start_date)">{{ $lang('ok') }}</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-dialog
                                    ref="end_date_dialog"
                                    v-model="end_date_modal"
                                    :return-value.sync="picker_end_date"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.end_date"
                                            :label="$lang('end')"
                                            append-outer-icon="fas fa-times"
                                            @click:append-outer="form.end_date = null"
                                            append-icon="fas fa-calendar-alt"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="errors.end_date"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="picker_end_date"
                                        scrollable
                                        @change="$refs.end_date_dialog.save(picker_end_date)"
                                        :min="copyProject ? projectDateLimits.min_end_date : null"
                                        :first-day-of-week="1"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="picker_rnf_date = ''">{{ $lang('delete') }}</v-btn>
                                        <v-btn text color="primary" @click="end_date_modal = false">{{ $lang('cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.end_date_dialog.save(picker_end_date)">{{ $lang('ok') }}</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                        </v-row>


                        <v-row
                            v-if="copyProject"
                        >
                            <v-col
                                cols="12"
                                md="3"
                            >

                                <v-switch
                                    v-model="form.copy_issues"
                                    :label="$lang('copy issues')"
                                ></v-switch>

                            </v-col>
                            <v-col
                                cols="12"
                                md="3"
                            >
                                <v-switch
                                    v-model="form.copy_first_level_todos"
                                    :label="$lang('copy first level todos')"
                                    :disabled="!form.copy_issues"
                                ></v-switch>
                            </v-col>
                            <v-col
                                cols="12"
                                md="3"
                            >
                                <v-switch
                                    v-model="form.copy_all_todos"
                                    :label="$lang('copy all todos')"
                                    :disabled="!form.copy_first_level_todos"
                                ></v-switch>
                            </v-col>
                            <v-col
                                cols="12"
                                md="3"
                            >
                                <v-switch
                                    v-model="form.copy_permissions"
                                    :label="$lang('copy permissions')"
                                ></v-switch>
                            </v-col>
                        </v-row>


                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">{{ $lang('cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save">{{ $lang('save') }}</v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog
            v-model="confirmCloseDialog"
            width="1000"
        >
            <v-card>
                <v-card-title
                    primary-title
                    class="justify-center"
                >
                    {{$lang('close dialog')}}
                </v-card-title>
                <v-card-text
                    class="text-center"
                >
                    <strong>
                        {{$lang('do you really want to close without saving?')}}
                    </strong>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text
                        @click="saveAndClose"
                    >
                        {{$lang('save and close')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="closeWithoutSaving"
                    >
                        {{$lang('close without saving')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="confirmCloseDialog = false"
                    >
                        {{$lang('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Bus from "../../../bus";
    export default {
        name: "CreateProjectDialog",
        props: [
            'createProjectDialog'
        ],
        data() {
            return {
                errors: [],
                confirmCloseDialog: false,
                picker_start_date:'',
                picker_end_date:'',
                start_date_modal: false,
                end_date_modal: false,
                filtered_divisions: [],
                filtered_departments: [],
                form: {
                    name: '',
                    description: '',
                    project_status_id: '',
                    project_type_id: null,
                    project_leader_id: null,
                    start_date:'',
                    end_date:'',
                    location_id: null,
                    division_id: null,
                    department_id: null,
                    copy_project_id: null,
                    copy_issues: 0,
                    copy_first_level_todos: 0,
                    copy_all_todos: 0,
                    copy_permissions: 0
                }
            }
        },

        created() {
            this.fetchProjectStatuses()
            this.fetchProjectTypes()
            this.fetchUsers()
            this.fetchLocations()
            if(this.copyProject) {
                this.copyData()
            }
        },

        computed: {
            ...mapGetters({
                showCreateProjectDialog: 'projects/showCreateProjectDialog',
                projectTypes: 'projectTypes/projectTypes',
                projectStatuses: 'projectStatuses/projectStatuses',
                project: 'projects/project',
                users: 'users/users',
                locations: 'locations/locations',
                copyProject: 'projects/copyProject',
                projectDateLimits: 'projects/projectDateLimits'
            }),
        },

        methods: {
            ...mapActions({
                fetchUsers: 'users/fetchUsers',
                fetchLocations: 'locations/fetchLocations',
                store: 'projects/store',
                fetchProjectStatuses: 'projectStatuses/fetchProjectStatuses',
                fetchProjectTypes: 'projectTypes/fetchProjectTypes',
                actionShowCreateProjectDialog: 'projects/setCreateProjectDialog',
                setCopyProject: 'projects/setCopyProject',
                fetchProjectDateLimits: 'projects/fetchProjectDateLimits'
            }),

            save () {
                this.errors = []
                this.store({
                    payload: this.form,
                    context: this
                }).then(() => {
                    if(this.errors.length === 0) {
                        this.$refs.form.reset()
                        this.$refs.form.resetValidation()
                        this.closeDialog()
                        Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('project successfully created')});

                        this.$router.push({name: `projects.show`, params: { projectId: this.project.id}})
                    }
                })
            },

            saveAndClose() {
                this.confirmCloseDialog = false
                this.save()
            },

            closeWithoutSaving() {
                this.$refs.form.reset()
                this.errors = []
                this.closeDialog()
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            },

            closeDialog () {
                this.actionShowCreateProjectDialog(false)
                this.setCopyProject(false)
            },

            copyData() {

                this.form.name = this.project.name
                this.form.copy_project_id = this.project.id
                this.form.start_date = this.project.start_date
                this.form.end_date = this.project.end_date
            }
        },

        mounted() {
            if(this.project) {
                this.fetchProjectDateLimits(`/api/projects/${this.project.id}`)
            }
        },


        watch: {
            picker_start_date (val) {
                this.form.start_date = this.formatDate(this.picker_start_date)
            },
            picker_end_date (val) {
                this.form.end_date = this.formatDate(this.picker_end_date)
            },

            'form.location_id': {
                handler: function() {
                    const self = this
                    this.form.division_id = null
                    this.filtered_divisions = []
                    if(this.form.location_id !== null && typeof this.form.location_id !== 'undefined' ) {
                        this.filtered_divisions = _.find(this.locations, function(o) { return o.id === self.form.location_id  }).divisions
                    }
                }
            },

            'form.division_id': {
                handler: function() {
                    const self = this
                    this.form.department_id = null
                    this.filtered_departments = []
                    if(this.form.division_id !== null) {
                        this.filtered_departments = _.find(this.filtered_divisions, function(o) { return o.id === self.form.division_id  }).departments
                    }
                },
            },
        },
    }
</script>

<style scoped>

</style>
