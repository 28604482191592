export const issueTags = (state) => {
    return state.issueTags
}

export const activeIssueTags = (state) => {
    return state.activeIssueTags
}

export const issueTag = (state) => {
    return state.issueTag
}

export const showCreateIssueTagDialog = (state) => {
    return state.showCreateIssueTagDialog
}
