<template>
    <v-dialog
        v-if="kanbanCircuit"
        v-model="showKanbanCircuitDialog"
        width="1200"
        @click:outside="closeDialog"
    >
        <v-toolbar flat color="white" dense v-if="kanbanCircuit">
            <v-toolbar-title >{{ kanbanCircuit.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                small
                outlined
                color="primary"
                @click="closeDialog"
            >
                <v-icon class="mr-1">fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>
        <KanbanCircuitTabs :key="kanbanCircuit.id"></KanbanCircuitTabs>
    </v-dialog>
</template>

<script>
    import KanbanCircuitTabs from "./KanbanCircuitTabs";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "KanbanCircuitDialog",
        components: {KanbanCircuitTabs},
        data() {
            return {


            }
        },

        computed: {
            ...mapGetters({
                kanbanCircuit: 'kanbanCircuits/kanbanCircuit',
                showKanbanCircuitDialog: 'kanbanCircuits/showKanbanCircuitDialog',
            }),
        },

        methods: {
            ...mapActions({
                actionShowKanbanCircuitDialog: 'kanbanCircuits/setKanbanCircuitDialog',
            }),

            closeDialog () {
                this.actionShowKanbanCircuitDialog(false)
            }
        },
    }
</script>

<style scoped>

</style>
