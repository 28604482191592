<template>
    <div style="min-height: 300px">
        <v-toolbar flat color="white" >
            <v-toolbar-title >
                {{ $lang('files') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="searchQuery"
                :label="$lang('search')"
                class="mr-5 mt-6"
                clearable
                @click:clear="clearSearchQuery"
            ></v-text-field>
            <v-btn
                v-if="showArchived"
                small
                outlined
                color="primary"
                class="mr-2"
                @click="showArchived = false"
            >
                {{$lang('hide archived')}}
            </v-btn>
            <v-btn
                v-if="!showArchived"
                small
                outlined
                color="primary"
                class="mr-2"
                @click="showArchived = true"
            >
                {{$lang('show archived')}}
            </v-btn>
            <template
                v-if="!searchQuery ||!searchQuery.length"
                >
                <CreateFolder
                    :endpoint="`${baseUrl}/api/fileManager/${objects.id}/storeFolder`"
                    v-if="_.includes(objects.user_permissions, 'create folder')"
                ></CreateFolder>
                <FileUpload
                    :endpoint="`${baseUrl}/api/fileManager/${objects.id}`"
                    v-if="_.includes(objects.user_permissions, 'upload file')"
                    :key="objects.id"></FileUpload>
            </template>


        </v-toolbar>

        <template
            v-if="searchQuery !== null && searchQuery.length"
            >
            <template
                v-if="objects.length > 1 || searchQuery.length === 0"
            >
                {{objects.length}} {{ $lang('results') }}
            </template>
            <template
                v-else
            >
                {{objects.length}} {{ $lang('result') }}
            </template>
        </template>

        <template
            v-else
            v-for="(ancestor,index) in ancestors"
            >
            <v-btn

                color="primary"
                text
                @click="fetchObjects(`/api/fileManager/${ancestor.id}`)"
                :disabled="index === ancestors.length - 1"
            >
                {{ ancestor.objectable.name.toUpperCase() }}
            </v-btn>
            <template v-if="index !== ancestors.length - 1">
                |
            </template>
        </template>
        <v-simple-table
            fixed-header
            :height="tableHeight"
        >
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        {{ $lang('name') }}
                    </th>
                    <th class="text-right">
                        {{ $lang('size') }}
                    </th>
                    <th class="text-right">
                        {{ $lang('created') }}
                    </th>
                    <th
                        v-if="showArchived"
                        class="text-right"
                        nowrap="true"
                    >
                        {{ $lang('archived') }}
                    </th>
                    <th
                        v-if="showArchived"
                        class="text-right"
                        nowrap="true"
                    >
                        {{ $lang('archived') }}
                    </th>
                    <th class="text-right">

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-if="objects.children.length && (showArchived || !object.archived_at)"
                    v-for="object in objects.children"
                    :key="object.uuid"
                >
                    <td>
                        <v-icon
                            style="width: 25px"
                            v-if="object.objectable_type === 'file'"
                        >
                            far fa-file
                        </v-icon>
                        <v-icon
                            style="width: 25px"
                            v-if="object.objectable_type === 'folder'"
                        >
                            far fa-folder
                        </v-icon>

                        <template
                            v-if="object.objectable_type === 'folder'"
                        >
                            <v-btn
                                color="primary"
                                text
                                @click="openFolder(object.id)"
                            >
                                {{ object.objectable.name }}
                            </v-btn>
                        </template>
                        <template
                            v-else
                        >
                            <v-btn
                                color="primary"
                                text
                                :href="`${baseUrl}/api/fileManager/${object.objectable.id}/downloadFile`"
                            >
                                {{ object.objectable.name }}
                            </v-btn>
                        </template>
                    </td>
                    <td class="text-right">
                        <template
                            v-if="object.objectable_type === 'file'"
                        >
                            {{ object.objectable.size | prettyBytes }}
                        </template>
                        <template
                            v-else
                            >
                            &mdash;
                        </template>
                    </td>
                    <td class="text-right">
                        {{ object.created_at}}
                    </td>
                    <td
                        v-if="showArchived"
                        class="text-right"
                        nowrap="true"
                    >
                        {{ object.archivist_name}}
                    </td>
                    <td
                        v-if="showArchived"
                        class="text-right"
                        nowrap="true"
                    >
                        {{ object.archived_at}}
                    </td>
                    <td class="text-right">
                        <v-btn
                            v-if="!object.archived_at"
                            color="primary"
                            text
                            small
                            @click.prevent="archive(object)"
                        >
                            {{ $lang('archive') }}
                        </v-btn>
                        <v-btn
                            v-if="object.archived_at"
                            color="error"
                            text
                            small
                            @click.prevent="retrieve(object)"
                        >
                            {{ $lang('retrieve') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            text
                            small
                            @click="renameObject(object)"
                            :disabled="object.relationable_id !== null || !_.includes(object.user_permissions, 'edit file/folder')"
                        >
                            {{ $lang('rename') }}
                        </v-btn>
                        <v-btn
                            color="error"
                            text
                            small
                            @click="confirmDeleteObject(object)"
                            :disabled="object.relationable_id !== null || !_.includes(object.user_permissions, 'edit file/folder')"
                        >
                            {{ $lang('delete') }}
                        </v-btn>
                    </td>
                </tr>
                <tr
                    v-if="objects.length"
                    v-for="object in objects"
                    :key="object.uuid"
                >
                    <td>
                        <v-icon
                            style="width: 25px"
                            v-if="object.objectable_type === 'file'"
                        >
                            far fa-file
                        </v-icon>
                        <v-icon
                            style="width: 25px"
                            v-if="object.objectable_type === 'folder'"
                        >
                            far fa-folder
                        </v-icon>

                        <template
                            v-if="object.objectable_type === 'folder'"
                        >
                            <v-btn
                                color="primary"
                                text
                                @click="openFolder(object.id)"
                            >
                                {{ object.objectable.name }}
                            </v-btn>
                        </template>
                        <template
                            v-else
                        >
                            <v-btn
                                color="primary"
                                text
                                :href="`${baseUrl}/api/fileManager/${object.objectable.id}/downloadFile`"
                            >
                                {{ object.objectable.name }}
                            </v-btn>
                        </template>
                    </td>
                    <td class="text-right">
                        <template
                            v-if="object.objectable_type === 'file'"
                        >
                            {{ object.objectable.size | prettyBytes }}
                        </template>
                        <template
                            v-else
                        >
                            &mdash;
                        </template>
                    </td>
                    <td class="text-right">
                        {{ object.created_at}}
                    </td>
                    <td class="text-right">
                        <v-btn
                            v-if="!object.archived_at"
                            color="primary"
                            text
                            small
                            @click="archive(object)"
                        >
                            {{ $lang('archive') }}
                        </v-btn>
                        <v-btn
                            v-if="object.archived_at"
                            color="error"
                            text
                            small
                            @click="retrieve(object)"
                        >
                            {{ $lang('retrieve') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            text
                            small
                            @click="renameObject(object)"
                            :disabled="object.relationable_id !== null || !_.includes(object.user_permissions, 'edit file/folder')"
                        >
                            {{ $lang('rename') }}
                        </v-btn>
                        <v-btn
                            color="error"
                            text
                            small
                            @click="confirmDeleteObject(object)"
                            :disabled="object.relationable_id !== null || !_.includes(object.user_permissions, 'edit file/folder')"
                        >
                            {{ $lang('delete') }}
                        </v-btn>

                    </td>
                </tr>
                </tbody>


                <template
                        v-if="objects && objects.children && !objects.children.length"
                    >
                    <h3
                        class="grey--text ml-4 mt-4"

                    >
                        {{ $lang('this folder is empty') }}
                    </h3>

                </template>
            </template>
        </v-simple-table>
        <v-dialog
            v-model="confirmDeleteObjectDialog"
            max-width="600"
        >
            <v-card>
                <v-card-title>
                    {{$lang('do you really want to delete this file/folder?')}}
                </v-card-title>

                <v-card-text>
                    <v-switch
                        v-model="confirm"
                        :label="`${ $lang('yes, please delete!')}` "
                    ></v-switch>
                </v-card-text>
                    <v-card-actions>
                        <v-btn
                            v-if="confirm"
                            color="error"
                            @click="destroyObject(`${baseUrl}/api/fileManager/${object.id}`)"
                            text
                        >
                            {{$lang('delete')}}
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="confirmDeleteObjectDialog = false"
                            text
                        >
                            {{ $lang('cancel') }}
                        </v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="renameObjectDialog"
            max-width="600"
        >
            <v-card>
                <v-card-title>
                    {{ $lang('rename file/folder')}}
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        v-model="name"
                    >
                    </v-text-field>
                </v-card-text>



                <v-card-actions>
                    <v-btn
                        color="primary"
                        @click="submit(object)"
                        text
                    >
                        {{ $lang('save') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="renameObjectDialog = false"
                        text
                    >
                        {{ $lang('cancel') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import CreateFolder from "./CreateFolder";
import FileUpload from "./FileUpload";
import Bus from "../../../bus";

export default {
name: "FileBrowser",
    components: {
    CreateFolder,
    FileUpload
    },
    data() {
        return {
            confirmDeleteObjectDialog: false,
            renameObjectDialog: false,
            confirm: false,
            file: null,
            object: null,
            searchQuery: '',
            name: null,
            componentEndpoint: '',
            ancestors: '',
            showArchived: false
        }
    },
    props: {
        endpoint: String,
        tableMarginBottom: {
            type: Number,
            default: 300
        }
    },

    computed: {
        ...mapGetters({
            objects: 'fileManager/objects'
        }),

        tableHeight: function() {
            return window.innerHeight - this.tableMarginBottom + "px"
        },

        baseUrl: function () {
            return process.env.VUE_APP_BASE_URL
        }
    },

    methods: {
        ...mapActions({
            fetchObjects: 'fileManager/fetchObjects',
            updateObject: 'fileManager/updateObject',
            deleteObject: 'fileManager/deleteObject',
            archiveObject: 'fileManager/archiveObject',
            retrieveObject: 'fileManager/retrieveObject'
        }),

        clearSearchQuery() {
            this.searchQuery = ''
        },

        getObjects() {
            const self = this
            if (this.searchQuery !== null && this.searchQuery.length) {
                this.fetchObjects(`/api/fileManager?searchQuery=${this.searchQuery}`)
            }
            else {
                this.fetchObjects(this.componentEndpoint).then(function (){
                    self.ancestors =  _.reverse(self.objects.ancestors_and_self)
                })
            }
        },

        openFolder(id) {
            const self = this
            this.componentEndpoint = `/api/fileManager/${id}`
            this.fetchObjects(this.componentEndpoint).then(function (){
                self.ancestors =  _.reverse(self.objects.ancestors_and_self)
            })
        },

        confirmDeleteObject(object) {
            this.object = object
            this.confirmDeleteObjectDialog =true
        },

        renameObject(object) {
            this.object = object
            this.name = object.objectable.name
            this.renameObjectDialog = true
        },

        destroyObject(endpoint) {
            const self = this
            this.deleteObject(endpoint).then(function (){
                self.confirm = false
                self.getObjects()
                self.confirmDeleteObjectDialog =false
                Bus.$emit('showAlert', {color: 'success', 'message': this.$lang('file/folder successfully deleted')});
            })
        },

        submit () {
            this.errors = []
            this.updateObject({
                payload: {
                    name: this.name
                },
                endpoint: `/api/fileManager/${this.object.id}`,
                context: this,
            }).then(() => {
                const self = this
                if(self.errors.length === 0) {
                    self.getObjects()
                    self.renameObjectDialog = false
                    Bus.$emit('showAlert', {color: 'success', 'message': this.$lang('file/folder successfully renamed')});
                }
            })
        },

        archive(object) {
            this.archiveObject({
                endpoint: `/api/fileManager/${object.id}/archiveObject`,
                context: this,
            }).then(() => {
                this.getObjects()
                Bus.$emit('showAlert', {color: 'success', 'message': this.$lang('file/folder successfully archived')});
            })
        },

        retrieve(object) {
            this.retrieveObject({
                endpoint: `/api/fileManager/${object.id}/retrieveObject`,
                context: this,
            }).then(() => {
                this.getObjects()
                Bus.$emit('showAlert', {color: 'success', 'message': this.$lang('file/folder successfully retrieved')});
            })
        }

    },

    created() {
    const self = this
        this.componentEndpoint = this.endpoint
        this.fetchObjects(this.endpoint).then(function (){
            self.ancestors =  _.reverse(self.objects.ancestors_and_self)
        })

    },

    mounted() {
        const self = this


    },
    watch: {
        searchQuery: function (value) {
            this.getObjects()
        },
        objects:  function () {
            this.ancestors =  _.reverse(this.objects.ancestors_and_self)
        }
    }
}
</script>

<style scoped>

</style>
