<template>
    <div>

        <v-menu
            offset-y

        >
            <template v-slot:activator="{ on }">
                <v-btn
                    v-if="!user.avatar"
                    color="primary"
                    v-on="on"
                    small
                >
                    {{ user.first_name}} {{ user.last_name}}
                </v-btn>
                <v-btn
                    v-else
                    text
                    v-on="on"
                >
                    <v-avatar
                        size="32px"
                        item
                    >
                        <img :src="user.avatar">
                    </v-avatar>
            </v-btn>

            </template>
            <v-list>

                <v-list-item to="/account">
                    <v-list-item-title>{{ $lang('my 7app')}}</v-list-item-title>
                </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title>{{ $lang('logout')}}</v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog
            v-model="avatarDialog"
            width="700"
        >
            <Avatar v-on:close-dialog="closeDialog"></Avatar>

        </v-dialog>
    </div>
</template>

<script>
    import Avatar from "./Avatar";
    import { mapGetters, mapActions} from "vuex"
    export default {
        name: "AvatarDropDown",
        components: {
            Avatar
        },

        computed: {
            ...mapGetters({
                user: 'auth/user',
            }),
        },

        data: () => ({
            avatarDialog: false,

        }),
        methods: {
            ...mapActions ({
              logout: 'auth/logout'
            }),

            closeDialog() {
                this.avatarDialog = false
            },

        }

    }
</script>

<style scoped>
img {
    height: 32px; min-width: 32px; width: 32px;
}
</style>
