<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                md="4"
            >
                <v-btn
                    @click="setShowScannerDialog(true)"
                    block
                    color="primary"
                    x-large
                >
                    {{$lang('scanner')}}
                </v-btn>

            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
name: "MobileDashboard",

    methods: {
        ...mapActions({
            setShowScannerDialog: 'scanner/setScannerDialog'
        }),
    }
}
</script>

<style scoped>

</style>
