<template>
    <v-dialog
        v-if="kanbanCircuit"
        v-model="showKanbanCircuitDialog"
        width="600"
        @click:outside="closeDialog"
    >


        <v-card>
            <v-card-title>
                {{ kanbanCircuit.name }}
                <v-spacer></v-spacer>
                <v-btn
                    small
                    outlined
                    color="primary"
                    @click="closeDialog"
                >
                    <v-icon class="mr-1">fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="kanbanCircuit.supplier_stock"
                    :label="$lang('supplier stock')"
                    :error-messages="errors.supplier_stock"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="closeDialog">{{$lang('cancel')}}</v-btn>
                <v-btn color="blue darken-1" text @click="submit">{{$lang('save')}}</v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "KanbanCircuitDialog",
        components: {},
        data() {
            return {
                errors: []
            }
        },

        computed: {
            ...mapGetters({
                kanbanCircuit: 'salesKanbanCircuits/kanbanCircuit',
                showKanbanCircuitDialog: 'salesKanbanCircuits/showKanbanCircuitDialog',
            }),
        },

        methods: {
            ...mapActions({
                setKanbanCircuitDialog: 'salesKanbanCircuits/setKanbanCircuitDialog',
                update: 'salesKanbanCircuits/update'
            }),


            submit () {

                const self = this
                this.errors = []
                this.update({
                    kanbanCircuitId: this.kanbanCircuit.id,
                    payload: {
                        supplier_stock: this.kanbanCircuit.supplier_stock
                    },
                    context: this,
                }).then(() => {
                    if(this.errors.length === 0) {
                        this.errors = []
                        Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('kanban cirquit successfully changed')})
                        this.setKanbanCircuitDialog(false)
                    }
                })
            },

            closeDialog () {
                this.setKanbanCircuitDialog(false)
            }
        },
    }
</script>

<style scoped>

</style>
