<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title> {{ $lang('kanban circuits')}}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn
                small
                outlined
                color="primary"
                @click="setCreateKanbanCircuitDialog(true)"
            >
                <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('kanban circuit')}}
            </v-btn>
        </v-toolbar>
        <CreateKanbanCircuitDialog></CreateKanbanCircuitDialog>
        <KanbanCircuitsTable></KanbanCircuitsTable>
        <KanbanCircuitDialog></KanbanCircuitDialog>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import CreateKanbanCircuitDialog from "../components/CreateKanbanCircuitDialog";
    import KanbanCircuitsTable from "../components/KanbanCircuitsTable";
    import KanbanCircuitDialog from "../components/KanbanCircuitDialog";

    export default {
        name: "KanbanCircuits",
        components: {
            KanbanCircuitDialog,
            KanbanCircuitsTable,
            CreateKanbanCircuitDialog
        },

        methods: {
            ...mapActions({
                fetchKanbanCircuits: 'kanbanCircuits/fetchKanbanCircuits',
                setCreateKanbanCircuitDialog: 'kanbanCircuits/setCreateKanbanCircuitDialog',
            }),
        },
        mounted() {
            this.fetchKanbanCircuits()
        }
    }

</script>

<style scoped>

</style>
