<template>
    <div class="container">
        <Bar :chart-data="chartData" :chart-options="options"/>
    </div>
</template>
<script>
import { Bar } from 'vue-chartjs'
import { Chart, LineController, LineElement, BarController, BarElement,  PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from 'chart.js';

Chart.register(LineController, LineElement, BarController, BarElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend);
export default {
    name: "KanbanSupplierTimeUntilShipmentChart",

    components: { Bar },

    props: {
        height: Number,
        filteredDashboardData: Array
    },

        data () {
            return {
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            label: this.$lang('time until shipment') + ' ' + this.$lang('days'),
                            borderWidth: 1,
                            borderColor: 'rgba(21, 101, 192, 0.7)',
                            backgroundColor: 'rgba(21, 101, 192, 0.2)',
                            data: []
                        },
                    ]
                },
                options: {

                    scales: {
                        yAxes: {
                            ticks: {
                                min: 0,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.$lang('days')
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        },

        methods: {
            render() {
                const hash = {};
                this.filteredDashboardData.forEach(o => {
                    if(hash[o.supplier_name]!== undefined) {
                        hash[o.supplier_name] = {
                            numOrders: hash[o.supplier_name].numOrders + 1,
                            totalDays: hash[o.supplier_name].totalDays + o.time_until_shipment,
                            numSentOrders: o.shipped_at ? hash[o.supplier_name].numSentOrders + 1 : hash[o.supplier_name].numSentOrders
                        }
                    }
                    else {
                        hash[o.supplier_name] = {
                            numOrders: 1,
                            totalDays: o.time_until_shipment,
                            numSentOrders: o.shipped_at ? 1 : 0
                        }
                    }
                })

                const suppliers = Object.keys(hash).map(name => ({ name: name, summary: hash[name] }))

                this.chartData.labels = _.map(suppliers, 'name')
                this.chartData.datasets[0].data = _.map(suppliers, this.getAverageTimeToSend)
            },

            getAverageTimeToSend(o) {
                return (o.summary.totalDays / o.summary.numSentOrders).toFixed(2)
            }
        },

        mounted() {
            this.render()
        },

        watch: {
            filteredDashboardData: {
                handler: function() {
                    this.render()
                },
                deep: true
            },
        },
    }
</script>

<style scoped>

</style>



