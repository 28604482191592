<template>
    <div>
        <v-btn
            small
            color="primary"
            dark
            @click.stop="dialog = true"
        >
            <v-icon class="mr-1">fas fa-plus</v-icon> {{ $lang('user')}}
        </v-btn>

        <v-dialog
            v-model="dialog"
            max-width="600"
        >
            <v-card>
                <v-card-title class="headline">{{   $lang('create user')}}</v-card-title>

                <v-card-text>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.first_name"
                            :label="$lang('first name') "
                            :error-messages="errors.first_name"
                            autofocus
                        ></v-text-field>
                        <v-text-field
                            v-model="form.last_name"
                            :label="$lang('last name') "
                            :error-messages="errors.last_name"
                        ></v-text-field>
                        <v-radio-group v-model="form.is_email">
                            <v-radio
                                :label="$lang('email') "
                                :value="1"
                            ></v-radio>
                            <v-radio
                                :label="$lang('username') "
                                :value="0"
                            ></v-radio>
                        </v-radio-group>
                        <v-text-field
                            v-model="form.email"
                            :label="form.is_email ? this.$lang('email') : this.$lang('username')"
                            :error-messages="errors.email"
                            :autofocus="false"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.password"
                            :label="$lang('password') "
                            :error-messages="errors.password"
                        ></v-text-field>
                        <v-select
                            v-model="form.company_id"
                            :items="companies"
                            :label="$lang('company') "
                            item-text="name"
                            item-value="id"
                            :error-messages="errors.company_id"
                        ></v-select>
                        <v-select
                            v-model="form.lang"
                            :items="supportedLangs"
                            :label="$lang('language') "
                            :error-messages="errors.lang"
                        ></v-select>
                        <v-switch
                            v-model="form.active"
                            :label="$lang('active')"
                        ></v-switch>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        {{ $lang('cancel') }}
                    </v-btn>

                    <v-btn
                        color="primary"
                        text
                        @click="save"
                    >
                        {{ $lang('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CreateUserDialog",
    data() {
        return {
            dialog: false,
            errors: [],
            form: {
                first_name: '',
                last_name:'',
                is_email: 1,
                email: '',
                active: 1,
                password: '',
                company_id: '',
                lang: ''
            }
        }
    },

    computed: {
        ...mapGetters({
            companies: 'adminCompanies/companies',
            supportedLangs: 'lang/supportedLangs'
        }),
    },

    methods: {
        ...mapActions({
            store: 'adminUsers/store',
            fetchCompanies: 'adminCompanies/fetchCompanies',
        }),

        save () {
            this.errors = []
            this.store({
                payload: this.form,
                context: this
            }).then(() => {
                if(this.errors.length === 0) {
                    this.$refs.form.reset()
                    this.dialog = false
                }
            })
        },
    },

    mounted() {
        this.fetchCompanies()
    }
}
</script>

<style scoped>

</style>
