import router from "../../../router";
import localforage from 'localforage'

export const login = ({ dispatch, commit }, credentials) => {
    const self = this
    axios.get('/sanctum/csrf-cookie').then((respones) => {
        axios.post('/api/login', credentials).then((response) => {
            dispatch('me').then((response) =>{
                let companyPlans = response.data.data.companyPlans

                if(!companyPlans.includes('project'))  {
                    if(!companyPlans.includes('kanban')) {
                        router.push('procurement/kanbanCircuits')
                    }
                    if(!companyPlans.includes('supplier')) {
                        router.push('sales/kanbanOrders')
                    }
                }
                else {
                    router.push('dashboard')
                }
            })
        })
            .catch(function (error) {
                commit('SET_LOGIN_ERROR', 'Fehler bei Email/Benutzername oder Passwort!')
            })
    })
}

export const me =  ({ commit, dispatch }) => {

    return axios.get('/api/me').then((response) => {
        commit('setAuthenticated', true)
        commit('setUserData', response.data.data)
        window.number_format = response.data.data.number_format
        return dispatch('masterData/fetchMasterData', [], {root: true}).then(() =>{
            return response
        })

        //window.translations = response.data.data.translations
    }).catch(() => {
        commit('setAuthenticated', false)
        commit('setUserData', null)
    })
}


export const fetchMe =  ({ commit, dispatch }) => {
    return axios.get('/api/me').then((response) => {
        commit('setUserData', response.data.data)
    }).catch(() => {

    })
}

export const checkAuthenticated = ({ state }) => {
        if (!state.authenticated) {
            return Promise.reject('NO_STORAGE_TOKEN');
        }
        return Promise.resolve()
}

export const setUnauthenticated = ({ state, commit }) => {
    commit('setAuthenticated', false)
}


export const setCompany = ({ commit, dispatch }, id) => {
    dispatch('resetData').then(function (){
        return axios.post(`/api/setCompany/${id}`).then((response) => {
            dispatch('goToDashboard').then(function (){
                location.reload()
            })
        })
    })
}
export const goToDashboard = ({ dispatch }, id) => {
    router.push({ name: 'dashboard' })
}


export const logout = ({ dispatch }, id) => {
    return axios.post('/api/logout').then((response) => {
        location.reload()
        return dispatch('me')
    })
}

export const resetData = ({ dispatch,commit }) => {
    commit('projects/SET_PROJECTS_DATA', [], {root: true})
    commit('projects/SET_PROJECT_DATA', [], {root: true})
    commit('issues/SET_ISSUES_DATA', [], {root: true})
    commit('issues/SET_ISSUE_DATA', [], {root: true})
    commit('todos/SET_TODOS_DATA', [], {root: true})
    commit('todos/SET_TODO_DATA', [], {root: true})
    commit('potentials/SET_POTENTIALS_DATA', [], {root: true})
    commit('potentials/SET_POTENTIAL_DATA', [], {root: true})
}

export const setShowLogoutMessage = ({ commit }, value) => {
    commit('SET_SHOW_LOGOUT_MESSAGE', value)
}



