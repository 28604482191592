export const products = (state) => {
    return state.products
}

export const activeProducts = (state) => {
    return state.activeProducts
}

export const product = (state) => {
    return state.product
}

export const showCreateProductDialog = (state) => {
    return state.showCreateProductDialog
}
