export const SET_SHOW_CONTROLLING_SELECT_FILTER = (state, data) => {
    state.showControllingSelectFilter = data
}

export const SET_DASHBOARD_ISSUES_DATA = (state, data) => {
    state.dashboardIssuesData = data
}

export const SET_DASHBOARD_FILTERS_DATA = (state, data) => {
    state.dashboardFilters = data
}

export const SET_ACTIVE_FILTER_DATA = (state, data) => {
    state.activeFilters[data.dashboardId] = data.filter
}

export const UNSET_ACTIVE_FILTER_DATA = (state, dashboardId) => {
    delete state.activeFilters.dashboardId;
}

export const SET_FILTERING = (state, value) => {
    state.filtering = value;
}

export const SET_CURRENT_FILTER_DATA = (state, data) => {
    state.currentFilter = data
}
