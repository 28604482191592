<template>
    <div v-if="location">
        <v-toolbar flat color="white" dense >
                <v-btn
                    small
                    outlined
                    color="primary"
                    to="/masterData/locations"
                    class="mr-2"
                >
                    <v-icon class="mr-1">fas fa-list</v-icon>
                </v-btn>
            <v-toolbar-title >{{ location.name }}</v-toolbar-title>
        </v-toolbar>

        <LocationTabs ></LocationTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import LocationTabs from "../components/LocationTabs";

    export default {
        name: "Project",
        components: {
            LocationTabs
        },
        props: [
            'locationId'
        ],
        computed: {
            ...mapGetters({
                location: 'locations/location',
            }),
        },
        created() {
            this.fetchLocation(this.locationId)
        },

        methods: {
            ...mapActions({
                fetchLocation: 'locations/fetchLocation',
            }),
        }
    }
</script>

<style scoped>

</style>
