export const setDepartmentsData = (state, data) => {
    state.departments = data
}

export const setDepartmentData = (state, data) => {
    state.department = data
}

export const addDepartment = (state, data) => {
    state.departments.push(data)
}

export const updateDepartmentData = (state, data) => {
    Object.assign(state.departments[data.index], data.data)
}

export const removeDepartment = (state, item) => {
    const index = _.indexOf(state.departments, item)
    state.departments.splice(index, 1)
}

export const setCreateDepartmentDialog = (state, data) => {
    state.showCreateDepartmentDialog = data
}
