<template>
    <div>

        <v-timeline dense clipped>
            <TimelineItem v-for="timelineItem in timelineItems" :key="timelineItem.id" :timelineItem="timelineItem" />
            <NewComment :endpoint="endpoint"></NewComment>
        </v-timeline>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import TimelineItem from "./TimelineItem";
import NewComment from "./NewComment";
import bus from "../../../bus";


export default {
name: "Timeline",
    components: {
        NewComment,
        TimelineItem
    },
    data () {
        return {
            timelineItems: [],
        }
    },
    props: [
        'endpoint'
    ],
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        avatar: function () {
            return this.user.first_name.charAt(0).toUpperCase() + this.user.last_name.charAt(0).toUpperCase()
        }
    },

    methods : {
        fetchTimeline() {
            return axios.get(`${this.endpoint}/timeline`)
        },

        async loadTimeline() {
            let timelineItems = await this.fetchTimeline()

            this.timelineItems = timelineItems.data.data
        },

        appendItem(item) {
            this.timelineItems.push(item)
        },

        editItem (item) {
            if (item.child) {
                _.assign(
                    _.find(
                        this.timelineItems, { id: item.parent_id }
                    ).children.find((child) => child.id === item.id),
                    item
                )

                return
            }

            _.assign(_.find(this.timelineItems, { id: item.id }), item)
        },

        removeItem(item) {
            if (!item.parent_id) {
                this.timelineItems = this.timelineItems.filter((o) => o.id !== item.id)
            }
        }
    },
    mounted() {
    const self = this
    this.loadTimeline()

        bus.$on('comment:stored', (payload) => {
            self.appendItem(payload)
        })
        bus.$on('comment:deleted', (payload) => {
            self.removeItem(payload)
        })

        bus.$on('comment:edited', this.editItem)
    }
}
</script>

<style scoped>

</style>
