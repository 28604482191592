export const SET_POTENTIALS_DATA = (state, data) => {
    state.potentials = data
}

export const SET_POTENTIAL_DATA = (state, data) => {
    state.potential = data
}

export const SET_POTENTIAL_DIALOG = (state, data) => {
    state.showPotentialDialog = data
}

export const PUSH_POTENTIALS_DATA = (state, data) => {
    state.potentials.push(data)
}

export const UPDATE_POTENTIALS_DATA = (state, data) => {
    Vue.set(state.potentials, state.potentials.findIndex(potentials => potentials.id === data.id), data)
}

export const REMOVE_POTENTIAL = (state, id) => {
    state.potentials.splice(state.potentials.findIndex(potentials => potentials.id === id), 1);
}

export const SET_CREATE_POTENTIAL_DIALOG = (state, data) => {
    state.showCreatePotentialDialog = data
}

export const SET_CREATE_POTENTIAL_ENDPOINT = (state, data) => {
    state.createPotentialEndpoint = data
}
