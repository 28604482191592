<template>
    <div>
        <v-row
            :style="$vuetify.breakpoint.lgAndUp ? 'padding-top: 20px' : ''"
        >
            <template
                v-if="$vuetify.breakpoint.lgAndUp"
                style="margin-top: 20px"
            >
                <v-radio-group
                    style="padding-top: 2px"
                    v-model="scale"
                    row
                >
                    <v-radio
                        :label="$lang('day_d')"
                        value="day"
                    ></v-radio>
                    <v-radio
                        :label="$lang('week_w')"
                        value="week"
                    ></v-radio>
                    <v-radio
                        :label="$lang('month_m')"
                        value="month"
                    ></v-radio>
                    <v-radio
                        :label="$lang('quarter_q')"
                        value="quarter"
                    ></v-radio>
                    <v-radio
                        :label="$lang('year_y')"
                        value="year"
                    ></v-radio>
                </v-radio-group>
                <v-btn
                    color="primary"
                    outlined
                    small
                    class="mr-2"
                    @click="zoomIn"
                >
                    <v-icon>
                        fas fa-search-plus
                    </v-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    small
                    class="mr-2"
                    @click="zoomOut"
                >
                    <v-icon>
                        fas fa-search-minus
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="_.includes(project.user_permissions, 'gantt dates')"
                    color="primary"
                    outlined
                    small
                    class="mr-2"
                    @click="ganttUndo"
                >
                    <v-icon>
                        fas fa-undo
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="_.includes(project.user_permissions, 'gantt dates')"
                    color="primary"
                    outlined
                    small
                    class="mr-2"
                    @click="ganttRedo"
                >
                    <v-icon>
                        fas fa-redo
                    </v-icon>
                </v-btn>

                <v-btn
                    v-if="!moveProject && _.includes(project.user_permissions, 'gantt dates')"
                    class="mr-2"
                    small
                    :outlined="true"
                    color="primary"
                    @click="setGanttMoveProject()"
                >
                    <v-icon>
                        fas fa-arrows-alt-h
                    </v-icon>
                </v-btn>

                <v-btn

                    v-if="moveProject && _.includes(project.user_permissions, 'gantt dates')"
                    class="mr-2"
                    small
                    color="primary"
                    @click="setGanttMoveProject()"
                >
                    <v-icon>
                        fas fa-arrows-alt-h
                    </v-icon>
                </v-btn>
            </template>
            <template
            v-else
            >
                <v-menu
                    offset-y
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            small
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                        >
                            {{$lang('menu')}}
                        </v-btn>
                    </template>
                    <v-list
                        class="text-center"
                    >
                        <v-list-item
                            @click="setGanttScale('day')"
                        >
                            <v-list-item-title>{{ $lang('day') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="setGanttScale('week')"
                        >
                            <v-list-item-title>{{ $lang('week') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="setGanttScale('month')"
                        >
                            <v-list-item-title>{{ $lang('month') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="setGanttScale('quarter')"
                        >
                            <v-list-item-title>{{ $lang('quarter') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="setGanttScale('year')"
                        >
                            <v-list-item-title>{{ $lang('year') }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="_.includes(project.user_permissions, 'gantt dates')"
                            v-model="moveProject"
                            @click="setGanttMoveProject()"
                            class="text-center"
                        >
                            <v-list-item-title>
                                <v-icon>
                                    fas fa-arrows-alt-h
                                </v-icon>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="_.includes(project.user_permissions, 'gantt dates')"
                            @click="ganttRedo"
                            class="text-center"
                        >
                            <v-list-item-title>
                                <v-icon>
                                    fas fa-redo
                                </v-icon>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="_.includes(project.user_permissions, 'gantt dates')"
                            @click="ganttUndo"
                            class="text-center"
                        >
                            <v-list-item-title>
                                <v-icon>
                                    fas fa-undo
                                </v-icon>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="zoomIn"
                            class="text-center"
                        >
                            <v-list-item-title>
                                <v-icon>
                                    fas fa-search-plus
                                </v-icon>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="zoomOut"
                            class="text-center"
                        >
                            <v-list-item-title>
                                <v-icon>
                                    fas fa-search-minus
                                </v-icon>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>

                </v-menu>
            </template>

            <template
                v-if="changedTasks.length && _.includes(project.user_permissions, 'gantt dates')"
            >
                <v-btn
                    class="mr-2"
                    small
                    color="primary"
                    @click="submit"
                >
                    {{ $lang('save') }}
                </v-btn>
            </template>
        </v-row>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../bus";

export default {
    name: "GanttActions",
    data() {
        return {
            defaultScale: 0,
        }
    },
    computed: {
        ...mapGetters({
            project: 'projects/project',
            openAll: 'projects/ganttOpenAll',
            moveProject: 'projects/ganttMoveProject',
            changedTasks: 'projects/changedTasks',
            range: 'projects/ganttRange',
            ganttScale: 'projects/ganttScale'
        }),
        scale: {
            get() {
                return this.ganttScale
            },
            set(value) {
                this.setGanttScale(value)
            }
        }
    },

    methods: {
        ...mapActions({
            setGanttOpenAll: 'projects/setGanttOpenAll',
            setGanttMoveProject: 'projects/setGanttMoveProject',
            updateTasks: 'projects/updateGanttTasks',
            setChangedTasks: 'projects/setChangedTasks',
            setGanttScale: 'projects/setGanttScale',
            setGanttRange: 'projects/setGanttRange',

        }),
        submit () {
            const self = this
            this.updateTasks({
                project: this.project,
                payload: {
                    tasks: this.changedTasks
                },
            }).then(() => {
                this.setChangedTasks(0)
                Bus.$emit('gantt:updated')
            })
        },

        ganttUndo() {
            custom_gantt.undo()
        },

        ganttRedo() {
            custom_gantt.redo()
        },

        zoomOut() {
            custom_gantt.ext.zoom.zoomOut();
            custom_gantt.showDate(new Date())
        },

        zoomIn() {
            custom_gantt.ext.zoom.zoomIn();
            custom_gantt.showDate(new Date())
        }
    },
}
</script>

<style scoped>

</style>
