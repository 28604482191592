 <template>
<div>
    <v-toolbar flat color="white" >
        <v-toolbar-title >
            {{ $lang('lists') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <TableFilterDropDown table-id="listIssues" key="listIssues" v-if="selectedTab === 'issues'"></TableFilterDropDown>
        <TableFilterDropDown table-id="listTodos" key="listTodos" v-if="selectedTab === 'todos'"></TableFilterDropDown>

        <TableFilterDropDown table-id="UserDashboardTable" key="UserDashboardTable" v-if="selectedTab === 'userDashboardTable'"></TableFilterDropDown>
        <TableFilterDropDown table-id="listSolexes" key="listSolexes" v-if="selectedTab === 'solex'"></TableFilterDropDown>
        <TableFilterDropDown table-id="leadTimeChangeDashboardTable" key="leadTimeChangeDashboardTable" v-if="selectedTab === 'leadTimeChangeDashboardTable'"></TableFilterDropDown>
    <v-col
        v-if="selectedTab === 'userDashboardTable' && _.includes(user.permissions, 'user assignment')"
        md="2"
    >
        <v-select
            class="mt-5"
            v-model="userId"
            :items="users"
            :label="$lang('user')"
            item-text="full_name"
            item-value="id"
            single-line
        ></v-select>
    </v-col>

    </v-toolbar>
    <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
    ></v-progress-linear>
    <ListTabs ></ListTabs>
</div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ListTabs from "../components/ListTabs";
    import TableFilterDropDown from "../../helpers/tableFilters/components/TableFilterDropDown";


    export default {
        name: "Lists",
        components: {TableFilterDropDown, ListTabs},
        data () {
            return {
                loading: true
            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/user',
                projects: 'projects/projects',
                selectedTab: 'lists/selectedTab',
                users: 'users/users',
                listUserId:'lists/userId'
            }),

            userId: {
                get: function () {
                    if(this.listUserId) {
                        return this.listUserId
                    }
                    return this.user.id
                },
                set: function (value) {
                    this.setUserId(value)
                }
            }
        },
        methods: {
            ...mapActions({
                fetchProjects: 'projects/fetchProjects',
                setUserId: 'lists/setUserId'
            }),
        },

        mounted() {
            const self = this
            this.loading = false
        }


    }
</script>

<style scoped>

</style>
