<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../../bus";

export default {
    name: "ProcurementKanbanCircuitsTable",
    data() {
        return {
            datatableExists: true,
            defaultState: null,
            loading: true
        }
    },

    props: {
        tableId: {
            type: String
        },
    },

    computed: {
        ...mapGetters({
            kanbanOrders: 'procurementKanban/kanbanOrders',
            me: 'auth/user',
            activeFilters: 'tableFilters/activeFilters'
        }),
    },

    methods: {
        ...mapActions({
            fetchKanbanOrders: 'procurementKanban/fetchKanbanOrders',
            fetchKanbanCircuit: 'procurementKanban/fetchKanbanCircuit',
            fetchKanbanOrder: 'procurementKanban/fetchKanbanOrder',
            setShowKanbanOrderDialog: 'procurementKanban/setShowKanbanOrderDialog',
            setShowCreateManualKanbanOrderDialog: 'procurementKanban/setShowCreateManualKanbanOrderDialog',
            setShowKanbanCircuitDialog: 'procurementKanban/setShowKanbanCircuitDialog'
        }),

        loadDatatable() {
            const self = this
            if (this.datatableExists) {
                axios.get('/api/procurement/kanban/kanbanCircuits').then(function (response) {
                    self.webixId.parse(webix.copy(response.data))
                })
            }
        }
    },


    mounted() {
        const self = this

        this.datatableExists = true

        this.webixId = webix.ui({
            container: this.$el,
            $scope: this,
            view: "datatable",
            height: window.innerHeight - 200,
            id: self.tableId,
            headermenu:{
                width:250,
            },
            resizeColumn: true,
            select: "row", multiselect: true,
            dragColumn: true,
            headerRowHeight: 40,

            columns: [

                {
                    id: "part_name",
                    header: [self.$lang('part name'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string",
                    template: "{common.subrow()} #part_name#",
                    width: 220
                },
                {
                    id: "part_number",
                    header: [self.$lang('part number'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 100,
                    sort: "string"
                },
                {
                    id: "name",
                    header: [self.$lang('name'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "number",
                    header: [self.$lang('number'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "supplier_name",
                    header: [self.$lang('supplier'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.supplier_name
                    }
                },
                {
                    id: "supplier_info",
                    header: [self.$lang('supplier info'), {content: "textFilter"}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                },

                {
                    id: "location_name",
                    header: [self.$lang('location'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.location_name
                    }
                },
                {
                    id: "workplace_name",
                    header: [self.$lang('workplace'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    minWidth: 150,
                    adjust: true,
                    sort: "string",
                    template: function (obj) {
                        return obj.workplace_name
                    }
                },
                {
                    id: "status_label",
                    header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string",
                    css: "text-right"
                },
                {
                    id: "stock",
                    header: [self.$lang('stock'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "unit",
                    header: [self.$lang('unit'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "lot_item_quantity",
                    header: [self.$lang('lot item quantity'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 50,
                    sort: "string",
                    css: {'text-align': 'right'}
                },
                {
                    id: "lot_item_unit",
                    header: [self.$lang('lot item unit'), {content: "textFilter"}],
                    fillspace: true,
                    minWidth: 50,
                    sort: "string",
                    css: {'text-align': 'right'}
                },
                {
                    id: "safety_stock",
                    header: [self.$lang('safety stock'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "reporting_stock",
                    header: [self.$lang('reporting stock'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "supplier_stock",
                    header: [self.$lang('supplier stock'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "on_going_order_quantity",
                    header: [self.$lang('ordered'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "on_going_order_missing_confirmed_quantity",
                    header: [self.$lang('missing confirmations'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "on_going_order_confirmed_quantity",
                    header: [self.$lang('confirmed'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "on_going_order_sent_quantity",
                    header: [self.$lang('shipped'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
                {
                    id: "on_going_order_received_quantity",
                    header: [self.$lang('received'), {content: "textFilter"}],
                    fillspace: false,
                    minWidth: 200,
                    sort: "string"
                },
            ],

            subview: function (obj, target) {
                return webix.ui({
                    template: "some",
                    view: "datatable",
                    id: obj.id,
                    scrollX: true,

                    columns: [
                        {
                            id: "status_label",
                            header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                            minWidth: 150,
                            adjust: true,
                            sort: "string",
                            template: function (obj) {
                                return obj.status_label
                            }
                        },
                        {
                            id: "order_number_link",
                            header: [self.$lang('order number'), {content: "textFilter"}],
                            fillspace: true,
                            minWidth: 200,
                            sort: "string"
                        },
                        {
                            id: "quantity",
                            header: [self.$lang('quantity'), {content: "textFilter"}],
                            fillspace: false,
                            minWidth: 200,
                            sort: "string"
                        },
                        {
                            id: "unit",
                            header: [self.$lang('unit'), {content: "textFilter"}],
                            fillspace: false,
                            minWidth: 200,
                            sort: "string"
                        },
                        {
                            id: "delivery_date",
                            map: "(date)#delivery_date#",
                            header: [self.$lang('delivery date'), {content: "dateRangeFilter"}],
                            adjust: true,
                            template: function (obj) {
                                return webix.i18n.parseFormatStr(obj.delivery_date)
                            }
                        },

                        {
                            id: "created_at",
                            map: "(date)#created_at#",
                            header: [self.$lang('created'), {content: "dateRangeFilter"}],
                            adjust: true,
                            template: function (obj) {
                                return webix.i18n.parseFormatStr(obj.created_at)
                            }
                        },
                        {
                            id: "creator_name",
                            header: [self.$lang('created'), {content: "textFilter"}],
                            fillspace: false,
                            adjust: true,
                            sort: "string"
                        },
                        {
                            id: "confirmed_at",
                            map: "(date)#confirmed_at#",
                            header: [self.$lang('confirmed'), {content: "dateRangeFilter"}],
                            adjust: true,
                            template: function (obj) {
                                return webix.i18n.parseFormatStr(obj.confirmed_at)
                            }
                        },
                        {
                            id: "confirmer_name",
                            header: [self.$lang('confirmed'), {content: "textFilter"}],
                            fillspace: false,
                            adjust: true,
                            sort: "string"
                        },
                        {
                            id: "shipped_at",
                            map: "(date)#shipped_at#",
                            header: [self.$lang('shipped'), {content: "dateRangeFilter"}],
                            adjust: true,
                            template: function (obj) {
                                return webix.i18n.parseFormatStr(obj.shipped_at)
                            }
                        },
                        {
                            id: "shipper_name",
                            header: [self.$lang('shipped'), {content: "textFilter"}],
                            fillspace: false,
                            adjust: true,
                            sort: "string"
                        },

                        {
                            id: "received_at",
                            header: [self.$lang('received'), {content: "textFilter"}],
                            fillspace: false,
                            adjust: true,
                            sort: "string"
                        },
                        {
                            id: "received_from",
                            header: [self.$lang('received'), {content: "textFilter"}],
                            fillspace: false,
                            adjust: true,
                            sort: "string"
                        },
                    ],
                    autoheight: true,
                    onClick: {
                        "order_number_link": function (event, row, target) {
                            self.fetchKanbanOrder(row.row).then(function () {
                                self.setShowKanbanOrderDialog(true)
                            })
                        }
                    },
                }, target);
            },


            on: {
                onSubViewCreate: function (view, item) {
                    view.clearAll();
                    view.parse(item.kanban_orders);
                },


                onAfterLoad: function () {
                    if (!self.defaultState) {
                        self.defaultState = $$(self.tableId).getState()
                    }
                    self.loading = false
                },

                onItemDblClick: function (row) {
                    self.fetchKanbanCircuit(row.row).then(function (){
                        self.setShowKanbanCircuitDialog(true)
                    })
                }
            },

        })

        this.loadDatatable()

        Bus.$on('filterTable:' + self.tableId, function (data) {
                this.$nextTick(() => {
                    if (!self.defaultState) {
                        self.defaultState = $$(self.tableId).getState()
                    }
                    if (self.datatableExists) {
                        $$(self.webixId).setState(JSON.parse(data))
                    }
                })
        });

        Bus.$on('resetFilterTable:' + self.tableId, function () {
            if (self.datatableExists) {
                $$(self.tableId).setState(self.defaultState)
            }
        });

        var channel = this.$pusher.subscribe(`kanbanCircuit.${this.me.company.id}`)

        channel.bind('updateKanbanCircuitItem', (data) => {
            if (self.datatableExists) {
                this.webixId.load('/api/procurement/kanban/kanbanCircuits').then((response) => {
                    this.webixId.data.each((obj) => {
                        if (obj.$subopen && obj.kanban_orders) {
                            $$(obj.$subContent).clearAll();
                            $$(obj.$subContent).parse(obj.kanban_orders);
                        }
                    })
                })
            }
        });



    },

    destroyed: function () {
        this.datatableExists = false
        webix.$$(this.webixId).destructor();
    }
}
</script>

<style scoped>

</style>
