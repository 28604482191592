<template>
    <div v-if="issueTag">
        <v-toolbar flat color="white" dense >
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        outlined
                        color="primary"
                        to="/masterData/issueTags"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="mr-1">fas fa-list</v-icon>
                    </v-btn>
                </template>
                <span>Alle {{ $lang('issue') }} Tags</span>
            </v-tooltip>
            <v-toolbar-title >{{ issueTag.name }}</v-toolbar-title>
        </v-toolbar>

        <IssueTagTabs ></IssueTagTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import IssueTagTabs from "../components/IssueTagTabs";

    export default {
        name: "Project",
        components: {
            IssueTagTabs
        },
        props: [
            'issueTagId'
        ],
        computed: {
            ...mapGetters({
                issueTag: 'issueTags/issueTag',
            }),
        },
        created() {
            this.fetchIssueTag(this.issueTagId)
        },

        methods: {
            ...mapActions({
                fetchIssueTag: 'issueTags/fetchIssueTag',
            }),
        }
    }
</script>

<style scoped>

</style>
