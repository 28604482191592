export const fetchIssueTags = ({ commit }) => {
    return axios.get('/api/masterData/issueTags').then((response) => {
        commit('setIssueTagsData', response.data)
        let issueTags = response.data.filter((issueTag)=> {
            return issueTag.active;
        })
        commit('SET_ACTIVE_ISSUE_TAGS_DATA', issueTags)
    })
}

export const fetchIssueTag = ({ commit }, id) => {
    return axios.get(`/api/masterData/issueTags/${id}`).then((response) => {
        commit('setIssueTagData', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/masterData/issueTags', payload).then((response) => {
        commit('setIssueTagData', response.data)
        dispatch('fetchIssueTags')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/masterData/issueTags/${payload.id}`, payload).then((response) => {
        commit('setIssueTagData', response.data)
        dispatch('fetchIssueTags')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, {id, context} ) => {
    return axios.delete(`/api/masterData/issueTags/${id}`).catch((error) => {
        context.error = true
    })
}

export const setCreateIssueTagDialog = ({ dispatch, state, commit }, value) => {
    commit('setCreateIssueTagDialog', value)
}
