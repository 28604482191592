export const setDivisionsData = (state, data) => {
    state.divisions = data
}

export const setDivisionData = (state, data) => {
    state.division = data
}

export const addDivision = (state, data) => {
    state.divisions.push(data)
}

export const updateDivisionData = (state, data) => {
    Object.assign(state.divisions[data.index], data.data)
}

export const removeDivision = (state, item) => {
    const index = _.indexOf(state.divisions, item)
    state.divisions.splice(index, 1)
}

export const setCreateDivisionDialog = (state, data) => {
    state.showCreateDivisionDialog = data
}
