
export const fetchProducts = ({ commit }) => {
    return axios.get('/api/products').then((response) => {

        commit('SET_PRODUCTS_DATA', response.data)
    })
}

export const fetchProduct = ({ commit }, id) => {
    return axios.get(`/api/products/${id}`).then((response) => {
        commit('SET_PRODUCT_DATA', response.data)
        commit('SET_SELECTED_PRODUCT', response.data.id)
    })
}

