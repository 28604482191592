<template>
    <div v-if="supplier">
        <v-toolbar flat color="white" dense >
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        outlined
                        color="primary"
                        to="/masterData/suppliers"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="mr-1">fas fa-list</v-icon>
                    </v-btn>
                </template>
                <span>Alle Lieferanten</span>
            </v-tooltip>
            <v-toolbar-title >{{ supplier.name }}</v-toolbar-title>
        </v-toolbar>

        <SupplierTabs :key="supplierId"></SupplierTabs>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import SupplierTabs from "../components/SupplierTabs";

    export default {
        name: "Supplier",
        components: {
            SupplierTabs
        },
        props: [
            'supplierId'
        ],
        computed: {
            ...mapGetters({
                supplier: 'suppliers/supplier',
            }),
        },
        created() {
            this.fetchSupplier(this.supplierId)

        },

        methods: {
            ...mapActions({
                fetchSupplier: 'suppliers/fetchSupplier',
            }),
        }
    }
</script>

<style scoped>

</style>
