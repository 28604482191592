import { LangExport} from '../views'


export default [
    {
        path: '/admin/lang/export',
        component: LangExport,
        name: 'admin.lang.export',
        meta: {
            guest: false,
            needsAuth: true,
            role: 'Admin'
        }
    },


]

