export const fetchPermissions = ({ commit }) => {
    return axios.get(`/api/admin/permissions`).then((response) => {
        commit('SET_PERMISSIONS_DATA', response.data)
    })
}

export const fetchCompanyPermissions = ({ commit }, companyId) => {
    return axios.get(`/api/admin/companies/${companyId}/permissions`).then((response) => {
        commit('SET_COMPANY_PERMISSIONS_DATA', response.data)
    })
}
