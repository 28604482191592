export const fetchKanbanCircuits = ({ commit }) => {
    return axios.get('/api/masterData/kanbanCircuits').then((response) => {
        commit('SET_KANBAN_CIRCUITS_DATA', response.data)
    })
}

export const fetchKanbanCircuit = ({ commit }, id) => {
    return axios.get(`/api/masterData/kanbanCircuits/${id}`).then((response) => {
        commit('SET_KANBAN_CIRCUIT_DATA', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/masterData/kanbanCircuits', payload).then((response) => {
        commit('ADD_KANBAN_CIRCUIT', response.data)
        commit('SET_KANBAN_CIRCUIT_DATA', response.data)
        dispatch('fetchKanbanCircuits')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { kanbanCircuitId, payload, context }) => {
    return axios.patch(`/api/masterData/kanbanCircuits/${kanbanCircuitId}`, payload).then((response) => {
        commit('UPDATE_KANBAN_CIRCUITS_DATA', response.data)
        commit('SET_KANBAN_CIRCUIT_DATA', response.data)
        dispatch('fetchKanbanCircuits')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, { kanbanCircuitId }) => {
    return axios.delete(`/api/masterData/kanbanCircuits/${kanbanCircuitId}`).then((response) => {
        dispatch('fetchKanbanCircuits')
        commit('SET_KANBAN_CIRCUIT_DATA', null)
    }).catch((error) => {
        //context.errors = error.response.data.errors
    })
}

export const setKanbanCircuitDialog = ({ dispatch, state, commit }, value) => {
    commit('SET_KANBAN_CIRCUIT_DIALOG', value)
}

export const setCreateKanbanCircuitDialog = ({ dispatch, state, commit }, value) => {
    commit('SET_CREATE_KANBAN_CIRCUIT_DIALOG', value)
}

