export const fetchCostCenters = ({ commit }) => {
    return axios.get('/api/masterData/costCenters').then((response) => {
        commit('SET_COST_CENTERS_DATA', response.data)
        let costCenters = response.data.filter((costCenter)=> {
            return costCenter.active;
        })
        commit('SET_ACTIVE_COST_CENTERS_DATA', costCenters)
    })
}

export const fetchCostCenter = ({ commit }, id) => {
    return axios.get(`/api/masterData/costCenters/${id}`).then((response) => {
        commit('setCostCenterData', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post('/api/masterData/costCenters', payload).then((response) => {
        commit('setCostCenterData', response.data)
        dispatch('fetchCostCenters')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { payload, context}) => {
    return axios.patch(`/api/masterData/costCenters/${payload.id}`, payload).then((response) => {
        commit('setCostCenterData', response.data)
        dispatch('fetchCostCenters')
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const destroy = ({ dispatch, state, commit }, {id, context} ) => {
    return axios.delete(`/api/masterData/costCenters/${id}`).catch((error) => {
        context.error = true
    })
}

export const setCreateCostCenterDialog = ({ dispatch, state, commit }, value) => {
    commit('setCreateCostCenterDialog', value)
}
