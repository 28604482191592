export const SET_USERS_DATA = (state, data) => {
    state.users = data
}

export const SET_ACTIVE_USERS_DATA = (state, data) => {
    state.activeUsers = data
}

export const SET_INACTIVE_USERS_DATA = (state, data) => {
    state.inactiveUsers = data
}
