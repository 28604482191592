<template>
    <v-dialog
        v-model="showCreateSupplierDialog"
        width="500"
        @click:outside="closeDialog"

    >
        <v-card>
            <v-card-title
                class="headline grey lighten-2"
                primary-title
            >
                {{$lang('create supplier')}}
            </v-card-title>
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                    >
                        <v-radio-group v-model="form.internal_supplier">
                            <v-radio
                                :label="$lang('external')"
                                :value="0"
                            ></v-radio>
                            <v-radio
                                :label="$lang('internal')"
                                :value="1"
                            ></v-radio>
                        </v-radio-group>

                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-text-field
                            v-model="form.street"
                            :label="$lang('address')"
                            :error-messages="errors.street"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.zip"
                            :label="$lang('zip')"
                            :error-messages="errors.zip"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.city"
                            :label="$lang('city')"
                            :error-messages="errors.city"
                        ></v-text-field>
                        <v-select
                            v-if="form.internal_supplier"
                            v-model="form.contact_ids"
                            :items="users"
                            :item-disabled="disableUsers"
                            :label="$lang('internal contacts')"
                            append-outer-icon="fas fa-times"
                            @click:append-outer="form.contact_ids = []"
                            item-text="full_name"
                            item-value="id"
                            multiple
                            :error-messages="errors.contact_ids"
                        ></v-select>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">{{$lang('cancel')}}</v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="loading">{{$lang('save')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Bus from "../../../../bus";
    export default {
        name: "CreateSupplierDialog",

        data() {
            return {
                loading: false,
                errors: [],
                form: {
                    internal_supplier: 0,
                    name: '',
                    street: '',
                    zip: '',
                    city: '',
                    contact_ids: []
                }
            }
        },

        computed: {
            ...mapGetters({
                users: 'users/users',
                showCreateSupplierDialog: 'suppliers/showCreateSupplierDialog',
            }),
        },

        methods: {
            ...mapActions({
                store: 'suppliers/store',
                actionShowCreateSupplierDialog: 'suppliers/setCreateSupplierDialog',
            }),

            disableUsers(user) {
                return !user.active
            },

            save () {
                this.loading= true
                this.errors = []
                this.store({
                    payload: this.form,
                    context: this
                }).then(() => {
                    this.loading = false
                    if(this.errors.length === 0) {
                        this.$refs.form.reset()
                        this.closeDialog()
                        Bus.$emit('supplier:created');
                    }
                })
            },

            closeDialog () {
                this.actionShowCreateSupplierDialog(false)
            }
        },

        created() {

        }
    }
</script>

<style scoped>

</style>
