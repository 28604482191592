<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "NotesTable",

        data() {
            return {
                loading: true,
            }
        },

        computed: {
            ...mapGetters({
                notes: 'notes/notes',
                note: 'notes/note',
                showNoteDialog: 'notes/showNoteDialog',
                notesFilter: 'notes/notesFilter'
            })
        },
        methods: {
            ...mapActions({
                fetchNotes: 'notes/getNotes',
                fetchNote: 'notes/getNote',
                actionShowNoteDialog: 'notes/setShowNoteDialog'
            }),

            parseData: function () {
                this.loading = true
                this.webixId.clearAll()
                this.webixId.parse(this.notes)
            },

            filterTable(filter) {
                if(filter === 'all') {
                    this.webixId.filter(function(obj){
                        return true
                    });
                }

                if(filter === 'open') {
                    this.webixId.filter(function(obj){
                        return !obj.closed_at
                    });
                }

                if(filter === 'closed') {
                    this.webixId.filter(function(obj){
                        return obj.closed_at
                    });
                }
            }
        },

        mounted() {
            const self = this

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: 600,
                id: 'notes',

                headermenu: true,
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                columns: [
                    {id: "closed_label", header: [self.$lang('status'), { content:"multiSelectFilter", suggest: {fitMaster: false, width: 220} }], minWidth: 150, adjust: true , sort: "string", template: function (obj){
                            return obj.closed_label
                        }},
                    {id: "title", header: [ self.$lang('title'),{ content:"textFilter" }],fillspace: true, minWidth: 200,  sort: "string", template: "#title_link#"},
                    {id:"closed_at", map:"(date)#closed_at#", header:[self.$lang('closed'), { content:"dateRangeFilter"}], sort: "date", adjust: true , template: function (obj){
                            return webix.i18n.parseFormatStr(obj.closed_at)
                        }},
                    {id:"created_at", map:"(date)#created_at#", header:[self.$lang('created'), { content:"dateRangeFilter"}], sort: "date", adjust: true , template: function (obj){
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }}
                ],
                onClick:{
                    "title_link" : function  (event, column, target) {
                        self.actionShowNoteDialog(true)
                    }
                },
                on: {
                    onAfterSelect: function(){
                        self.fetchNote(this.getSelectedId(true))
                    },
                    onItemDblClick: function (id) {
                        self.actionShowNoteDialog(true)
                    },

                    onAfterLoad: function () {
                        if (self.note && this.exists(self.note.id)) {
                            this.select(self.note.id)
                            this.showItem(self.note.id)
                        }

                        self.loading = false
                        self.filterTable(self.notesFilter)
                    },

                },
            })
            this.parseData()
        },

        watch: {
            notes: function () {
                this.parseData()
            },

            notesFilter (filter) {
                this.filterTable(filter)
            }
        },
        destroyed:function(){
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style scoped>

</style>
