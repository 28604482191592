<template>
<div>
    <v-toolbar flat color="white" >
        <v-toolbar-title >Firmen</v-toolbar-title>
        <v-spacer></v-spacer>
        <CreateCompanyDialog></CreateCompanyDialog>
    </v-toolbar>
    <CompaniesTable></CompaniesTable>
</div>
</template>

<script>
import CompaniesTable from "../components/CompaniesTable";
import CreateCompanyDialog from "../components/CreateCompanyDialog";
export default {
name: "Companies",
    components: {CreateCompanyDialog, CompaniesTable}
}
</script>

<style scoped>

</style>
