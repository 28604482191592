<template>
    <v-app id="inspire">

    <template v-if="authenticated ">
<!--            <MenuDrawer></MenuDrawer>-->
            <AppBar ></AppBar >

            <v-main>
                <v-container fluid>
                    <SnackBar></SnackBar>

                    <router-view ></router-view>
                    <v-dialog
                            v-model="logoutWarningDialog"
                    >
                        <v-card
                                color="error"
                        >
                            <v-card-title
                                    class="white--text"
                            >
                                {{$lang('logout info')}}
                            </v-card-title>
                            <v-card-text
                                    class="white--text"
                            >
                                <h3>
                                    {{counter}} {{$lang('seconds until automatic logout')}}
                                </h3>

                            </v-card-text>

                        </v-card>

                    </v-dialog>

                    <TodoDialog :key="`todoDialogKey${componentKey}`" v-if="todo"></TodoDialog>

                    <NoteDialog></NoteDialog>
                    <CreateIssueDialog :key="`createIssueDialogKey${componentKey}`"></CreateIssueDialog>
                    <IssueDialog v-if="issue" :key="`issueDialogKey${componentKey}`"></IssueDialog>
                    <CreateTodoDialog :key="`createTodoDialogKey${componentKey}`"></CreateTodoDialog>
                    <CreatePotentialDialog :key="`createPotentialDialogKey${potentialComponentKey}`"></CreatePotentialDialog>
                    <PotentialDialog v-if="potential" :key="`potentialDialogKey${potentialComponentKey}`"></PotentialDialog>
                    <CreateProjectDialog :key="`createProjectDialogKey${componentKey}`"></CreateProjectDialog>
                    <SalesKanbanOrderDialog :key="`salesKanbanOrdersOrderDialogKey${componentKey}`"></SalesKanbanOrderDialog>
                    <ProcurementKanbanOrderDialog :key="`procurementKanbanOrderDialogKey${componentKey}`"></ProcurementKanbanOrderDialog>
                    <Scanner :key="`scannerKey${componentKey}`"></Scanner>
                    <CreateSolexDialog :key="`createSolexDialogKey${componentKey}`"></CreateSolexDialog>
                    <SolexDialog  :key="`solexDialogKey${componentKey}`" v-if="solex"></SolexDialog>

                </v-container>
            </v-main>
        </template>
        <template v-else>
            <v-main>
                <Login></Login>
            </v-main>
        </template>
    </v-app>
</template>

<script>

import CreateSolexDialog from "../app/issues/solexes/components/CreateSolexDialog";
(function () { // make vuetify dialogs movable
    const d = {};
    document.addEventListener("mousedown", e => {
        const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
        if (e.button === 0 && closestDialog != null && (e.target.classList.contains("v-card__title") || e.target.classList.contains("v-toolbar__content"))) { // element which can be used to move element
            d.el = closestDialog; // element which should be moved
            d.mouseStartX = e.clientX;
            d.mouseStartY = e.clientY;
            d.elStartX = d.el.getBoundingClientRect().left;
            d.elStartY = d.el.getBoundingClientRect().top;
            d.el.style.position = "fixed";
            d.el.style.margin = 0;
            d.oldTransition = d.el.style.transition;
            d.el.style.transition = "none"
        }
    });
    document.addEventListener("mousemove", e => {
        if (d.el === undefined) return;
        d.el.style.left = Math.min(
            Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
            window.innerWidth - d.el.getBoundingClientRect().width
        ) + "px";
        d.el.style.top = Math.min(
            Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
            window.innerHeight - d.el.getBoundingClientRect().height
        ) + "px";
    });
    document.addEventListener("mouseup", () => {
        if (d.el === undefined) return;
        d.el.style.transition = d.oldTransition;
        d.el = undefined
    });
    setInterval(() => { // prevent out of bounds
        const dialog = document.querySelector(".v-dialog.v-dialog--active");
        if (dialog === null) return;
        dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
        dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
    }, 100);
})();

    import {mapActions, mapGetters} from 'vuex'
    import Login from "../app/auth/views/Login";
    import AppBar from "../app/layout/components/AppBar";
    import MenuDrawer from "../app/layout/components/MenuDrawer";
    import NoteDialog from "../app/notes/components/NoteDialog";
    import CreateIssueDialog from "../app/issues/components/CreateIssueDialog";
    import IssueDialog from "../app/issues/components/IssueDialog";
    import SnackBar from "./SnackBar";
    import TodoDialog from "../app/todos/components/TodoDialog";
    import CreateTodoDialog from "../app/todos/components/CreateTodoDialog";
    import CreatePotentialDialog from "../app/potentials/components/CreatePotentialDialog";
    import PotentialDialog from "../app/potentials/components/PotentialDialog";
    import NotificationDialog from "../app/notifications/components/NotifcationDialog";
    import CreateProjectDialog from "../app/projects/components/CreateProjectDialog";
    import ProcurementKanbanOrderDialog from "../app/procurement/kanban/components/ProcurementKanbanOrderDialog";
    import SalesKanbanOrderDialog from "../app/sales/kanbanOrders/components/SalesKanbanOrderDialog";
    import Scanner from "../app/scanner/components/Scanner";
    import SolexDialog from "../app/issues/solexes/components/SolexDialog";

    export default {
        props: {
            source: String,
        },

        data() {
            return {
                componentKey: 1,
                potentialComponentKey: 1,
                logoutWarningDialog: false,
                counter: 60
            }
        },

        components: {
            SolexDialog,
            CreateSolexDialog,
            Scanner,
            ProcurementKanbanOrderDialog,
            SalesKanbanOrderDialog,
            CreateProjectDialog,
            NotificationDialog,
            PotentialDialog,
            CreatePotentialDialog,
            CreateTodoDialog,
            TodoDialog,
            SnackBar,
            IssueDialog,
            CreateIssueDialog,

            NoteDialog,

            Login,
            AppBar,
            MenuDrawer,

        },


        computed: {
            ...mapGetters({
                user: 'auth/user',
                authenticated : 'auth/authenticated',
                issue: 'issues/issue',
                showIssueDialog: 'issues/showIssueDialog',
                showCreateIssueDialog: 'issues/showCreateIssueDialog',
                showCreatePotentialDialog: 'potentials/showCreatePotentialDialog',
                todo: 'todos/todo',
                potential: 'potentials/potential',
                showPotentialDialog: 'potentials/showPotentialDialog',
                notification: 'notifications/notification',
                showNotificationDialog: 'notifications/showNotificationDialog',
                showCreateProjectDialog: 'projects/showCreateProjectDialog',
                showScannerDialog: 'scanner/showScannerDialog',
                showCreateSolexDialog: 'solexes/showCreateSolexDialog',
                showSolexDialog: 'solexes/showSolexDialog',
                solex: 'solexes/solex'
            }),

            isIdle() {
                return this.$store.state.idleVue.isIdle;
            }
        },

        created() {
            this.fetchUsers();
        },

        methods: {
            ...mapActions({
                fetchUsers: 'users/fetchUsers',
                logout: 'auth/logout',
                setShowLogoutMessage: 'auth/setShowLogoutMessage'
            }),

            logoutTimeOut() {
                this.counter= 60
                this.countdown()
                setTimeout(() => {
                    if(this.logoutWarningDialog) {
                        this.logout()
                    }
                }, this.counter * 1000);
            },

            countdown () {
                if(!this.logoutWarningDialog) return
                setTimeout(() => {
                    this.counter--
                    this.countdown()
                }, 1000);
            }
        },

        mounted() {
            if(this.user) {
                this.$vuetify.lang.current = this.user.lang.toLowerCase()
            }

        },

        watch: {
            isIdle: {
                handler: function (value) {

                    if(value && this.$route.name !== 'login') {
                        this.logoutWarningDialog = true
                        this.logoutTimeOut()
                    }
                    else
                    {
                        this.logoutWarningDialog = false
                    }
                }

            },

            showIssueDialog: {
                handler: function (value) {
                    if (value) {
                        this.componentKey += 1
                    }
                }
            },
            showCreateIssueDialog: {
                handler: function (value) {
                    if (value) {
                        this.componentKey += 1
                    }
                }
            },
            showCreateTodoDialog: {
                handler: function (value) {
                    if (value) {
                        this.componentKey += 1
                    }
                }
            },

            showCreatePotentialDialog: {
                handler: function (value) {
                    if (value) {
                        this.potentialComponentKey += 1
                    }
                }
            },

            showPotentialDialog: {
                handler: function (value) {
                    if (value) {
                        this.potentialComponentKey += 1
                    }
                }
            },


            showCreateProjectDialog: {
                handler: function (value) {
                    if (value) {
                        this.componentKey += 1
                    }
                }
            },

            showSalesKanbanOrderDialog: {
                handler: function (value) {
                    if (value) {
                        this.componentKey += 1
                    }
                }
            },

            showProcurementKanbanOrderDialog: {
                handler: function (value) {
                    if (value) {
                        this.componentKey += 1
                    }
                }
            },

            showScannerDialog: {
                handler: function (value) {
                    if (value) {
                        this.componentKey += 1
                    }
                }
            },

            showSolexDialog: {
                handler: function (value) {
                    if (value) {
                        this.componentKey += 1
                    }
                }
            },

            showCreateSolexDialog: {
                handler: function (value) {
                    if (value) {
                        this.componentKey += 1
                    }
                },
            }
        }
    }
</script>

