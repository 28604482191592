import Bus from "../../../bus";

export const fetchTodos = ({ commit }, endpoint) => {
    return axios.get(endpoint).then((response) => {
        commit('SET_TODOS_DATA', response.data)
    })
}

export const fetchTodo = ({ commit, dispatch }, id) => {
    if(id.length ) {
        return axios.get(`/api/todos/${id}`).then((response) => {
            commit('SET_TODO_DATA', response.data)
        })
    }
}

export const setTodos = ({ state, commit }, todos) => {
    commit('SET_TODOS_DATA', todos)
}

export const setTodo = ({ state, commit }, id) => {
    let todo = state.todos.find(todo => todo.id === id)
    commit('SET_TODO_DATA', todo)
}

export const setTodoDialog = ({ dispatch, state, commit }, value) => {
        commit('SET_TODO_DIALOG', value)
}

export const store = ({ dispatch, state, commit }, { payload, context }) => {
    return axios.post(`${state.createTodoEndpoint}`, payload).then((response) => {
        // commit('PUSH_TODOS_DATA', response.data)
         commit('SET_TODO_DATA', response.data)
        // if(response.data.todoable_type === 'issue') {
        //     //dispatch('issues/fetchIssue', response.data.todoable_id, {root: true})
        // }
        // if(response.data.todoable_type === 'todo') {
        //     dispatch('fetchTodo', response.data.todoable_id)
        // }
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { id,  payload, context }) => {
    return axios.patch(`/api/todos/${id}`, payload).then((response) => {

        commit('SET_TODO_DATA', response.data)
       // commit('UPDATE_TODOS_DATA', response.data)
         // if(response.data.todoable_type === 'issue') {
         //     dispatch('issues/fetchIssue', response.data.todoable_id, {root: true})
         // }

    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const updateOrder = ({ dispatch, state, commit }, orderIds) => {
    return axios.patch(`/api/todos/updateOrder`, orderIds).then((response) => {
        if(response.data.todoable_type === 'issue') {
            dispatch('issues/fetchIssue', response.data.todoable_id, {root: true})
        }
    })
}


export const deleteTodo = ({ state, dispatch, commit }, {id, message}) => {
    return axios.delete(`/api/todos/${id}`).then((response) => {
        commit('REMOVE_TODO', id)
        commit('SET_TODO_DIALOG', false)
        Bus.$emit('todo:deleted');
        Bus.$emit('showAlert', {color : 'success', 'message' : message});
    })
}

export const setCreateTodoDialog = ({ dispatch, state, commit }, {value, endpoint}) => {
    commit('SET_CREATE_TODO_ENDPOINT', endpoint)
    commit('SET_CREATE_TODO_DIALOG', value)
}

export const fetchTodoDateLimits = ({ commit }, endpoint) => {
    return axios.get(`${endpoint}/dateLimits`).then((response) => {
        commit('SET_TODO_DATE_LIMITS', response.data)
    })
}

export const setTodoDialogWidth = ({ dispatch, commit }, value) => {
    commit('SET_TODO_DIALOG_WIDTH', value)
}

export const setSelectedTab = ({ dispatch, state, commit}, value) => {
    commit('SET_SELECTED_TAB', value)
}
