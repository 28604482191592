<template>
    <v-tabs
    >
        <v-tab href="#profile">{{ $lang('profile')}}</v-tab>
        <v-tab href="#avatar" >{{ $lang('profile image')}}</v-tab>
        <v-tab href="#userSettings" >{{ $lang('settings')}}</v-tab>
        <v-tab href="#password">{{ $lang('password')}}</v-tab>

        <v-tab-item value="profile" >
            <Profile></Profile>
        </v-tab-item>

        <v-tab-item value="avatar">
            <Avatar></Avatar>
        </v-tab-item>

        <v-tab-item value="userSettings">
            <Settings></Settings>
        </v-tab-item>

        <v-tab-item value="password">
            <ChangePassword></ChangePassword>
        </v-tab-item>

    </v-tabs>
</template>

<script>
    import Avatar from "./Avatar";
    import Profile from "./Profile";
    import ChangePassword from "./ChangePassword";
    import Settings from "./Settings";

    export default {
        name: "AccountTabs",

        components: {
            Settings,
            ChangePassword,
            Profile,
            Avatar
        }
    }
</script>

<style scoped>

</style>
