<template>
    <div
        v-if="kanbanCircuit"
    >

        <v-expansion-panels
        class="mt-2"
        >
            <v-expansion-panel
            >
                <v-expansion-panel-header>
                    {{$lang('info')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content>{{$lang('kanban circuit')}}:</v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{ kanbanCircuit.name }}
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{$lang('kanban circuit number')}}:</v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{ kanbanCircuit.number }}
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{$lang('part number')}}:</v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{ kanbanCircuit.part_number }}
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{$lang('stock')}}::</v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{ kanbanCircuit.stock }}
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>{{$lang('supplier')}}:</v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{ kanbanCircuit.supplier.name }}
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>

                            <v-list-item-content class="align-end">
                                <v-img
                                    max-width="352"
                                    v-ripple v-if="kanbanCircuit.image_url" class="mb-3" style="height: 300px; min-width: 300px; width: 300px;">
                                    <img :src="kanbanCircuit.image_url" alt="avatar">
                                </v-img>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
                <v-expansion-panel-header>
                    {{$lang('log')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card
                        v-for="log in kanbanCircuit.activity_logs"
                        class="mb-1 pt-1 pb-1"
                        :color="log.background_color"
                    >
                        <v-card-text
                            class="pt-1 pb-1"
                        >
                            <v-row>
                                <v-col
                                    class="pt-2 pb-2"
                                >
                                    <span v-html="log.activity_type_label"></span>
                                    <br>
                                    <strong>
                                        <span v-html="log.quantity_with_unit"></span>
                                    </strong>

                                </v-col>
                                <v-col
                                    class="pt-2 pb-2"
                                    align="right"
                                >
                                    {{log.created_at_for_humans}}
                                    <br>
                                    {{log.causer_name}}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
            v-if="_.includes(me.permissions, 'kanban goods withdrawal')"
            class="mt-4"
            color="primary"
            outlined
            block
            @click="goodsWithdrawalDialog = true"
        >
            {{$lang('goods withdrawal')}}
        </v-btn>
        <v-btn
            v-if="shippedKanbanOrders.length && _.includes(me.permissions, 'kanban post goods receipt')"
            class="mt-4"
            color="primary"
            outlined
            block
            @click="goodsReceiptDialog = true"
        >
            {{$lang('goods receipt')}}
        </v-btn>

        <v-dialog
            v-model="goodsWithdrawalDialog"
            :fullscreen="$vuetify.breakpoint.mobile"
            @click:outside="goodsWithdrawalDialog = false"
            width="500"
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{ $lang('goods withdrawal')}}
                </v-card-title>
                <v-card-text>
                    <v-alert
                        dense
                        class="mt-2"
                        v-if="remainingStock <= 0"
                        type="error"
                    >
                        {{ $lang('stock is 0')}}
                    </v-alert>
                    <span
                        class="mt-2"
                    >
                         <strong>
                            {{$lang('remaining stock')}}: {{remainingStock}}
                        </strong>
                    </span>

                    <v-row
                    class="pt-10"
                    >
                        <v-col>
                            <v-text-field
                                v-model="quantity"
                                :disabled="loading"
                            >

                            </v-text-field>
                        </v-col>

                        <v-col>
                            <v-btn
                                class="mt-2"
                                color="primary"
                                @click="quantity += remainingStock >= kanbanCircuit.withdrawal_lot_size ? kanbanCircuit.withdrawal_lot_size : 1"
                                block
                                :disabled="loading || quantity >= kanbanCircuit.stock"

                                style="touch-action: none"
                            >
                                plus
                            </v-btn>
                        </v-col>
                        <v-col>

                            <v-btn
                                class="mt-2"
                                color="primary"
                                @click="quantity -= 1"
                                block
                                :disabled="loading || quantity <= 0 "
                                style="touch-action: none"
                            >
                                minus
                            </v-btn>
                        </v-col>

                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="goodsWithdrawal"
                                class="mt-2"
                                block
                                :disabled="loading || quantity <= 0 || remainingStock < 0 || disablePostGoodsWithdrwal"
                            >
                                {{$lang('post goods withdrawal')}}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="secondary"
                                @click="goodsWithdrawalDialog = false"
                                class="mt-2"
                                block
                                :disabled="loading"
                            >
                                {{$lang('cancel')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="goodsReceiptDialog"
            :fullscreen="$vuetify.breakpoint.mobile"
            @click:outside="goodsReceiptDialog = false"
            width="500"
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{ $lang('post goods receipt')}}
                </v-card-title>
                <v-card-text>
                    <v-btn
                        class="mt-2"
                        v-for="order in shippedKanbanOrders "
                        block
                        color="success"
                        @click="goodsReceiptOrder = order"
                        :outlined="goodsReceiptOrder !== order"
                    >
                        {{$lang('no.')}} {{order.order_number}} - {{order.remaining_quantity}} / {{order.quantity}} {{order.unit}}

                    </v-btn>

                    <template
                        v-if="goodsReceiptOrder && (kanbanCircuit.allow_over_delivery || kanbanCircuit.allow_under_delivery)"
                    >
                        <v-row
                            class="pt-10"
                        >
                            <v-col>
                                <v-text-field
                                    v-model="goodsReceiptOrder.received_quantity"
                                    :disabled="loading"
                                    :error-messages="errors.received_quantity"
                                >

                                </v-text-field>
                            </v-col>

                            <v-col>
                                <v-btn
                                    class="mt-2"
                                    color="primary"
                                    @click="goodsReceiptOrder.received_quantity +=  1"
                                    block
                                    :disabled="disableGoodsReceiptPlusButton"
                                    style="touch-action: none"
                                >
                                    plus
                                </v-btn>
                            </v-col>
                            <v-col>

                                <v-btn
                                    class="mt-2"
                                    color="primary"
                                    @click="goodsReceiptOrder.received_quantity -= 1"
                                    :disabled="disableGoodsReceiptMinusButton"
                                    block
                                    style="touch-action: none"
                                >
                                    minus
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-if="goodsReceiptOrder.received_quantity < goodsReceiptOrder.remaining_quantity"
                                    v-model="closeOrder"
                                    :label="$lang('close order')"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </template>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="postGoodsReceipt"
                                class="mt-2"
                                block
                                :disabled="loading || !goodsReceiptOrder"
                            >
                                {{$lang('post goods receipt')}} <span v-if="goodsReceiptOrder">&nbsp; {{goodsReceiptOrder.received_quantity}} {{goodsReceiptOrder.unit}}</span>
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="secondary"
                                @click="goodsReceiptDialog = false"
                                class="mt-2"
                                block
                                :disabled="loading"
                            >
                                {{$lang('cancel')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Bus from "../../../../bus";

export default {
name: "ScanKanbanCard",
    data () {
        return {
            loading: false,
            disablePostGoodsWithdrwal: false,
            goodsWithdrawalDialog: false,
            goodsReceiptDialog: false,
            quantity: 0,
            received_quantity: 0,
            goodsReceiptOrder: null,
            errors:[],
            closeOrder: false
        }
    },

    computed: {
        ...mapGetters({
            me: 'auth/user',
            scanString: 'scanner/scanString',
            kanbanCircuit: 'procurementKanban/kanbanCircuit'
        }),

        shippedKanbanOrders: function () {
            return _.filter(this.kanbanCircuit.kanban_orders, function(o) {
                return o.status === 'shipped' || o.status === 'partial delivery';
            });
        },

        remainingStock: function () {
            return this.kanbanCircuit.stock - parseFloat(this.received_quantity)
        },

        disableGoodsReceiptPlusButton: function () {
            if(
                this.goodsReceiptOrder.received_quantity >= this.goodsReceiptOrder.quantity &&
                !this.kanbanCircuit.allow_over_delivery
            ) {
                return true
            }
        },

        disableGoodsReceiptMinusButton: function () {
            if(
                this.goodsReceiptOrder.received_quantity <= this.goodsReceiptOrder.quantity &&
                !this.kanbanCircuit.allow_under_delivery
            ) {
                return true
            }
            return this.goodsReceiptOrder.received_quantity  <= 0
        }
    },

    methods: {
        ...mapActions({
            fetchKanbanCircuit: 'procurementKanban/fetchKanbanCircuit',
            submitGoodsWithdrawal: 'procurementKanban/postGoodsWithdrawal',
            postGoods: 'procurementKanban/postGoodsReceipt'
        }),

        goodsWithdrawal() {
            this.loading = true
            const self = this
            this.submitGoodsWithdrawal({
                kanbanCircuitId: this.kanbanCircuit.id,
                payload: {
                    quantity: this.quantity
                }
            }).then(function (){
                self.fetchKanbanCircuit(self.scanString.kanbanCircuitId)
                Bus.$emit('showAlert', {color: 'success', 'message': `${self.quantity} ${self.kanbanCircuit.unit} ${self.$lang( 'taken')}` });
                self.quantity = 0
                self.removePartDialog = false
                self.loading = false
            })
        },

        postGoodsReceipt() {
            this.errors = []
            this.loading = true
            const self = this
            this.postGoods({
                id: this.goodsReceiptOrder.id,
                received_quantity: this.goodsReceiptOrder.received_quantity,
                close_order: this.closeOrder,
                context: this
            }).then(function (){
                self.loading = false
                if(self.errors.length === 0) {
                    self.fetchKanbanCircuit(self.scanString.kanbanCircuitId)
                    Bus.$emit('showAlert', {
                        color: 'success',
                        'message': `${self.goodsReceiptOrder.received_quantity} ${self.kanbanCircuit.unit} ${self.$lang('posted')}`
                    });
                    self.quantity = 0
                    self.goodsReceiptDialog = false
                    self.goodsReceiptOrder = null
                }
            })
        }
    },

    mounted() {
        this.fetchKanbanCircuit(this.scanString.kanbanCircuitId)

        var channel = this.$pusher.subscribe(`kanbanCircuit.${this.me.company.id}`)

        channel.bind('updateKanbanCircuitItem', (data) => {
            this.fetchKanbanCircuit(this.scanString.kanbanCircuitId)
        });
    },

    watch: {
        'goodsReceiptOrder.received_quantity': function () {
            if(this.goodsReceiptOrder) {
                this.goodsReceiptOrder.received_quantity = parseFloat(this.goodsReceiptOrder.received_quantity)
            }
        },

        quantity: function (quantity) {
            this.received_quantity = parseFloat(quantity)
        }

    },
}
</script>

<style scoped>

</style>
