<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>

</template>

<script>
    import Bus from "../../../../bus";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "SolexesTable",
        data() {
            return {
                loading: true,
                datatableExists: true,
                defaultState: null,
                enableSelect: false,
            }
        },

        props: {
            endpoint: {
                type: String
            },
            tableId: {
                type: String
            },
            height: {
                type: Number,
                default: 600
            }
        },

        computed: {
            ...mapGetters({
                solex: 'solexes/solex',
                user: 'auth/user',
                showSolexDialog: 'solexes/showSolexDialog',
                activeFilters: 'tableFilters/activeFilters'
            })
        },

        methods: {
            ...mapActions({
                fetchSolex: 'solexes/fetchSolex',
                actionShowSolexDialog: 'solexes/setSolexDialog',
                fetchIssue: 'issues/fetchIssue',
                setIssueDialog: 'issues/setIssueDialog',
                setSelectedProjectTab: 'projects/setSelectedTab'
            }),
            loadDatatable() {
                const self = this
                if(this.datatableExists) {
                    axios.get(this.endpoint).then(function (response){
                        self.webixId.parse(webix.copy(response.data))
                    })
                }
            }
        },

        mounted() {
            const self = this

            this.datatableExists = true

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: window.innerHeight - 200,
                id: self.tableId,
                headermenu:{
                    width:250,
                },
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                headerRowHeight:40,
                footer:false,
                columns: [

                    {
                        id: "solex_number",
                        header: [self.$lang('no.'),{content: "textFilter"}],
                        width: 50,
                        sort: "int"
                    },

                    {
                        id: "status_label",
                        header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 50,
                        adjust: false,
                        sort: "string",
                        css: {'text-align': 'center'},
                        template: function (obj) {
                            return obj.status_label
                        }
                    },

                    {
                        id: "title",
                        header: [self.$lang('title'), {content: "textFilter"}],
                        footer: self.$lang('total'),
                        fillspace: true,
                        minWidth: 200,
                        sort: "string",
                        template: "#title_link#"
                    },

                    {
                        id: "assignee_label",
                        header: [self.$lang('responsible'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 200}
                        }],
                        minWidth: 150,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.assignee_label
                        }
                    },

                    {
                        id: "user_labels",
                        header: [self.$lang('team'), {
                            content: "textFilter",
                        }],
                        minWidth: 150,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.user_labels
                        }
                    },

                    {
                        id: "creator_name",
                        header: [self.$lang('creator'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 200}
                            }
                        ],
                        adjust: false,
                        sort: "string"
                    },

                    {
                        id: "created_at",
                        map: "(date)#created_at#",
                        header: [self.$lang('created'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }
                    },

                    {
                        id: "issue_link",
                        header: [self.$lang('issue'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string",
                        template: "#issue_link#"
                    },

                    {
                        id: "issue_created_at",
                        map: "(date)#issue_created_at#",
                        header: [self.$lang('created'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.issue_created_at)
                        }
                    },

                    {
                        id: "project_link",
                        header: [self.$lang('project'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string",
                        template: "#project_link#"
                    },
                ],
                onClick:{
                    "title_link" : function  (event, row, target) {
                        self.fetchSolex(row.row).then(function () {
                            self.actionShowSolexDialog(true)
                        })
                    },
                    "issue_link" : function  (event, row, target) {
                        let solex = this.getItem(row.row)
                        self.fetchIssue(solex.issue_id).then(function () {
                            self.setIssueDialog(true)
                        })
                    },

                    "project_link" : function  (event, row, target) {
                        let solex = this.getItem(row.row)
                        self.fetchSolex(row.row).then(function () {
                            self.setSelectedProjectTab('solex')
                            self.$router.push({name: 'projects.show', params: {projectId: solex.project_id}}).catch(()=>{});
                        })

                    }
                },
                on: {
                    onSelectChange: function(){
                        if (self.enableSelect) {
                            self.fetchSingleSolex(this.getSelectedId(true))
                        }
                    },

                    onItemDblClick: function (row) {
                        self.fetchSingleSolex(row.row).then(function () {
                            self.actionShowSolexDialog(true)
                        })
                    },

                    onBeforeLoad() {
                        if(!self.defaultState) {
                            self.defaultState = this.getState()
                        }
                    },

                    onAfterLoad: function () {
                        self.loading = false
                        if (self.tableId in self.activeFilters) {
                            this.setState(JSON.parse(self.activeFilters[self.tableId].filter_data))
                        }

                        if (self.solex && this.exists(self.solex.id)) {
                            this.select(self.solex.id)
                            this.showItem(self.solex.id)
                        }
                    },

                    onAfterDrop: function () {
                        let orderedIds = []
                        this.eachRow(function(row){
                            orderedIds.push(row)
                        });
                        self.updateOrder({orderedIds: orderedIds}).then(function (){
                            Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('order successfully changed')});
                        })
                    }
                },
            })

            Bus.$on('solex:updated', function () {
                this.enableSelect = false
                this.loadDatatable()
                this.enableSelect = true
            });

            Bus.$on('solex:created', function () {
                this.loadDatatable()
            });

            Bus.$on('solex:deleted', function (id) {
                this.loadDatatable()
            });

            Bus.$on('issue:created', function () {
                this.loadDatatable()
            });

            Bus.$on('issue:deleted', function () {
                this.loadDatatable()
            });

            Bus.$on('filterTable:' + self.tableId, function (data) {
                this.loadDatatable()
            });

            Bus.$on('resetFilterTable:' + self.tableId, function () {
                this.loadDatatable()
            });

            this.loadDatatable()
        },

        destroyed:function(){
            this.datatableExists = false
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style scoped>

</style>
