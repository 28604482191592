<template>
    <v-col
        cols="12"
        md="5"
    >
        <v-alert
            v-if="errorAlert"
            dense
            text
            type="error"
        >
            >
            <strong>{{ projectType.name}}</strong> {{ $lang('cannot be deleted')}}.
        </v-alert>
        <template v-if="projectType.count_projects > 0">
            <v-card
                class="mt-2 text--white"
                outlined
                color="red darken-4"
            >
                <v-card-text
                    class="white--text"
                >
                    <h4>
                        {{ $lang('the project type cannot be deleted because there are connections to projects.' )}}
                    </h4>
                </v-card-text>
            </v-card>
        </template>
        <template v-else>
            <v-card>
                <v-card-title>
                    {{ $lang('do you really want to delete this project type?')}}
                </v-card-title>
                <v-card-text>
                    <v-switch
                        v-model="confirmDelete"
                        :label="$lang('yes, please delete!')"
                    ></v-switch>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="deleteProjectType" v-if="confirmDelete">{{ $lang('delete') }}</v-btn>
                </v-card-actions>
            </v-card>

        </template>
    </v-col>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "DeleteProjectType",

        data() {
            return {
                error: false,
                errorAlert: false,
                confirmDelete: false,
            }
        },

        computed: {
            ...mapGetters({
                projectType: 'projectTypes/projectType',
            }),
        },

        methods: {
            ...mapActions({
                destroy: 'projectTypes/destroy'
            }),
            deleteProjectType () {

                this.destroy({
                    id: this.projectType.id,
                    context: this
                }).then( ()  => {
                    if(!this.error) {
                        Bus.$emit('showAlert', {color : 'success', 'message' : this.$lang('project type successfully deleted')});
                        this.$router.push({name: 'projectTypes'});
                    }
                    else {
                        Bus.$emit('showAlert', {color : 'error', 'message' : this.$lang('project type successfully changed')});
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
