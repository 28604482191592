<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../bus";

    export default {
        name: "TodosTable",

        data() {
            return {
                loading: true,
                enableSelect: false,
                datatableExists: true,
                defaultState: null
            }
        },

        props : {
            endpoint: String,
            tableId:  [String, Number],
            dragRows: Boolean,
            subTodo: {
                type: Boolean,
                default: false
            },
            dialog: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            ...mapGetters({
                todo: 'todos/todo',
                todos: 'todos/todos',
                issue:'issues/issue',
                issueDialogWidth: 'issues/issueDialogWidth',
                todoDialogWidth: 'todos/todoDialogWidth',
                activeFilters: 'tableFilters/activeFilters'
            })
        },

        methods: {
            ...mapActions({
                fetchTodo: 'todos/fetchTodo',
                actionShowTodoDialog: 'todos/setTodoDialog',
                setTodo: 'todos/setTodo',
                updateOrder: 'todos/updateOrder',
                fetchIssue: 'issues/fetchIssue',
                setIssueDialog: 'issues/setIssueDialog',
                fetchProject: 'projects/fetchProject'
            }),

            filterTable(filter) {
                if(filter === 'all') {
                    this.webixId.filter(function(obj){
                        return true
                    });
                }

                if(filter === 'open') {
                    this.webixId.filter(function(obj){
                        return !obj.closed_at
                    });
                }

                if(filter === 'closed') {
                    this.webixId.filter(function(obj){
                        return obj.closed_at
                    });
                }
            },

            loadDatatable() {
                const self = this
                if(this.datatableExists) {
                    axios.get(this.endpoint).then(function (response){
                        self.webixId.parse(webix.copy(response.data))
                    })
                }
            }
        },

        mounted() {
            const self = this

            const endpoint = this.endpoint

            this.datatableExists = true

            this.webixId = webix.ui({
                container: this.$el,
                $scope: this,
                view:"datatable",
                height: window.innerHeight - 200,
                id: self.tableId,
                headermenu:{
                    width:250,
                },
                resizeColumn: true,
                select: "row",
                dragColumn:true,
                drag:self.dragRows,
                headerRowHeight:40,
                columns: [

                    {id: "todo_number", header: [self.$lang('no.'), {content: "textFilter"}], width: 50, sort: "int"},

                    {
                        id: "title",
                        header: [self.$lang('title'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string",
                        template: "#title_link#"
                    },

                    {
                        id: "todoable_link",
                        header: [self.$lang('issue'), {content: "textFilter"}],
                        fillspace: true,
                        minWidth: 200,
                        sort: "string",
                    },

                    {
                        id: "assignee_label",
                        header: [self.$lang('responsible'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 250}
                        }],
                        minWidth: 150,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.assignee_label
                        }
                    },


                    {
                        id: "progress",
                        header: [self.$lang('progress'), {content: "textFilter"}],
                        sort: "string",
                        adjust: false,
                        css: {'text-align': 'center'},
                        template: function (obj) {
                            return obj.progress_bar
                        }
                    },

                    {
                        id: "due_date_status_label",
                        header: [self.$lang('status'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 200}}],
                        minWidth: 50,
                        adjust: false,
                        sort: "string",
                        css: {'text-align': 'center'},
                        template: function (obj) {
                            return obj.due_date_status_label
                        }
                    },

                    {
                        id: "start_date",
                        map: "(date)#start_date#",
                        header: [self.$lang('start'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.start_date)
                        }
                    },

                    {
                        id: "end_date",
                        map: "(date)#end_date#",
                        header: [self.$lang('end'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.end_date)
                        }
                    },

                    {
                        id: "closed_at",
                        map: "(date)#closed_at#",
                        header: [self.$lang('closed'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.closed_at)
                        }
                    },

                    {
                        id: "closer_label",
                        header: [self.$lang('closed'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 200}
                        }],
                        minWidth: 150,
                        adjust: false,
                        sort: "string",
                        template: function (obj) {
                            return obj.closer_label
                        }
                    },
                    {
                        id: "priority_label",
                        header: [self.$lang('prio'), {
                            content: "multiSelectFilter",
                            suggest: {fitMaster: false, width: 100}
                        }],
                        minWidth: 70,
                        adjust: false,
                        css: {'text-align': 'center'},
                        sort: "string",
                    },
                    {
                        id: "creator_name",
                        header: [self.$lang('creator'), {content: "multiSelectFilter", suggest: {fitMaster: false, width: 100}}],
                        adjust: false,
                        sort: "string"
                    },
                    {
                        id: "created_at",
                        map: "(date)#created_at#",
                        header: [self.$lang('created'), {content: "dateRangeFilter"}],
                        sort: "date",
                        adjust: false,
                        template: function (obj) {
                            return webix.i18n.parseFormatStr(obj.created_at)
                        }
                    }
                ],
                onClick:{
                    "title_link" : function  (event, row, target) {
                        self.fetchTodo(row.row).then(function () {
                            self.fetchIssue(self.todo.todoable_id).then(function () {
                                self.fetchProject(self.issue.issueable_id).then(function (){
                                    self.actionShowTodoDialog(true)
                                })
                            })
                        })
                    },
                    "todoable_link" : function  (event, row, target) {
                        let todo = this.getItem(row.row)
                        self.fetchIssue(todo.todoable_id).then(function () {
                            self.fetchProject(self.issue.issueable_id).then(function (){
                                self.setIssueDialog(true)
                            })
                        })
                    }
                },
                on: {
                    onSelectChange: function(){
                        self.fetchTodo(this.getSelectedId(true))
                    },

                    onItemDblClick: function (row) {
                        self.fetchTodo(this.getSelectedId(true)).then(function () {
                            self.fetchIssue(self.todo.todoable_id).then(function () {
                                self.fetchProject(self.issue.issueable_id).then(function (){
                                    self.actionShowTodoDialog(true)
                                })
                            })
                        })
                    },

                    onAfterLoad: function () {
                        self.loading = false
                        self.filterTable(self.todosFilter)

                        if(!self.defaultState) {
                            self.defaultState = this.getState()
                        }

                        if(self.tableId in self.activeFilters ) {
                            this.setState(JSON.parse(self.activeFilters[self.tableId].filter_data))
                        }

                        if (self.todo && this.exists(self.todo.id)) {
                            this.select(self.todo.id)
                            this.showItem(self.todo.id)
                        }

                        if(self.dialog) {
                            this.define("width", self.issueDialogWidth - 2);
                            this.resize();
                        }
                    },


                    onAfterDrop: function () {
                        let orderedIds = []
                        this.eachRow(function(row){
                            orderedIds.push(row)
                        });
                        self.updateOrder({orderedIds: orderedIds}).then(function (){
                            Bus.$emit('showAlert', {color : 'success', 'message' : self.$lang('order successfully changed')});
                        })
                    }
                },
            })

            Bus.$on('project:updated', function () {
                self.loadDatatable()
            });

            Bus.$on('gantt:updated', function () {
                self.loadDatatable()
            });

            Bus.$on('issue:updated', function () {
                self.loadDatatable()
            });

            Bus.$on('issue:created', function () {
                self.loadDatatable()
            });

            Bus.$on('todo:updated', function () {
                self.loadDatatable()
            });

            Bus.$on('todo:created', function () {
                self.loadDatatable()
            });

            Bus.$on('todo:deleted', function (id) {
                if(self.datatableExists) {
                    self.webixId.clearAll()
                    self.loadDatatable()
                }
            });

            Bus.$on('filterTable:' + self.tableId, function (data) {

                self.filter = data
                if(self.datatableExists) {
                    if(!self.defaultState) {
                        self.defaultState = $$(self.webixId).getState()
                    }
                    $$(self.webixId).setState(JSON.parse(data))
                }
            });

            Bus.$on('resetFilterTable:' + self.tableId, function () {
                if(self.datatableExists) {
                    $$(self.webixId).setState(self.defaultState)
                }
            });

            this.loadDatatable()
        },

        watch: {
            endpoint(endpoint) {
                this.webixId.clearAll()
                this.loadDatatable()
            },

            todosFilter (filter) {
                this.filterTable(filter)
            },

            issueDialogWidth (value) {
                this.webixId.define("width", value -2);
                this.webixId.resize();
            },

            todoDialogWidth (value) {
                if(this.subTodo) {
                    this.webixId.define("width", value -2);
                    this.webixId.resize();
                }
            },

        },

        destroyed:function(){
            this.datatableExists = false
            webix.$$(this.webixId).destructor();
        }
    }
</script>

<style>
progress[value] {
    -webkit-appearance: none;
    appearance: none;

    width: 120px;
    height: 15px;
}
progress[value]::-webkit-progress-bar {
    background-color: lightgrey !important;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
    border-radius: 2px;
    background-color: rgba(30, 136, 229, 0.8) !important;
}
</style>
