<template>
    <v-col
        cols="12"
        md="5"
    >
        <v-card class="mt-2">
            <v-card-text>
                <v-container>
                    <v-alert
                        v-if="formAlert"
                        dense
                        text
                        type="success"
                    >
                        {{ $lang('department successfully changed')}}
                    </v-alert>
                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-select
                            v-model="form.division_id"
                            :items="divisions"
                            :label="$lang('division')"
                            item-text="name_with_parent_name"
                            item-value="id"
                            :error-messages="errors.division_id"
                        ></v-select>
                        <v-color-picker class="ma-2"
                            v-model="form.color"
                            :label="$lang('color')"
                            show-swatches
                            hide-canvas
                            hide-inputs
                            width="400"
                        >
                        </v-color-picker>

                        <v-switch
                            v-model="form.active"
                            :label="$lang('active')"
                        ></v-switch>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="reset">{{ $lang('reset') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="loading">{{ $lang('save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "EditDepartment",

        props: [
            'departmentId'
        ],

        data() {
            return {
                loading: false,
                formAlert: false,
                errors: [],
                form: {
                    name: '',
                    division_id: '',
                    color: '',
                    active: ''
                }
            }
        },

        computed: {
            ...mapGetters({
                department: 'departments/department',
                divisions: 'divisions/divisions',
            }),
        },

        methods: {
            ...mapActions({
                update: 'departments/update',
                fetchDivisions: 'divisions/fetchDivisions',
            }),

            save () {
                this.loading = true
                this.errors = []
                this.update({
                    payload: this.form,
                    context: this
                }).then(() => {
                    this.loading = false
                    if(this.errors.length === 0) {
                        this.showAlert()
                    }
                })
            },

            reset() {
                this.form.name = this.department.name
            },

            showAlert() {
                this.formAlert = true
                setTimeout(this.hideAlert, 3000)
            },

            hideAlert() {
                this.formAlert = false
            }
        },

        watch: {
            department: function (value) {
                this.form = this.department
            }
        },
        mounted() {
            this.fetchDivisions()
            this.form = this.department
        }
    }
</script>

<style scoped>

</style>
