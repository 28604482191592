import Bus from "../../../bus";
import router from "../../../router";

export const fetchNotifications = ({ commit }) => {
    return axios.get('/api/users/notifications').then((response) => {
        commit('SET_NOTIFICATIONS_DATA', response.data)
    })
}

export const getNotification = ({ commit }, id) => {
    return axios.get(`/api/notifications/${id}`).then((response) => {
        commit('setNotificationData', response.data)
    })
}

export const store = ({ dispatch, state, commit }, { endpoint, payload, context }) => {
    return axios.post(endpoint, payload).then((response) => {
        dispatch('getNotifications')
        commit('setNotificationData', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const update = ({ dispatch, state, commit }, { id,  payload, context }) => {
    return axios.patch(`/api/notifications/${id}`, payload).then((response) => {
        dispatch('getNotifications')
        commit('setNotificationData', response.data)
    }).catch((error) => {
        context.errors = error.response.data.errors
    })
}

export const deleteReadNotifications = ({ state, dispatch, commit }) => {
    return axios.patch(`/api/notifications/deleteReadNotifications`).then((response) => {
        dispatch('fetchNotifications')
    })
}


export const deleteNotification = ({ state, dispatch, commit }, id) => {
    return axios.delete(`/api/notifications/${id}`).then((response) => {
        dispatch('getNotifications')
        commit('SET_SHOW_NOTE_DIALOG', false)
        Bus.$emit('showAlert', {color : 'success', 'message' : `Notiz <strong>${state.notification.title} </strong> gelöscht!`});
    })
}

export const markAsReadNotification = ({ state, dispatch, commit }, id) => {
    return axios.patch(`/api/notifications/${id}/markAsRead`).then((response) => {
        dispatch('fetchNotifications')
    })
}

export const markAsUnreadNotification = ({ state, dispatch, commit }, id) => {
    return axios.patch(`/api/notifications/${id}/markAsUnread`).then((response) => {
        dispatch('fetchNotifications')
        commit('setNotificationData', response.data)
    })
}
export const setNotificationsFilter= ({ dispatch, commit }, value) => {
    commit('SET_NOTES_FILTER', value)
}

export const gotoNotificationObject = ({ rootState, dispatch, commit }, notification) => {
    if(notification.object_type === 'project') {
        router.push({name: 'projects.show', params: {projectId: notification.object_id}}).catch(()=>{})
    }
    else if (notification.object_type === 'issue'){
        dispatch('issues/fetchIssue', notification.object_id, {root: true} ).then(function (){
            router.push({name: 'projects.show', params: {projectId: rootState.issues.issue.issueable_id}}).catch(()=>{})
            dispatch('projects/setSelectedTab', 'issues', {root: true}  )
            dispatch('issues/setIssueDialog', true, {root: true} )
        })
    }
    else if (notification.object_type === 'todo'){
        dispatch('todos/fetchTodo', notification.object_id, {root: true} ).then(function (){
            dispatch('issues/fetchIssue', rootState.todos.todo.todoable_id, {root: true} ).then(function (){
                router.push({name: 'projects.show', params: {projectId: rootState.issues.issue.issueable_id}}).catch(()=>{})
                dispatch('projects/setSelectedTab', 'issues', {root: true}  )
                dispatch('issues/setIssueDialog', true, {root: true} ).then(function (){
                    dispatch('todos/setTodoDialog', true, {root: true} )
                })

            })
        })
    }
    else if (notification.object_type === 'solex'){
        dispatch('solexes/fetchSolex', notification.object_id, {root: true} ).then(function (){

                dispatch('projects/fetchProject', rootState.solexes.solex.project_id, {root: true}  ).then(function (){
                    dispatch('solexes/setSolexDialog', true, {root: true} )
                    router.push({name: 'projects.show', params: {projectId: rootState.solexes.solex.project_id}})
                })

        })
    }
}

