export const setNotesData = (state, data) => {
    state.notes = data
}

export const pushNotesData = (state, data) => {
    state.notes.push(data)
}

export const UPDATE_NOTES_DATA = (state, data) => {
    Vue.set(state.notes, state.notes.findIndex(notes => notes.id === data.id), data)
}

export const setNoteData = (state, data) => {
    state.note = data
}

export const SET_SHOW_NOTE_DIALOG = (state, trueOrFalse) => {
    state.showNoteDialog = trueOrFalse
}

export const SET_NOTES_FILTER = (state, value) => {
    state.notesFilter = value
}
