import { Workplaces, Workplace } from '../views'
import {Project} from "../../../projects/views";

export default [
    {
        path: '/masterData/workplaces',
        component: Workplaces,
        name: 'workplaces',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
    {
        path: '/masterData/workplaces/:workplaceId/show',
        component: Workplace,
        name: 'workplaces.show',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true
    },
]

