<template>
    <v-col
        cols="12"
        md="5"
    >
        <v-card class="mt-2">
            <v-card-text>
                <v-container>
                    <v-alert
                        v-if="formAlert"
                        dense
                        text
                        type="success"
                    >
                        {{ $lang('cost center successfully changed')}}
                    </v-alert>
                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    <v-form
                        ref="form"
                    >
                        <v-text-field
                            v-model="form.name"
                            :label="$lang('name')"
                            :error-messages="errors.name"
                            autofocus
                        ></v-text-field>
                        <v-text-field
                            v-model="form.number"
                            :label="$lang('number')"
                            :error-messages="errors.number"
                        ></v-text-field>
                        <v-switch
                            v-model="form.active"
                            :label="$lang('active')"
                        ></v-switch>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="reset">{{ $lang('reset') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="loading">{{ $lang('save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Bus from "../../../../bus";

    export default {
        name: "EditCostCenter",

        props: [
            'costCenterId'
        ],

        data() {
            return {
                loading: false,
                formAlert: false,
                errors: [],
                form: {
                    name: '',
                    number: '',
                    active: ''
                }
            }
        },

        computed: {
            ...mapGetters({
                costCenter: 'costCenters/costCenter',
            }),
        },

        methods: {
            ...mapActions({
                update: 'costCenters/update',
                fetchCostCenter: 'costCenters/fetchCostCenters',
            }),

            save () {
                this.loading = true
                this.errors = []
                this.update({
                    payload: this.form,
                    context: this
                }).then(() => {
                    this.loading = false
                    if(this.errors.length === 0) {
                        this.showAlert()
                    }
                })
            },

            reset() {
                this.form.name = this.costCenter.name
            },

            showAlert() {
                this.formAlert = true
                setTimeout(this.hideAlert, 3000)
            },

            hideAlert() {
                this.formAlert = false
            }
        },
        watch: {
            costCenter: function (value) {
                this.form = this.costCenter
            }
        },


        mounted() {
            this.form = this.costCenter
        }
    }
</script>

<style scoped>

</style>
