<template>
    <div>
        <v-toolbar flat color="white">
            <v-row>
                <v-col
                    cols="12"
                    md="6"
                    >
                    <v-toolbar-title>   {{$lang('kanban export')}}</v-toolbar-title>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    class="text-right"
                >
                    <TableFilterDropDown table-id="KanbanCircuitsExportTable"></TableFilterDropDown>
                </v-col>
            </v-row>

        </v-toolbar>
        <v-row>
            <v-col
                cols="12"
                md="10"
                >
                <KanbanCircuitsExportTable></KanbanCircuitsExportTable>

            </v-col>
            <v-col
                cols="12"
                md="2"
            >
                <v-date-picker
                    v-model="range"
                    :max="new Date().toISOString()"
                    no-title
                    range
                    :first-day-of-week="1"
                    @change="loadExportData"
                ></v-date-picker>

                <v-btn
                    outlined
                    color="primary"
                    small
                    block
                    @click="exportToExcel"
                >
                    {{$lang('excel export')}}
                </v-btn>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import {mapGetters} from "vuex";
import KanbanCircuitsExportTable from "../components/KanbanCircuitsExportTable";
import TableFilterDropDown from "../../../helpers/tableFilters/components/TableFilterDropDown";

export default {
name: "KanbanExport",
    components: {
        TableFilterDropDown,
        KanbanCircuitsExportTable
    },
    data () {
        return {
            range:  null
        }
    },

    computed: {
        ...mapGetters({
            me: 'auth/user',
        }),
    },

    methods: {
        loadExportData() {
            $$('KanbanCircuitsExportTable').clearAll()
            axios.get(`/api/procurement/kanbanExport/kanbanCircuitExports?range=${this.range}`).then(function (response) {
                $$('KanbanCircuitsExportTable').parse(webix.copy(response.data))
            })
        },

        exportToExcel() {
            webix.toExcel($$("KanbanCircuitsExportTable"), {
                filename: "Kanban Export "+ moment().format('DD_MM_YYYY_HH_mm')
            });

        }
    }


}
</script>

<style scoped>

</style>
