<template>
        <v-card-text
            class="pa-0"
            >
            <v-list dense
            v-if="product"
            >
                <v-list-item>
                    <v-list-item-content>{{ $lang('name')}}:</v-list-item-content>
                    <v-list-item-content class="align-end text-right"><span>{{ product.name }}</span></v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>{{ $lang('number')}}:</v-list-item-content>
                    <v-list-item-content class="align-end text-right"><span>{{ product.sku }}</span></v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>{{ $lang('current costs')}}:</v-list-item-content>
                    <v-list-item-content class="align-end text-right"><span>{{ product.current_manufacturing_costs }}</span></v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>{{ $lang('target costs')}}:</v-list-item-content>
                    <v-list-item-content class="align-end text-right"><span>{{ product.target_manufacturing_costs}}</span></v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>{{ $lang('quantity p.a.')}}:</v-list-item-content>
                    <v-list-item-content class="align-end text-right"><span>{{ product.production_amount_per_year }}</span></v-list-item-content>
                </v-list-item>


                <v-list-item>
                    <v-list-item-content>{{ $lang('responsible')}}:</v-list-item-content>
                    <v-list-item-content class="text-right"><span>{{ product.responsible.full_name}}</span></v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>{{ $lang('status')}}:</v-list-item-content>
                    <v-list-item-content class="align-end text-right"><span v-html="product.active_label"></span> </v-list-item-content>
                </v-list-item>

            </v-list>

        </v-card-text>


</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "ProductInfo",
        computed: {
            ...mapGetters({
                product: 'products/product'
            }),
        },

    }
</script>

<style>
    img {
        max-width: 60%;
        height: auto;
    }
</style>
