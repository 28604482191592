export default {
    solex: null,
    solexes: null,
    showSolexDialog: false,
    showCreateSolexDialog: false,
    createSolexEndpoint: null,
    solexDialogWidth: 1200,
    selectedTab: 'info',
    solexToIssue: null
}
