<template>
    <v-col>
        <div v-html="note.body" style="min-height: 200px">

        </div>
    </v-col>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "NoteInfo",
        computed: {
            ...mapGetters({
                note: 'notes/note'
            }),
        },

    }
</script>

<style>
    img {
        max-width: 60%;
        height: auto;
    }
</style>
